<div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'ALL_DRIVERS', pageCode: 'ALL', subPageCode: 'ASSIGN_DRIVER_GROUP' }">
<p-dialog [header]="header" [(visible)]="visibleDriverStatus" modal="true" (onHide)="onHide()" [style]="{ 'max-width': '100%', 'min-width': '40vw', 'height': 'auto' }"   [breakpoints]="{'960px': '80vw', '640px': '90vw'}">
    <div class="flex pb-3 driver-group">
        <span class="p-input-icon-left relative w-full">
            <i class="pi pi-search absolute"></i>
            <input type="text" pInputText class="w-full" (input)="onSearch($event, tableDriverGroupData)"
                [placeholder]="'lbl_search' | translate" />
        </span>
    </div>
    <div class="flex flex-column justify-content-between align-items-center pb-3">
        @if (tableDriverGroupData?.length > 0) {
            @for(data of tableDriverGroupData; track data; let i = $index) {
                <div class="w-full flex justify-content-between align-content-center separator pt-2 pb-2 cursor-pointer" (click)="selectGroupRadioButton(data)">
                    <div class="align-items-center flex">
                        <p-radioButton name="data" [value]="data" class="flex ml-2" [(ngModel)]="driverGroupStatus"></p-radioButton>
                        <label class="ml-2 cursor-pointer">{{data?.group_name}}</label>
                    </div>
                    <button pButton class="blue-text bg-white default-text-black-color border-1 border-black-alpha-20 px-3 py-1" (click)="selectDriverGroup(data)" [label]="'lbl_select_only' | translate"></button>
                </div>
            }
        }
        @else{
            <div class="w-full pt-0 pr-2 pb-0 pl-3">
                <app-no-result-found></app-no-result-found>
            </div>
        }       
    </div>
</p-dialog>
</div>