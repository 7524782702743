import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppIcons } from "../../../../shared/app.icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { QuickFilterFormComponent } from "../../../../shared/components/quick-filter-form/quick-filter-form.component";
import { FilterBoxComponent } from "../../../../shared/components/filter-box/filter-box.component";
import { InputTextModule } from "primeng/inputtext";
import { ManageColumnComponent } from "../../../../shared/components/manage-column/manage-column.component";
import { AppSettings } from "../../../../shared/app.settings";
import { MITableService } from "../../../../shared/components/mi-table/mitable.service";
import { Column } from "../../../../vehicles/models/attribute.models";
import { NgClass } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ConfigService } from "app/modules/shared/services/config.service";
import * as _ from "lodash";

@Component({
  selector: "app-filter-section",
  templateUrl: "./filter-section.component.html",
  styleUrls: ["./filter-section.component.scss"],
  standalone: true,
  imports: [
    TranslateModule,
    NgClass,
    InputTextModule,
    ButtonModule,
    QuickFilterFormComponent,
    FilterBoxComponent,
    ManageColumnComponent,
    FormsModule,
  ],
})
export class FilterSectionComponent implements OnInit {
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  @Output() onValueChange = new EventEmitter<any>();
  @Input() attributeLabels;
  @Input() filterList;
  miIcons = AppIcons;
  moduleName = AppSettings.ENTITY_CODE.BOOKING_ACTIVITY;
  show;
  position;
  selectedFilterCount;
  isModal: boolean = false;
  selectedColumns!: Column[];
  collectionOfColumns: any;
  columns;
  searchValue: string = "";
  advanceFilterValue: any[] = [];
  quickFilterValue: any[] = [];

  constructor(private miTableService: MITableService, private configService: ConfigService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() { }

  clearSearch() {
    this.searchValue = "";
    this.onSearchValueChange.emit('');
  }

  // openDialog() {
  //   this.isModal = true;
  // }

  onToggle(event) {
    this.isModal = false;
  }

  onUpdateColumns(event) {
    this.selectedColumns = event;
    this.isModal = false;

    const data = {
      config: JSON.stringify(event),
      forTenantCode: this.configService.getForTenantCode(),
      prefType: AppSettings.USER_PREFERENCE.COLUMN,
    };

    this.miTableService
      .updateUserEntityConfig("booking", data)
      .subscribe((res) => { });
  }

  onSearch(event) {
    const value = event.target.value;
    if (event.target.value.length >= 3) {
      this.onSearchValueChange.emit(value);
    } else if (event.target.value.length === 0) {
      this.onSearchValueChange.emit(value);
    }
  }

  onFilterValueChange(filterFlag, event) {
    filterFlag ? (this.advanceFilterValue = event) : (this.quickFilterValue = event);
    const combineFilter = { ...this.advanceFilterValue, ...this.quickFilterValue }

    this.selectedFilterCount = this.countNonNullValues(combineFilter)
    this.onValueChange.emit(combineFilter);
  }

  clearFilter() {
    this.selectedFilterCount = 0;
    this.advanceFilterValue = this.setAllValuesToNull(this.advanceFilterValue);
    this.quickFilterValue = this.setAllValuesToNull(this.quickFilterValue);
    const combineFilter = { ...this.advanceFilterValue, ...this.quickFilterValue }
    this.onValueChange.emit(combineFilter);
    const filter = _.cloneDeep(this.filterList);
    this.filterList = [];
    this.cd.detectChanges();
    this.filterList = filter;
  }

  
  countNonNullValues(obj) {
    return _.reduce(obj, (count, value) => {
      return (value !== null && value !== "" && value?.length !== 0) ? count + 1 : count;
    }, 0);
  }

  setAllValuesToNull(obj) {
    return _.mapValues(obj, () => null);
  }

}
