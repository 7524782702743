<div appAccessProvider [accessCode]="{ menuCode: 'COMMUNICATION', pageCode: 'MANAGE_SEGMENTS', subPageCode: 'ADD' }">
  <app-module-header [label]="btnLabel" (onBtnClick)="onAddSegments()"></app-module-header>
</div>
<div appAccessProvider [accessCode]="{ menuCode: 'COMMUNICATION', pageCode: 'MANAGE_SEGMENTS', subPageCode: 'LIST' }">
  <p-tabView [(activeIndex)]="activeIndex" (onChange)="tabViewChange($event)">
    <p-tabPanel>
      <ng-template pTemplate="body">
        @if(data){
          <app-mi-table
            [collectionOfColumns]="allColumns"
            [attributeLabels]="filterAttributeLabels"
            [data]="tableData"
            entityName="segment"
            [isLoading]="isLoading"
            (onPageChange)="onPageChange($event)"
            (itemPerPageChange)="itemPerPageChange($event)"
            [totalRecords]="totalRecords"
            [message]="deleteSegmentMsg"
            [header]="deleteSegmentHeaderMsg"
            (onDelete)="onSegmentDelete($event)"
            (onRowSelection)="onRowSelect($event)"
            [filterList]="filterList"
            (onValueChange)="onFilterValueChange($event)"
            [filterFields]="filterFieldsArray"
            [label]="btnLabel"
            (onBtnClick)="onAddSegments()"
            [attributeData]="attributeData"
            (onSearchValueChange)="onSearchValueChanges($event)"
            (onUpdate)="onUpdateSegments($event)"
            [rowItems]="rowItems"
            (setCurrentRowData)="setCurrentRowData($event)"
            [visible]="visible"
            >
          </app-mi-table>
        }
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
<p-toast></p-toast>
