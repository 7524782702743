import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { CalendarModule } from 'primeng/calendar';
import { DeviceDetectionService } from 'app/modules/shared/services/device-detection.service';
import { InputSwitchModule } from 'primeng/inputswitch';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrl: './date-time.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, ReactiveFormsModule, CalendarModule, InputSwitchModule, NgClass, TranslateModule]
})
export class DateTimeComponent {
  @Output() onSelectedDate: EventEmitter<any> = new EventEmitter();
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  isTouchDevice: boolean = false;
  placeholder: any;
  switchValue: boolean = false;
  timeValue: Date;
  defaultDate: Date;
  minDate: Date;
  rangeDates: Date[] | undefined;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private deviceDetectionService: DeviceDetectionService,
    private cdr: ChangeDetectorRef) {
    this.formName = this.formGroupDirective.control;

  }

  ngOnInit(): void {
    this.isTouchDevice = this.deviceDetectionService.isTouchDevice();
    this.setPlaceholder();
    this.initializeDateTime();


  }

  setPlaceholder(): void {
    this.placeholder = this.cs.getLabelValue(`${this.moduleName}.fields.${this.field.attributeCode}.label`, this.attributeLabels, null);
    if (this.placeholder === 'Return Time & Date') {
      this.placeholder = this.placeholder.replace('Time & ', '');
    }
  }

  initializeDateTime(): void {
    if (this.field.attributeCode === "departure_time_and_date" || this.field.attributeCode === "return_time_and_date") {
      const timeVa = new Date();
      timeVa.setHours(timeVa.getHours() + 2);
      timeVa.setMinutes(timeVa.getMinutes());
      timeVa.setSeconds(0);
      this.timeValue = timeVa;
      this.defaultDate = new Date();
      this.minDate = new Date();
      this.formName.controls[this.field.attributeCode].setValue(this.defaultDate);

    }
  }

  onDateSelect(event, type?) {
    const value = event;
    if (type === 'date') {
      const data = this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].value;
      data.setHours(value.getHours());
      data.setMinutes(value.getMinutes());


      if (this.field.attributeCode === 'departure_time_and_date') {
        this.minDate = this.formName.controls['return_time_and_date'].value || new Date();
      }
      if (data) {
        this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(data);
      }
      this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(value);
      this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].updateValueAndValidity();

    } else {
      this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(value);
      this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].updateValueAndValidity();

    }

    const departDate = this.formName.controls['departure_time_and_date'].value;
    const returnDate = this.formName.controls['return_time_and_date'].value;
    if (departDate && returnDate) {
      const differenceInMilliseconds = Math.abs(returnDate - departDate);

      const differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
      const differenceInMinutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      this.formName.controls['journey_time'].setValue(`${differenceInHours} hours ${differenceInMinutes} minutes`);
      this.formName.controls['journey_time'].updateValueAndValidity();

    }
  }

  onDateRangeSelect() {
    if (this.rangeDates[0] && this.rangeDates[1]) {
      this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(this.rangeDates);
      this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].updateValueAndValidity();
    }
  }


  onSwitchChange(event) {
    this.switchValue = event.checked;
    if (this.switchValue) {
      const timeVal = new Date();
      timeVal.setHours(timeVal.getHours());
      timeVal.setMinutes(timeVal.getMinutes());
      timeVal.setSeconds(0);
      this.timeValue = timeVal;
      this.formName.controls[this.field.attributeCode].setValue(this.defaultDate);
    } else {
      const timeVa = new Date();
      timeVa.setHours(timeVa.getHours() + 2);
      timeVa.setMinutes(timeVa.getMinutes());
      timeVa.setSeconds(0);
      this.timeValue = timeVa;
      this.formName.controls[this.field.attributeCode].setValue(this.defaultDate);
    }
  }
}
