import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appOnlyLetters]',
  standalone: true,
})
export class OnlyLettersDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = this.el.nativeElement as HTMLInputElement;
    const filteredValue = input.value.replace(/[^A-Za-z\s]/g, '');
    input.value = filteredValue;
    event.preventDefault();
  }
}
