import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { AppIcons } from '../../shared/app.icons';
import { AppSettings } from '../../shared/app.settings';
import { StatusComponent } from '../../shared/components/status/status.component';
import { Country } from '../../shared/models/country';
import { Language } from '../../shared/models/language';
import { Status } from '../../shared/models/status';
import { CommonBindingDataService } from '../../shared/services/common-binding-data.service';
import { ConfigService } from '../../shared/services/config.service';
import { EntityService } from '../../shared/services/entity.service';
import { EventService } from '../services/event.service';
import { Countries } from 'app/modules/shared/countries';
import { TabViewModule } from 'primeng/tabview';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-event-details',
  standalone: true,
  imports: [TranslateModule, DatePipe, BreadcrumbModule, TabViewModule,
            StatusComponent, ButtonModule, MenuModule, AccessProviderDirective],
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss', '../../shared/components/mi-fields/mobile-input/flags.scss'],
  providers: [DatePipe]
})
export class EventDetailsComponent implements OnInit {
  routePath: MenuItem[] = [];
  eventId: string;
  dataForStatus: Array<Status> = [];
  country: Country;
  language: Language;
  activeIndex: any = 0;
  imgFileId: any;
  showStatus: boolean = false;
  eventDetails: any;
  entityForStatus: any;
  eventImage: any;
  editBtnLabel: any;
  countryCodeList = Countries.COUNTRY_CODE;
  countryCodeForTransportSupervisorNumber: any;
  emergencyNumber: any;
  miIcons = AppIcons;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  languageCode: string;
  eventState: string;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  center: google.maps.LatLngLiteral;
  map: google.maps.Map;
  address: any;

  constructor(private route: ActivatedRoute,
    private router: Router,
    public cs: CommonBindingDataService,
    private datePipe: DatePipe,
    private entityService: EntityService,
    private configService: ConfigService,
    private confirmationService: ConfirmationService,
    private cd: ChangeDetectorRef,
    private eventService: EventService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.eventId = this.route.snapshot.paramMap.get('id');
    this.getEventDetails();
    this.setCountryAndLanguage();
    this.editBtnLabel = this.cs.getLabel('lbl_edit');
    this.setRoutePath()

  }

  ngAfterViewInit() {
    this.initMap();
  }

  setRoutePath() {
    this.routePath = [
      {
        label: "Events",
        routerLink: '/app/events/list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: "Details",
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
  }

  setCountryAndLanguage() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  async setMarker(markerlocation): Promise<void> {
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: markerlocation,
    });
    this.map.setCenter(markerlocation);
    this.map.setZoom(17);
  }

  async initMap(): Promise<void> {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 18.50932924799519, lng: 74.32022387996037 },
      zoom: 8,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapId: AppSettings.MAP_ID,
    });
  }

  setStateForEvent() {
    const currentTime = new Date().getTime();
    if (this.eventDetails.eventStartDateTime && this.eventDetails.eventEndDateTime) {
      this.eventState = this.determineEventState(this.eventDetails.eventStartDateTime, this.eventDetails.eventEndDateTime, currentTime);
    }
  }

  private determineEventState(eventStartDate, eventEndDate, currentTime) {
    if (eventStartDate < currentTime && eventEndDate > currentTime) {
      return AppSettings.EVENT_STATE.ONGOING;
    } else if (eventStartDate > currentTime) {
      return AppSettings.EVENT_STATE.UPCOMING;
    } else {
      return AppSettings.EVENT_STATE.COMPLETED;
    }
  }

  setModuleHeaderSecondaryBtnMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.eventDetails.eventStatusStr === AppSettings.EVENT_STATUS.ACTIVE ? this.cs.getLabel('events.lbl_make_event_inactive') : this.cs.getLabel('events.lbl_make_event_active'),
        icon: AppIcons.OVERDUE + ` wh-16 ${this.eventDetails.eventStatusStr === AppSettings.EVENT_STATUS.ACTIVE ? 'bg-red-500' : 'bg-teal-300'}`,
        command: () => {
          this.showConsentPopup();
        }
      }
    ];
  }

  showConsentPopup() {
    const statusChangeConfirmMsg = this.eventDetails.eventStatusStr === AppSettings.EVENT_STATUS.ACTIVE ? this.cs.getLabel('events.lbl_make_event_inactive_msg') : this.cs.getLabel('events.lbl_make_event_active_msg')
    this.confirmationService.confirm({
      header: this.eventDetails.eventStatusStr === AppSettings.EVENT_STATUS.ACTIVE ? this.cs.getLabel('events.lbl_make_event_inactive') : this.cs.getLabel('events.lbl_make_event_active'),
      message: statusChangeConfirmMsg,
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptLabel: this.cs.getLabel('events.lbl_save'),
      rejectLabel: this.cs.getLabel('events.lbl_cancel'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        this.updateEventStatus();
      },
      reject: () => {
      }
    });
  }

  updateEventStatus() {
    const data = {
      active: this.eventDetails.eventStatusStr === AppSettings.EVENT_STATUS.ACTIVE ? AppSettings.EVENT_STATUS.INACTIVE : AppSettings.EVENT_STATUS.ACTIVE,
      forTenantCode: this.configService.getForTenantCode()
    }
    this.eventService.updateEventStatus(this.eventId, data).subscribe({
      next: (result) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('events.lbl_event_status_updated_successfully') });
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  getEventImage() {
    this.entityService.getFile(this.imgFileId, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'event');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        that.eventImage = base64data;
        that.cd.detectChanges();
      }
    })
  }

  getEventDetails() {
    this.eventService.getEvent(this.eventId).subscribe({
      next: (result) => {
        this.eventDetails = result;
        this.eventDetails.transportationType = JSON.parse(this.eventDetails.transportationType).join(' | ')
        const currentTime = new Date().getTime();
        const markerlocation = {lat:  this.eventDetails.latitude, lng: this.eventDetails.longitude };
        this.setMarker(markerlocation);
        this.imgFileId = this.eventDetails.eventLogoImgId;
        this.parseDates();
        this.setStatusData();
        this.getEventImage();
        this.getFlagsForMobileNumber();
        this.showStatus = true;
        this.setModuleHeaderSecondaryBtnMenuItems();
      },
      error: (error) => {

      }
    })
  }

  getFlagsForMobileNumber() {
    const findFlag = (flagCode: string) =>
    this.countryCodeList.find(ele => ele.value === flagCode)?.code;

    const flag1 = findFlag(this.eventDetails.transportSupervisorNumberCountryCode);
    this.countryCodeForTransportSupervisorNumber = flag1 ? flag1 : null;

    const flag2 = findFlag(this.eventDetails.emergencyNumberCountryCode)
    this.emergencyNumber = flag2 ? flag2 : null;

  }

  parseDates() {
    let emptyLabel =  this.cs.getLabel('lbl_empty');
    this.eventDetails.eventStartDateTime = this.eventDetails.eventStartDateTime ? this.transformDate(this.eventDetails.eventStartDateTime) : emptyLabel;
    this.eventDetails.eventEndDateTime = this.eventDetails.eventEndDateTime ? this.transformDate(this.eventDetails.eventEndDateTime) : emptyLabel;
    this.eventDetails.startBookingDatePassengerApp = this.eventDetails.startBookingDatePassengerApp ? this.transformDate(this.eventDetails.startBookingDatePassengerApp) : emptyLabel;
    this.eventDetails.stopBookingDatePassengerApp = this.eventDetails.stopBookingDatePassengerApp ? this.transformDate(this.eventDetails.stopBookingDatePassengerApp) : emptyLabel;
  }

  transformDate(date) {
    return this.datePipe.transform(date, AppSettings.DATE_FORMAT_DD_MMM_YYYY_FOR_TS_FILE)
  }

  setStatusData() {
    this.entityForStatus = {
      entityName: this.eventDetails.eventName
    };

    this.dataForStatus = [
      { label: 'Short Code', text: this.eventDetails.eventShortcode }
    ];
  }

  edit() {
    this.router.navigate(['app/events/edit/' + this.eventId,]);
  }

}
