<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'SHIFT_MANAGEMENT', subMenuCode: 'SHIFT_TEMPLATES', pageCode: 'ALL', subPageCode: 'ADD' }">
<app-module-header [label]="btnLabel" (onBtnClick)="onAddShiftTemplate()" [moduleHeaderSecondaryBtnMenuItems]="moduleHeaderSecondaryBtnMenuItems"></app-module-header>
</div>

<div appAccessProvider [accessCode]="{ menuCode: 'SHIFT_MANAGEMENT', subMenuCode: 'SHIFT_TEMPLATES', pageCode: 'ALL', subPageCode: 'LIST' }">
<p-tabView [(activeIndex)]="activeIndex">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="mi-lg details mr-1"></i>
      <span translate>lbl_all</span>&nbsp;<span class="iconNumber text-color-secondary">({{count}})</span>
    </ng-template>
    <ng-template pTemplate="body">

          <app-mi-table
              [collectionOfColumns]="allColumns"
              [attributeLabels]="filterAttributeLabels"
              [data]="tableData"
              [isLoading]="isLoading"
              [entityNameForBulkUpdate]="entityNameForBulkUpdate"
              (onPageChange)="onPageChange($event)"
              (itemPerPageChange)="itemPerPageChange($event)"
              [message]="deleteShiftTemplateMsg"
              [header]="deleteShiftTemplateHeaderMsg"
              (onDelete)="onShiftTemplateDelete($event)"
              (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
              (onRowSelection)="onRowSelect($event)"
              [filterList]="filterList"
              [filterFields]="filterFieldsArray"
              [label]="btnLabel"
              (onBtnClick)="onAddShiftTemplate()"
              [attributeData]="attributeData"
              [rowItems]="rowItems"
              [bulkSelectionsItems] = "bulkSelectionsItems"
              [bulkUpdateLabel]="bulkUpdateLabel"
              [bulkUpdateHeader]="bulkUpdateHeader"
              [bulkDeleteLabel]="bulkDeleteLabel"
              (setCurrentRowData)="setCurrentRowData($event)"
              [showBulkUpdateDialog]="showBulkUpdateDialog"
              (onBulkDataUpdateEvent)="onBulkDataUpdateEvent($event)"
              [reviewHeader]="reviewHeader"
              (onSearchValueChange)="onSearchValueChanges($event)"
              [totalRecords]="totalRecords"
              [visible]="visible"
              (onToggleOverLay)="showBulkUpdateDialog = false; visiblePrintLabel = false"
              [isEntity]="isEntity"
              [uniqueId]="uniqueId"
              >
            </app-mi-table>

    </ng-template>
  </p-tabPanel>
</p-tabView>
</div>
<input hidden type="file" id="upload" (change)="onChange($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" #uploader>
