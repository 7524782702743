<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'BOOKING', subMenuCode: 'CURRENT_BOOKING', pageCode: 'ALL_BOOKING', subPageCode: 'VIEW' }">
@if(isReady){
    <div class="grid details-div">
        <div class="flex-grow-1 flex flex-row flex-wrap">
            @if(attributeValues){
            <app-booking-header [data]="attributeValues" [bookingId]="bookingId" [passenger]=" relatedData[0]" [passengerCount]="relatedData.length"> </app-booking-header>
            }
    
        </div>
        @if(attributeValues){
            <div class="flex flex-row-reverse flex-wrap">
                <p-button label="{{'bookings.replace'| translate }}" class="mr-2" (click)="onReplace()" ></p-button>
                <app-action-buttons [rowData]="attributeValues"  class="mr-2"></app-action-buttons>
                <div class="status-btn cursor-pointer font-semibold mr-2" (click)="showStatusDialog($event,attributeValues)" [ngStyle]="bookingStyle">
                    {{BOOKING_STATUS_LABEL[attributeValues?.booking_status] || "-"}}
                    <i class="{{miIcons.CHEVRON_BIG_DOWN}} inline-block relative mr-2 text-xs  wh-16 ml-1 chevron-big-down mr-1"></i>
                 </div>
        
                    
            </div>
        }
    </div>
    
    
    <p-tabView [(activeIndex)]="activeIndex" [scrollable]="true">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i  class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-16 relative cursor-pointer mr-1"></i>
                <label translate>bookings.details</label>
            </ng-template>
            @if (bookingAttributeData) {
            <app-booking-info
                [attributeValues]="attributeValues"
                [attributeLabels]="attributeLabels"
                [bookingAttributeData]="bookingAttributeData"
                [bookingDetails]="bookingDetails"
                [driverAttributeData]="driverAttributeData"
                [bookingStatus]="bookingStatus"
                [bookingId]="bookingId"
                [passengerRelatedData]="passengerRelatedData"
                [vehicleRelatedData]="vehicleRelatedData"
                [driverRelatedData]="driverRelatedData"></app-booking-info>
            }
    
        </p-tabPanel>
    
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i  class="{{miIcons.BOOKING_ACTIVITY}} inline-block wh-16 relative cursor-pointer mr-1"></i>
                <label translate>bookings.activity</label>
            </ng-template>
        </p-tabPanel>
    
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i  class="{{miIcons.MAP_2}} inline-block wh-16 relative cursor-pointer"></i>
                <label translate>bookings.map</label>
            </ng-template>


            <app-dispatch-map [geoLocationsData]="geoLocationsData"></app-dispatch-map>

            
        </p-tabPanel>
    </p-tabView>


    
    @if(statusData){
        <app-booking-status [visible]="isStatusDialogShow" [statusData]="statusData"  (onClose)="onHideStatusDialog()" (updateStatus)="onUpdatedStatus()"></app-booking-status>
    }
}
</div>
