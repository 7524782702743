import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../app.settings';
import { RestApiService } from '../services/rest-api.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class UserCommonService {

  constructor(private restApiService: RestApiService, private confService: ConfigService) { }
  signOut(): Observable<any> {
    return this.restApiService.delete('sign-out', `${this.confService.getForTenantCode()}/api/v1/sessions`, 'page-center');
  }


  signIn(tenantCode, data): Observable<any> {
    return this.restApiService.post('SignIn', `${tenantCode}/api/v1/sessions`, data, 'page-center');
  }

  setPassword(data): Observable<any> {
    return this.restApiService.post('password', '/users/set-password', data, 'page-center');
  }

  getUserProfile(): Observable<any> {
    return this.restApiService.get('profile', '/secure/users/profile', 'page-center');
  }

  forgotPassword(data): Observable<any> {
    return this.restApiService.post('password', '/users/forgot-password', data, 'page-center');
  }

}

