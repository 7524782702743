import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { SettingsService } from 'app/modules/settings/settings.service';
import { AutoFocusDirective } from 'app/modules/shared/directives/auto-focus.directive';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-settings-add-labels',
  standalone: true,
  imports: [TranslateModule, ButtonModule, DialogModule, ToastModule, InputTextModule, ReactiveFormsModule, FormsModule, CheckboxModule, AccessProviderDirective],
  templateUrl: './settings-add-labels.component.html',
  styleUrl: './settings-add-labels.component.scss',
  providers: [AutoFocusDirective]
})
export class SettingsAddLabelsComponent {
  @ViewChild('nameInput', { static: false }) nameInput!: ElementRef;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  settingsForm: FormGroup;
  @Input() isVisible;
  @Input() rowData;
  dialogHeader: string;

  constructor(private cs: CommonBindingDataService, private formBuilder: FormBuilder,
    private configService: ConfigService, private settingsService: SettingsService,
    private messageService: MessageService, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.formValidation();
    this.updateDialogHeader();
    if (this.rowData) {
      this.isEditModeForm();
    } else {
      this.settingsForm.reset();
    }
  }

  formValidation() {
    this.settingsForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    });
  }

  updateDialogHeader() {
    this.dialogHeader = this.rowData ? this.cs.getLabel('settings.labels.lbl_edit_label') : this.cs.getLabel('settings.labels.lbl_add_label');
  }

  isEditModeForm() {
    this.patchFormValues(this.rowData);
  }

  patchFormValues(formData) {
    this.settingsForm.patchValue({
      name: formData.label,
    });

    if (!this.rowData.customValue) {
      this.settingsForm.get('name')?.disable();
    } else {
      this.settingsForm.get('name')?.enable();
    }
  }

  onHide() {
    this.isVisible = false;
    this.settingsForm.reset();
    this.cd.detectChanges();
    this.onToggleOverLay.emit(this.isVisible);
  }

  onDialogShow() {
    setTimeout(() => {
      if (this.nameInput) {
        this.nameInput?.nativeElement?.focus();
      } else {
        console.error('nameInput is undefined');
      }
    }, 0);
  }

  onAddSettings() {
    if (this.settingsForm.valid) {
      let reqBody;
      reqBody = {
        forTenantCode: this.configService.getForTenantCode(),
        label: [this.settingsForm.value.name],
      }

      const addEditAPI = this.rowData ? this.settingsService.editTags(AppSettings.ENTITY_CODE.TAGS, this.rowData.tagId, reqBody) : this.settingsService.addTags(AppSettings.ENTITY_CODE.TAGS, reqBody)
      addEditAPI.subscribe((res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.labels.lbl_label_added_successfully') });
        this.onHide();
      },
        error => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('lbl_invalid_data') });
        });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('settings.lbl_please_fill_the_form') });
    }
  }

}
