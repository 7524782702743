import { CommonModule, NgClass } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HideButtonComponent } from "../../dashboard/hide-button/hide-button.component";
import { HideService } from "../../../services/hide-button-service";
import { CommonBindingDataService } from "../../../../shared/services/common-binding-data.service";

@Component({
  selector: "app-vehicle-status",
  templateUrl: "./vehicle-status.component.html",
  standalone: true,
  imports: [TranslateModule, NgClass, HideButtonComponent, CommonModule],
})
export class VehicleStatusComponent implements OnInit {
  @Input() vehicleStatusData: any;
  cardId: string;

  constructor(
    public hideService: HideService,
    private cs: CommonBindingDataService
  ) { }

  ngOnInit(): void {
    this.cardId = this.cs.getLabel("dashboard_card_ids.vehicle_status_section");
  }

  getBackgroundClass(color: string): string {
    switch (color) {
      case '#32CD32':
        return 'icon-bg-green';
      case '#1E90FF':
        return 'icon-bg-blue';
      case '#FFA500':
        return 'icon-bg-yellow';
      case '#FF0000':
        return 'icon-bg-red';
      case '#A2ABAE':
        return 'icon-bg-grey';
      default:
        return '';
    }
  }
}
