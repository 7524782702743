<div class="card auto-manual-card">
    <div class="flex justify-content-between align-items-center auto-manual-dispatch">
        <div class="flex align-items-center input-switch-container">
            <p-inputSwitch [(ngModel)]="autoToManual" (onChange)="onSwitchChange($event)" class="ml-4"></p-inputSwitch>
            <span class="switch-text " [ngClass]="{'auto': !autoToManual,'manual':autoToManual}">{{ autoToManual ? 'Manual': 'Auto' }}</span>
            @if(!autoToManual){    
                <div class="flex flex-column mb-2">
                    <span class="font-bold text-lg ml-3" translate>bookings.lbl_auto_dispatch</span>
                    <span class="text-base font-normal ml-3 mt-1" translate>bookings.lbl_assign_vehicles_automatically</span>
                </div>       
            }@else{
                <div class="flex flex-column mb-2">
                    <span class="font-bold text-lg ml-3" translate>bookings.lbl_manual_dispatch</span>
                    <span class="text-base font-normal ml-3 mt-1" translate>bookings.lbl_assign_vehicles_manually</span>
                </div>   
            }
        </div>
        @if(!autoToManual){
            <i class="{{miIcons.BOOKING_AUTO_DISPATCH}} inline-block wh-22 relative mr-2 auto-dispatch-icon"></i>
        }@else {
            <i class="{{miIcons.BOOKING_MANUAL_DISPATCH}} inline-block wh-30 relative mr-2"></i>
        }
    </div>    
</div>
@if(autoToManual) {
    <div class="flex passenger-detials">
        @if (formValueChanges) {
            <app-manual-dispatch-details [miFormGroup]="miFormGroup"></app-manual-dispatch-details>
        }
    </div>
    <div class="card">
        <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center">
                <i class="{{miIcons.PASSENGER_VEHICLE_MANUAL}} inline-block wh-22 relative mr-2"></i>
                <label class="text-base font-bold ml-1 mt-0" translate>bookings.lbl_passenger_vehicle</label>
            </div>
            @if (!isAssignedPassenger) {
                <button pButton class="font-bold px-3 py-2 surface-0 manual-assign" (click)="showRightDriversDialog('right', 'passenger')" translate>bookings.lbl_assign_now</button>
            } @else {
                <i class="{{miIcons.OFF_CLOSE}} inline-block wh-18 cursor-pointer" (click)="cancelAssignment('passenger', 0)"></i>
            }
        </div>
        @if (selectedPassengerDriverData) {
            <div class="mt-2">
                <app-manual-passenger-vehicle-details [driverDetail]="selectedPassengerDriverData" [isBookingScreen]="false"></app-manual-passenger-vehicle-details>
            </div>
        }
    </div>
    @for(number of [].constructor(assignConfiguration.additionalVehicleCount); track number; let i = $index) {
        <div class="card">
            <div class="flex justify-content-between align-items-center">
                <div class="flex align-items-center">
                    <i class="{{miIcons.BOOKING_ADDITIONAL_PASSENGER_VEHICLE}} inline-block wh-22 relative mr-2"></i>
                    <label class="text-base font-bold ml-1 mt-0" translate>bookings.lbl_additional_passenger_vehicle</label>
                </div>
                @if (!isAssignedAdditional[i]) {
                    <button pButton class="font-bold px-3 py-2 surface-0 manual-assign" (click)="showRightDriversDialog('right', 'additional', i)" translate>bookings.lbl_assign_now</button>
                } @else {
                    <i class="{{miIcons.OFF_CLOSE}} inline-block wh-18 cursor-pointer" (click)="cancelAssignment('additional', i)"></i>
                }
            </div>
            @if (selectedAdditionalDriverData[i]) {
                <div class="mt-2">
                    <app-manual-passenger-vehicle-details [driverDetail]="selectedAdditionalDriverData[i]" [isBookingScreen]="false"></app-manual-passenger-vehicle-details>
                </div>
            }
        </div>
    }
    @for(number of [].constructor(assignConfiguration.luggageVehicleCount); track number; let i = $index){
        <div class="card">
            <div class="flex justify-content-between align-items-center">
                <div class="flex align-items-center">
                    <i class="{{miIcons.BOOKING_LUGGAGE_VEHICLE_REQUIRED}} inline-block wh-22 relative mr-2"></i>
                    <label class="text-base font-bold ml-1 mt-0" >{{ 'bookings.lbl_luggage_vehicle' |translate: {luggageCount: (i + 1)} }}</label>
                </div>
                @if (!isAssignedLuggage[i]) {
                    <button pButton class="font-bold px-3 py-2 surface-0 manual-assign"  (click)="showRightDriversDialog('right', 'luggage', i)" translate>bookings.lbl_assign_now</button>
                } @else {
                    <i class="{{miIcons.OFF_CLOSE}} inline-block wh-18 cursor-pointer" (click)="cancelAssignment('luggage', i)"></i>
                }
            </div>
            @if (selectedLuggageDriverData[i]) {
                <div class="mt-2">
                    <app-manual-passenger-vehicle-details [driverDetail]="selectedLuggageDriverData[i]" [isBookingScreen]="false"></app-manual-passenger-vehicle-details>
                </div>
            }
        </div>
    }
}

<app-driver-sidebar  [(visible)]="rightDriversSectionVisible"  [rightDialogPosition]="rightDialogPosition" [position]="position" [bookingId]="bookingId"></app-driver-sidebar>