<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'SHIFT_MANAGEMENT', subMenuCode: 'MANAGE_GROUPS', pageCode: 'ALL', subPageCode: 'ADD' }">
<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white border-button default-text-light-black-color cursor-pointer"
        [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button class="flex align-items-center justify-content-center cursor-pointer" (click)="onNextBtnClick()"
        [label]="nextBtnLabel"></p-button>
</div>
<p-tabView [(activeIndex)]="activeIndex">
    @for (tab of groupAttributeData?.tabs; track tab; let i = $index) {
    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
                <i class="mi-lg" [class]="tab.tabCode"></i>
                <span>{{ cs.getLabelValue('driver_group.tabs.'+tab.tabCode+'.label',attributeLabels,tab.tabCode) }}</span>
            </div>
        </ng-template>
        <p-card>
            <div class="grid group-dimension relative">
                <div class="col-6 md:col-12 lg:col-6 ">
                    <app-mi-form id="details" [attributeLabels]="attributeLabels" [attributeValues]="attributeValues"
                        [moduleName]="moduleName" [groups]="tab.groups" (saveData)="onSaveGroups($event)" [showImg]="true"
                        [activeIndex]="activeIndex" (onSaveAndAdd)="onSaveAndAddGroups($event)"  (nextClick)="onNextClick()" 
                        [submitBtnText]="nextBtnLabel" [previousBtnText]="saveAndAddBtnText" [saveBtnId]="'add-group'"></app-mi-form>
                </div>
                <div class="col-6 md:col-12 lg:col-6">
                    <app-selected-driver (onChangeSelectedDriver)="onChangeSelectedDriver($event)" [selectedDriver]="selectedDriver"></app-selected-driver>
                </div>
            </div>
        </p-card>
    </p-tabPanel>
    }
</p-tabView>
</div>
