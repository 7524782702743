import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import type { Country } from '../../../shared/models/country';
import type { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../shared/services/entity.service';
import type { EntityCountsResponse, EntityList } from '../../models/entity.models';
import type { ListResponse, Values } from '../../models/listResponse.models';

import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { forkJoin } from 'rxjs';
import { ModuleHeaderComponent } from '../../../shared/components/module-header/module-header.component';
import { ConfigService } from '../../../shared/services/config.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { PermissionAccessService } from 'app/modules/shared/services/permission-access.service';

@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss'],
  standalone: true,
  imports: [BreadcrumbModule, ModuleHeaderComponent, TabViewModule, TranslateModule, MITableComponent, MITableComponent, AsyncPipe, AccessProviderDirective]
})

export class VehicleListComponent implements OnInit {
  @ViewChild('uploader', { static: true }) uploader: ElementRef<HTMLElement>;
  @ViewChild(MITableComponent) tableRef: MITableComponent;

  miIcons = AppIcons;
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];
  moduleName = 'vehicle';
  filterList;
  btnLabel: string;
  items: MenuItem[] | undefined;
  isShowMenu: boolean = true;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  totalRecords: number = 0;
  tableData: any = [];
  buttons: any[] = [];
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  itemsPerPage: number;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.BOTH,
    forTenantCode: "",
    actionStatus: ''
  };
  routePath: MenuItem[] = [];
  deleteVehicleMsg: string;
  deleteVehicleHeaderMsg: string;
  showMenu: boolean = false;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  filterFieldsArray: any[];
  isReady: boolean = false;
  attributeData: any;
  data;
  rowItems: MenuItem[] | undefined;
  bulkSelectionsItems: MenuItem[] | undefined;
  currentRow;
  visiblePrintLabel: boolean = false;
  showBulkUpdateDialog: boolean = false;
  visible: boolean = false;
  bulkUpdateHeader;
  reviewHeader: any;
  btnUpdate: any;

  constructor(private vehicleService: EntityService,
    private cs: CommonBindingDataService, private confirmationService: ConfirmationService,
    private router: Router, private messageService: MessageService, private actRoute: ActivatedRoute,
    private configService: ConfigService, private permissionAccessService: PermissionAccessService) { }


  ngOnInit() {
    this.initializeLabels();
    this.initializeRowItems();
    this.initializeBulkSelectionsItems();
    this.initializeModuleHeaderSecondaryBtnMenuItems();
    this.initializeOtherVariablesAndAPI();
  }

  private initializeRowItems() {
    const isEdit = this.permissionAccessService.hasAccess('VEHICLES', '', 'ALL', 'EDIT');
    const isDelete = this.permissionAccessService.hasAccess('VEHICLES', '', 'ALL', 'DELETE');
    this.rowItems = [
      ...(isEdit ? [{
        label: "Edit",
        icon: AppIcons.EDIT_2 + " wh-16",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.update();
        }
      }] : []),
      { separator: true },
      ...(isDelete ? [{
        label: 'Delete',
        icon: AppIcons.BASIC_TRAY + " wh-16",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.ROW);
        }
      }] : []),
    ];
  }

  private initializeBulkSelectionsItems() {
    const isBulkAction = this.permissionAccessService.hasAccess('VEHICLES', '', 'ALL', 'BULK_ACTION');
    const isPrintLabels = this.permissionAccessService.hasAccess('VEHICLES', '', 'ALL', 'PRINT');
    this.bulkSelectionsItems = [
      ...(isBulkAction ? [{
        label: this.cs.getLabel('vehicle.lbl_update'),
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: () => {
          this.tableRef.showBulkRowDialog();
        },
      }] : []),
      ...(isPrintLabels ? [{
        label: this.cs.getLabel('vehicle.print_label'),
        icon: AppIcons.MI_PRINT + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: () => {
          this.handleBulkPrint();
        },
      }] : []),
      {
        separator: true,
      },
      ...(isBulkAction ? [{
        label: this.cs.getLabel('vehicle.delete'),
        icon: AppIcons.BASIC_TRAY + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: () => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.BULK);
        },
      }] : []),
    ];
  }

  handleBulkUpdate() {
    this.visible = true;
    this.showBulkUpdateDialog = true;
  }

  handleBulkPrint() {
    this.visiblePrintLabel = true;
  }

  private initializeModuleHeaderSecondaryBtnMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.cs.getLabel('vehicle.add_multiple_vehicle'),
        icon: AppIcons.ADD_MULTIPLE + " wh-16",
        command: () => {

        }
      },
      {
        label: this.cs.getLabel('vehicle.import_vehicle'),
        icon: AppIcons.UPLOAD + " wh-16",
        command: () => {
          const el: HTMLElement = this.uploader.nativeElement;
          el.click();
        }
      },
      {
        label: this.cs.getLabel('vehicle.download_important_template'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
          this.downloadImportTemplate();
        }
      },
      {
        label: this.cs.getLabel('vehicle.download_important_guide'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
          this.downloadImportGuide();
        }
      },
      {
        label: this.cs.getLabel('vehicle.add_gps_telematics_integration'),
        icon: AppIcons.EXTERNAL_LINK + " wh-16",
      },
      {
        separator: true
      },
      {
        label: this.cs.getLabel('vehicle.export_xlsx_csv'),
        icon: AppIcons.EXPORT + " wh-16",
      },
    ];
  }

  downloadImportTemplate() {
    this.vehicleService.downloadImportTemplate(AppSettings.ENTITY_CODE.VEHICLE).subscribe(
      (result: any) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(result);
        a.download = 'Import Template.xlsx';
        a.click();
      },
      err => {
        console.log(err);
      });
  }

  downloadImportGuide() {
    this.vehicleService.downloadImportGuide(AppSettings.ENTITY_CODE.VEHICLE).subscribe(result => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(result);
      a.download = 'Import Guide.xlsx';
      a.click();
    },
      err => {
        console.log(err);
      });
  }

  private initializeLabels() {
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.bulkUpdateHeader = this.cs.getLabel('lbl_update_vehicles');
    this.reviewHeader = this.cs.getLabel('lbl_review_vehicles');
    this.btnUpdate = this.cs.getLabel('btn_update_vehicles2');
    this.routePath = [{ label: "Vehicles", routerLink: 'vehicles/list' }];
  }

  private initializeOtherVariablesAndAPI() {
    this.btnLabel = this.cs.getLabel('add_vehicle');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.deleteVehicleMsg = this.cs.getLabel('vehicle.message.confirm_delete_msg');
    this.deleteVehicleHeaderMsg = this.cs.getLabel('vehicle.message.confirm_delete_header');
    this.getAttributeData();
    this.getData();
  }



  public onChange(event) {
    for (const file of event.target.files) {
      if (file) {
        if (file.size > AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('err_file_size_exceed_msg') });
        } else {
          const formData = new FormData();
          formData.append("file", file);
          formData.append('forTenantCode', this.configService.getForTenantCode());
          formData.append('entityCode', AppSettings.ENTITY_CODE.VEHICLE);
          formData.append('viewCode', AppSettings.VIEW_CODE.EXCEL_IMPORT_VIEW);
          this.cs.importEntity(formData, AppSettings.ENTITY_CODE.VEHICLE).subscribe(() => {
            this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('lbl_file_uploaded_successfully') });
          })
        }
      }
    }
  }

  getAttributeData() {
    const bulkUpdateView = this.vehicleService.getCachedEntityView(this.moduleName, AppSettings.VIEW_CODE.BULK_UPDATE_VIEW);
    if (bulkUpdateView) {
      this.setVehicleView(bulkUpdateView);
      return;
    }

    this.vehicleService.getAttributeDefinition(AppSettings.ENTITY_CODE.VEHICLE, AppSettings.VIEW_CODE.BULK_UPDATE_VIEW).subscribe(res => {
      if (res) {
        this.setVehicleView(res);
        this.vehicleService.setEntityView(this.moduleName, AppSettings.VIEW_CODE.BULK_UPDATE_VIEW, res);
      }
    });
  }

  setVehicleView(res) {
    this.attributeData = res;
    this.isLoading = false;
    this.filterFieldsArray = res.tabs[0].groups[0].fields;
    this.filterFieldsArray.forEach(element => {
      switch (element.attributeCode) {
        case 'vehicle_type':
          element.attributeValue = 'Type';
          element.isDisable = false;
          break;
        case 'vehicle_status':
          element.attributeValue = 'Status';
          element.isDisable = false;
          break;
        case 'group':
          element.attributeValue = 'Group';
          element.isDisable = false;
          break;
      }
    });

  }

  setCurrentRowData(event) {
    this.currentRow = event;
    this.setEditOptionDisabledForDeletedRecord(event);
  }

  setEditOptionDisabledForDeletedRecord(data) {
    this.rowItems[0].disabled = data.deleted;
  }

  update() {
    this.onUpdateVehicle(this.currentRow.id);
  }

  getTableView() {
    const tableView = this.vehicleService.getCachedEntityView(this.moduleName, AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW);
    if (tableView) {
      this.setVehicleTableView(tableView);
      return;
    }

    this.vehicleService.getAttributeDefinition(AppSettings.ENTITY_CODE.VEHICLE, AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW).subscribe(res => {
      if (res) {
        this.setVehicleTableView(res);
        this.vehicleService.setEntityView(this.moduleName, AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW, res);
      }
    });
  }

  setVehicleTableView(res) {
    this.isLoading = false;
    this.data = res;
    this.attributeLabels = this.cs.getAttributeLabels(this.data);
    this.allColumns = this.cs.getColumns(this.data);
    this.searchEntity();
    this.allColumns?.forEach((key, index) => {
      this.allColumns[index] = key;
      this.allColumns[index].field = key.attributeCode;
      this.allColumns[index].header = this.cs.getLabelValue(
        "vehicle" + ".fields." + key.attributeCode + ".label",
        this.attributeLabels,
        key.attributeCode
      );
    });
    this.filterAvailableColumns = _.clone(this.allColumns);
  }

  getFilterView() {
    const filterView = this.vehicleService.getCachedEntityView(this.moduleName, AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW);
    if (filterView) {
      this.setVehicleFilterView(filterView);
      return;
    }
    this.vehicleService.getAttributeDefinition(AppSettings.ENTITY_CODE.VEHICLE, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
      if (filterResponse) {
        this.setVehicleFilterView(filterResponse);
        this.vehicleService.setEntityView(this.moduleName, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW, filterResponse);
      }
    })
  }

  setVehicleFilterView(filterResponse) {
    this.filterAttributeLabels = this.cs.getAttributeLabels(filterResponse);
    this.filterList = this.cs.getColumns(filterResponse);
    this.filterList = _.sortBy(this.filterList, 'attributeIndex');
  }

  getData() {
    return forkJoin({
      tableViewData: this.getTableView(),
      filterViewDate: this.getFilterView(),
      getEntityCount: this.getEntityCount()
    })
  }

  searchEntity() {
    this.tableData = [];
    this.entityData.countryCode = this.country[0].countryCode;
    this.vehicleService.searchEntity(AppSettings.ENTITY_CODE.VEHICLE, this.entityData).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0]?.values;
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = res.data?.map(data => {
        return {
          id: data.id,
          driverGroup: data.relatedData?.map(ele => {
            return `${ele.values.first_name} ${ele.values.last_name}`
          }).join(', '),
          ...data.values,
        };
      }) || [];
      this.tableData.forEach(rowData => {
        if (rowData?.vehicle_images && (rowData?.vehicle_images[0])) {
          this.getFile(rowData)
        }
      });
    })
  }

  getFile(rowData) {
    this.vehicleService.getFile(rowData.vehicle_images[0], AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const reader = new FileReader();
      reader.readAsDataURL(result);
      reader.onloadend = function() {
        const base64data = reader.result;
        rowData.vehicleImage = base64data;
      }
    })
  }

  onAddVehicle() {
    this.router.navigate(['app/vehicles/add',]);
  }

  getLabel(params) {
    return params + 'hi';
  }

  onRowClicked(e: any) {
    alert('Row click : ' + JSON.stringify(e));
  }

  onActionBtnClicked(e: any) {
    alert('Action btn click : ' + JSON.stringify(e));
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.itemsPerPage = event;
    this.entityData.limit = this.itemsPerPage;
    this.searchEntity();
  }

  onConfirmDelete() {
  }


  onVehicleDelete(event) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.VEHICLE,
      entityIds: [
        event
      ]
    };
    this.vehicleService.deleteEntityActionStatus(requestBody).subscribe({
      next: () => {
        this.cs.onDisplayMessage(this.data, 'vehicle', AppSettings.MSG_ACTION.DELETE, 'success');
        setTimeout(() => {
          this.searchEntity();
          this.getEntityCount();
        }, 300);
      },
      error: () => {
        this.cs.onDisplayMessage(this.data, 'vehicle', AppSettings.MSG_ACTION.DELETE, 'error');
      }
    })
  }

  onUpdateVehicle(vehicleId) {
    this.router.navigate(['app/vehicles/add/' + vehicleId,]);
  }


  onRowSelect(event) {
    if (event.type === 'row') {
      this.router.navigate(['app/vehicles/' + event.data.id,]);
      this.vehicleService.vehicleCurrentRow.next(event.data);
    }
  }


  onFilterValueChange(event) {
    const vData = event;
    this.entityData.filters = [];
    for (const [key, value] of Object.entries(vData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.entityData.filters.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    this.entityData.filters = _.filter(_.uniq(this.entityData.filters, function(item) {
      if (item.attributeValue.length > 0) {
        return item.attributeCode;

      }
    }), function(element) {
      if (element.attributeValue.length > 0) {
        return true;
      }
      return false;
    });
    this.entityData.offset = event.first;
    this.entityData.filters = this.cs.mapAttributeIds(this.entityData.filters, this.filterList, 'filter');
    this.searchEntity();
  }


  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  getEntityCount() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const entityCountPayload = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.VEHICLE,
      countryCode: <string>country[0].countryCode,
    };

    this.vehicleService.getEntityCount(AppSettings.ENTITY_CODE.VEHICLE, entityCountPayload).subscribe((res: EntityCountsResponse) => {
      this.entityCount = res;
    })
  }

  tabViewChange(event) {
    this.activeIndex = event.index;
    const actionStatus = '';
    switch (event.index) {
      case 0:
        this.entityData.actionStatus = actionStatus;
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        this.entityData.filters = [];
        this.searchEntity();
        break;
      case 1:
        this.entityData.actionStatus = AppSettings.VEHICLE_TAB_LIST.ASSIGNED;
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        this.searchEntity();
        break;

      case 2:
        this.entityData.actionStatus = AppSettings.VEHICLE_TAB_LIST.UNASSIGNED;
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        this.searchEntity();
        break;

      case 3:
        this.entityData.actionStatus = AppSettings.VEHICLE_TAB_LIST.DELETE
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_DELETED;
        this.searchEntity();
        break;

      default:
        break;
    }
  }

  onBulkDataUpdateEvent() {
    this.searchEntity();
  }

  onBulkDataDeleteEvent(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.VEHICLE,
      countryCode: this.country[0].countryCode,
      entityIds: event
    };
    this.entityData.actionStatus = AppSettings.VEHICLE_TAB_LIST.DELETE
    this.vehicleService.deleteEntityActionStatus(requestBody).subscribe({
      next: () => {
        this.cs.onDisplayMessage(this.data, 'vehicle', AppSettings.MSG_ACTION.BULK_DELETE, 'success');
        this.searchEntity();
        this.getEntityCount();
      },
      error: () => {
        this.cs.onDisplayMessage(this.data, 'vehicle', AppSettings.MSG_ACTION.BULK_DELETE, 'error');
      }
    });
  }

}
