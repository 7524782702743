import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { startWith } from 'rxjs/operators';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-select-depend-on-with-other',
  templateUrl: './select-depend-on-with-other.component.html',
  styleUrls: ['./select-depend-on-with-other.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DropdownModule, TranslateModule]
})
export class SelectDependOnWithOtherComponent implements OnInit {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  filteredOptions;
  parentLabelValue: string = '';
  presetValue = [];

  constructor(
    private messageService: MessageService,
    private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef) {
    this.formName = formGroupDirective.control;
  }


  ngOnInit(): void {
    this.filterByParentLabelKey();
    this.formName.valueChanges.subscribe(value => {
      if (value) {
        this.parentLabelValue = value?.[this.field.dependsOnAttributeCode]
        this.filterByParentLabelKey();
      }
    });

  }

  filterByParentLabelKey() {
    const parentKey = this.formName.controls[this.field.dependsOnAttributeCode].value;
    if (parentKey === 'Other') {
      this.presetValue = this.field.presetValues;
    } else {
      this.presetValue = this.field.presetValues.filter(v => v.parentLabelKey === this.parentLabelValue || v.parentLabelKey === parentKey);
    }
  }

  changedValue(value: string) {
  }
}

