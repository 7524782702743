<div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'DRIVER', subPageCode: 'LIST' }">
    <div class="main-settings">
        <p-tabView>
            <p-tabPanel>
                <ng-template pTemplate="header" class="col-12 col:md-6">
                    <i class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-14 mr-2 relative"></i>
                    <span translate>settings.lbl_reason_for_duty_status</span>
                </ng-template>
                <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'DRIVER', subPageCode: 'ADD' }">
                    <div class="col-12 md:col-6 flex justify-content-end flex-wrap btn-with-links">
                        <p-button class="flex align-items-center justify-content-center" icon="pi pi-plus" [label]="addBtnLabel" (click)="onAddDialogOpen()"></p-button>
                    </div>
                </div>
                <div class="flex">
                    <div class="flex-50 flex col-12 md:col-12">
                        <div class="p-fluid ml-1 mr-1 w-full">
                            <div class="p-fluid p-formgrid grid">
                                <div class="mi-table w-full">
                                    <div class="flex mi-filter-container">
                                        @if (selectedRows?.length>0) {
                                            <div>
                                                <p-button icon="pi pi-chevron-down" [badge]="selectedRows.length" badgeClass="badge-selected-row" class="mr-2 button-with-icon cursor-pointer" severity="secondary"
                                                    (click)="actions.toggle($event)" [label]="'lbl_actions' | translate"></p-button>
                                                <p-menu #actions [model]="bulkSelectionsItems" [popup]="true" appendTo="body"></p-menu>
                                                <button pButton [label]="'clear_selection' | translate" (click)="clearSelected()" class="mr-2 bg-white default-text-blue-color border-none cursor-pointer clear-btn"></button>
                                            </div>
                                        }
                                        @else {
                                            <div class="flex flex-wrap justify-content-center">
                                                <div class="flex-1 flex justify-content-end mr-2">
                                                    <span class="p-input-icon-left">
                                                        <i class="pi search-icon relative left-20 cursor-pointer"
                                                            [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue }"
                                                            (click)="searchValue ? clearSearch() : null"></i>
                                                        <input class="width-200 search-filter" type="text" pInputText  (input)="onSearch($event, tableData)"
                                                            [placeholder]="'lbl_search' | translate" [(ngModel)]="searchValue"/>
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex background-not">
                    @if(tableData) {
                        <p-table [columns]="cols" [value]="tableData" class="w-full" [(selection)]="selectedRows">
                            <ng-template pTemplate="header" let-columns>
                                <tr class="table-row-header">
                                    <th>
                                        @if(tableData.customValue) {
                                            <p-tableHeaderCheckbox class="checkbox"></p-tableHeaderCheckbox>
                                        }
                                        @else {
                                            <span class="{{miIcons.BOOKING_LOCK}} block wh-16"></span>
                                        }
                                        
                                    </th>
                                    @for (col of columns; track $index) {
                                        <th>
                                            {{ col.header }}
                                        </th>
                                    }
                                    <th translate alignFrozen="right" pFrozenColumn>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr (mouseover)="rowMouseOver(rowData)" (mouseout)="rowMouseOut(rowData)">
                                    <td>
                                        @if(rowData.customValue) {
                                            <p-tableCheckbox class="checkbox" [value]="rowData"></p-tableCheckbox>
                                        }
                                        @else {
                                            <span class="{{miIcons.BOOKING_LOCK}} block wh-16"></span>
                                        }
                                    </td>
                                    @for (col of columns; track $index) {
                                        <td>
                                            @switch (col.field) {
                                                @case ('labelValue') {
                                                    <div>
                                                        {{ rowData[col.field] }}
                                                        @if(rowData.defaultPreset) {
                                                            <span class="default-value" translate>settings.lbl_default</span>
                                                        }
                                                    </div>
                                                }
                                                @case ('usage') {
                                                    @if (rowData[col.field] > 0) {
                                                        <div class="default-text-blue-color">
                                                            {{ rowData[col.field] }} 
                                                            <span translate>sidebar_lbl_drivers</span>
                                                        </div>
                                                    }
                                                    @else {
                                                        <div>
                                                            {{ rowData[col.field] }} 
                                                            <span translate>settings.driver.lbl_driver</span>
                                                        </div>
                                                    }
                                                }
                                                @default {
                                                    {{ rowData[col.field] }}
                                                }
                                            }
                                        </td>
                                    }
                                    <td alignFrozen="right" pFrozenColumn>
                                        <p-button [style.visibility]="rowData?.showRowMenu ? 'visible' : 'hidden'" icon="{{miIcons.MORE_HORIZONTAL}} block wh-24" class="flex cursor-pointer height-40-px width-48 more-horizontal-icon float-r" styleClass="p-button-secondary"
                                        (click)="setCurrentRow(rowData);menu.toggle($event)" severity="secondary"></p-button>
                                        <p-menu #menu styleClass="menu-width" [model]="rowItems" [popup]="true" appendTo="body"></p-menu>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    }
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
@if(isVisible) {
    <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'DRIVER', subPageCode: 'ADD' }">
        <app-settings-add-reason-for-duty-status (onToggleOverLay)="handleOverlayToggle()" [isVisible]="isVisible" [rowData]="rowData"></app-settings-add-reason-for-duty-status>
    </div>
}
