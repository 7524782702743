<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white default-text-light-black-color border-button" [label]="'cancel' | translate"
        (click)="onCancel()"></button>
    <p-button class="flex align-items-center justify-content-center" [label]="addBtnLabel"
        (click)="onAdd()"></p-button>
</div>


<div class="main-settings">
    <p-tabView>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="mi-lg {{miIcons.DETAILS}}"></i>
                <span translate>content.lbl_contact_support</span>
            </ng-template>
            <form [formGroup]="supportForm">
                <div class="card background-not">
                    <div class="grid">
                        <div class="form-field col-12 md:col-12 lg:col-12 field">
                            <label class="flex mb-3 uppercase" translate>content.lbl_contact_support
                                <span class="required"></span>
                            </label>

                            <hr class="hr-line">

                            <div formArrayName="supports">
                                @for (support of supports.controls; track support; let i = $index) {
                                <div [formGroupName]="i" class="p-mb-3">
                                    <div class="grid">
                                        <div class="form-field col-3 md:col-3 lg:col-3 field">
                                            <label class="flex" for="supportName{{i}}">
                                                {{'content.lbl_support_name' | translate}} {{i + 1}}
                                            </label>

                                            <div class="form-control">
                                                <input id="supportName{{i}}" type="text" pInputText
                                                    formControlName="supportName"
                                                    class="p-component p-element w-full" />
                                            </div>
                                        </div>
                                        <div class="form-field col-3 md:col-3 lg:col-3 field">
                                            <label class="flex" for="supportNumber{{i}}">
                                                {{'content.lbl_support_number' | translate}} {{i + 1}}
                                            </label>
                                            <div class="form-control">
                                                <div class="flex mobile-dropdown">
                                                    <p-dropdown #dropdown (onChange)="selectCountry($event, i)" [options]="countries" [formControlName]="'countryCode'" [filter]="true" filterBy="value" (onFilter)="onFilter($event)" [ngModelOptions]="{standalone : true}" optionLabel="name" [showClear]="false">
                                                        <ng-template pTemplate="selectedItem">
                                                            @if(supports.controls[i].get('countryCode')?.value) {
                                                                <div class="flex align-items-center gap-2">
                                                                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + supports.controls[i].get('countryCode')?.value?.code?.toLowerCase()" class="width-18"/>
                                                                    <div>{{ supports.controls[i].get('countryCode')?.value?.value }}</div>
                                                                </div>
                                                            }
                                                        </ng-template>
                                                        <ng-template let-country pTemplate="item">
                                                            <div class="flex align-items-center gap-2">
                                                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" class="width-18"/>
                                                                <div>{{ country.value }}</div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="filtericon">
                                                            <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
                                                        </ng-template>
                                                    </p-dropdown>
                                                    <input pInputText type="tel" id="supportNumber{{i}}" class="p-component p-element w-full input-mobile" (input)="selectCountry($event)" formControlName="supportNumber" pKeyFilter="int" />
                                                </div>
                                            </div>
                                        </div>   
                                        <div class="col-2 md:col-2 lg:col-2 flex justify-content-between field flex align-items-end">
                                            <div class="form-field flex align-items-end mr-4">
                                                <label class="flex mr-3 mb-2" for="driver{{ i }}"> {{ 'content.lbl_driver' | translate }} </label>
                                                <p-inputSwitch id="driver{{ i }}" formControlName="driver"></p-inputSwitch>
                                            </div>
                                            <div class="form-field flex align-items-end">
                                                <label class="flex mr-3 mb-2" for="passenger{{ i }}"> {{ 'content.lbl_passenger' | translate }}</label>
                                                <p-inputSwitch id="passenger{{ i }}" formControlName="passenger"></p-inputSwitch>
                                          </div>
                                        </div>
                                        <div class="col-2 md:col-2 lg:col-2 mt-2 flex align-items-end">
                                            @if (supports.length > 1) {
                                                <p-button icon="pi pi-trash" [outlined]="true"  (click)="removeSupport(i)"/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>

                            <div class="p-mt-3">
                                <p-button label="Add More" icon="pi pi-plus-circle" [text]="true" (click)="addSupport()" [disabled]="(supports.length >  maxRestriction)"/>
                            </div>

                        </div>
                    </div>
                </div>
            </form>

            <div class="flex justify-content-end mr-17">
                <button pButton class="mr-2 mb-2 bg-color-gray default-text-light-black-color border-button"
                    [label]="'cancel' | translate" (click)="onCancel()"></button>
                <p-button class="mb-2 flex align-items-center justify-content-center" [label]="addBtnLabel"
                    (click)="onAdd()"></p-button>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>