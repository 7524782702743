<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'EVENTS', pageCode: 'ALL_EVENTS', subPageCode: 'ADD' }">
<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="cancel-btn-margin bg-white border-0 default-text-light-black-color cursor-pointer border-button"
        [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button class="save-btn-margin inline-flex height-44" (click)="saveEvent()"  [label]="nextBtnLabel"></p-button>
</div>

<p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
              <i class="mi-lg details mr-2"></i>
              <span translate>organization.details</span>
            </div>
          </ng-template>
              <div class="event-details">
                <form  [formGroup]="addEventForm" class="details-group">
                        <div class="col-12 pl-3 pr-3 tab-group">
                            <div class="event-card card p-fluid">
                                <label class="w-full mt-0 uppercase text-lg font-semibold" translate>events.lbl_details</label>

                                <div class="w-full formgrid grid pt-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.lbl_name</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <input type="text" formControlName="name" pInputText class="w-full" [placeholder]="'events.lbl_enter_event_name' | translate" />
                                            @if (addEventForm.controls.name.errors && (addEventForm.controls.name.dirty || addEventForm.controls.name.touched)) {
                                                <div class="form-errors">
                                                <div [hidden]="!addEventForm.controls.name.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    events.name_is_required
                                                </div>
                                                <div [hidden]="!addEventForm.controls.name.errors.pattern" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    events.please_enter_valid_event_name
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>

                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.lbl_short_code</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <input type="text" formControlName="shortCode" pInputText class="w-full" [placeholder]="'events.lbl_enter_event_short_code' | translate" />
                                            @if (addEventForm.controls.shortCode.errors && (addEventForm.controls.shortCode.dirty || addEventForm.controls.shortCode.touched)) {
                                                <div class="form-errors ">
                                                <div [hidden]="!addEventForm.controls.shortCode.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    events.short_code_is_required
                                                </div>
                                                <div [hidden]="!addEventForm.controls.shortCode.errors.pattern" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    events.please_enter_valid_short_code
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div class="w-full formgrid grid pt-3 pb-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.lbl_transport_supervisor_number</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                                <div class="flex mobile-dropdown">
                                                    <p-dropdown #dropdown (onChange)="onSelectCountryCodeForTransportSupervisor($event)" [options]="countries" [(ngModel)]="selectedCountryCodeForTransportSuperVisor" [filter]="true" 
                                                        filterBy="value" (onFilter)="onFilter($event)" [ngModelOptions]="{standalone : true}" optionLabel="name" [showClear]="false"
                                                        appendTo="body">
                                                        <ng-template pTemplate="selectedItem">
                                                            @if(selectedCountryCodeForTransportSuperVisor) {
                                                                <div class="flex align-items-center gap-2">
                                                                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountryCodeForTransportSuperVisor?.code?.toLowerCase()" class="width-18"/>
                                                                    <div>{{ selectedCountryCodeForTransportSuperVisor.value }}</div>
                                                                </div>
                                                            }
                                                        </ng-template>
                                                        <ng-template let-country pTemplate="item">
                                                            <div class="flex align-items-center gap-2">
                                                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" class="width-18"/>
                                                                <div>{{ country.value }}</div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="filtericon">
                                                            <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
                                                        </ng-template>
                                                    </p-dropdown>
                                                    <input pInputText type="text" class="w-full input-mobile"  formControlName="transportSupervisorNumber" 
                                                            (input)="getPhoneNumberWithCountryCodeForTransportSupervisor()" pKeyFilter="int"  
                                                            [placeholder]="'events.lbl_enter_transport_supervisor_number' | translate"/>

                                                </div>
                                                <input  style="display: none;" pInputText type="text" class="w-full input-mobile" formControlName="transportSupervisorNumberCountryCode" pKeyFilter="int"  />
                                                @if (addEventForm.controls.transportSupervisorNumberCountryCode.errors && (addEventForm.controls.transportSupervisorNumberCountryCode.dirty || addEventForm.controls.transportSupervisorNumberCountryCode.touched)) {
                                                    <div class="form-errors ">
                                                    <div [hidden]="!addEventForm.controls.transportSupervisorNumberCountryCode.errors.required" class="text-err" translate>
                                                        <i class="error_outline"></i>
                                                        events.transport_supervisor_contact_number_is_required
                                                    </div>
                                                </div>
                                                }
                                                @if (addEventForm.controls.transportSupervisorNumber.errors && (addEventForm.controls.transportSupervisorNumber.dirty || addEventForm.controls.transportSupervisorNumber.touched)) {
                                                    <div class="form-errors ">
                                                    <div [hidden]="addEventForm.get('transportSupervisorNumber').hasError(minLength) || addEventForm.get('transportSupervisorNumber').hasError(maxLength)" class="text-err" translate>
                                                        <i class="error_outline"></i>
                                                        lbl_mobile_number_max_length
                                                    </div>
                                                </div>
                                                }
                                        </div>
                                    </div>

                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.lbl_emergency_number</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                                <div class="flex mobile-dropdown">
                                                    <p-dropdown #dropdown (onChange)="onSelectCountryCodeForEmergencyNumber($event)" [options]="countries" [(ngModel)]="selectedCountryCodeForEmergencyNumber" 
                                                        [filter]="true" filterBy="value" (onFilter)="onFilter($event)" [ngModelOptions]="{standalone : true}" optionLabel="name" [showClear]="false"
                                                        appendTo="body">
                                                        <ng-template pTemplate="selectedItem">
                                                            @if(selectedCountryCodeForEmergencyNumber) {
                                                                <div class="flex align-items-center gap-2">
                                                                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountryCodeForEmergencyNumber?.code?.toLowerCase()" class="width-18"/>
                                                                    <div>{{ selectedCountryCodeForEmergencyNumber.value }}</div>
                                                                </div>
                                                            }
                                                        </ng-template>
                                                        <ng-template let-country pTemplate="item">
                                                            <div class="flex align-items-center gap-2">
                                                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" class="width-18"/>
                                                                <div>{{ country.value }}</div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="filtericon">
                                                            <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
                                                        </ng-template>
                                                    </p-dropdown>
                                                    <input pInputText type="text" class="w-full input-mobile"  formControlName="emergencyContactNumber" 
                                                            (input)="getPhoneNumberWithCountryCodeForEmergencyNumber()" pKeyFilter="int"  
                                                            [placeholder]="'events.lbl_enter_emergency_number' | translate"/>

                                                </div>
                                                <input  style="display: none;" pInputText type="text" class="w-full input-mobile" formControlName="emergencyContactNumberWithCountryCode" pKeyFilter="int"  />
                                                @if (addEventForm.controls.emergencyContactNumberWithCountryCode.errors && (addEventForm.controls.emergencyContactNumberWithCountryCode.dirty || addEventForm.controls.emergencyContactNumberWithCountryCode.touched)) {
                                                    <div class="form-errors ">
                                                    <div [hidden]="!addEventForm.controls.emergencyContactNumberWithCountryCode.errors.required" class="text-err" translate>
                                                        <i class="error_outline"></i>
                                                        events.emergency_contact_number_is_required
                                                    </div>
                                                </div>
                                                }
                                                @if (addEventForm.controls.emergencyContactNumber.errors && (addEventForm.controls.emergencyContactNumber.dirty || addEventForm.controls.emergencyContactNumber.touched)) {
                                                    <div class="form-errors ">
                                                    <div [hidden]="addEventForm.get('emergencyContactNumber').hasError(minLength) || addEventForm.get('emergencyContactNumber').hasError(maxLength)" class="text-err" translate>
                                                        <i class="error_outline"></i>
                                                        lbl_mobile_number_max_length
                                                    </div>
                                                </div>
                                                }
                                        </div>
                                    </div>
                                </div>

                                <div class="w-full formgrid grid pt-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.description</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <textarea
                                                class="w-full"
                                                rows="5" 
                                                cols="30"
                                                pInputTextarea 
                                                formControlName="description">
                                            </textarea>
                                        </div>
                                    </div>

                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.map_address</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            @if (showGeoLocation) {
                                                <app-geo-search [moduleName]="moduleName" [(form)]="addEventForm" [field]="geoLocationAddressField" [attributeLabels]="attributeLabels"></app-geo-search>
                                                <input style="display: none;" type="text" pInputText formControlName="event_address" class="w-full"/>
                                            }
                                           @if (addEventForm.controls.event_geolocation.errors && (addEventForm.controls.event_geolocation.dirty || addEventForm.controls.event_geolocation.touched)) {
                                            <div class="form-errors ">
                                            <div [hidden]="!addEventForm.controls.event_geolocation.errors.required" class="text-err" translate>
                                                <i class="error_outline"></i>
                                                events.map_address_is_required
                                            </div>
                                        </div>
                                        }
                                        </div>
                                        <div class="col-12 mb-1 align-content-end">
                                            <label translate>events.lbl_this_address_will_be_used_to_determine_the_timezone_for_this_event</label> 
                                        </div>
                                    </div>
                                </div>

                                <div class="w-full formgrid grid pt-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.event_logo</label>
                                        </div>
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label translate>events.lbl_this_image_will_appear_in_event_list</label>
                                        </div>
                                        <div class="col-12">
                                            @if (showImgUpload) {
                                                <app-mi-upload-file
                                                    [showImg]="true"
                                                    [isImageFile]="true"
                                                    [isMultipleFilesUpload]="false"
                                                    [field]="imgField"
                                                    [docType]="'profile'"
                                                    [moduleName]="'event'"
                                                    [attributeLabels]="attributeLabels"
                                                    (fileUploadEvent)="fileUploadEvent($event)"
                                                ></app-mi-upload-file>
                                            }

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 pl-3 pr-3 tab-group">
                            <div class="event-card card p-fluid">
                                <label class="w-full mt-0 uppercase text-lg font-semibold" translate>events.lbl_related_settings</label>

                                <div class="w-full formgrid grid pt-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.transportation_type</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <p-multiSelect #miSelect
                                                [options]="transportationTypeList"
                                                formControlName="transportationType"
                                                [placeholder]="'events.select_transportation_type' | translate"
                                                optionValue="labelValue"
                                                optionLabel="labelValue"
                                                [display]="multiSelectDisplay"
                                                maxSelectedLabels="10"
                                                (onFilter)="onFilter($event)">
                                                    <ng-template pTemplate="filtericon">
                                                        <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
                                                    </ng-template>
                                            </p-multiSelect>
                                            @if (addEventForm.controls.transportationType.errors && (addEventForm.controls.transportationType.dirty || addEventForm.controls.transportationType.touched)) {
                                                <div class="form-errors">
                                                <div [hidden]="!addEventForm.controls.transportationType.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    events.transportation_type_is_required
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div class="w-full formgrid grid pt-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.transportation_start_date</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <p-calendar formControlName="startDate" [showIcon]="true" [dateFormat]="dateFormat" appendTo="body"
                                                (onSelect)="onStartDateSelect($event)" [minDate]="currentDate"
                                                [placeholder]="'events.select_transportation_start_date' | translate"></p-calendar>
                                            @if (addEventForm.controls.startDate.errors && (addEventForm.controls.startDate.dirty || addEventForm.controls.startDate.touched)) {
                                                <div class="form-errors">
                                                <div [hidden]="!addEventForm.controls.startDate.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    events.start_date_is_required
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>

                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.transportation_end_date</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <p-calendar formControlName="endDate" [showIcon]="true" [dateFormat]="dateFormat" appendTo="body"
                                                (onSelect)="onEndDateSelect($event)" [minDate]="currentDate"
                                                [placeholder]="'events.select_transportation_end_date' | translate"></p-calendar>
                                            @if (addEventForm.controls.endDate.errors && (addEventForm.controls.endDate.dirty || addEventForm.controls.endDate.touched)) {
                                                <div class="form-errors">
                                                <div [hidden]="!addEventForm.controls.endDate.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    events.end_date_is_required
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div class="w-full formgrid grid pt-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.start_booking_date_passenger_app</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <p-calendar formControlName="startBookingDate" [showIcon]="true" [dateFormat]="dateFormat" appendTo="body"
                                                (onSelect)="onStartBookingDateSelect($event)" [minDate]="transportationStartDate" [maxDate]="transportationEndDate"
                                                [placeholder]="'events.select_start_booking_date' | translate"></p-calendar>
                                            @if (addEventForm.controls.startBookingDate.errors && (addEventForm.controls.startBookingDate.dirty || addEventForm.controls.startBookingDate.touched)) {
                                                <div class="form-errors">
                                                <div [hidden]="!addEventForm.controls.startBookingDate.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    events.start_booking_date_is_required
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>

                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.stop_booking_date_passenger_app</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <p-calendar formControlName="endBookingDate" [showIcon]="true" [dateFormat]="dateFormat" appendTo="body"
                                                (onSelect)="onEndBookingDateSelect($event)"  [minDate]="transportationStartDate" [maxDate]="transportationEndDate"
                                                [placeholder]="'events.select_stop_booking_date' | translate"></p-calendar>
                                            @if (addEventForm.controls.endBookingDate.errors && (addEventForm.controls.endBookingDate.dirty || addEventForm.controls.endBookingDate.touched)) {
                                                <div class="form-errors">
                                                <div [hidden]="!addEventForm.controls.endBookingDate.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    events.end_booking_date_is_required
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div class="w-full formgrid grid pt-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.operating_radius</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <div class="relative">
                                                <p-inputNumber formControlName="operatingRadius" [min]="1" [max]="100" mode="decimal" [useGrouping]="false" class="w-full" 
                                                [placeholder]="'events.select_operating_radius' | translate"> </p-inputNumber>
                                                <span class="unit right">mi</span>
                                            </div>
                                           
                                            @if (addEventForm.controls.operatingRadius.errors && (addEventForm.controls.operatingRadius.dirty || addEventForm.controls.operatingRadius.touched)) {
                                                <div class="form-errors">
                                                <div [hidden]="!addEventForm.controls.operatingRadius.errors.required" class="text-err" translate>
                                                    <i class="error_outline"></i>
                                                    events.operating_radius_is_required
                                                </div>
                                            </div>
                                            }
                                            <label translate>events.lbl_the_maximum_distance_the_services_can_be_provided_or_operate_effectively</label>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                </form>
              </div>

    </p-tabPanel>

    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
              <i class="mi-lg mi-settings mr-2"></i>
              <span translate>events.time_and_regional_settings</span>
            </div>
          </ng-template>
              <div class="event-details">
                <form  [formGroup]="addEventForm" class="details-group">
                                @for(tab of timeSettingsAttributeData?.tabs; track tab?.tabCode) {
                                    <app-mi-form
                                        [attributeLabels]="attributeLabelsForTimeSettings"
                                        [moduleName]="timeSettingsModuleName"
                                        [attributeValues]="attributeValues"
                                        [groups]="tab.groups"
                                        [isMultipleFilesUpload]="false"
                                        [previousBtnText]="previousBtnLabel"
                                        [submitBtnText]="saveTimeSettingsBtnLabel"
                                        (saveData)="saveTimeSettings($event)"
                                        [saveBtnId]="'time'"
                                        [activeIndex]=activeIndex
                                        [hideCancelAndSubmitBtn]="true"
                                        >
                                    </app-mi-form>
                                }
                        <div class="col-12 pl-3 pr-3 pt-2 tab-group">
                            <div class="event-card card p-fluid">
                                <label class="w-full mt-0 uppercase text-lg font-semibold" translate>events.lbl_regional_settings</label>

                                <div class="w-full formgrid grid pt-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.short_date_format</label>
                                            <span class="required ml-1">*</span>
                                        </div>
                                        <div class="col-12">
                                            <p-dropdown [options]="dateFormatList"
                                             optionLabel="name" [showClear]="false"></p-dropdown>
                                        </div>
                                    </div>
                                </div>


                                <div class="w-full formgrid grid pt-3">
                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.time_zone</label>
                                        </div>
                                        <div class="col-12">
                                            <p-dropdown [options]="dateFormatList"
                                             optionLabel="name" [showClear]="false"></p-dropdown>
                                        </div>
                                    </div>

                                    <div class="col-6 pl-0 pr-0">
                                        <div class="col-12 mb-1 align-content-end md:col-6">
                                            <label class="label-text text-base" translate>events.time_format</label>
                                        </div>
                                        <div class="col-12">
                                            <p-dropdown [options]="dateFormatList"
                                             optionLabel="name" [showClear]="false"></p-dropdown>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                </form>
              </div>

    </p-tabPanel>

    <div class="flex justify-content-end flex-wrap pb-1 btn-div pr-3 pt-2">
        <button pButton class="cancel-btn-margin btn-bg-color default-text-light-black-color cursor-pointer border-button"
            [label]="'cancel' | translate" (click)="onCancel()"></button>
        <p-button class="save-btn-margin inline-flex height-44" (click)="saveEvent()"  [label]="nextBtnLabel"></p-button>
    </div>
</p-tabView>
</div>
