<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'MANAGE_ZONES', pageCode: 'ALL', subPageCode: 'VIEW' }">
<div class="flex flex-row justify-content-between bg-white p-3 mt-1 sticky">
  <div>
    <span class="font-20 font-semibold default-text-black-color mr-1"
      >{{ zoneItem?.zoneName }}
    </span>
    <span class="font-16 font-normal default-text-light-black-color"
      >({{ zoneItem?.aliasName }})
    </span>
  </div>
  <div>
    <div appAccessProvider [accessCode]="{ menuCode: 'MANAGE_ZONES', pageCode: 'ALL', subPageCode: 'EDIT' }">
      <button
        icon="mi-lg inline-block wh-20 edit_pencil vertical-align-bottom"
        (click)="editZone(zoneId)"
        pButton
        class="bg-white text-color mr-1 edit-btn font-16 font-semibold"
        [label]="editBtnLabel">
      </button>
    </div>
  </div>
</div>
<div class="grid m-2 wrapper overflow-y-auto">
  <div class="col-6">
    <div class="card padding-16 pb-0">
      <div class="font-18 font-semibold pb-16 uppercase" translate>
        manage_zones.details
      </div>
      <div class="flex flex-row align-items-center pb-10 border-bottom-1 border-100">
        <div class="w-6 font-14 font-normal default-print-label-color" translate>
          manage_zones.status
        </div>
        <div [style.background-color]="zoneItem?.active ? '#32cd32' : 'red'" class="wh-10 mr-2" style="border-radius: 50%"></div>
        <div class="font-14 font-medium">{{zoneItem?.active ? 'Active' : 'Inactive'}}</div>
      </div>
      <div class="flex flex-row py-10 border-bottom-1 border-100">
        <div class="w-6 font-14 font-normal default-print-label-color" translate>
          manage_zones.dispatch_zone_name
        </div>
        <div class="w-6 font-14 font-normal">{{ zoneItem?.zoneName }}</div>
      </div>
      <div class="flex flex-row py-10 border-bottom-1 border-100">
        <div class="w-6 font-14 font-normal default-print-label-color" translate>
          manage_zones.alias_name
        </div>
        <div class="w-6 font-14 font-normal">{{ zoneItem?.aliasName }}</div>
      </div>
      <div class="flex flex-row py-10">
        <div class="w-6 font-14 font-normal default-print-label-color" translate>
          manage_zones.description
        </div>
        <div class="w-6 font-14 font-normal flex-wrap description-content ">
          {{ zoneItem?.description ? zoneItem?.description : "--" }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="card padding-16 pb-0">
      <div class="font-18 font-semibold pb-16 uppercase" translate>
        manage_zones.geofence
      </div>
      <div class="flex flex-row pb-10 border-bottom-1 border-100">
        <div class="w-6 font-14 font-normal default-print-label-color" translate>
          manage_zones.latitude
        </div>
        <div class="w-6 font-14 font-normal">
          {{ zoneItem?.latitude }}
        </div>
      </div>
      <div class="flex flex-row py-10 border-bottom-1 border-100">
        <div class="w-6 font-14 font-normal default-print-label-color" translate>
          manage_zones.longitude
        </div>
        <div class="w-6 font-14 font-normal">
          {{ zoneItem?.longitude }}
        </div>
      </div>
      <div class="flex flex-row py-10">
        <div class="w-6 font-14 font-normal default-print-label-color" translate>
          manage_zones.radius
        </div>
        <div class="w-6 font-14 font-normal">
          {{ zoneItem?.radius ? zoneItem?.radius : "0" }}
          <span translate>manage_zones.meters</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card padding-16" style="height: 610px">
      <div class="font-18 font-semibold pb-16 uppercase" translate>
        manage_zones.dispatch_zone
      </div>
      <app-zone-map
        [zoneDetailsItem]="zoneDetailsItem"
        [zoneType]="zoneType"
      ></app-zone-map>
    </div>
  </div>
</div>
</div>
