<div class="main-settings">
    <p-tabView>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-14 mr-2 relative"></i>
                <span translate>settings.lbl_custom_fields</span>
            </ng-template>
            <div class="flex h-full overflow-hidden">
                <div class="col-4 col:md-3 col:lg-4 p-2">
                    <div class="card background-not-custom-field">
                        <div class="font-semibold pb-2">
                            <label class="navigation-item-label font-20" translate>settings.custom_fields.lbl_custom_fields_upper</label>
                            @if(isShowCustomFieldList) {
                                <div appAccessProvider [accessCode]="{ menuCode: 'SYSTEM_SETTINGS', pageCode: 'CUSTOM_FIELDS', subPageCode: 'ADD' }" class="field-button">
                                    <span class="float-r default-text-blue-color font-14 cursor-pointer font-semibold" (click)="addCustomLabel()" translate><i class="{{miIcons.PLUS}} wh-14 inline-block"></i>settings.custom_fields.lbl_add_new</span>
                                </div>
                            }
                        </div>
                        <div class="add-new-field">
                            <label class="font-12 default-print-label-color" translate>settings.custom_fields.lbl_these_custom_fields_text</label>
                        </div>
                        <div class="grid custom-field-div">
                            <div class="form-field col-6 md:col-6 lg:col-6 field">
                                <div class="form-control">
                                    <p-dropdown class="module-name-dropdown p-component p-element w-full" [options]="moduleNameList" formControlName="moduleName" 
                                        (onChange)="getSelectedModuleNameFilter($event)" optionLabel="label" optionValue="key" [placeholder]="'settings.custom_fields.lbl_all_modules' | translate" appendTo="body">
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>
                        <hr>
                        @if(isShowCustomFieldList) {
                            <div appAccessProvider [accessCode]="{ menuCode: 'SYSTEM_SETTINGS', pageCode: 'CUSTOM_FIELDS', subPageCode: 'LIST' }">
                                <div class="p-0 border-none border-noround custom-field-list">
                                    <ul class="m-0 p-0">
                                        @for(item of attributeCodes; track item){
                                            <li class="py-3 pl-6 pr-3 list-none font-16 font-medium flex" 
                                                [class.selected]="item === selectedAttributeCodes" 
                                                (click)="selectCustomList(item)"
                                                (mouseenter)="onMouseEnter(item)" 
                                                (mouseleave)="onMouseLeave()">
                                                <i class="{{miIcons.BOOKING_WATCHLIST}} wh-24 inline-block"></i>
                                                {{item}}
                                                <i class="wh-24 inline-block right-icon mr-0" (click)="onIconClick($event, menu)"
                                                    [ngClass]="item === hoveredItem ? miIcons.MORE_HORIZONTAL : miIcons.CARET_RIGHT"></i>
                                                <div class="custom-field-menu">
                                                    <p-menu #menu styleClass="menu-width" [model]="rowItems" [popup]="true" appendTo="body"></p-menu>
                                                </div>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        }
                        @else {
                            <div appAccessProvider [accessCode]="{ menuCode: 'SYSTEM_SETTINGS', pageCode: 'CUSTOM_FIELDS', subPageCode: 'ADD' }">
                                @if(!showCustomLabel) {
                                    <div class="add-custom-label">
                                        <div class="align-items-center" (click)="addCustomLabel()">
                                            <label class="flex justify-content-center default-text-light-gray font-18 cursor-pointer font-semibold" translate>settings.custom_fields.lbl_no_custom_fields_added</label>
                                            <label class="flex justify-content-center default-print-label-color font-14 cursor-pointer my-2" translate>settings.custom_fields.lbl_add_first_custom_field</label>
                                            <label class="flex justify-content-center default-text-blue-color font-14 cursor-pointer font-semibold" translate><i class="{{miIcons.PLUS}} wh-14 inline-block"></i>settings.custom_fields.lbl_add_custom_fields_btn</label>
                                        </div>
                                    </div>
                                }
                                @else {
                                    <input [(ngModel)]="fieldName" class="p-component p-element w-full" type="text" pInputText/>
                                    <div class="flex mt-3 justify-content-end custom-add-field">
                                        <button pButton class="border-none bg-white default-text-light-black-color" [label]="'cancel' | translate" (click)="onCancelCustomLabel()"></button>
                                        <button pButton class="" [label]="'lbl_save' | translate" (click)="onAddCustomLabel()"></button>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div class="col-8 col:md-9 col:lg-8 p-2">
                    <div class="card background-not-details">
                        <div class="flex justify-content-between">
                            <div class="flex-column">
                                <div class="font-semibold pb-2">
                                    <label class="font-20" translate>settings.custom_fields.lbl_details_upper</label>
                                </div>
                                <div class="">
                                    <label class="font-12 default-print-label-color" translate>settings.custom_fields.lbl_customize_how_you_want_to_show_text</label>
                                </div>
                            </div>
                            @if(isCustomFieldDetailedData) {
                                <div class="flex justify-content-end">
                                    <div class="flex justify-content-end">
                                        <p-button icon="pi pi-pencil"  [label]="'settings.passenger.edit' | translate" [text]="true" (click)="editCustomField()"/>
                                        <p-button icon="pi pi-minus-circle"  [label]="deleteLabel" [text]="true" severity="danger" (click)="delete()"/>
                                        </div> 
                                </div>
                            }
                        </div>
                        <hr>
                        @if(isCustomFieldDetailedData) {
                            <div appAccessProvider [accessCode]="{ menuCode: 'SYSTEM_SETTINGS', pageCode: 'CUSTOM_FIELDS', subPageCode: 'VIEW' }">
                                <div class="flex border-bottom-settings p-1">
                                    <div class="col-3"translate>settings.custom_fields.lbl_module</div>
                                    <div class="col-9 font-semibold">{{entityCodeForCustomFieldDetails || '-'}}</div>
                                </div>
                                <div class="flex border-bottom-settings p-1">
                                    <div class="col-3"translate>settings.custom_fields.lbl_tab</div>
                                    <div class="col-9 font-semibold">{{tabCodeForCustomFieldDetails || '-'}}</div>
                                </div>
                                <div class="flex border-bottom-settings p-1">
                                    <div class="col-3"translate>settings.custom_fields.lbl_sections</div>
                                    <div class="col-9 font-semibold">{{sectionForCustomFieldDetails || '-'}}</div>
                                </div>
                                <div class="flex border-bottom-settings p-1">
                                    <div class="col-3"translate>settings.custom_fields.lbl_data_type</div>
                                    <div class="col-9 font-semibold">{{customFieldDetailedData?.inputType?.inputName || '-'}}</div>
                                </div>
                                <div class="flex border-bottom-settings p-1">
                                    <div class="col-9"translate>settings.custom_fields.lbl_content</div>
                                    @for(item of customFieldDetailedData.attributePresetValues; track item){
                                        <div class="font-semibold">{{item.labelKey || '-'}}</div>
                                        <span>|</span>
                                    }
                                </div>
                                <div class="flex border-bottom-settings p-1">
                                    <div class="col-3"translate>settings.custom_fields.lbl_allow_action</div>
                                    <div class="col-9 font-semibold">{{allowActionsViewArray || '-'}}</div>
                                </div>
                                <div class="flex border-bottom-settings p-1">
                                    <div class="col-3"translate>settings.custom_fields.lbl_information_text</div>
                                    <div class="col-9 font-semibold">{{helpTipsLabel || '-'}}</div>
                                </div>
                                <div class="flex border-bottom-settings p-1">
                                    <div class="col-3"translate>settings.custom_fields.lbl_note</div>
                                    <div class="col-9 font-semibold">{{exampleLabel || '-'}}</div>
                                </div>
                                <div class="flex border-bottom-settings p-1">
                                    <div class="col-3"translate>settings.custom_fields.lbl_validation</div>
                                    @for(item of displayedPatterns; track item){
                                        <div class="col-9 font-semibold">{{item}}</div>
                                    }
                                </div>
                                <div class="flex border-bottom-settings p-1">
                                    <div class="col-3"translate>settings.custom_fields.lbl_required_field</div>
                                    <div class="col-9 font-semibold">{{requiredYesNo || '-'}}</div>
                                </div>
                            </div>
                        }
                        @else {
                            @if(isFormShow){
                                <div appAccessProvider [accessCode]="{ menuCode: 'SYSTEM_SETTINGS', pageCode: 'CUSTOM_FIELDS', subPageCode: 'ADD' }">
                                    <form [formGroup]="customFieldForm">
                                        <div class="grid">
                                            <div class="form-field col-10 md:col-10 lg:col-10 field">
                                                <label class="flex" translate>settings.custom_fields.lbl_module
                                                    <span class="required">*</span>
                                                </label>
                                                <div class="form-control">
                                                    <p-dropdown class="module-name-dropdown p-component p-element w-full" [options]="moduleNameList" formControlName="moduleName" 
                                                        (onChange)="getSelectedModuleName($event)" optionLabel="label" optionValue="key" [placeholder]="'settings.custom_fields.lbl_please_select' | translate">
                                                    </p-dropdown>
                                                    <div class="mt-1">
                                                        <label class="font-12" for="moduleName" translate>settings.custom_fields.lbl_select_the_module_text</label>
                                                    </div>
                                                </div>
                                                @if (customFieldForm.controls.moduleName.errors && (customFieldForm.controls.moduleName.dirty || customFieldForm.controls.moduleName.touched)) {
                                                    <div class="form-errors mt-1 required-field">
                                                        <div [hidden]="!customFieldForm.controls.moduleName.errors.required" translate>
                                                            settings.custom_fields.lbl_module_required
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div class="form-field col-10 md:col-10 lg:col-10 field">
                                                <label class="flex" translate>settings.custom_fields.lbl_tab</label>
                                                <div class="form-control">
                                                    <p-dropdown class="module-name-dropdown p-component p-element w-full" [options]="uniqueTabsArray()" formControlName="tab" 
                                                        [placeholder]="'settings.custom_fields.lbl_please_select' | translate">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="form-field col-10 md:col-10 lg:col-10 field">
                                                <label class="flex" translate>settings.custom_fields.lbl_sections</label>
                                                <div class="form-control">
                                                    <p-dropdown class="module-name-dropdown p-component p-element w-full" [options]="uniqueGroupsArray()" formControlName="sections" 
                                                        [placeholder]="'settings.custom_fields.lbl_please_select' | translate">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="form-field col-10 md:col-10 lg:col-10 field">
                                                <label class="flex" translate>settings.custom_fields.lbl_data_type
                                                    <span class="required">*</span>
                                                </label>
                                                <div class="form-control">
                                                    <p-dropdown class="module-name-dropdown p-component p-element w-full" [options]="dataTypes" formControlName="dataType" 
                                                        optionLabel="inputName" (onChange)="onChangeDataType($event)"
                                                        [placeholder]="'settings.custom_fields.lbl_please_select' | translate">
                                                    </p-dropdown>
                                                    <div class="mt-1">
                                                        <label class="font-12" for="dataType" translate>settings.custom_fields.lbl_select_the_type_text</label>
                                                    </div>
                                                </div>
                                                @if (customFieldForm.controls.dataType.errors && (customFieldForm.controls.dataType.dirty || customFieldForm.controls.dataType.touched)) {
                                                    <div class="form-errors mt-1 required-field">
                                                        <div [hidden]="!customFieldForm.controls.dataType.errors.required" translate>
                                                            settings.custom_fields.lbl_data_type_required
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div class="form-field col-10 md:col-10 lg:col-10 field">
                                                <label class="flex" translate>settings.custom_fields.lbl_content</label>
                                                <div class="form-control">
                                                    <p-chips [(ngModel)]="contentArray" (onAdd)="updateContentArray()" (onRemove)="updateContentArray()" [ngModelOptions]="{standalone: true}" [disabled]="isChipsDisabled"></p-chips>
                                                    <div class="mt-1">
                                                        <label class="font-12" for="content" translate>settings.custom_fields.lbl_you_can_add_multiple_items</label>
                                                        <div class="float-r">
                                                            <p-checkbox class="mr-2" [binary]="true" formControlName="otherOption" inputId="otherOption" (onChange)="onOtherOptionChange()" [disabled]="isChipsDisabled"></p-checkbox>
                                                            <label for="otherOption" translate>settings.custom_fields.lbl_add_option_of_other</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-field col-10 md:col-10 lg:col-10 field">
                                                <label class="flex" translate>settings.custom_fields.lbl_allow_action</label>
                                                <div class="form-control my-3">
                                                    <p-checkbox formControlName="allowActionSearch" class="mr-2" [binary]="true" inputId="allowActionSearch"></p-checkbox>
                                                    <label for="allowActionSearch" translate>settings.custom_fields.lbl_search</label>
                                                </div>
                                                <div class="form-control my-3">
                                                    <p-checkbox formControlName="allowActionAdvanceFilter" class="mr-2" [binary]="true" inputId="allowActionAdvanceFilter"></p-checkbox>
                                                    <label for="allowActionAdvanceFilter" translate>settings.custom_fields.lbl_advance_filters</label>
                                                </div>
                                                <div class="form-control mt-3">
                                                    <p-checkbox formControlName="allowActionManageColumn" class="mr-2" [binary]="true" inputId="allowActionManageColumn"></p-checkbox>
                                                    <label for="allowActionManageColumn" translate>settings.custom_fields.lbl_manage_columns</label>
                                                </div>
                                            </div>
                                            <div class="form-field col-10 md:col-10 lg:col-10 field">
                                                <label class="flex" translate>settings.custom_fields.lbl_information_text</label>
                                                <div class="form-control">
                                                    <textarea rows="3" cols="60" pInputTextarea formControlName="informationText" class="w-full"></textarea>
                                                    <div class="mt-1">
                                                        <label class="font-12" for="informationText" translate>settings.custom_fields.lbl_additional_details_about_this_field_text</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-field col-10 md:col-10 lg:col-10 field">
                                                <label class="flex" translate>settings.custom_fields.lbl_note
                                                    <span class="required">*</span>
                                                </label>
                                                <div class="form-control">
                                                    <input formControlName="note" class="p-component p-element w-full" type="text" pInputText/>
                                                    <div class="mt-1">
                                                        <label class="font-12" for="note" translate>settings.custom_fields.lbl_this_note_is_to_get_more_information_text</label>
                                                    </div>
                                                </div>
                                                @if (customFieldForm.controls.note.errors && (customFieldForm.controls.note.dirty || customFieldForm.controls.note.touched)) {
                                                    <div class="form-errors mt-1 required-field">
                                                        <div [hidden]="!customFieldForm.controls.note.errors.required" translate>
                                                            settings.custom_fields.lbl_note_required
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div class="form-field col-10 md:col-10 lg:col-10 field">
                                                <label class="flex" translate>settings.custom_fields.lbl_validation</label>
                                                <div class="form-control w-full">
                                                    <p-multiSelect class="validation-multiselect" appendTo="body" [options]="validationList" formControlName="validation" optionLabel="label" 
                                                        optionValue="value" [placeholder]="'settings.custom_fields.lbl_please_select' | translate" (onChange)="onChangeValidation($event)">
                                                    </p-multiSelect>
                                                </div>
                                            </div>
                                            <div class="form-field col-10 md:col-10 lg:col-10 field">
                                                <div class="form-control">
                                                    <p-checkbox class="mr-2" [binary]="true" formControlName="requiredField" inputId="requiredField"></p-checkbox>
                                                    <label for="requiredField" translate>settings.custom_fields.lbl_required_field</label>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="flex justify-content-end">
                                    <button pButton class="mr-2 mb-2 border-none bg-white default-text-light-black-color" [label]="'cancel' | translate" (click)="onCancel()"></button>
                                    @if(attributeId) {
                                        <div appAccessProvider [accessCode]="{ menuCode: 'SYSTEM_SETTINGS', pageCode: 'CUSTOM_FIELDS', subPageCode: 'EDIT' }">
                                            <p-button class="mb-2 flex align-items-center justify-content-center" [disabled]="!customFieldForm.valid" [label]="'lbl_update' | translate" (click)="updateCustomFields()"></p-button>
                                        </div>
                                    }
                                    @else {
                                        <div appAccessProvider [accessCode]="{ menuCode: 'SYSTEM_SETTINGS', pageCode: 'CUSTOM_FIELDS', subPageCode: 'ADD' }">
                                            <p-button class="mb-2 flex align-items-center justify-content-center" [disabled]="!customFieldForm.valid" [label]="'lbl_save' | translate" (click)="onAddCustomFields()"></p-button>
                                        </div>
                                    }
                                </div>
                            }
                        }
                    </div>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>
