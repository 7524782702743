<p-dialog [header]="'lbl_modify_default_vehicle' | translate" modal="true" (onHide)="onHide($event)" [(visible)]="visibleModify" [style]="{ width: '40vw' }">
    <div class="flex">
        <span class="p-input-icon-left relative right-15">
          <i class="pi pi-search relative left-20"></i>
          <input type="text" pInputText (input)="onSearch($event, tableData)" [placeholder]="'lbl_search' | translate" />
        </span>
    </div>
    <div class="flex justify-content-between align-items-center grid pt-2">
        @for(data of tableData; track data; let i = $index) {
            <div class="flex justify-content-between align-items-center w-full">
                <div class="flex align-items-center">
                    <p-radioButton name="data" [value]="data?.id" class="flex ml-2" [(ngModel)]="selectedModify"></p-radioButton>
                    <div class="flex flex-column">
                        <div class="col-12 lg:col-6 xl:col-3 flex align-items-center white-space-nowrap relative top-5">
                            {{data?.name_code}} - {{data?.reg_number}} - {{data?.vehicle_status}}
                        </div>
                        <div class="col-12 lg:col-6 xl:col-3 flex align-items-center white-space-nowrap relative bottom-5">
                            {{data?.vehicle_type}} | {{data?.manufacturer}} | {{data?.fuel_type}}
                        </div>
                    </div>
                </div>
                <div>
                    <button pButton class="bg-white default-text-black-color border-1 border-black-alpha-20 px-10px" (click)="rowData?.default_vehicle_id === data?.id ? removeDefaultVehicle() : selectDefaultVehicle(data) " 
                        [label]="rowData?.default_vehicle_id === data?.id ? ('lbl_remove' | translate) : ('lbl_select' | translate)"></button>
                </div>
            </div>
        }
        @if (tableData?.length === 0) {
            <div class="w-full pt-0 pr-2 pb-0 pl-3">
              <app-no-result-found></app-no-result-found>    
            </div>         
        }
    </div>
</p-dialog>