<div class="mi-table">
  <div class="flex mi-filter-container">
    <div class=" flex flex-wrap justify-content-center">

      <div class="flex-1 flex justify-content-end mr-2">
        <span class="p-input-icon-left ">
          <i
          class="pi search-icon relative left-20 cursor-pointer"
          [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue }"
          (click)="clearSearch()"
        ></i>
          <input class="width-200 search-filter" type="text" pInputText  (input)="onSearch($event)"
            [placeholder]="'lbl_search' | translate" [(ngModel)]="searchValue"
          />
        </span>
      </div>

      @if (!exclude_modules.includes(moduleName)) {
        @if(filterList &&  filterList?.length>0){
          <app-quick-filter-form [fields]="filterList?.slice(0,filterSize)" [moduleName]="moduleName ? moduleName : uniqueId" class="relative mr-2 "
          [attributeLabels]="attributeLabels" [attributeValues]="filterValues" (onValueChange)="onFilterValueChange(false, $event)"></app-quick-filter-form>
        }


        @if(filterList && filterList.length>filterSize){
          <app-filter-box [filterList]="filterList?.slice(filterSize,filterList.length)" [moduleName]="moduleName" [show]="show" [position]="position"
            [selectedFilterCount]="selectedFilterCount" [filterAttributeLabels]="attributeLabels" [attributeValues]="filterValues" class="filter-box-div"
          (click)="onFiltersPopup()" (onValueChange)="onFilterValueChange(true, $event)" (onToggleOverLay)="show = false" isFilterBox="true"></app-filter-box>

          <p-button
          label="{{'filters_lbl' | translate}}"
          icon="{{miIcons.TEXT_ALIGN}} inline-block wh-13 relative left-5 top-1"
          class="filter-secondary mr-2"
          [badge]="selectedFilterCount>0&&selectedFilterCount"
          badgeClass="filter-btn"
          (click)="showFilterDialog('right')"/>

          <!-- <button badge="8"   badgeClass="p-badge-info"  class="flex width-90 surface-ground text-color border-0 cursor-pointer" pButton  label="{{'filters_lbl' | translate}}">
            <span><i class="{{miIcons.TEXT_ALIGN}} inline-block wh-13 relative left-5 top-1"></i></span>
          </button> -->
        }
        @if(selectedFilterCount>0){
          <button   pButton [label]="'clear_all' | translate" (click)="clearFilter()" class="mr-2 bg-white text-primary border-none cursor-pointer clear-btn"></button>
        }

      } @else {
        @if (filterList && filterList?.length>0) {
          <app-quick-filter-form [fields]="filterList?.slice(0,4)" [moduleName]="moduleName" class="relative mr-2 "
            [attributeLabels]="attributeLabels" (onValueChange)="onFilterValueChange(false, $event)"></app-quick-filter-form>
        }
      }
  </div>

    <div class=" flex-1 flex align-items-center justify-content-end">
      <p-paginator [first]="first2" [rows]="rows2" [totalRecords]="totalRecords" (onPageChange)="onPageChange2($event)"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first}-{last} of {totalRecords}" [showPageLinks]="false"
        [showFirstLastIcon]="false"></p-paginator>
      <div class="flex justify-content-end">
        <app-per-page-columns [columns]="columns"
          [collectionOfColumns]="collectionOfColumns"
        (onToggleManageColumn)="onToggleManageColumn()" (itemPerPage)="updateRows($event)" [itemsPerPage]="itemsPerPage"></app-per-page-columns>
      </div>
    </div>
  </div>


  @if(!EXCLUDE_CHECKBOX?.includes(entityName) && !EXCLUDE_CHECKBOX?.includes(staticEntity)) {
    @if (selectedRows?.length>0) {
      <div class="mt-2 pb-2 border-bottom-1 border-200 leave-list">
        <p-button icon="pi pi-chevron-down" [badge]="selectedRows.length" badgeClass="badge-selected-row" class="mr-2 button-with-icon cursor-pointer" severity="secondary"
          (click)="actions.toggle($event)" [label]="'lbl_actions' | translate"></p-button>
        <p-menu #actions [model]="bulkSelectionsItems" [popup]="true" appendTo="body"></p-menu>
        <button pButton [label]="'clear_selection' | translate" (click)="clearSelected()" class="mr-2 bg-white text-primary border-none cursor-pointer clear-btn"></button>
      </div>
    }
  }

  <p-table [scrollable]="true" scrollHeight="calc(100vh - 176px)" [columns]="selectedColumns" [value]="data" [loading]="isLoading"
    [tableStyle]="{ 'min-width': '100rem' }" [(selection)]="selectedRows" selectionMode="multiple" (selectionChange)="selectionChange()"
    (onRowSelect)="onRowSelect($event)" #dt1>
    <ng-template pTemplate="header" let-columns>
      <tr>
        @if(!EXCLUDE_CHECKBOX?.includes(entityName) && !EXCLUDE_CHECKBOX?.includes(staticEntity)  && !isDeletedTab) {
          <th>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
        }
        @for (col of columns; track col.field; let idx = $index) {
          @if (idx === 0) {
            <th [pSortableColumn]="col.field" class="flex align-items-center">
              {{ col.header }}
              @if (sortable) {
                <p-sortIcon [field]="col.field"></p-sortIcon>
              }
            </th>
          }
          @if (idx !== 0) {
            @if (col.attributeCode === 'transportation_end_date') {
              <th [pSortableColumn]="col.field">
                {{ col.header }} <i [pTooltip]="titleForTransportationEndDate" tooltipPosition="right" class="mi-lg issues-icon ml-2"></i>
              </th>
            } @else {
              <th class="capitalize" [pSortableColumn]="col.field">
                {{ col.header }}
              </th>
            }
          }
        }
        <th translate alignFrozen="right" pFrozenColumn>
          <!-- lbl_action -->
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
      @if (data?.length > 0) {
        <tr [pSelectableRow]="rowData" (mouseover)="rowMouseOver(rowData)" (mouseout)="rowMouseOut(rowData)" [ngClass]="{'deleted-row': rowData.deleted}">
          @if(!EXCLUDE_CHECKBOX?.includes(entityName) && !EXCLUDE_CHECKBOX?.includes(staticEntity) && !isDeletedTab) {
            <td style="width: 4rem">
              <p-tableCheckbox [value]="rowData" (click)="toggleCheckbox($event)" [disabled]="rowData.deleted"></p-tableCheckbox>
            </td>
          }
          @for (col of columns; track col) {
            <td style="min-width:200px" class="ellipsis">
              <div>
                @switch (col.attributeCode) {
                  @case ('startTime') {
                    <div>
                      <div class="inline-block vertical-align-middle">
                        <span class="ml-1 mt-1">{{ rowData[col.field] | date: HOUR_MINUTE_FORMAT }}</span>
                      </div>
                    </div>
                  }
                  @case ('endTime') {
                    <div>
                      <div class="inline-block vertical-align-middle">
                        <span class="ml-1 mt-1">{{ rowData[col.field] | date: HOUR_MINUTE_FORMAT }}</span>
                      </div>
                    </div>
                  }
                  @case ('createdOn') {
                    <div>
                      <div class="inline-block vertical-align-middle">
                        <span class="ml-1 mt-1">{{ rowData[col.field] | date: dd_MMM_yyyy_hh_mm_FORMAT }}</span>
                      </div>
                    </div>
                  }
                  @case ('breakCount') {
                    <div>
                      <div class="inline-block vertical-align-middle">
                          <span class="ml-1 mt-1">{{ rowData[col.field] === 1 ? rowData[col.field] + breakLabel : rowData[col.field] + breaksLabel }}</span>
                      </div>
                    </div>
                  }
                  @case ('name_code') {
                    <div>
                      <div class="flex vertical-align-middle">
                        @if (rowData?.vehicle_images && rowData?.vehicle_images[0]) {
                          <app-mi-image-container [miImage]="rowData.vehicleImage" miHeight="26" miWidth="26"></app-mi-image-container>
                        } @else {
                          <div class="passenger-no-image mr-2">
                            <img [src]="defaultLoaderImg" class="vertical-align-middle border-round-sm mr-2" />
                          </div>
                        }
                        <span class="ml-1 mt-1">{{ rowData[col.field] }}</span>
                      </div>
                    </div>
                  }
                  @case ('email') {
                    <div>
                      <a [href]="'mailto:' + rowData[col.field]"> {{ rowData[col.field] }}</a>
                    </div>
                  }
                  @case ('emailAddress') {
                    <div>
                      <div class="flex vertical-align-middle">
                       <div> {{rowData?.email}}</div>
                      </div>
                    </div>
                  }
                  @case ('first_name') {
                    <div>
                      <div class="flex vertical-align-middle">
                        @if ((rowData?.passenger_profile_image && rowData?.passenger_profile_image[0]) || (rowData?.driver_profile_image && rowData?.driver_profile_image[0])) {
                          <app-mi-image-container [miImage]="rowData.profileImage" miHeight="26" miWidth="26"></app-mi-image-container>
                        } @else {
                          <div class="passenger-no-image inline-flex mr-2">
                            <img [src]="defaultLoaderImg" class="vertical-align-middle border-round-sm mr-2" />
                          </div>
                        }
                        <span class="ml-1 mt-1">{{ rowData[col.field] }}</span>
                      </div>
                    </div>
                  }
                  @case ('select_location') {
                    <div>
                      <div class="flex vertical-align-middle">
                       <div> {{rowData?.selectLocation}}</div>
                      </div>
                    </div>
                  }
                  @case ('stay_home_address_section') {
                    <div>
                      <span class="ml-1 mt-1"
                        [pTooltip]="tooltipContent"
                       >
                          <ng-template #tooltipContent>
                              <div class="">
                                @for (address of rowData?.stayHomeAddress; track address; let i = $index) {
                                  <div> {{address}}</div><br/>
                                }
                              </div>
                          </ng-template>

                          {{rowData?.stayHomeAddress?.join(', ')?.length}} {{ rowData?.stayHomeAddress?.join(', ')?.length > 14 ? rowData?.stayHomeAddress?.join(', ')?.substring(0, 14) + '...' : rowData.stayHomeAddress?.join(', ') }}
                      </span>
                    </div>
                  }
                  @case ('fullName') {
                    <div>
                      <div class="flex vertical-align-middle">
                        @if (rowData?.driverProfileImageId && rowData?.driverProfileImageId[0]) {
                          <app-mi-image-container [miImage]="rowData.profileImage" miHeight="26" miWidth="26"></app-mi-image-container>
                        } @else {
                          <div class="passenger-no-image inline-flex mr-2">
                            <img [src]="defaultLoaderImg" class="vertical-align-middle border-round-sm mr-2" />
                          </div>
                        }
                        <span class="ml-1 mt-1">{{ rowData[col.field] }}</span>
                      </div>
                    </div>
                  }
                  @case ('event_name') {
                    <div>
                      <div class="flex vertical-align-middle">
                        @if (rowData?.eventLogoImgId) {
                          <app-mi-image-container [miImage]="rowData.profileImage" miHeight="26" miWidth="26"></app-mi-image-container>
                        } @else {
                          <div class="passenger-no-image inline-flex mr-2">
                            <img [src]="defaultLoaderImg" class="vertical-align-middle border-round-sm mr-2" />
                          </div>
                        }
                       
                       
                        <span class="ml-1 mt-1"
                            pTooltip="{{ rowData[col.field] }}"
                            [tooltipDisabled]="rowData[col.field].length <= 14">
                          {{ rowData[col.field].length > 14 ? rowData[col.field].substring(0, 14) + '...' : rowData[col.field] }}
                        </span>

                      </div>
                    </div>
                  }
                  @case('shiftName') {
                    <div>
                      <label class="capitalize">{{ rowData[col.field] }}</label>
                    </div>
                  }
                  @case('breakDuration') {
                    <div>
                      <label>{{ rowData['breakDurationStr'] }}</label>
                    </div>
                  }
                  @case('assignCount') {
                    <div>
                      @if (rowData[col.field]) {
                        <label>{{ rowData['assignedEntity'] === DRIVER_TYPE.DRIVERS ? rowData[col.field] + ' ' + cs.getLabel('shift.lbl_drivers') :  rowData[col.field] + ' ' + cs.getLabel('shift.groups')}}</label>
                      } @else {
                        <a class="text-primary cursor-pointer" (click)="assignDriver(rowData)">Assign</a>
                      }

                    </div>
                  }
                  @case('assignedEntity') {
                    <div>
                      @if (rowData['assignedEntity']) {
                        <label>{{ rowData['assignedEntity'] === DRIVER_TYPE.DRIVERS ? cs.getLabel('shift.individuals') :  cs.getLabel('shift.group')}}</label>
                      } @else {
                        <label>--</label>
                      }

                    </div>
                  }
                  @case('group_status') {
                    <div>
                      <app-status-cell [data]="rowData[col.field]"></app-status-cell>
                    </div>
                  }
                  @case ('org_status') {
                    @if (rowData[col.field]) {
                      <div class="inline-flex pl-0 pt-2 org-status">
                        <div>
                          <p-inputSwitch  [ngModel]="rowData[col.field]"
                          (onChange)="onOrganizationStatusChange(rowData, $event)"  (click)="stopPropagation($event)"></p-inputSwitch>
                        </div>
                      </div>
                    } @else {
                      <div class="inline-flex pl-0 pt-2">
                        <div>
                          <p-inputSwitch  [ngModel]="rowData[col.field]"
                        (onChange)="onOrganizationStatusChange(rowData, $event)" (click)="stopPropagation($event)"></p-inputSwitch>
                        </div>
                      </div>
                    }

                  }
                  @case ('event_status') {
                    @if (rowData[col.field] === EVENT_STATUS.ACTIVE) {
                      <div class="col-12 inline-flex">
                        <div>
                          <p-inputSwitch appendTo="body" [ngModel]="rowData[col.field] === EVENT_STATUS.ACTIVE"
                          (onChange)="onEventStatusChange(rowData, $event)" [disabled]="rowData['deleted']" (click)="stopPropagation($event)"></p-inputSwitch>
                        </div>

                      </div>
                    } @else {
                      <div class="col-12 inline-flex">
                        <div>
                          <p-inputSwitch appendTo="body" [ngModel]="rowData[col.field] !== EVENT_STATUS.INACTIVE"
                        (onChange)="onEventStatusChange(rowData, $event)" [disabled]="rowData['deleted']" (click)="stopPropagation($event)"></p-inputSwitch>
                        </div>

                      </div>
                    }

                  }
                  @case ('vehicle_status') {
                    <div>
                      <app-status-cell [data]="rowData[col.field]"></app-status-cell>
                    </div>
                  }
                  @case ('account_status') {
                    <div>
                      <app-status-cell [data]="rowData[col.field]"></app-status-cell>
                    </div>
                  }
                  @case ('location_status') {
                    <div>
                      <app-status-cell [data]="rowData[col.field]"></app-status-cell>
                    </div>
                  }
                  @case ('driver_status'){
                    <div>
                      <app-status-cell [data]="rowData[col.field]"></app-status-cell>
                    </div>
                  }
                  @case ('passenger_status') {
                    <div>
                      <app-status-cell [data]="rowData[col.field]"></app-status-cell>
                    </div>
                  }
                  @case ('role_status') {
                    <div>
                      <app-status-cell [data]="rowData[col.field]"></app-status-cell>
                    </div>
                  }
                  @case ('user_status') {
                    <div>
                      <app-status-cell [data]="rowData[col.field]"></app-status-cell>
                    </div>
                  }
                  @case ('roleName') {
                    <div>
                      {{ rowData[col.field] }}
                      <div class="font-12 text-color-secondary" [ngClass]="{ 'mt-2': rowData?.description, 'mt-0': !rowData?.description }">
                        @if (rowData?.description) {
                          <span pTooltip="{{ rowData?.description }}" tooltipPosition="top" [tooltipDisabled]="rowData?.description.length <= 30">
                            {{ rowData?.description | truncate:30}}
                          </span>
                        }
                      </div>
                    </div>
                  }
                  @case ('userRole') {
                    @if (rowData?.roles) {
                      {{rowData?.roles[0]?.roleName}}
                    }
                  }
                  @case ('createdByName') {
                    <div>
                      {{ rowData[col.field] }}
                    </div>
                  }
                  @case ('updatedByName') {
                    <div>
                      {{ rowData[col.field] }}
                    </div>
                  }
                  @case ('updatedAt') {
                    <div>
                      {{ rowData[col.field] | date: dd_MMM_yyyy_hh_mm_FORMAT }}
                    </div>
                  }
                  @case ('createdAt') {
                    <div>
                      {{ rowData[col.field] | date: dd_MMM_yyyy_hh_mm_FORMAT }}
                    </div>
                  }
                  @case ('time_and_date') {
                    <div>
                      {{ rowData[col.field] | date: dd_MMM_yyyy_hh_mm_FORMAT2 }}
                    </div>
                  }
                  @case ('event_start_date') {
                    <div>
                      {{ rowData[col.field] | date: dd_MMM_yyyy_FORMAT }}
                    </div>
                  }
                  @case ('event_end_date') {
                    <div>
                      {{ rowData[col.field] | date: dd_MMM_yyyy_FORMAT }}
                    </div>
                  }
                  @case ('startDateStr') {
                    <div>
                      {{ rowData[col.field] | date: dd_MMM_yyyy_FORMAT }}
                    </div>
                  }
                  @case ('endDateStr') {
                    <div>
                      {{ rowData[col.field] | date: dd_MMM_yyyy_FORMAT }}
                    </div>
                  }
                  @case ('assigned_at') {
                    <div>
                      {{ rowData[col.field] | date: DATE_FORMAT_DD_MM_YYYY_hh_mm_a }}
                    </div>
                  }
                  @case ('released_at') {
                    <div>
                      {{ rowData[col.field] | date: DATE_FORMAT_DD_MM_YYYY_hh_mm_a }}
                    </div>
                  }
                  @case ('State') {
                    <div class="capitalize">
                      <label class="status-dot" [class]="rowData[col.field]"></label>
                      {{ rowData[col.field] }}
                    </div>
                  }
                  @case ('duration') {
                    <div class="capitalize">
                      <label class="status-dot" [class]="rowData[col.field]"></label>
                      {{ rowData[col.field] }}
                    </div>
                  }
                  @case ('transportation_end_date') {
                    @if (rowData[col.field]) {
                      <div class="date-select">
                        {{ rowData[col.field] | date: dd_MMM_yyyy_FORMAT }}
                      </div>
                    } @else {
                      <div class="date-select">
                        <p-calendar appendTo="body" (onSelect)="onTransportationDateSelect($event, rowData)" placeholder="Select" dateFormat="dd MM yy"  #selectDate ></p-calendar>
                      </div>
                    }

                  }
                  @case ('incidents_and_complaint_status') {
                    <p-dropdown appendTo="body" class="incident-status" [options]="col.presetValues" [(ngModel)]="rowData[col.field]" optionLabel="labelKey" optionValue="labelValue"
                      (onChange)="onStatusChange(rowData)" (click)="stopPropagation($event)" [ngClass]="getStatusClassForStatus(rowData[col.field])"></p-dropdown>
                  }
                  @case ('duty_status') {
                    @if(staticEntity === 'add_queue') {
                      <app-status-cell [data]="rowData[col.field]"></app-status-cell>
                    }
                    @else {
                      @if(!rowData.deleted){
                        <p-inputSwitch appendTo="body" [ngModel]="rowData[col?.field]?.includes('On')"
                          (onChange)="onDutyStatus(rowData, $event)" (click)="stopPropagation($event)"></p-inputSwitch>
                          @if(rowData['off_duty_reason']) {
                            <span>
                              <i class="{{miIcons.CONTENT_MANAGEMENT}} inline-block wh-24 bg-default-text-blue ml-2" (click)="showOverlayPanel(rowData['off_duty_reason']); stopPropagation($event)"></i>
                              @if(isShowDutyReason){
                                <app-show-duty-reason></app-show-duty-reason>
                              }
                            </span>
                          }
                      }
                    }
                  }
                  @case ('driver') {
                    @if (rowData.driverGroup) {
                      <span class="ml-1 mt-1" pTooltip="{{ rowData.driverGroup }}" [tooltipDisabled]="rowData.driverGroup.length <= 14">
                        {{ rowData.driverGroup.length > 14 ? rowData.driverGroup.substring(0, 14) + '...' : rowData.driverGroup }}
                      </span>
                    } @else {
                      @if(!rowData[col.field]){
                        <span>--</span>
                      }
                    }
                  }
                 
                  @case ('driver_group_id') {
                    @if(staticEntity === 'add_queue') {
                      <div>
                        <a class="text-primary cursor-pointer">{{rowData?.driverGroup?.group_name}}</a>
                      </div>
                    }
                    @else {
                      @if (rowData[col.field]) {
                        <div>
                          <a class="cursor-pointer">{{rowData?.driverGroup[0]?.group_name}}</a>
                        </div>
                      }
                      @else {
                        @if(!rowData.deleted){
                          <div>
                            <a class="text-primary cursor-pointer" (click)="assignDriverGroup(rowData)">Assign Group</a>
                          </div>
                        }
                      }
                    }
                  }
                  @case ('active_vehicle_id') {
                    @if(staticEntity === 'add_queue') {
                      @if(rowData?.vehicle) {
                        <div>
                          {{rowData?.vehicle?.name_code}} ({{rowData?.vehicle?.body_type}})
                        </div>
                      }
                    }
                    @else {
                      @if (rowData[col.field]) {
                        <div>
                          {{rowData?.activeVehicle[0]?.name_code}}
                        </div>
                      }
                      @else {

                        @if(!rowData.deleted){
                          <div>
                            <a class="text-primary cursor-pointer" (click)="defaultVehicle(rowData, $event)">Assign Vehicle</a>
                          </div>
                        }
                      }
                    }
                  }
                  @case ('default_vehicle_id') {
                    @if (rowData[col.field]) {
                      <div>
                        {{rowData?.defaultVehicle[0]?.name_code}}
                      </div>
                    }
                    @else {     
                      <div>
                        ---
                      </div>
                    }
                    
                  }
                  @case ('checkin_location_id') {
                    @if (rowData[col.field]) {
                      <a (click)="selectCheckInLocation(rowData)">
                        <span pTooltip="{{ rowData?.location[0]?.location_display_name_for_booker }}" tooltipPosition="top" [tooltipDisabled]="rowData?.location[0]?.location_display_name_for_booker?.length <= 30">
                          {{ rowData?.location[0]?.location_display_name_for_booker | truncate:30}}
                        </span>
                      </a>
                    }
                    @else {
                      @if(!rowData.deleted){
                        <div>
                          <a class="text-primary cursor-pointer" (click)="selectCheckInLocation(rowData)">Select</a>
                        </div>
                      }
                    }
                  }
                  @case ('group_color') {
                    @if (rowData[col.field]) {
                      <div>
                        <app-group-color [colorCode]="rowData[col.field]"></app-group-color>
                      </div>
                    }
                  }
                  @case ('people_count') {
                    @if (rowData[col.field]) {
                      <div>{{ rowData[col.field].length }}</div>
                    }
                    @else {
                      <div>0</div>
                    }
                  }
                  @case ('assigned_drivers') {
                    @if (rowData[col.field]) {
                      <div>{{ rowData[col.field].length }}</div>
                    }
                    @else {
                      <div>0</div>
                    }
                  }
                  @case ('select_time') {
                    <div>{{ rowData[col.field] | date: hh_mm_dd_MMM_yyyy_FORMAT }}</div>
                  }
                  @case ('fromDate') {
                    <div>{{ rowData[col.field] | date: dd_MMM_yyyy_FORMAT }}</div>
                  }
                  @case ('toDate') {
                    <div>{{ rowData[col.field] | date: dd_MMM_yyyy_FORMAT }}</div>
                  }
                  @case ('leaveNoOfDays') {
                    <div>{{ rowData[col.field]}}</div>
                  }
                  @case ('approvalStatus') {
                    <div>
                      <app-status-cell [data]="rowData[col.field]"></app-status-cell>
                    </div>
                  }
                  @case ('historyStatus') {
                    <div>
                      <app-status-cell [data]="rowData[col.field]"></app-status-cell>
                    </div>
                  }
                  @case ('driver_duty_status') {
                    <app-status-cell [data]="rowData[col.field]"></app-status-cell>
                  }
                  @case ('vehicle_entity_id') {
                    <div>{{ rowData?.vehicle[0]?.name_code }}</div>
                  }
                  @case ('location_entity_id') {
                    <div>{{ rowData?.location[0]?.location_category }}</div>
                  }
                  @case ('driver_group_entity_id') {
                    <div>
                      <a class="text-primary cursor-pointer">{{ rowData?.driverGroup[0]?.group_name }}</a>
                    </div>
                  }
                  @case ('created_on') {
                    <div>{{ rowData[col.field] | date: dd_MMM_yyyy_hh_mm_FORMAT }}</div>
                  }
                  @case ('document_added_on') {
                    <div>{{ rowData[col.field] | date: dd_MMM_yyyy_FORMAT }}</div>
                  }
                  @case ('expiry_date') {
                    <div>{{ rowData[col.field] | date: dd_MMM_yyyy_FORMAT }}</div>
                  }
                  @case ('added_on') {
                    <app-get-time-for-queue [data]="rowData[col.field]"></app-get-time-for-queue>
                  }
                  @case ('driver_check_in_time') {
                    <app-get-time-for-queue [data]="rowData[col.field]"></app-get-time-for-queue>
                  }
                  @case ('add_queue_location') {
                    <app-queue-location-dropdown (queueLocationChange)="getQueueLocationChange($event, rowIndex)"></app-queue-location-dropdown>
                  }
                  @case ('queue_action') {
                    <div>
                      <button class="flex cursor-pointer height-35" [disabled]="true" #queueLocationButton (click)="setCurrentRow(rowData)" pButton translate>lbl_add_to_queue</button>
                    </div>
                  }
                  @case ('actionLeaveStatus') {
                    <div class="flex">
                      <button class="flex cursor-pointer height-35 mr-2" (click)="setCurrentRowForLeaveApprove(rowData)" pButton translate>lbl_approve</button>
                      <button class="flex cursor-pointer height-35 border-1 border-color-red border-round default-text-color-red bg-transparent" (click)="setCurrentRowForLeaveReject(rowData)" pButton translate>lbl_reject</button>
                    </div>
                  }
                  @case ('parking') {
                    @if(rowData?.location[0]) {
                      <div>
                        {{ rowData?.location[0]?.location_display_name_for_booker }}
                      </div>
                    }
                    @else {
                      <div>NA</div>
                    }
                  }
                  @default {
                    <div 
                        pTooltip="{{ rowData[col.field] }}"
                        [tooltipDisabled]="rowData[col.field]?.toString()?.length <= 14">
                        {{ rowData[col.field]?.length > 14 ? rowData[col.field]?.substring(0, 14) + '...' : rowData[col.field] }}
                    </div>
                  }
                }
              </div>
            </td>
          }
          <!-- @if(rowData?.showRowMenu) { -->
            <td alignFrozen="right" pFrozenColumn>
              @if(rowItems){
                <p-button [style.visibility]="rowData?.showRowMenu ? 'visible' : 'hidden'" icon="{{miIcons.MORE_HORIZONTAL}} block wh-24" class="flex cursor-pointer height-40-px width-46 more-horizontal-icon" styleClass="p-button-secondary"
                  (click)="setCurrentRow(rowData);menu.toggle($event); stopImmediatePropagation();" severity="secondary"></p-button>
                <p-menu #menu styleClass="menu-width" [model]="rowItems" [popup]="true" appendTo="body"></p-menu>

              }
            </td>
          <!-- } -->
        </tr>
      }
    </ng-template>
  </p-table>
  <!-- <div class=" flex-1 flex align-items-center justify-content-end pr-7">
    <p-paginator [first]="first2" [rows]="rows2" [totalRecords]="totalRecords" (onPageChange)="onPageChange2($event)"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first}-{last} of {totalRecords}" [showPageLinks]="false"
    [showFirstLastIcon]="false"></p-paginator>
  </div> -->


  @if (data?.length === 0) {
    @if (!isLeaveRequest){
      <div>
        <div class="flex flex-column h-27rem surface-200 p-2 justify-content-center align-items-center">
          <div class="m-3">
            <div class="flex justify-content-center align-items-center">
              <span><i class="{{miIcons.ARCHIVED_SEARCH}} inline-block wh-80"></i></span>
            </div>
            <div>
              <p translate>{{emptyResultMsg}}</p>
            </div>
          </div>
          <div class="mb-4">
            <p class="my-0" translate>lbl_no_records_available1</p>
            <p class="flex justify-content-center" translate>lbl_no_records_available2</p>
          </div>
          <div>
            <p-button class="flex align-items-center justify-content-center" icon="pi pi-plus" (click)="onAdd()" [label]="label"></p-button>
          </div>
        </div>
      </div>
    }
  }
</div>

@if (showBulkUpdateDialog) {
  <app-bulk-update-dialog
    [filterFields]="filterFields"
    [bulkUpdateHeader]="bulkUpdateHeader"
    [reviewHeader]="reviewHeader"
    [btnUpdate]="btnUpdate"
    [bulkRowCount]="bulkRowCount"
    [entityNameForBulkUpdate]="entityNameForBulkUpdate"
    [attributeData]="attributeData"
    [selectedRecords]="selectedRows"
    (onBulkDataUpdate)="onBulkDataUpdate($event)"
    [entityName]="entityName"
    [entityNameToDisplay]="entityNameToDisplay"
    [isEntity]="isEntity"
    (onStaticBulkDataUpdate)="onStaticBulkDataUpdate($event)"
    (clearSelectedEvent)="clearSelected()"
  ></app-bulk-update-dialog>
}

@if (showBulkLeaveUpdateDialog) {
  <app-bulk-leave-update-dialog
    (onToggleOverLay)="onToggleOverLay.emit($event)"
    [visible]="visible"
    [filterFields]="filterFields"
    [bulkUpdateHeader]="bulkUpdateHeader"
    [reviewHeader]="reviewHeader"
    [btnUpdate]="btnUpdate"
    [bulkRowCount]="bulkRowCount"
    [entityName]="entityName"
    [attributeData]="attributeData"
    [selectedRecords]="selectedRows"
    (onBulkLeaveDataUpdate)="onBulkLeaveDataUpdate($event)"
  ></app-bulk-leave-update-dialog>
}

<app-manage-column [isShow]="isModal" (onManageColumns)="onUpdateColumns($event)"
  (onToggleOverLay)="onToggle()"
></app-manage-column>

<!-- [manageColumnFromLocal]="manageColumnFromLocal" -->

  @if(visiblePrintLabel){
    <app-print-labels-dialog (onToggleOverLay)="onToggleOverLay.emit($event)" [visiblePrintLabel]="visiblePrintLabel"
      [bulkRowCount]="bulkRowCount" [rowData]="selectedRows"></app-print-labels-dialog>
  }

<p-toast></p-toast>
