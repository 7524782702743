import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, FormArray, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SettingsService } from 'app/modules/settings/settings.service';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Countries } from 'app/modules/shared/countries';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';

@Component({
  selector: 'app-contact-support',
  standalone: true,
  imports: [TranslateModule, BreadcrumbModule, ButtonModule, TabViewModule, FormsModule, ReactiveFormsModule, InputTextModule, InputSwitchModule, DropdownModule],
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss', '../../../shared/components/mi-fields/mobile-input/flags.scss']
})
export class ContactSupportComponent {
  @ViewChild('dropdown') dropdown!: Dropdown;
  btnLabel: string;
  routePath: MenuItem[] = [];
  supportForm: FormGroup;
  addBtnLabel: string;
  maxRestriction = 9;
  miIcons = AppIcons;
  countries: any[] | undefined;
  selectedCountry: any;
  filterIcon = 'pi pi-search';

  constructor(private cs: CommonBindingDataService,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private configService: ConfigService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) {

  }


  ngOnInit(): void {
    this.setLabels();
    this.setRoutePath();
    this.setCountries();
    this.initForm();
    this.getContactSupportData();
  }

  private setLabels() {
    this.addBtnLabel = this.cs.getLabel('label_save');
  }

  private initForm() {
    this.supportForm = this.fb.group({
      supports: this.fb.array([this.createSupport()])
    });
  }

  private setRoutePath() {
    this.routePath = [
      { label: this.cs.getLabel('content.lbl_contact_support'), routerLink: 'content/terms-and-conditions' },
    ];
  }

  createSupport(): FormGroup {
    return this.fb.group({
      supportName: ['', Validators.required],
      supportNumber: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
      driver: [false],
      passenger: [false],
      countryCode: [this.selectedCountry]
    });
  }



  get supports(): FormArray {
    return this.supportForm.get('supports') as FormArray;
  }

  addSupport(): void {
    this.supports.push(this.createSupport());
  }

  removeSupport(index: number): void {
    if (this.supports.length > 1) {
      this.showConsentPopup(index);
    }
  }

  showConsentPopup(index) {
    const confirmHeader = this.cs.getLabel('content.delete_contact_header');
    const confirmHeaderMsg = this.cs.getLabel('content.delete_contact_msg');
    this.confirmationService.confirm({
      header: confirmHeader,
      message: confirmHeaderMsg,
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptLabel: this.cs.getLabel('content.lbl_delete'),
      rejectLabel: this.cs.getLabel('content.lbl_cancel'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        this.supports.removeAt(index);
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('content.contact_delete_message') });
      },
      reject: () => {
      }
    });
  }

  onSubmit(): void {
    console.log(this.supportForm.value);
  }


  onCancel() {
    this.supportForm.reset();
    this.addBtnLabel = this.cs.getLabel('label_save');
  }

  setCountries() {
    this.countries = Countries.COUNTRY_CODE;
    this.selectedCountry = this.countries[AppSettings.DEFAULT_COUNTRY_CODE];
  }

  selectCountry(event: any, index: number) {
    const selectedCountry = event.value;
    this.supports.at(index).get('countryCode')?.setValue(selectedCountry);
  }

  onFilter(event) {
    if (event.filter) {
      this.filterIcon = 'pi pi-times'
    } else {
      this.filterIcon = 'pi pi-search';
    }
  }

  onFilterIconClick() {
    if (this.filterIcon.includes('times')) {
      this.dropdown.filterValue = null;
      this.filterIcon = 'pi pi-search';
    }
  }

  onAdd() {
    const requestBody = this.transformedPayload();
    this.settingsService.updateStaticContentInfo(requestBody).subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('settings.contact_support_update_msg') });
    }, (error) => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: error.errors.general[0].message });
    });
  }

  private transformedPayload(): any {
    const supportsArray = this.supports.controls;
    const staticContentReqDto = [];

    let driverIndex = 1;
    let passengerIndex = 1;

    supportsArray.forEach((control) => {
      const formValue = control.value;
      const countryCode = formValue.countryCode ? formValue.countryCode.value : '';

      if (formValue.driver) {
        staticContentReqDto.push({
          contentType: `CONTACT_SUPPORT_NAME_${this.padIndex(driverIndex)}`,
          roleCode: 'driver',
          content: formValue.supportName
        });

        staticContentReqDto.push({
          contentType: `CONTACT_SUPPORT_NUMBER_${this.padIndex(driverIndex)}`,
          roleCode: 'driver',
          content: `${countryCode} ${formValue.supportNumber}`.trim()
        });

        driverIndex++;
      }

      if (formValue.passenger) {
        staticContentReqDto.push({
          contentType: `CONTACT_SUPPORT_NAME_${this.padIndex(passengerIndex)}`,
          roleCode: 'passenger',
          content: formValue.supportName
        });

        staticContentReqDto.push({
          contentType: `CONTACT_SUPPORT_NUMBER_${this.padIndex(passengerIndex)}`,
          roleCode: 'passenger',
          content: `${countryCode} ${formValue.supportNumber}`.trim()
        });

        passengerIndex++;
      }
    });

    return {
      forTenantCode: this.configService.getForTenantCode(),
      contentType: 'CONTACT_SUPPORT',
      staticContentReqDto
    };
  }

  private padIndex(index: number): string {
    return index.toString().padStart(2, '0');
  }

  getContactSupportData() {
    const contentType = AppSettings.STATIC_CONTENT.CONTACT_SUPPORT;
    this.settingsService.getStaticContentData(contentType).subscribe((res: any) => {
      if (res && res.length > 0) {
        const formArray = this.transformApiResponse(res);
        this.supportForm.setControl('supports', formArray);
        this.addBtnLabel = this.cs.getLabel('content.lbl_update');
      }
    });
  }

  private transformApiResponse(response: any[]): FormArray {
    const roleDataMap = new Map<number, { driverName?: string; driverNumber?: string; passengerName?: string; passengerNumber?: string }>();
    const countryCodeMap = new Map<number, string>();

    const reqDtoData = response.reduce((acc, item) => {
      return acc.concat(item.staticContentReqDto);
    }, []);
    reqDtoData.forEach(item => {
      const { contentType, roleCode, content } = item;
      const match = contentType.match(/_(\d{2})$/);

      if (match) {
        const index = parseInt(match[1], 10);

        if (!roleDataMap.has(index)) {
          roleDataMap.set(index, {});
        }
        const roleData = roleDataMap.get(index)!;

        if (roleCode === 'driver') {
          if (contentType.includes('NAME')) {
            roleData.driverName = content;
          } else if (contentType.includes('NUMBER')) {
            const countryCodeMatch = content.match(/^\+(\d+)/);
            if (countryCodeMatch) {
              countryCodeMap.set(index, `+${countryCodeMatch[1]}`);
              roleData.driverNumber = content.replace(/^\+\d+\s*/, '');
            } else {
              roleData.driverNumber = content;
            }
          }
        } else if (roleCode === 'passenger') {
          if (contentType.includes('NAME')) {
            roleData.passengerName = content;
          } else if (contentType.includes('NUMBER')) {
            const countryCodeMatch = content.match(/^\+(\d+)/);
            if (countryCodeMatch) {
              countryCodeMap.set(index, `+${countryCodeMatch[1]}`);
              roleData.passengerNumber = content.replace(/^\+\d+\s*/, '');
            } else {
              roleData.passengerNumber = content;
            }
          }
        }
      }
    });

    const supportsArray: FormGroup[] = [];
    roleDataMap.forEach((data, index) => {
      const countryCode = countryCodeMap.get(index) || '';
      supportsArray.push(this.fb.group({
        supportName: [data.driverName || data.passengerName || ''],
        supportNumber: [data.driverNumber || data.passengerNumber || ''],
        driver: [!!data.driverName],
        passenger: [!!data.passengerName],
        countryCode: [this.countries.find(country => country.value === countryCode) || null]
      }));
    });
    const firstCountryCode = [...countryCodeMap.values()][0] || '';
    this.selectedCountry = this.countries.find(country => country.value === firstCountryCode) || this.countries[AppSettings.DEFAULT_COUNTRY_CODE];

    return this.fb.array(supportsArray);
  }

}
