@if(isShowBookingMap) {
    <form [formGroup]="formName">
        <div class="flex">
            <span id="googleSearch" class="p-input-icon-left">
                <i class="pi search-icon relative left-30 cursor-pointer pi-search"></i>
                <input [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" class="search-field" type="text"
                    pInputText id="autocompleteSearch" [placeholder]="'lbl_search' | translate" appSearchLocation (setAddress)="getAndStoreAddress($event)"/>
                <!-- <input id="autocompleteSearch" [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" type="text" 
                    pInputText class="search-field"  [placeholder]="'lbl_search' | translate"/> -->
            </span>
        </div>
    </form>
    <div class="map-div mb-3 mt-3" id="map"></div>
} @else {
    <form [formGroup]="formName">
        <div class="flex">
            @if(moduleName === 'booking') {
                <p-dropdown class="booking" [options]="displayedAddresses" optionLabel="select_location_address" [placeholder]="placeholder"  [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" [class]="field.attributeCode" (onChange)="changedValue()">
                    <ng-template pTemplate="header">
                        <div class="dropdown-header">
                            <div class="flex justify-content-between align-items-center px-3 py-2">
                                <div>
                                    <span class="saved-address px-3 py-2 mr-2 text-sm" (click)="showLocationOption('saved')" [ngClass]="{'active-class': addressOption === 'saved'}">Saved Addresses</span>
                                    <span class="predefined-address px-3 py-2 text-sm" (click)="showLocationOption('predefined')" [ngClass]="{'active-class': addressOption === 'predefined'}">Predefined Location</span>
                                </div>
                                <div>
                                    <p-button [label]="'lbl_add_address' | translate" class=" default-text-blue-color cursor-pointer font-semibold add-new-address" [outlined]="true"
                                        icon="pi pi-plus-circle" (click)="passengerSidebarOpen()"></p-button>
                                </div>
                            </div>
                            <div class="flex align-items-center px-3 py-1 w-max" (click)="showDialog()">
                                <i class="{{miIcons.BOOKING_SET_LOCATION_ON_MAP}} wh-16 inline-block"></i>
                                <span class="ml-2 text-primary font-16">Select location on map</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template let-address pTemplate="item">
                        <div class="dropdown-content p-0">
                            <hr class="hr-line mt-2 mb-2">
                            <div class="flex justify-content-between align-items-center address-item border-none">
                                <div class="">
                                    <div class="address-header">
                                        <i class="{{ getIconClass(address.select_location_type) }} mi-lg wh-16 inline-block mr-2 relative top-2"></i>
                                        <span class="font-semibold font-14">
                                            {{ address['select_location_type'] }}
                                        </span>
                                    </div>
                                    <div class="ml-5 mt-2">
                                        {{ address['select_location_address'] }}
                                        @if (address['address_line_1']) {
                                            <span>, {{ address['address_line_1'] }}</span>
                                        }
                                        @if (address['address_line_2']) {
                                            <span>, {{ address['address_line_2'] }}</span>
                                        }
                                        @if (address['nearby_landmark']) {
                                            <span>, {{ address['nearby_landmark'] }}</span>
                                        }
                                        @if (address['state']) {
                                            <span>, {{ address['state'] }}</span>
                                        }
                                        @if (address['country']) {
                                            <span>, {{ address['country'] }}</span>
                                        }
                                        @if (address['postal_code']) {
                                            <span> - {{ address['postal_code'] }}</span>
                                        }
                                    </div>
                                </div>
                                <div class="flex address-action cursor-pointer p-1 border-round">
                                    @if (addressOption === 'saved') {
                                        <i class="{{miIcons.BASIC_DELETE}} wh-20 inline-block" (click)="removeAddress(address)"></i>
                                    } @else {
                                        <i class="{{miIcons.BOOKMARK}} wh-20 inline-block" (click)="BookmarkAddress()"></i>
                                    }
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template let-selectedItem pTemplate="selectedItem">
                        <div class="selected-address">
                            <i class="{{ getIconClass(selectedItem.select_location_type) }} wh-16 inline-block mr-2 relative top-2"></i>
                            <span class="font-semibold font-16">
                                {{ selectedItem.select_location_type }}: 
                            </span>
                            <span>
                                {{ selectedItem.select_location_address }}
                                @if (selectedItem.address_line_1) {
                                    <span>, {{ selectedItem.address_line_1 }}</span>
                                }
                                @if (selectedItem.address_line_2) {
                                    <span>, {{ selectedItem.address_line_2 }}</span>
                                }
                                @if (selectedItem.nearby_landmark) {
                                    <span>, {{ selectedItem.nearby_landmark }}</span>
                                }
                                @if (selectedItem.state) {
                                    <span>, {{ selectedItem.state }}</span>
                                }
                                @if (selectedItem.country) {
                                    <span>, {{ selectedItem.country }}</span>
                                }
                                @if (selectedItem.postal_code) {
                                    <span> - {{ selectedItem.postal_code }}</span>
                                }
                            </span>
                        </div>
                    </ng-template>
                </p-dropdown>
                <!-- <input style="display: none;" type="text" pInputText [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" class="w-full"/> -->
            } @else {
                <p-dropdown #miSelect [options]="field.presetValues" optionLabel="labelValue.address" [placeholder]="placeholder"
                    optionValue="labelValue" (onChange)="changedValue()"
                    [filter]="true"
                    filterBy="labelValue.address" 
                    [showClear]="true"
                    [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" [class]="field.attributeCode"></p-dropdown>
            }
            <div>
                @if(moduleName !=='booking'){
                    <p-button [class]="moduleName === ENTITY_CODE.PASSENGER_ADDRESS ? 'geo-location' : 'location'" class="inline-flex ml-3" icon="mi-lg wh-24" [icon]="moduleName === ENTITY_CODE.PASSENGER_ADDRESS ? 'basic-location' : 'mi-basic-location'" (click)="showDialog()"></p-button>
                }
            </div>
        </div>
    </form>

    @if( '1001_booking_stops_location_geolocation' ===  field?.attributeCodeOneToMultiple && moduleName === 'booking'){
        <p-dialog header="Geo Location" (onShow)="initMap()" (onHide)="close()" focusOnShow="false" [(visible)]="showMap" [style]="{width: '50vw'}">
            @if (showBookingGoogleSearchBox) {
                <span id="bookingGoogleSearch" class="p-input-icon-left ">
                    <i class="pi search-icon relative left-30 cursor-pointer pi-search"></i>
                    <input id="autocompleteSearch" [(ngModel)]="searchText" type="text" 
                    pInputText class="search-field"  [placeholder]="'lbl_search' | translate"
                    />
                </span>
            }
            <div class="map-div mb-3 mt-3" id="map">
            </div>
    
            <div class="col-12 save-div mb-3 pr-0">
                <button pButton class="cancel mr-20 text-color height-44 border-button" (click)="close()" [label]="cancelBtnText"></button>
                <p-button class="inline-flex height-44" (click)="saveGeoLocationBooking()" [label]="saveBtnText"></p-button>
            </div>
        </p-dialog>
    } @else {
        <p-dialog header="Geo Location" (onShow)="initMap()" (onHide)="close()" focusOnShow="false" [(visible)]="visible" [style]="{width: '50vw'}">
            @if (showGoogleSearchBox) {
                <span id="googleSearch" class="p-input-icon-left ">
                    <i class="pi search-icon relative left-30 cursor-pointer pi-search"></i>
                    <input id="autocompleteSearch" [(ngModel)]="searchText" type="text" 
                    pInputText class="search-field"  [placeholder]="'lbl_search' | translate"
                    />
                </span>
            }
            <div class="map-div mb-3 mt-3" id="map">
            </div>
    
            <div class="col-12 save-div mb-3 pr-0">
                <button pButton class="cancel mr-20 text-color height-44 border-button" (click)="close()" [label]="cancelBtnText"></button>
                <p-button class="inline-flex height-44" (click)="saveGeoLocation()" [label]="saveBtnText"></p-button>
            </div>
        </p-dialog>
    }
}

<!-- <input style="display: none;" type="text" pInputText [formControlName]="field.attributeCode" class="w-full" /> -->

