import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { GroupChatsComponent } from '../../components/group-chats/group-chats.component';
import { GroupMessagingComponent } from '../../components/group-messaging/group-messaging.component';
import { IndividualChatsComponent } from '../../components/individual-chats/individual-chats.component';
import { IndividualMessagingComponent } from '../../components/individual-messaging/individual-messaging.component';
import { CommunicationService } from '../../services/communication.service';

import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { EntityCount, EntityCountsResponse } from 'app/modules/vehicles/models/entity.models';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabViewModule } from 'primeng/tabview';
import { BroadcastListComponent } from '../../../broadcasting/components/broadcast-list/broadcast-list.component';
import { SegmentListComponent } from '../../../manage-segments/components/segment-list/segment-list.component';
import { ConfigService } from 'app/modules/shared/services/config.service';

interface DropdownList {
  name: string;
  code: string;
}

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss'],
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, TranslateModule, DropdownModule, SelectButtonModule, FormsModule, InputTextModule, IndividualMessagingComponent, GroupMessagingComponent, IndividualChatsComponent, GroupChatsComponent, SegmentListComponent, BroadcastListComponent]
})

export class CommunicationComponent implements OnInit {
  @Output() setCountAllOfBroadcast: EventEmitter<any> = new EventEmitter<any>();
  miIcons = AppIcons;
  activeIndex: number = 0;
  routePath: MenuItem[] = [];
  dropdownList: DropdownList[] | undefined;
  stateOptions: any[];
  value: string = 'individual';
  isIndividual: boolean = true;
  messageCount = 1;
  broadcastCount;
  manageSegmentsCount: number;
  entityCount;
  isMessaging = false;
  @ViewChild(IndividualMessagingComponent) individualMessagingComponent: IndividualMessagingComponent;
  @ViewChild(GroupMessagingComponent) groupMessagingComponent: GroupMessagingComponent;

  constructor(private communicationService: CommunicationService, public appSettings: AppSettings,
    private broadcastService: EntityService, private router: Router, private route: ActivatedRoute,
    private configService: ConfigService) {

  }
  ngOnInit() {
    this.setFirstTab();
    this.setRoutePath();
    this.setStateOptions();
    this.setDropDownList();
    this.getBroadcastCount();
    this.getSegmentCount();
  }

  setFirstTab() {
    this.route.firstChild.url.subscribe(url => {
      if (url[0]) {
        switch (url[0].path) {
          case 'messaging':
            this.activeIndex = 0;
            break;
          case 'broadcast':
            this.activeIndex = 1;
            break;
          case 'segment':
            this.activeIndex = 2;
            break;
        }
      }
    });
  }

  private setRoutePath() {
    this.routePath = [
      { label: "Communication", routerLink: 'communication' },
    ];
  }

  private setStateOptions() {
    this.stateOptions = [
      { label: this.appSettings.COMMUNICATION_DROPDOWN[0].label, value: this.appSettings.COMMUNICATION_DROPDOWN[0].value },
      { label: this.appSettings.COMMUNICATION_DROPDOWN[1].label, value: this.appSettings.COMMUNICATION_DROPDOWN[1].value }
    ];
  }

  private setDropDownList() {
    this.dropdownList = [
      { name: 'Drivers', code: 'DR' },
      { name: 'Passengers', code: 'PS' },
    ];
  }

  onTabChange(event) {
    this.activeIndex = event.index;
    let route = '';
    switch (this.activeIndex) {
      case 0:
        route = 'messaging';
        break;
      case 1:
        route = 'broadcast';
        break;
      case 2:
        route = 'segment';
        break;
    }
    this.router.navigate([`app/communication/${route}`]);
  }

  getBroadcastCount() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const entityCountPayload = <EntityCount>{
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.BROADCAST_MESSAGE,
    };

    this.broadcastService.getEntityCount(AppSettings.ENTITY_CODE.BROADCAST_MESSAGE, entityCountPayload)
      .subscribe((res: EntityCountsResponse) => {
        this.entityCount = res;
        this.broadcastCount = this.entityCount.counts.ALL;
      });
  }

  getSegmentCount() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const entityCountPayload = <EntityCount>{
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.SEGMENT,
    };

    this.broadcastService.getEntityCount(AppSettings.ENTITY_CODE.SEGMENT, entityCountPayload)
      .subscribe((res: EntityCountsResponse) => {
        this.entityCount = res;
        this.manageSegmentsCount = this.entityCount.counts.ALL;
      });
  }

  searchRecords(searchFilter) {
    this.communicationService.searchValue.next(searchFilter);
  }

  clickOnGroup(event) {
    this.resetActiveClasses();
    if (event.value !== this.appSettings.COMMUNICATION_DROPDOWN[0].value) {
      this.isIndividual = false;
    } else {
      this.isIndividual = true;
    }
  }

  resetActiveClasses() {
    const profileData = this.isIndividual ? this.individualMessagingComponent?.profileData : this.groupMessagingComponent?.profileData;
    if (profileData) {
      profileData.forEach(ele => ele.active = false);
      profileData[0].active = true;
    }
  }

}
