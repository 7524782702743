import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as _ from 'lodash';
import { pairwise, startWith } from 'rxjs/operators';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { MiFieldsComponent } from '../mi-fields/mi-fields.component';

@Component({
  selector: 'app-quick-filter-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MiFieldsComponent],
  templateUrl: './quick-filter-form.component.html',
  styleUrl: './quick-filter-form.component.scss'
})
export class QuickFilterFormComponent implements OnInit {
  miQuickFilterForm: FormGroup | undefined;
  @Input() fields: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Output() onValueChange: EventEmitter<any> = new EventEmitter();
  @Input() attributeValues;
  formGroupFields = [];

  constructor(private cs: CommonBindingDataService, private cd: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.buildGroups();
    this.miQuickFilterForm.valueChanges.pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        _.isEqual(prev, next) ? "" : this.onValueChange.emit(next);
      });
  }

  private buildGroups() {
    const miFormControl = this.getFormControl();
    this.miQuickFilterForm = new FormGroup(miFormControl);
  }

  private getFormControl() {
    for (const field of this.fields) {
      field.fieldName = field.attributeCode;
      this.formGroupFields[field.attributeCode] = new FormControl('');
    }
    return this.formGroupFields;
  }

  resetForm() {
    for (const field of this.fields) {
      this.miQuickFilterForm.get(field.attributeCode).setValue([]);
    }
    this.miQuickFilterForm.reset();
    this.miQuickFilterForm.updateValueAndValidity();
    this.cd.detectChanges();
  }

  getLabel(field) {
    return this.cs.getLabelValue(this.moduleName
      + '.fields.' + field.attributeCode + '.label', this.attributeLabels, field.attributeCode)
  }
}
