<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'COMMUNICATION', pageCode: 'MANAGE_SEGMENTS', subPageCode: 'ADD' }">
    <div class="flex justify-content-end flex-wrap btn-with-links">
        <button pButton class="mr-2 bg-white border-0 default-text-light-black-color cursor-pointer"
            [label]="'cancel' | translate" (click)="onCancel()"></button>
        <p-button class="flex align-items-center justify-content-center cursor-pointer" (click)="onNextBtnClick()"
            [label]="'manage_segments.lbl_save_segment' | translate"></p-button>
    </div>
    <p-tabView [(activeIndex)]="activeIndex">
        @for (tab of segmentAttributeData?.tabs; track tab; let i = $index) {
        <p-tabPanel>
            <ng-template pTemplate="header">
                <div class="tab-icons">
                    <i class="mi-lg" [class]="tab.tabCode"></i>
                    <span>{{ cs.getLabelValue('segment.tabs.'+tab.tabCode+'.label',attributeLabels,tab.tabCode) }}</span>
                </div>
            </ng-template>
            <p-card>
                <div class="col-6 details-tab">
                    <div class="font-20 default-text-black-color uppercase font-semibold pl-7px" translate>lbl_details</div>
                </div>
                <div class="grid segment-dimension relative">
                    <div class="col-6">
                        <app-mi-form [attributeLabels]="attributeLabels" [attributeValues]="attributeValues" [saveBtnId]="'details'"
                            [moduleName]="moduleName" [groups]="tab.groups" (saveData)="onSaveSegments($event)" [showImg]="true"
                            [activeIndex]="activeIndex" (onSaveAndAdd)="onSaveAndAddSegments($event)" (nextClick)="onNextClick()" 
                            [submitBtnText]="nextBtnLabel" [previousBtnText]="saveAndAddBtnText"></app-mi-form>
                    </div>
                    <div class="col-6">
                        <app-selected-people (onChangeSelectedDriver)="onChangeSelectedDriver($event)" [selectedPeople]="selectedPeople"></app-selected-people>
                    </div>
                </div>
            </p-card>
        </p-tabPanel>
        }
    </p-tabView>
</div>