<div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'ALL_DRIVERS', pageCode: 'ALL', subPageCode: 'ASSIGN_VEHICLE' }">
<p-dialog [header]="'lbl_assign_vehicle' | translate" modal="true" (onHide)="onHide($event)" (onShow)="onShow()" [(visible)]="visibleAssignVehicle" [style]="{ width: '40vw' }" [breakpoints]="{'960px': '60vw', '640px': '80vw'}" >
    <div class="flex separator-1">
        <span class=" assign-dialog p-input-icon-left relative right-15">
          <i class="pi pi-search relative left-20"></i>
          <input type="text" pInputText (input)="onSearch($event, tableData)" [placeholder]="'lbl_search' | translate" class="input-box" />
        </span>
        @if (filterList) {
            <app-quick-filter-form [fields]="filterList" [moduleName]="moduleName" class="relative mr-2 assign-dialog"
                [attributeLabels]="attributeLabels" (onValueChange)="onFilterValueChange(false, $event)">
            </app-quick-filter-form>
        }
    </div>

    <div class="flex justify-content-start align-items-center align-content-start grid pr-3 dialog-content">
        @for(data of tableData; track data; let i = $index) {
            <div class="flex justify-content-between align-items-center w-full separator-2">
                <div class="flex align-items-center cursor-pointer" (click)="selectRadioButton(data?.id)">
                    <p-radioButton name="data" [value]="data?.id" class="flex ml-2" [(ngModel)]="selectedModify"></p-radioButton>
                    <div class="flex flex-column">
                        <div class="col-12 lg:col-6 xl:col-3 flex align-items-center white-space-nowrap relative top-5">
                            <div class="font-semibold name-code-number">{{data?.name_code}} - {{data?.reg_number}}</div>
                            <span class="ml-2 text-sm"> {{data?.vehicle_status}} </span>
                        </div>
                        <div class="col-12 lg:col-6 xl:col-3 flex align-items-center white-space-nowrap relative bottom-5 text-xs vehicle-detail">
                            {{data?.vehicle_type}} | {{data?.manufacturer}} | {{data?.fuel_type}}
                        </div>
                    </div>
                </div>
                <div>
                    <button pButton class="bg-white default-text-black-color border-1 border-black-alpha-20 px-3 py-1" [ngClass]="{'blue-text': selectedModify === data?.id}" (click)="assignVehicleData(data)" [label]="'lbl_select_only' | translate"></button>
                </div>
            </div>
        }
        @if (tableData?.length === 0) {
            <div class="w-full pt-0 pr-2 pb-0 pl-3">
              <app-no-result-found></app-no-result-found>    
            </div>         
        }
    </div>
</p-dialog>
@if(hideTable) {
    <app-mi-table (onSearchValueChange)="onSearchValueChanges($event)"></app-mi-table>
}
</div>