<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'EVENTS', pageCode: 'ALL_EVENTS', subPageCode: 'VIEW' }">
<div class="grid mt-1 mr-0 details-div">
    <div class="col-9">
        @if (showStatus) {
            <status [dataForStatus]="dataForStatus" [entityForStatus]="entityForStatus" [imgFileId]="imgFileId"></status>
        }
    </div>
    <div class="col-3 text-right align-content-center">
        <div>
            <p-button appAccessProvider [accessCode]="{ menuCode: 'EVENTS', pageCode: 'ALL_EVENTS', subPageCode: 'UPDATE_STATUS' }"
                styleClass="p-button-secondary" class="menu-btn p-2 p-button-secondary" (click)="menu.toggle($event)" icon="{{miIcons.MORE_HORIZONTAL}} block wh-24"></p-button>
            <p-button appAccessProvider [accessCode]="{ menuCode: 'EVENTS', pageCode: 'ALL_EVENTS', subPageCode: 'EDIT/UPDATE' }"
                class="menu-btn p-2 edit-btn" (click)="edit()" icon="{{miIcons.EDIT_PENCIL}} block wh-24" [label]="editBtnLabel"></p-button>
        </div>
    </div>
</div>

<p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
              <i class="mi-lg details mr-2"></i>
              <span translate>organization.details</span>
            </div>
          </ng-template>
          <div class="grid mt-2 ml-3 mr-3 overview-details">
            <div class="col-6 pl-0">
                <div class="card p-fluid">
                    <div class="flex justify-content-between flex-wrap">
                        <div class="flex justify-content-center mb-3">
                            <label class="card-heading uppercase" translate>events.lbl_details</label>
                        </div>
                        <div appAccessProvider [accessCode]="{ menuCode: 'EVENTS', pageCode: 'ALL_EVENTS', subPageCode: 'EDIT/UPDATE' }">
                            <div (click)="edit()" class="edit flex justify-content-center mb-3">
                                <label class="card-action" translate>events.lbl_edit</label>
                                <i class="mi-lg edit_pencil"></i>
                            </div>
                        </div>
                    </div>
        
                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.lbl_state</label>
                        </div>
                        <div class="col-6 status-container">
                            <span class="status-dot" [class]="eventDetails?.eventState"></span>
                            <label class="detail-value">{{ eventDetails?.eventState ? eventDetails?.eventState : '--' }}</label>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.lbl_name</label>
                        </div>
                        <div class="col-6">
                            <label class="detail-value">{{ eventDetails?.eventName }}</label>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.lbl_short_code</label>
                        </div>
                        <div class="col-6">
                            <label class="detail-value">{{ eventDetails?.eventShortcode }}</label>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.description</label>
                        </div>
                        <div class="col-6">
                            <label class="detail-value">{{ eventDetails?.eventDescription ? eventDetails?.eventDescription : '--' }}</label>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.lbl_transport_supervisor_number</label>
                        </div>
                        <div class="col-6">
                            @if(countryCodeForTransportSupervisorNumber) {
                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'mr-2 flag flag-' + countryCodeForTransportSupervisorNumber?.toLowerCase()" class="width-18"/>
                            }
                            <label class="detail-value">{{ eventDetails?.transportSupervisorNumberCountryCode + ' ' + eventDetails?.transportSupervisorNumber }}</label>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.lbl_emergency_contact_number</label>
                        </div>
                        <div class="col-6">
                            @if(emergencyNumber) {
                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'mr-2 flag flag-' + emergencyNumber?.toLowerCase()" class="width-18"/>
                            }
                            <label class="detail-value">{{ eventDetails?.emergencyNumberCountryCode + ' ' + eventDetails?.emergencyNumber }}</label>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.image</label>
                        </div>
                        <div class="col-6">
                            @if (eventImage) {
                            <div class="event-img" [style.backgroundImage]="'url(' + eventImage + ')'"></div>
                            } @else {
                            <span>{{ 'lbl_empty' | translate }}</span>
                            }
                        </div>
                    </div>
                    <hr class="hr-line">
                </div>
            
                <div class="card p-fluid">
                    <div class="flex justify-content-between flex-wrap">
                        <div class="flex justify-content-center mb-3">
                            <label class="card-heading uppercase" translate>events.event_related_settings</label>
                        </div>
                        <div appAccessProvider [accessCode]="{ menuCode: 'EVENTS', pageCode: 'ALL_EVENTS', subPageCode: 'EDIT/UPDATE' }">
                            <div (click)="edit()" class="edit flex justify-content-center mb-3">
                                <label class="card-action" translate>events.lbl_edit</label>
                                <i class="mi-lg edit_pencil"></i>
                            </div>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.transportation_type</label>
                        </div>
                        <div class="col-6">
                            <label class="detail-value"> {{eventDetails?.transportationType}} </label>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.transportation_start_date</label>
                        </div>
                        <div class="col-6">
                            <label class="detail-value">{{eventDetails?.eventStartDateTime}}</label>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.transportation_end_date</label>
                        </div>
                        <div class="col-6">
                            <label class="detail-value">{{eventDetails?.eventEndDateTime}}</label>
                        </div>
                    </div>
                    <hr class="hr-line">
        
                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.start_booking_date_passenger_app</label>
                        </div>
                        <div class="col-6">
                            <label class="detail-value">{{eventDetails?.startBookingDatePassengerApp}}</label>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.stop_booking_date_passenger_app</label>
                        </div>
                        <div class="col-6">
                            <label class="detail-value">{{eventDetails?.stopBookingDatePassengerApp}}</label>
                        </div>
                    </div>
                    <hr class="hr-line">
        
                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.operating_radius</label>
                        </div>
                        <div class="col-6">
                            <label class="detail-value">{{eventDetails?.serviceAreaRadius}}</label>
                        </div>
                    </div>
                    <hr class="hr-line">
                </div>
            </div>
            <div class="col-6 pr-0">
                <div class="card p-fluid">
                    <div class="flex justify-content-between flex-wrap">
                        <div class="flex justify-content-center mb-3">
                            <label class="card-heading uppercase text-lg" translate>events.lbl_address</label>
                        </div>
                    </div>
                   <div class="map-div" id="map"></div>
                   <div class="col-12 mt-3 p-0">
                        <label class="address font-sm text-400">{{ eventDetails?.eventAddress }}</label>
                    </div>
                </div>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
              <i class="mi-lg mi-settings mr-2"></i>
              <span translate>events.time_and_regional_settings</span>
            </div>
          </ng-template>
          <div class="grid mt-2 ml-3 mr-3 overview-details">
            <div class="col-7 pl-0">
                <div class="card p-fluid">
                    <div class="flex justify-content-between flex-wrap">
                        <div class="flex justify-content-center mb-3">
                            <label class="card-heading uppercase" translate>events.time_settings</label>
                        </div>
                        <div appAccessProvider [accessCode]="{ menuCode: 'EVENTS', pageCode: 'ALL_EVENTS', subPageCode: 'EDIT/UPDATE' }">
                            <div (click)="edit()" class="edit flex justify-content-center mb-3">
                                <label class="card-action" translate>events.lbl_edit</label>
                                <i class="mi-lg edit_pencil"></i>
                            </div>
                        </div>
                    </div>
        
                    <div class="grid">
                        <div class="col-9">
                            <label class="time-setting-field">Minimum Valid Time for new booking (App User)</label><br>
                            <label class="time-setting-field-info" translate>Passenger can schedule only after X hours/ Minutes from the current Time</label>
                        </div>
                        <div class="col-auto">
                            <label class="detail-value">8h - 20m - 00s</label>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="grid">
                        <div class="col-9">
                            <label class="time-setting-field">Minimum Valid Time for new booking (App User)</label><br>
                            <label class="time-setting-field-info" translate>Passenger can schedule only after X hours/ Minutes from the current Time</label>
                        </div>
                        <div class="col-auto">
                            <label class="detail-value">8h - 20m - 00s</label>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="grid">
                        <div class="col-9">
                            <label class="time-setting-field">Minimum Valid Time for new booking (App User)</label><br>
                            <label class="time-setting-field-info" translate>Passenger can schedule only after X hours/ Minutes from the current Time</label>
                        </div>
                        <div class="col-auto">
                            <label class="detail-value">8h - 20m - 00s</label>
                        </div>
                    </div>
                    <hr class="hr-line">
                </div>
            </div>
            <div class="col-5 pr-0">
                <div class="card p-fluid">
                    <div class="flex justify-content-between flex-wrap">
                        <div class="flex justify-content-center mb-3">
                            <label class="card-heading uppercase text-lg" translate>events.lbl_regional_settings</label>
                        </div>
                        <div appAccessProvider [accessCode]="{ menuCode: 'EVENTS', pageCode: 'ALL_EVENTS', subPageCode: 'EDIT/UPDATE' }">
                            <div (click)="edit()" class="edit flex justify-content-center mb-3">
                                <label class="card-action" translate>events.lbl_edit</label>
                                <i class="mi-lg edit_pencil"></i>
                            </div>
                        </div>
                    </div>

                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.short_date_format</label>
                        </div>
                        <div class="col-6">
                            <label class="detail-value">MM/DD/YYYY</label>
                        </div>
                    </div>
                    <hr class="hr-line">

                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.time_zone</label>
                        </div>
                        <div class="col-6">
                            <label class="detail-value">GMT +5:30 Chennai</label>
                        </div>
                    </div>
                    <hr class="hr-line">

                    <div class="grid">
                        <div class="col-6">
                            <label class="detail-heading" translate>events.time_format</label>
                        </div>
                        <div class="col-6">
                            <label class="detail-value">12 hour clock</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </p-tabPanel>

</p-tabView>
</div>


<div class="header-module-menu">
    <p-menu  #menu  [model]="moduleHeaderSecondaryBtnMenuItems" [popup]="true"></p-menu>
</div>