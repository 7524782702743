<div class="main-settings">
    <p-tabView>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-14 mr-2 relative"></i>
                <span translate>settings.lbl_privilege_settings</span>
            </ng-template>
            <div class="flex m-4">
                <div class=" left-section mt-0 p-0 ">
                    <div class="header p-3">
                        <label class="uppercase text-lg font-semibold m-1"
                            translate>settings.passenger.settings_section</label>
                        <p class="p-1" translate>settings.passenger.settings_section_p</p>

                    </div>
                    <div class=" p-0 border-none border-noround">
                        <ul class="m-0 p-0">
                            @for (group of groups; track group) {
                            <li class="py-3 pl-6 pr-3 list-none font-16 font-medium flex"
                                [class.selected]="group.code === selectedGroup?.code" (click)="selectGroup(group);onHide();onReset()">
                                <i class="{{miIcons.BOOKING_WATCHLIST}} wh-24 inline-block"></i>
                                {{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code)}}
                                <i class="{{miIcons.CARET_RIGHT}} wh-24 inline-block right-icon mr-0"></i>
                            </li>
                            }
                        </ul>
                    </div>
                </div>
                <div class="right-section ml-4 mt-0 p-0" #rightSection>
                    @if(selectedGroup?.fields && modes.isList) {
                    <div class="header p-3 flex align-items-center">
                        <div class="flex flex-grow-1 save-button justify-content-start flex-column">
                            <label class="uppercase text-lg font-semibold m-1" translate>
                                settings.passenger.privileges </label>
                            <p class="p-1" translate>settings.passenger.privilege_section_p</p>
                        </div>
                        <div class="flex save-button justify-content-end">
                            <label class="m-1 mr-2 flex align-items-center">
                                {{cs.getLabelValue(moduleName+'.fields.'+default?.fields[0]?.attributeCode+'.label',attributeLabels,default?.fields[0]?.attributeCode)}}</label>
                            <p-dropdown
                                [options]="default?.fields[0].presetValues"
                                optionValue="labelKey"
                                optionLabel="labelValue"
                                [(ngModel)]="selectedPassengerType"
                                [placeholder]="'Select '+cs.getLabelValue(moduleName+'.fields.'+default?.fields[0]?.attributeCode+'.label',attributeLabels,default?.fields[0]?.attributeCode)" />
                        </div>
                    </div>
                    @if(selectedGroup?.fields && selectedValues){                        
                    <p-table [columns]="cols" [value]="selectedGroup?.fields"  selectionMode="single" 
                        [(selection)]="selectedRows" dataKey="code"
                        (onRowSelect)="onRowSelect($event)"  [tableStyle]="{'min-width': '50rem'}">
                        <ng-template pTemplate="header" let-columns>
                            <tr class="table-row-header">
                                @for (col of columns; track $index) {
                                <th>
                                    {{ col.header }}
                                </th>
                                }
                                <th translate alignFrozen="right" pFrozenColumn>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr (mouseover)="rowMouseOver(rowData)" (mouseout)="rowMouseOut(rowData)"  [pSelectableRow]="rowData">
                                @for (col of columns; track $index) {
                                <td>
                                    @switch (col.field) {
                                    @case ('label') {
                                        <td> <div>
                                        @if(rowData?.attributeCode){
                                        {{cs.getLabelValue(moduleName+'.fields.'+rowData?.attributeCode+'.label',attributeLabels,rowData?.[col.field])}}
                                        }
                                    </div></td>
                                    }
                                    @case ('dataType') {
                                    @if (rowData[col?.field] > 0) {
                                        <td><div class="default-text-blue-color">
                                        {{ rowData[col?.field] }}
                                    </div></td>
                                    }
                                    @else {
                                        <td> <div>
                                        {{ rowData[col?.field] }}
                                    </div></td>
                                    }
                                    }
                                    @case ('attributeCode') {
                                        <td> 
                                            {{ selectedValues[rowData.attributeCode] }}
                                          
                                        </td>

                                    }
                                  
                                    @default {
                                        <td> {{ rowData[col?.field] }}</td>
                                    }
                                    }
                                </td>
                                }
                                <!-- <td>
                                    {{rowData|json}} 
                                   </td> -->
                                <td alignFrozen="right" pFrozenColumn>
                                    <p-button [style.visibility]="rowData?.showRowMenu ? 'visible' : 'hidden'"
                                        icon="{{miIcons.MORE_HORIZONTAL}} block wh-24"
                                        class="flex cursor-pointer height-40-px width-48 more-horizontal-icon float-r"
                                        styleClass="p-button-secondary"
                                        (click)="setCurrentRow(rowData);menu.toggle($event)"
                                        severity="secondary"></p-button>
                                    <p-menu #menu styleClass="menu-width" [model]="rowItems" [popup]="true"
                                        appendTo="body"></p-menu>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    }
                    }
                    @if(selectedField && modes.isView) {
                    <div class="header p-3 flex align-items-center">
                        <div class="flex flex-grow-1 save-button justify-content-start flex-column">
                            <label class="uppercase text-lg font-semibold m-1" translate>
                                settings.passenger.privileges </label>
                            <p class="p-1" translate>settings.passenger.privilege_section_p</p>
                        </div>
                        <div class="flex save-button justify-content-end">
                            <div class="flex justify-content-end mr-4 mb-2">
                                <p-button icon="pi pi-pencil"  [label]="'settings.passenger.edit' | translate" [text]="true"  (click)="onAddSettings()"/>
                                <p-button icon="pi pi-minus-circle"  [label]="'settings.passenger.clear' | translate" [text]="true" severity="danger"  (click)="onHide()" />
                                </div> 
                        </div>
                    </div>

                    <div class="flex border-bottom-settings p-3">
                        <div class="col-6"translate>settings.passenger.title</div>
                        <div class="
                        col-6 font-semibold">{{cs.getLabelValue(moduleName+'.fields.'+selectedField.attributeCode+'.label',attributeLabels,selectedField.attributeCode)}}</div>
                    </div>

                    <div class="flex border-bottom-settings p-3">
                        <div class="col-6"translate>settings.passenger.data_type</div>
                        <div class="
                        col-6 font-semibold">{{selectedField.inputCode }}</div>
                    </div>

                    
                    <div class="flex border-bottom-settings p-3">
                        <div class="col-6"translate>settings.passenger.content</div>
                        <div class="col-6 font-semibold"> 
                        @for (item of selectedField.presetValues; track $index) {
                            {{item.labelValue}}
                            }
                        </div>
                    </div>

                    
                    <div class="flex border-bottom-settings p-3">
                        <div class="col-6"translate>settings.passenger.info_text</div>
                        <div class="col-6 font-semibold">{{cs.getLabelValue(moduleName+'.fields.'+selectedField.attributeCode+'.helpTips',attributeLabels,"Information Text")}}</div>
                    </div>


                    <div class="flex border-bottom-settings p-3">
                        <div class="col-6"translate>settings.passenger.note</div>
                        <div class="col-6 font-semibold"> {{cs.getLabelValue(moduleName+'.fields.'+selectedField.attributeCode+'.example',attributeLabels,"Note")}}</div>
                    </div>

                    <div class="flex border-bottom-settings p-3">
                        <div class="col-6"translate>settings.passenger.required_field</div>
                        <div class="col-6 font-semibold"> {{selectedField.validation.required? 'Yes': 'No'}}</div>
                    </div>


                    

                    <div>

                        
                    </div>

                    }



                    @if(selectedField && modes.isEdit) {
                    <div class="header p-3 flex align-items-center">
                        <div class="flex flex-grow-1 save-button justify-content-start flex-column">
                            <label class="uppercase text-lg font-semibold m-1">
                                {{cs.getLabelValue(moduleName+'.fields.'+selectedField.attributeCode+'.label',attributeLabels,selectedField.attributeCode)}}
                            </label>
                            <p class="p-1" translate>settings.passenger.privilege_edit_p</p>
                        </div>

                      

                        <div class="flex save-button justify-content-end">
                            <button pButton class="mr-2 mb-2 bg-white default-text-light-black-color border-button"
                                [label]="'cancel' | translate" (click)="onHide()"></button>
                            <p-button class="mb-2 flex align-items-center justify-content-center"
                                [label]="'label_save' | translate" (click)="onAddSettings()"></p-button>
                        </div>
                    </div>

                    <div class="w-full">
                        <div class="flex flex-column p-3">
                            <div class="flex align-items-left justify-content-start flex-column mb-3">
                                <div class="flex align-items-center justify-content-start font-bold" translate>
                                    settings.passenger.title</div>
                                <div class="flex align-items-center justify-content-start" translate>
                                    {{cs.getLabelValue(moduleName+'.fields.'+selectedField.attributeCode+'.label',attributeLabels,selectedField.attributeCode)}}
                                </div>
                            </div>
                            <div class="flex align-items-left justify-content-start flex-column mb-3">
                                <div class="flex align-items-center justify-content-start font-bold" translate>
                                    settings.passenger.data_type</div>
                                <div class="flex align-items-center justify-content-start">{{selectedField.inputCode}}
                                </div>
                            </div>
                            @if(isIncludeChoice.includes(selectedField?.inputCode)){
                                <div class="flex align-items-left justify-content-start flex-column mb-3">
                                <div class="flex align-items-center justify-content-start align-items-center font-bold" translate>
                                  settings.passenger.content
                                </div>
                                <p-chips [(ngModel)]="radioContent" separator="," />
                                <label translate>settings.passenger.you_can_add</label>
                                
                              </div>
                              <div class="flex align-items-right justify-content-end flex-column mb-3">
                                <p-checkbox
                                  class="flex align-items-right justify-content-end"
                                  [(ngModel)]="other"
                                  [binary]="true" 
                                  [label]="'settings.passenger.add_other_option' | translate"
                                  (onChange)="onOtherChange($event)"
                                  inputId="other" />
                              </div>
                              
                            }
                              <div class="flex align-items-start justify-content-start flex-column mb-3">
                                <p-checkbox
                                  class="flex align-items-start justify-content-end"
                                  [(ngModel)]="requiredField"
                                  [binary]="true" 
                                  [label]="'settings.passenger.requiredField' | translate"
                                  inputId="requiredField" />
                              </div>

                              <div class="flex align-items-left justify-content-start flex-column mb-3">
                                <label class="flex" translate>settings.custom_fields.lbl_information_text</label>
                                <div class="form-control">
                                    <textarea rows="3" cols="60" pInputTextarea [(ngModel)]="informationText" ></textarea>
                                    <div class="mt-1">
                                        <label class="font-12" for="informationText" translate>settings.custom_fields.lbl_additional_details_about_this_field_text</label>
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items-left justify-content-start flex-column mb-3">
                                <label class="flex" translate>settings.custom_fields.lbl_note
                                    <span class="required">*</span>
                                </label>
                                <div class="form-control">
                                    <input  [(ngModel)]="note" class="p-component p-element w-full" type="text" pInputText/>
                                    <div class="mt-1">
                                        <label class="font-12" for="note" translate>settings.custom_fields.lbl_this_note_is_to_get_more_information_text</label>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div class="flex justify-content-end mr-4 mb-2">
                            <button pButton class="mr-2 mb-2 bg-white default-text-light-black-color border-button" [label]="'cancel' | translate" (click)="onHide();onReset()"></button>
                            <p-button class="mb-2 flex align-items-center justify-content-center" [label]="'label_save' | translate" (click)="onAddPrivilegeSettings()"></p-button>
                        </div> 
                    </div>

                    }
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>