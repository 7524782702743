import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { BookingListCardComponent } from '../booking-section/booking-list-card/booking-list-card.component';
import { BookingSectionComponent } from '../booking-section/booking-section.component';
import { DispatchBookingDetailsComponent } from '../booking-section/booking-details/dispatch-booking-details.component';
import * as _ from 'lodash';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-booking-sidebar',
  standalone: true,
  imports: [
    SidebarModule,
    DialogHeaderComponent,
    BookingListCardComponent,
    BookingSectionComponent,
    DispatchBookingDetailsComponent,
    AccessProviderDirective
  ],
  templateUrl: './booking-sidebar.component.html',
  styleUrl: './booking-sidebar.component.scss'
})

export class BookingSidebarComponent {
  @ViewChild(BookingSectionComponent) bookingSectionComponent: BookingSectionComponent;
  @ViewChild(DialogHeaderComponent) dialogComponent: DialogHeaderComponent;
  @Input() visible: boolean
  @Input() leftDialogPosition: boolean
  @Input() showBookingDetails: boolean
  @Input() position: string;
  @Output() closeBtnClickedChanges: EventEmitter<any> = new EventEmitter();
  @Output() showBookingBtnClickedChanges: EventEmitter<any> = new EventEmitter();
  bookingDetailItem: any = {};
  bookingId: string;
  dispatchBookingDetails
  geoLocationsData: any = {};
  bookingDetailsById: any[] = [];
  bookingHeader: string;
  constructor(private cs: CommonBindingDataService) { }

  ngOnInit() {
    this.bookingHeader = this.cs.getLabel("dispatch.bookings");
  }

  onBookingFilterValueChange(event) {
    const bookingData = event;
    this.bookingSectionComponent.entityData.filters = [];
    for (const [key, value] of Object.entries(bookingData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.bookingSectionComponent.entityData.filters.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    this.bookingSectionComponent.entityData.filters = _.filter(_.uniq(this.bookingSectionComponent.entityData.filters, function(item, key, a) {
      if (item.attributeValue.length > 0) {
        return item.attributeId;

      }
    }), function(element) {
      if (element.attributeValue.length > 0) {
        return true;
      }
      return false;
    });
    this.bookingSectionComponent.entityData.offset = event.first;
    this.bookingSectionComponent.entityData.filters = this.cs.mapAttributeIds(this.bookingSectionComponent.entityData.filters, this.dialogComponent.filterList, 'filter');
    this.bookingSectionComponent.getBookingList();
  }

  closeBtnClicked() {
    this.closeBtnClickedChanges.emit(false);
  }

  showBookingDetailsClick() {
    this.showBookingBtnClickedChanges.emit(true);
  }




}
