import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../shared/services/config.service';
import { RestApiService } from '../shared/services/rest-api.service';
import { EventService } from '../events/services/event.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private confService: ConfigService,
    private restApiService: RestApiService,
    private eventService: EventService
  ) {


  }

  getInputLabels(): Observable<any> {
    return this.restApiService.get('ATTRIBUTE DEFINITION', `${this.confService.getLoggedInTenantCode()}/api/v1/attributes-input-types?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  getLoginOptions() {
    return this.restApiService.get('GET DRIVER APP LOGIN OPTIONS', `${this.confService.getLoggedInTenantCode()}/api/v1/login-options?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  saveCustomFields(data, entityCode) {
    return this.restApiService.post('SAVE CUSTOM FIELDS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/attributes`, data, 'page-center');
  }

  updateCustomFields(data, entityCode, attributeId) {
    return this.restApiService.put('UPDATE CUSTOM FIELDS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/attributes/${attributeId}`, data, 'page-center');
  }

  addAttributeSettings(entityCode: string, data: {}) {
    return this.restApiService.post('ADD ATTRIBUTE SETTINGS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/attributes/preset-values`, data, 'page-center');
  }

  editAttributeSettings(entityCode: string, presetLabelId, data: {}) {
    return this.restApiService.put('EDIT ATTRIBUTE SETTINGS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/attributes/preset-values/${presetLabelId}`, data, 'page-center');
  }

  searchAttributeSettings(entityCode: string, data: {}, loader?: boolean) {
    return this.restApiService.post('SEARCH ATTRIBUTE SETTINGS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/attributes/preset-values/search`, data, loader ? 'page-center' : '');
  }

  deleteAttributeSettings(entityCode: string, data) {
    return this.restApiService.put('DELETE ATTRIBUTE SETTINGS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/attributes/preset-values/action/bulk-delete`, data, 'page-center');
  }

  updateUserProfileById(userId, data: {}) {
    return this.restApiService.put('UPDATE USER PROFILE', `${this.confService.getLoggedInTenantCode()}/api/v1/settings/user-profile/${userId}`, data, 'page-center');
  }

  updateCompanyInfo(data: {}) {
    this.setEventCode(data);
    return this.restApiService.put('UPDATE ABOUT US PROFILE', `${this.confService.getLoggedInTenantCode()}/api/v1/company-info`, data, 'page-center');
  }

  getCompanyInfo() {
    return this.restApiService.get('GET ABOUT US INFO', `${this.confService.getLoggedInTenantCode()}/api/v1/company-info?forTenantCode=${this.confService.getForTenantCode()}&eventCode=${this.eventService.getSelectedEventcode()}`, 'page-center');
  }

  addFaq(data: {}) {
    this.setEventCode(data);
    return this.restApiService.post('ADD FAQ', `${this.confService.getLoggedInTenantCode()}/api/v1/faqs`, data, 'page-center');
  }

  getFaqData(rolecode: string, data: {}) {
    this.setEventCode(data);
    return this.restApiService.post('GET FAQ DATA', `${this.confService.getLoggedInTenantCode()}/api/v1/faqs/search?forTenantCode=${this.confService.getForTenantCode()}&roleCode=${rolecode}`, data, 'page-center')
  }

  updateFaqById(faqId: string, data: {}) {
    this.setEventCode(data);
    return this.restApiService.put('UPDATE FAQ', `${this.confService.getLoggedInTenantCode()}/api/v1/faqs/${faqId}`, data, 'page-center');
  }

  deleteFaq(faqId: string) {
    return this.restApiService.delete('DELETE FAQ', `${this.confService.getLoggedInTenantCode()}/api/v1/faqs/${faqId}?forTenantCode=${this.confService.getForTenantCode()}&eventCode=${this.eventService.getSelectedEventcode()}`, 'page-center');
  }

  updateStaticContentInfo(data: {}) {
    this.setEventCode(data);
    return this.restApiService.put('UPDATE STATIC CONTENT', `${this.confService.getLoggedInTenantCode()}/api/v1/static-contents`, data, 'page-center');
  }

  getStaticContentData(contentType: string) {
    return this.restApiService.get('GET STATIC CONTENT INFO', `${this.confService.getLoggedInTenantCode()}/api/v1/static-contents/${contentType}?forTenantCode=${this.confService.getForTenantCode()}&eventCode=${this.eventService.getSelectedEventcode()}`, 'page-center')
  }

  updateLoginInformationById(userId, data: {}) {
    return this.restApiService.put('UPDATE LOGIN INFO', `${this.confService.getLoggedInTenantCode()}/api/v1/settings/users/${userId}`, data, 'page-center');
  }

  updatePasswordById(userId, data: {}) {
    return this.restApiService.put('UPDATE PASSWORD', `${this.confService.getLoggedInTenantCode()}/api/v1/users/${userId}/password`, data, 'page-center');
  }

  getAttributeDataTypes() {
    return this.restApiService.get('GET ATTRIBUTE DATA TYPES', `${this.confService.getLoggedInTenantCode()}/api/v1/attributes-input-types?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  getCustomFieldListByEntityCode(entityCode, data) {
    return this.restApiService.post('GET CUSTOM FIELD LIST BY ENTITY CODE', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/attributes/search?forTenantCode=${this.confService.getForTenantCode()}`, data, 'page-center');
  }

  getCustomFieldDetailsByAttributeId(entityCode, attributeId, attributeCode) {
    return this.restApiService.get('GET CUSTOM FIELD DETAILS BY ID', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/attributes/${attributeId}?forTenantCode=${this.confService.getForTenantCode()}&attributeCode=${attributeCode}`, 'page-center');
  }

  deleteCustomFieldById(entityCode, attributeId, attributeCode) {
    return this.restApiService.delete('DELETE CUSTOM FIELD BY ID', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/attributes/${attributeId}?forTenantCode=${this.confService.getForTenantCode()}&attributeCode=${attributeCode}`, 'page-center');
  }

  getRoleLoginOption(roleCode: string): Observable<any> {
    return this.restApiService.get('GET ROLE LOGIN OPTION INFO', `${this.confService.getLoggedInTenantCode()}/api/v1/role-login-options/${roleCode}?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  getLoginOption(): Observable<any> {
    return this.restApiService.get('GET LOGIN OPTION INFO', `${this.confService.getLoggedInTenantCode()}/api/v1/login-options?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  setRoleLoginOption(data): Observable<any> {
    return this.restApiService.put('GET ROLE LOGIN OPTION INFO', `${this.confService.getLoggedInTenantCode()}/api/v1/role-login-options`, data, 'page-center');
  }

  addTags(entityCode: string, data: {}) {
    return this.restApiService.post('ADD TAGS', `${this.confService.getLoggedInTenantCode()}/api/v1/${entityCode}`, data, 'page-center');
  }

  editTags(entityCode: string, tagId, data: {}) {
    return this.restApiService.put('EDIT TAGS', `${this.confService.getLoggedInTenantCode()}/api/v1/${entityCode}/${tagId}`, data, 'page-center');
  }

  deleteTags(entityCode: string, data) {
    return this.restApiService.put('DELETE TAGS', `${this.confService.getLoggedInTenantCode()}/api/v1/${entityCode}/bulk-delete`, data, 'page-center');
  }

  getCommonPresetLabels(data) {
    return this.restApiService.post('GET COMMON PRESET LABELS', `${this.confService.getLoggedInTenantCode()}/api/v1/common-preset-labels/search`, data, 'page-center');
  }

  addCommonPresetLabels(data) {
    return this.restApiService.post('ADD COMMON PRESET LABELS', `${this.confService.getLoggedInTenantCode()}/api/v1/common-preset-labels`, data, 'page-center');
  }

  editCommonPresetLabels(data, id) {
    return this.restApiService.put('EDIT COMMON PRESET LABELS', `${this.confService.getLoggedInTenantCode()}/api/v1/common-preset-labels/${id}`, data, 'page-center');
  }

  deleteCommonPresetLabels(data) {
    return this.restApiService.put('DELETE COMMON PRESET LABELS', `${this.confService.getLoggedInTenantCode()}/api/v1/common-preset-labels/action/bulk-delete`, data, 'page-center');
  }

  private setEventCode(data) {
    data.eventCode = this.eventService.getSelectedEventcode();
   }

}
