import { Component, OnInit } from "@angular/core";
import { DashboardNavbarComponent } from "../dashboard-navbar/dashboard-navbar.component";
import { ToggleButtonModule } from "primeng/togglebutton";
import { AppSettings } from "../../../shared/app.settings";
import { FormsModule } from "@angular/forms";
import { AccessProviderDirective } from "app/modules/shared/directives/access-provider.directive";

@Component({
  selector: "app-maps",
  templateUrl: "./maps.component.html",
  styleUrls: ["./maps.component.scss"],
  standalone: true,
  imports: [DashboardNavbarComponent, ToggleButtonModule, FormsModule, AccessProviderDirective],
})
export class MapsComponent implements OnInit {
  map: google.maps.Map;
  checked: boolean = false;
  infoWindow: google.maps.InfoWindow;
  marker: any = null;
  markers: any = [];
  records: any = [];

  constructor() { }

  ngOnInit() {
    this.initMap();
  }

  async initMap(): Promise<void> {
    const { Map, InfoWindow } = (await google.maps.importLibrary(
      "maps"
    )) as google.maps.MapsLibrary;

    this.map = new Map(document.getElementById("map") as HTMLElement, {
      center: { lat: 18.50932924799519, lng: 74.32022387996037 },
      zoom: 10,
      zoomControl: true,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      mapId: AppSettings.MAP_ID,
    });
    this.infoWindow = new InfoWindow();
    this.setupControls();
    this.addMarkers();
  }

  setupControls() {
    const toggleControl = document.getElementById("style-selector-control");
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      toggleControl
    );

    const geolocationControl = document.getElementById("my-location-control");
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      geolocationControl
    );
  }

  async addMarkers() {
    const iconBase = "/assets/images/mi-theme/light/map/";
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      "marker"
    )) as google.maps.MarkerLibrary;
    const icons: Record<string, { icon: string }> = {
      green: {
        icon: iconBase + "green.svg",
      },
      yellow: {
        icon: iconBase + "yellow.svg",
      },
      red: {
        icon: iconBase + "red.svg",
      },
      purple: {
        icon: iconBase + "purple.svg",
      },
    };

    const features = [
      {
        position: new google.maps.LatLng(28.6139, 77.209),
        type: "green",
        title: "New Delhi",
      },
      {
        position: new google.maps.LatLng(12.9716, 77.5946),
        type: "yellow",
        title: "Bengaluru",
      },
      {
        position: new google.maps.LatLng(19.076, 72.8777),
        type: "red",
        title: "New Delhi",
      },
      {
        position: new google.maps.LatLng(17.385, 78.4867),
        type: "green",
        title: "New Delhi",
      },
      {
        position: new google.maps.LatLng(13.0827, 80.2707),
        type: "yellow",
        title: "New Delhi",
      },
      {
        position: new google.maps.LatLng(22.5726, 88.3639),
        type: "purple",
        title: "New Delhi",
      },
    ];

    for (let i = 0; i < features.length; i++) {
      const iconImage = document.createElement("img");
      iconImage.src = icons[features[i].type].icon;
      const marker = new AdvancedMarkerElement({
        map: this.map,
        position: features[i].position,
        content: iconImage,
        title: features[i].title,
      });

      const infoWindow = new google.maps.InfoWindow({
        content: features[i].title,
        maxWidth: 200,
      });

      marker.addListener("click", () => {
        infoWindow.open(this.map, marker);
      });
    }
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.infoWindow.setPosition(pos);
          this.infoWindow.setContent("Location found.");
          this.infoWindow.open(this.map);
          this.map.setCenter(pos);
        },
        () => {
          this.handleLocationError(true);
        }
      );
    } else {
      this.handleLocationError(false);
    }
  }

  handleLocationError(browserHasGeolocation: boolean) {
    const pos = this.map.getCenter();
    this.infoWindow.setPosition(pos);
    this.infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
    this.infoWindow.open(this.map);
  }

  toggleDarkAndLightMap(event) {
    this.checked = event.checked;
    const mapStyles = this.checked
      ? AppSettings.LOCATION_MAP_STYLE_DARK
      : AppSettings.LOCATION_MAP_STYLE_DEFAULT;
    if (!this.checked) {
      mapStyles.push({ featureType: "poi", stylers: [{ visibility: "off" }] });
    }
    this.map.setOptions({ styles: mapStyles });
  }
}
