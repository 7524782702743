import { JsonPipe } from '@angular/common';
import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import type { GetAttributes } from 'app/modules/bookings/models/booking.models';
import { SettingsService } from 'app/modules/settings/settings.service';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import type { Column } from 'app/modules/vehicles/models/attribute.models';
import { EntityList } from 'app/modules/vehicles/models/entity.models';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';

interface Modes {
  isView: boolean;
  isEdit: boolean;
  isList: boolean;
}

@Component({
  selector: 'app-settings-privilege-settings',
  standalone: true,
  imports: [ToastModule, InputTextareaModule, TabViewModule, TranslateModule, DropdownModule, FormsModule, JsonPipe, ButtonModule, MenuModule, TableModule, ButtonModule, ChipsModule, CheckboxModule],
  templateUrl: './settings-privilege-settings.component.html',
  styleUrl: './settings-privilege-settings.component.scss'
})
export class SettingsPrivilegeSettingsComponent implements OnInit {
  miIcons = AppIcons;
  country: Country;
  language: Language;
  passengerAttributes: any;
  attributeLabels: any;
  groups: any;
  moduleName = AppSettings.ENTITY_CODE.PASS_PRIVILEGE_CONF;
  selectedGroup: any;
  default: any;
  cols!: Column[];
  selectedRows;
  rowItems: MenuItem[] | undefined;
  cities: any;
  currentRow;
  modes: Modes = {
    isView: false,
    isEdit: false,
    isList: true,
  };
  selectedField: any;
  selectedPassengerType: any;
  radioContent: string[] = [];
  other: boolean = false;
  requiredField: boolean = false;
  defaultItems = ['Yes', 'No'];
  isIncludeChoice = ['radioButton', 'select', 'geoPoint'];
  selectedProduct: any;
  note: any;
  informationText: any;
  selectedValues: any;

  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [{
      "attributeId": 1,
      "attributeValue": "VIP"
    }],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: "",
    actionStatus: ''
  };

  constructor(private messageService: MessageService, private entityService: EntityService,
    public cs: CommonBindingDataService, private confirmationService: ConfirmationService,
    private cd: ChangeDetectorRef, private ngZone: NgZone, private configService: ConfigService,
    private settingService: SettingsService) {

  }

  ngOnInit(): void {

    this.setLabels();
    this.getAttributes();
    this.getColumns();
    this.setRowItems();
  }

  selectGroup(group) {
    this.selectedGroup = group;
  }

  setLabels() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.settingService.getInputLabels().subscribe((res) => {
      console.log(res)
    })
  }

  getAttributes() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.PASS_PRIVILEGE_CONF, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((passenger: GetAttributes) => {
      this.passengerAttributes = this.cs.getOrganizedAttribute(passenger);
      this.attributeLabels = this.cs.getAttributeLabels(passenger);
      this.groups = this.passengerAttributes?.tabs[0]?.groups;
      this.default = _.remove(this.groups, { code: 'default' })[0];
      this.selectedGroup = this.groups?.[0];
      this.getEntity();
    });
  }

  getEntity() {
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.PASS_PRIVILEGE_CONF, this.entityData).subscribe((result: any) => {
      console.log(result)

      const selectedPlayerRole = result.data[0].id;
      this.getData(selectedPlayerRole);

    })

  }

  getData(selectedPlayerRole) {
    this.entityService.getEntity(selectedPlayerRole, AppSettings.ENTITY_CODE.PASS_PRIVILEGE_CONF, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((result: any) => {
      this.selectedValues = result.attributeCodeValueDtoList.reduce((map, attr) => {
        map[attr.attributeCode] = attr.attributeValue;
        return map;
      }, {});

    });
  }

  getColumns() {
    this.cols = [
      { field: 'label', header: this.cs.getLabel('settings.passenger.title') },
      { field: 'inputCode', header: this.cs.getLabel('settings.passenger.data_type') },
      { field: 'attributeCode', header: this.cs.getLabel('settings.passenger.required') },
    ];
  }


  rowMouseOver(rowData) {
    rowData.showRowMenu = true;
  }

  rowMouseOut(rowData) {
    rowData.showRowMenu = false;
  }

  private setRowItems() {
    this.rowItems = [
      {
        label: this.cs.getLabel('settings.edit'),
        icon: AppIcons.EDIT_2 + " wh-16",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.update();
        }
      },
      {
        label: this.cs.getLabel('settings.delete'),
        icon: AppIcons.BASIC_TRAY + " wh-16",
        iconClass: "bulk-update-icon",
        command: (e) => {
          const clonedEvent = _.cloneDeep(e);
          this.delete(clonedEvent, this.selectedRows, AppSettings.DELETE_TYPE.ROW);
        },
      },
    ];
  }

  setCurrentRow(rowData) {
    this.currentRow = rowData;
  }


  update() {
    this.setModeTrue('isEdit');
    this.selectedField = this.currentRow;
    this.radioContent = _.map(this.selectedField.presetValues, 'labelValue');
  }
  delete(event: Event, rowData: any, deleteType: string) {
    this.confirmationService.confirm({
      header: this.cs.getLabel('settings.passenger.passenger_privilege_header'),
      message: this.cs.getLabel('settings.passenger.delete_msg'),
      acceptIcon: '',
      rejectIcon: '',
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        if (deleteType === AppSettings.DELETE_TYPE.ROW) {
          this.onSettingsDelete(this.currentRow.attributePresetLabelId);
        }

      },
      reject: () => {
      }
    });
  }

  onSettingsDelete(id) {
    console.log(id)
  }


  setModeTrue(mode: keyof Modes) {
    Object.keys(this.modes).forEach(key => {
      this.modes[key as keyof Modes] = (key === mode);
    });
  }

  onAddSettings() {
    this.setModeTrue('isEdit');
  }

  onHide() {
    this.setModeTrue('isList');
  }

  onRowSelect(event) {
    this.setModeTrue('isView');
    this.selectedField = event.data;
    const data = [...this.selectedGroup];
    this.selectedGroup = data;
  }




  onAddPrivilegeSettings() {

  }

  onReset() {
    this.radioContent = []
    this.other = false;
    this.requiredField = false;

  }

  onOtherChange(event: any): void {
    this.ngZone.run(() => {
      if (event.checked) {
        if (!this.radioContent.includes('Other')) {

          this.radioContent = [...this.radioContent, 'Other']
        }
      } else {
        this.radioContent = this.radioContent.filter(option => option !== 'Other');
      }
      console.log('Updated radioContent:', this.radioContent);
    });
  }

}
