<form [formGroup]="formName">
    @if (moduleName === 'booking_pet_info') {
        <div class="relative pet-info">
            <p-inputNumber pKeyFilter="num" [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" [showButtons]="true" class="w-full" [placeholder]="placeholder"> </p-inputNumber>
        </div>
    } @else {
        <div class="relative">
            <p-inputNumber pKeyFilter="num" [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" [showButtons]="true" class="w-full"> </p-inputNumber>
            <span class="unit right">{{unit}}</span>
        </div>
    }
</form>