import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ImageModule } from 'primeng/image';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-mi-image-container',
  standalone: true,
  imports: [NgStyle, ImageModule],
  templateUrl: './mi-image-container.component.html',
  styleUrl: './mi-image-container.component.scss'
})
export class MiImageContainerComponent {
  @Input() miImage;
  @Input() miHeight;
  @Input() miWidth;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;

}
