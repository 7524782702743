import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollerModule } from 'primeng/scroller';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabViewModule } from 'primeng/tabview';
import { AppSettings } from '../../../shared/app.settings';
import type { Country } from '../../../shared/models/country';
import type { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { getTimeDiffInHours } from '../../../shared/utility-functions/date';
import { AddEditBreakComponent } from '../../../shift-management/components/add-edit-break/add-edit-break.component';
import { ShiftTemplateService } from '../../services/shift-template.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-add-shift-template',
  standalone: true,
  imports: [
    ButtonModule, TranslateModule, TabViewModule, BreadcrumbModule, DropdownModule, CalendarModule, SelectButtonModule,
    RadioButtonModule, AddEditBreakComponent, ScrollPanelModule, ScrollerModule, InputTextModule, CheckboxModule,
    FormsModule, ReactiveFormsModule, AccessProviderDirective
  ],

  templateUrl: './add-shift-template.component.html',
  styleUrl: './add-shift-template.component.scss'
})
export class AddShiftTemplateComponent {
  routePath: MenuItem[] = [];
  items!: string[];
  dateFormat = AppSettings.HOUR_MINUTE_FORMAT;
  hourFormat = AppSettings.HOUR_FORMAT;

  DRIVER_ASSIGNMENT_TYPE = AppSettings.DRIVER_ASSIGNMENT_TYPE;
  addShiftTemplateForm: FormGroup;
  shiftHours: any;
  startTime: Date;
  endTime: Date;
  country: Country;
  language: Language;
  shiftTemplateId: any;
  isTimeValid: boolean = false;
  shiftHoursSuffixed: any;
  disabled: boolean = true;
  saveBtnLabel: string;

  constructor(private shiftService: ShiftTemplateService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private confService: ConfigService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute) { }


  ngOnInit() {
    this.shiftTemplateId = this.route.snapshot.paramMap.get('id');
    this.saveBtnLabel = this.shiftTemplateId ? this.cs.getLabel('shiftTemplate.lbl_update_template') : this.cs.getLabel('shiftTemplate.lbl_save_template');
    if (this.shiftTemplateId) {
      this.getShiftTemplateDetails();
    }
    this.addShiftTemplateForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(AppSettings.ALPHA_NUMERIC_NAME)]],
      startTime: ['', [Validators.required]],
      endTime: ['', [Validators.required]],
      calculateHours: [],
      calculateHoursSuffixed: [],
      breakFields: this.formBuilder.array([]),
    });
    this.addShiftTemplateForm.controls['calculateHoursSuffixed'].disable();

    this.setAppSettings();
    this.setRoutePath();
  }

  onStartTimeInput(event) {
    if (this.addShiftTemplateForm.controls['startTime'].value) {
      this.startTime = this.addShiftTemplateForm.controls['startTime'].value;
      this.shiftHours = getTimeDiffInHours(this.startTime, this.endTime);
      this.shiftHoursSuffixed = `${this.shiftHours} Hours`;
    }
  }

  onEndTimeInput(event) {
    if (this.addShiftTemplateForm.controls['endTime'].value) {
      this.endTime = this.addShiftTemplateForm.controls['endTime'].value;
      this.shiftHours = getTimeDiffInHours(this.startTime, this.endTime);
      this.shiftHoursSuffixed = `${this.shiftHours} Hours`;
    }
  }

  getShiftTemplateDetails() {
    this.shiftService.getDetails(this.shiftTemplateId).subscribe((result: any) => {
      this.startTime = new Date(result.startTime);
      this.endTime = new Date(result.endTime);
      this.addShiftTemplateForm.controls['name'].setValue(result.shiftName);
      this.addShiftTemplateForm.controls['startTime'].setValue(new Date(result.startTime));
      this.addShiftTemplateForm.controls['endTime'].setValue(new Date(result.endTime));
      this.addShiftTemplateForm.controls['calculateHoursSuffixed'].setValue(`${result.calculateHours / 60} Hours`);
      this.addShiftTemplateForm.controls['calculateHours'].setValue(`${result.calculateHours / 60}`);
      this.setBreakValues(result);
    })
  }

  setBreakValues(result) {
    result.shiftTemplateBreakList.forEach(element => {
      this.breakFields.push(
        this.formBuilder.group({
          breakStartTime: [new Date(element.breakStartTime)],
          breakEndTime: [new Date(element.breakEndTime)],
        })
      );
    });
  }

  setAppSettings() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  initializeForm() {
    this.breakFields.push(
      this.formBuilder.group({
        breakStartTime: [],
        breakEndTime: []
      })
    );
  }


  setRoutePath() {
    this.routePath = [
      {
        label: this.shiftTemplateId ? this.cs.getLabel('shiftTemplate.edit_shift_template') : this.cs.getLabel('shiftTemplate.add_new_shift_template'),
        routerLink: '/app/shift-template',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      }
    ];
  }

  onStartTimeSelect(event) {
    this.startTime = event;
    this.shiftHours = getTimeDiffInHours(this.startTime, this.endTime);
    this.shiftHoursSuffixed = `${this.shiftHours} Hours`;
  }

  onEndTimeSelect(event) {
    this.endTime = event;
    this.shiftHours = getTimeDiffInHours(this.startTime, this.endTime);
    this.shiftHoursSuffixed = `${this.shiftHours} Hours`;
  }

  shiftNameBlur() {
    this.addShiftTemplateForm.controls['name'].setValue(this.addShiftTemplateForm.controls['name'].value?.trim());
  }

  onSaveShiftTemplate(saveFlag) {
    if (!this.addShiftTemplateForm.valid) {
      this.addShiftTemplateForm.markAllAsTouched();
      return
    } else {
      if (this.startTime.getTime() > this.endTime.getTime()) {
        this.addShiftTemplateForm.markAllAsTouched();
        return
      }

      const breaks = [];
      this.breakFields.controls.forEach((element: any) => {
        if (element.controls['breakStartTime']?.value || element.controls['breakEndTime']?.value) {
          breaks.push({
            startTimeStr: dayjs(element.controls['breakStartTime']?.value).format(AppSettings.HOUR_MINUTE_FORMAT),
            endTimeStr: dayjs(element.controls['breakEndTime']?.value).format(AppSettings.HOUR_MINUTE_FORMAT)
          });
        }
      });

      const requestBody: any = {
        forTenantCode: this.confService.getLoggedInTenantCode(),
        countryCode: this.country[0].countryCode,
        shiftName: this.addShiftTemplateForm.controls['name'].value?.trim(),
        startTimeStr: dayjs(this.addShiftTemplateForm.controls['startTime'].value).format(AppSettings.HOUR_MINUTE_FORMAT),
        endTimeStr: dayjs(this.addShiftTemplateForm.controls['endTime'].value).format(AppSettings.HOUR_MINUTE_FORMAT),
        calculateHours: this.addShiftTemplateForm.controls['calculateHours'].value,
      }

      if (breaks.length > 0) {
        requestBody.breaks = breaks;
      }


      console.log(requestBody);
      if (this.shiftTemplateId) {
        this.UpdateShiftTemplate(requestBody);
      } else {
        this.AddShiftTemplate(requestBody, saveFlag);
      }
    }
  }

  AddShiftTemplate(requestBody, saveFlag) {
    this.shiftService.addShift(requestBody).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('shiftTemplate.shift_template_added_successfully'), });
        if (saveFlag === 1) {
          this.resetForm();
        } else {
          this.router.navigate(['/app/shift-template']);
        }
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  resetForm() {
    this.startTime = null;
    this.endTime = null;
    this.addShiftTemplateForm.reset();
  }


  UpdateShiftTemplate(requestBody) {
    this.shiftService.updateShift(requestBody, this.shiftTemplateId).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('shiftTemplate.shift_template_updated_successfully'), });
        this.router.navigate(['/app/shift-template']);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].messageCode) });
      }
    });
  }

  onCancel() {
    this.router.navigate(['/app/shift-template']);
  }

  addMoreSlots() {
    this.breakFields.push(
      this.formBuilder.group({
        breakStartTime: [],
        breakEndTime: []
      })
    );
  }

  removeBreak(index) {
    this.breakFields.removeAt(index);
  }

  get breakFields() {
    return this.addShiftTemplateForm.get('breakFields') as FormArray;
  }

}
