import { Injectable, signal } from '@angular/core';
import { Country } from '../../shared/models/country';
import { ConfigService } from '../../shared/services/config.service';
import { RestApiService } from '../../shared/services/rest-api.service';
import { StorageService } from 'app/modules/shared/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  country: Country;
  private selectedEventSignal = signal<any>({});

  constructor(private restApiService: RestApiService, private confService: ConfigService,
    private storageService: StorageService
  ) { }

  get getSelectedEventData() {
    return this.selectedEventSignal();
  }


  setSelectedEventData(selectedEvent: any): void {
    // this.selectedEventSignal.set(selectedEvent);
    this.storageService.setItem('eventCodeData', selectedEvent);
  }

  getSelectedEventcode() {
    const eventData = JSON.parse(localStorage.getItem('eventCodeData'));
    // const eventData = this.selectedEventSignal();
    return eventData ? eventData : null;
  }

  setSelectedEventId(eventId: any): void {
    this.storageService.setItem('selectedEventId', eventId);
  }

  getSelectedEventId() {
    const eventId = JSON.parse(localStorage.getItem('selectedEventId'));
    return eventId ? eventId : null;
  }

  getEventList(requestBody) {
    return this.restApiService.post('Event LIST', `${this.confService.getLoggedInTenantCode()}/api/v1/events/search`, requestBody, 'page-center');
  }

  createEvent(requestBody) {
    return this.restApiService.post('create event', `${this.confService.getLoggedInTenantCode()}/api/v1/events`, requestBody, 'page-center');
  }

  updateEvent(requestBody, eventId) {
    return this.restApiService.put('update event', `${this.confService.getLoggedInTenantCode()}/api/v1/events/${eventId}`, requestBody, 'page-center');
  }

  getEvent(eventId) {
    return this.restApiService.get('get event', `${this.confService.getLoggedInTenantCode()}/api/v1/events/${eventId}?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  updateEventStatus(eventId: string, data: any) {
    return this.restApiService.post('UPDATE EVENT STATUS', `${this.confService.getLoggedInTenantCode()}/api/v1/events/${eventId}/action`, data, 'page-center');
  }

  getTransportationTypes(eventId) {
    return this.restApiService.get('get transportation types', `${this.confService.getLoggedInTenantCode()}/api/v1/event-transportation-types/${eventId}?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

}
