import { Component, Input, OnInit } from '@angular/core';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-mi-mobile-value',
  standalone: true,
  imports: [],
  templateUrl: './mi-mobile-value.component.html',
  styleUrls: ['./mi-mobile-value.component.scss', '../../../shared//components//mi-fields//mobile-input/flags.scss']
})
export class MiMobileValueComponent {
  @Input() attributeValues;
  @Input() countryCodeForMobileNumber;
  @Input() field;
  @Input() isStaticEntity: boolean = false;
  @Input() mobileNumber: any;
  @Input() moduleName:any;

}
