import { DatePipe } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { Tooltip, TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-manual-dispatch-details',
  standalone: true,
  imports: [
    BreadcrumbModule,
    TranslateModule,
    DatePipe,
    DialogModule,
    ButtonModule,
    TooltipModule,
  ],
  templateUrl: './manual-dispatch-details.component.html',
  styleUrl: './manual-dispatch-details.component.scss'
})
export class ManualDispatchDetailsComponent {
  @Input() miFormGroup: any;
  routePath: MenuItem[];
  TRANSPORTATION_TYPE = AppSettings.TRANSPORTATION_TYPE;
  TRANSPORTATION_MODE = AppSettings.TRANSPORTATION_MODE;
  miIcons = AppIcons;

  bookingDetails = {
    actionStatus: null,
    deleted: false,
    entityCode: 'booking',
    id: '01J9TRNG70CHKYVJ6MG19W0WPM',
    relatedData: null,
    values: {
      infant_carrier: 0,
      wheelchair: 0,
      owner_pass_type: '',
      booking_status: '',
      booking_dropoff_location_address: '',
      booking_pickup_location_address: '',
      booking_stops_location_address: [''],
      booster: 0,
      child_seat: 0,
      luggage: 0,
      number_of_passenger: 0,
      owner_pass_full_name: '',
      owner_pass_unique_id: '',
      pickup_date: 0,
      pickup_time: 0,
      transportation_type: '',
      additional_vehicle_requirement: 0,
      booking_note: ''
    }
  };

  ngOnInit() {
    console.log(this.miFormGroup.value);
    this.bookingDetails.values.number_of_passenger = this.miFormGroup.controls['number_of_passenger'].value;
    this.bookingDetails.values.pickup_date = this.miFormGroup.controls['pickup_date'].value;
    const pickupTime = this.miFormGroup.controls['pickup_time'].value;
    const formattedTime = pickupTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    this.bookingDetails.values.pickup_time = formattedTime;
    this.bookingDetails.values.infant_carrier = this.miFormGroup.controls['infant_carrier'].value;
    this.bookingDetails.values.child_seat = this.miFormGroup.controls['child_seat'].value;
    this.bookingDetails.values.booster = this.miFormGroup.controls['booster'].value;
    this.bookingDetails.values.wheelchair = this.miFormGroup.controls['wheelchair'].value;
    this.bookingDetails.values.additional_vehicle_requirement = this.miFormGroup.controls['additional_passenger_vehicle'].value;
    this.bookingDetails.values.transportation_type = this.miFormGroup.controls['transportation_type'].value;
    this.bookingDetails.values.booking_note = this.miFormGroup.controls['instruction_note'].value;
  }


  getTotalChildSeats(): number {
    const { child_seat, infant_carrier, booster } = this.bookingDetails.values;
    return (child_seat || 0) + (infant_carrier || 0) + (booster || 0);
  }

}
