<div class="flex flex-row justify-content-between card-container" (click)="onCardClick(bookingListItem)">
  <div class="flex flex-column">
    <div class="flex flex-row">
      <div class="color-bar mr-2 mt-1" [ngClass]="{ 'new-request': bookingListItem.booking_status === BOOKING_STATUS_IDS.NEW_REQUEST,
        'driver-assigned': bookingListItem.booking_status === BOOKING_STATUS_IDS.DRIVER_ASSIGNED,
        'completed': bookingListItem.booking_status === BOOKING_STATUS_IDS.COMPLETED }">
      </div>
      <div class="flex flex-column">
        <div class="flex flex-row align-items-center mb-2">
          <div class="font-16 font-bold default-text-black-color mr-2">{{bookingListItem.departure_time_and_date | date: 'hh:mm, dd MMM yyyy'}}</div>
          <div class="font-14 font-semibold default-print-label-color mr-2">{{bookingListItem.id}}</div>
        </div>
        <div class="flex flex-row align-items-center">
           <div class="font-13 font-semibold default-text-black-color mr-2">
            {{(bookingListItem?.number_of_passenger ? bookingListItem?.number_of_passenger : null) + bookingListItem?.passengers?.length}} Pass
          </div>
           <div class="font-13 font-semibold default-print-label-color type-of-pass mr-2">{{bookingListItem?.passengers[0]?.passenger_type}}</div>
           <div><i class="mi-lg mi-watchlist inline-block vertical-align-text-bottom cursor-pointer wh-20"></i></div>
        </div>
      </div>
    </div>
    <div class="flex flex-row align-items-center mt-2">
        <div class="mr-2 flight-container">
          @if (bookingListItem?.transportation_type === TRANSPORTATION_TYPE.AIRPORT) {
            <i class="{{miIcons.BOOKING_AIRPORT}} inline-block vertical-align-text-bottom cursor-pointer wh-20"></i>
          } @else {
            @switch (bookingListItem?.transportation_mode) {
              @case (TRANSPORTATION_MODE.CAR) {
              <i class="{{miIcons.BOOKING_CAR}} inline-block vertical-align-text-bottom cursor-pointer wh-20 "></i>
              }
    
              @case (TRANSPORTATION_MODE.SHUTTLE) {
              <i class="{{miIcons.BOOKING_SHUTTLE_OLD}} inline-block vertical-align-text-bottom cursor-pointer wh-20 "></i>
              }
    
              @case (TRANSPORTATION_MODE.BUS) {
              <i class="{{miIcons.BOOKING_BUS}} inline-block vertical-align-text-bottom cursor-pointer wh-20 "></i>
              }
            }
          }
        </div>
        <div class="mr-2">
          @if ((bookingListItem?.number_of_passenger ? bookingListItem?.number_of_passenger : null) + bookingListItem?.passengers?.length > 1) {
            <i class="{{miIcons.BOOKING_SHARED_RIDE}} inline-block vertical-align-text-bottom cursor-pointer wh-20"></i>
          } @else {
            <i class="{{miIcons.BOOKING_PRIVATE_RIDE}} inline-block vertical-align-text-bottom cursor-pointer wh-20"></i>
          }
          <span class="default-separator-color font-15">|</span>
        </div>
        @if (bookingListItem.child_seat > 0) {
          <div class="mr-2">
            <span class="font-16 font-bold text-dark-green">{{bookingListItem.child_seat}}</span>
            <span class="font-14 font-semibold text-dark-green">C</span>
            <span class="default-separator-color ml-2 font-15">|</span>
          </div>
        }
        @if (bookingListItem.wheelchair > 0) {
          <div class="mr-2">
            <span class="font-16 font-bold text-dark-green">{{bookingListItem.wheelchair}}</span>
            <span class="font-14 font-semibold text-dark-green">W</span>
            <span class="default-separator-color ml-2 font-15">|</span>
          </div>
        }
        @if (bookingListItem.bags > 0) {
          <div class="mr-2">
            <span class="font-16 font-bold text-dark-green">{{bookingListItem.bags}}</span>
            <span class="font-14 font-semibold text-dark-green ml-1">Bags</span>
            <span class="default-separator-color ml-2 font-15">|</span>
          </div>
        }
        @if (bookingListItem.additionalVehicle) {
          <div>
            <i class="mi-lg mi-stepper_color mi-additional_vehicle inline-block vertical-align-text-bottom cursor-pointer wh-20"></i>
            <span class="font-18 font-semibold default-text-black-color ml-1">{{bookingListItem.additionalVehicle}}</span>
            <span class="default-separator-color ml-2 font-15">|</span>
          </div>
        }
        @if (bookingListItem.booking_note) {
          <div>
            <span #mydiv class="relative white-space-nowrap pl-1">
              <i class="mi-lg bg-orange mi-instructions mt-2 inline-block cursor-pointer wh-20" [appendTo]="mydiv"
                [pTooltip]="bookingListItem.booking_note" tooltipPosition="right" tooltipStyleClass="tooltip-container"
              ></i>
            </span>
          </div>
        }
      </div>
  </div>
  @if(!forDetail){<div class="flex align-items-center font-14 font-semibold">Auto <span><i class="mi-lg bg-route-icon chevron-right mt-2 inline-block cursor-pointer wh-24"></i></span></div>}
  @if(forDetail){<div class="flex flex-column justify-content-between align-items-center font-semibold">
    <div class="ml-4" (click)="onCloseDetailView()"><i class="mi-lg mi-off_close mt-2 inline-block cursor-pointer wh-24"></i></div>
    <button class="edit-btn bg-white mr-2 mb-1 cursor-pointer"><span class="font-13 font-medium mr-1 default-text-blue-color" (click)="onEditClick()">Edit</span><span><i class="mi-lg edit_2 inline-block vertical-align-text-bottom mi-stepper_color wh-16"></i></span></button>
  </div>}
  
</div>
