import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../shared/services/entity.service';
import { AppSettings } from '../../../shared/app.settings';
import * as _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { MiErrorComponent } from '../../../shared/components/mi-error/mi-error.component';
import { MiFieldsComponent } from '../../../shared/components/mi-fields/mi-fields.component';
import { MiTooltipComponent } from '../../../shared/components/mi-fields/mi-tooltip/mi-tooltip.component';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { Country } from 'app/modules/shared/models/country';
import { AppIcons } from 'app/modules/shared/app.icons';

@Component({
  selector: 'app-vehicle-document',
  standalone: true,
  imports: [AccordionModule, FormsModule, ReactiveFormsModule, MiFieldsComponent, MiErrorComponent, ButtonModule, MiTooltipComponent, TranslateModule],
  templateUrl: './vehicle-document.component.html',
  styleUrl: './vehicle-document.component.scss'
})
export class VehicleDocumentComponent {
  @Output() onRemoveVehicleDocument: EventEmitter<any> = new EventEmitter();
  @Output() vehicleDocumentFieldsChanges: EventEmitter<any> = new EventEmitter();
  @Input() group: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() miFormGroup: FormGroup;
  @Input() attributeValues;
  @Input() vehicleDocumentEntityIdList;
  @Input() edit: boolean = false;
  miIcons = AppIcons;
  slots: any = [];
  groupCopy: any = [];
  vehicleDocumentCount = 1000;
  accordions: string[] = ['Vehicle Document 1'];
  showButton = true;
  activeAccordionIndex: number = 0;
  uniqueVehicleDocumentEntityIdList;
  documentTypes: string[] = [];
  documentIds: string[] = [];
  documentCount: any;
  uploadDocumentIds: string[] = [];
  country: Country;
  documentPairs;

  constructor(
    public cs: CommonBindingDataService,
    private vehicleService: EntityService,
    private configService: ConfigService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef, private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    this.uniqueVehicleDocumentEntityIdList = Array.from(new Set(this.vehicleDocumentEntityIdList));
    this.moduleName = AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT;
    this.groupCopy = _.cloneDeep(this.group.fields);
    this.setFields();
    if (this.edit) {
      this.setFieldsForEdit();
    }
  }

  setFieldsForEdit() {
    for (let j = 1; j < this.uniqueVehicleDocumentEntityIdList.length; j++) {
      this.addMoreSlots();
    }
  }

  setFields() {
    const group = _.cloneDeep(this.group)
    for (const field of group.fields) {
      const validators = this.addValidator(field.validation);
      field.attributeCodeOneToMultiple = `${this.vehicleDocumentCount}_${field.attributeCode}`;
      field.isOneToMultiple = true;
      field.fieldName = field.attributeCode;
      const value = this.attributeValues ? this.attributeValues[field?.attributeCodeOneToMultiple] : '';
      field.attributeValue = value;
      this.miFormGroup.registerControl(field.attributeCodeOneToMultiple, new FormControl(value, validators));
    }
    this.slots.push(group.fields);
  }

  getHeader(index) {
    const codeType = `${1000 + index}_document_type`;
    const codeId = `${1000 + index}_document_id`;
    const codeDocument = `${1000 + index}_upload_document`;
    this.documentTypes = this.miFormGroup.controls[codeType].value;
    this.documentIds = this.miFormGroup.controls[codeId].value;
    const control = this.miFormGroup.controls[codeDocument];
    if (control && control.value) {
      control.value.forEach(item => {
        if (!this.uploadDocumentIds.includes(item)) {
          this.uploadDocumentIds.push(item);
        }
      });
      this.documentCount = this.uploadDocumentIds.length;
      this.miFormGroup.controls[codeDocument].setValue(this.uploadDocumentIds);
    } else {
      this.uploadDocumentIds = [];
      this.documentCount = 0;
    }
    if (!this.documentTypes && !this.documentIds) {
      return "Document (empty)";
    }
    return `${this.documentTypes || 'Document'} | ${this.documentIds || '(empty)'}`;
  }

  private addValidator(rules) {
    if (!rules) {
      return [];
    }
    const validators = [];
    (Object.keys(rules).map((rule) => {
      if (rule === "required" && rules.required == true) {
        validators.push(Validators.required);
      }

      if (rule === "min" && rules.min) {
        if (rules[rule] !== null) {
          validators.push(Validators.min(rules[rule] ? rules[rule] : 10))
        }
      }

      if (rule === "max" && rules.max) {
        if (rules[rule] !== null) {
          validators.push(Validators.max(rules[rule] ? rules[rule] : 50));
        }
      }

      if (rule === "pattern" && rules.pattern) {
        if (rules[rule] !== null) {
          const validatorRule = new RegExp([rules[rule]].join(''));
          validators.push(Validators.pattern(rules[rule] ? validatorRule : ''));
        }

      }
      if (rule === "unique" && rules.unique == true) {
        validators.push(Validators.pattern(''));

      }
    }));

    return validators;
  }

  addAccordion() {
    const newAccordion = `Vehicle Document ${this.accordions.length + 1}`;
    this.accordions.push(newAccordion);
    if (this.accordions.length >= 2) {
      this.showButton = false;
    }
  }

  addMoreSlots() {
    this.vehicleDocumentCount += 1;
    const newSlot = _.cloneDeep(this.groupCopy);
    for (const field of newSlot) {
      const validators = this.addValidator(field.validation);
      field.attributeCodeOneToMultiple = `${this.vehicleDocumentCount}_${field.attributeCode}`;
      field.isOneToMultiple = true;
      field.fieldName = field.attributeCode;
      const value = this.attributeValues ? this.attributeValues[field?.attributeCodeOneToMultiple] : '';
      this.miFormGroup.registerControl(field?.attributeCodeOneToMultiple, new FormControl(value, validators));
    }
    this.slots.push(newSlot);
    const controls = this.miFormGroup.controls;
    const fieldsLength = Object.keys(controls).length;
    this.vehicleDocumentFieldsChanges.emit(fieldsLength);
    this.activeAccordionIndex = this.slots.length - 1;
  }

  removeSlot(index, slot) {
    if (this.uniqueVehicleDocumentEntityIdList[index]) {
      this.deleteSlotFromDb(index, this.uniqueVehicleDocumentEntityIdList[index], slot);
    } else {
      this.confirmationService.confirm({
        header: this.cs.getLabel('vehicle.document_remove_header'),
        message: this.cs.getLabel('vehicle.message.document_remove_msg'),
        acceptIcon: null,
        rejectIcon: null,
        acceptLabel: this.cs.getLabel('vehicle.remove'),
        rejectLabel: this.cs.getLabel('vehicle.cancel'),
        rejectButtonStyleClass: 'bg-white text-color',
        acceptButtonStyleClass: 'bg-red-500',
        accept: () => {
          this.slots.splice(index, 1);
          this.cs.removeDocumentByIndex(this.miFormGroup.value, index);
        },
        reject: () => {
        }
      });
    }
  }

  deleteSlotFromDb(index, id, slot) {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT,
      entityIds: [id],
      countryCode: this.country[0].countryCode
    };
    this.confirmationService.confirm({
      header: this.cs.getLabel('vehicle.document_remove_header'),
      message: this.cs.getLabel('vehicle.message.document_remove_msg'),
      acceptIcon: null,
      rejectIcon: null,
      acceptLabel: this.cs.getLabel('vehicle.remove'),
      rejectLabel: this.cs.getLabel('vehicle.cancel'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: () => {
        this.vehicleService.deleteEntityActionStatus(requestBody).subscribe((res: any) => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
          this.slots.splice(index, 1);
          this.uniqueVehicleDocumentEntityIdList.splice(index, 1);
          slot.forEach(element => {
            this.miFormGroup.removeControl(element.attributeCode);
          });
          const removeVehicleDocument = [requestBody.entityIds, index]
          this.onRemoveVehicleDocument.emit(removeVehicleDocument);
          this.cd.detectChanges();
        })
      },
      reject: () => {
      }
    });
  }
}

