import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appOnlyDigits]',
  standalone: true,
})
export class OnlyDigitsDirective {
    constructor(private el: ElementRef) {}

    @HostListener('input', ['$event'])
    onInput(event: Event): void {
        const input = this.el.nativeElement as HTMLInputElement;
        const filteredValue = input.value.replace(/[^0-9]/g, '');
        input.value = filteredValue;
        event.preventDefault();
    }
}
