import { CdkDrag, CdkDragDrop, CdkDropList, CdkDropListGroup, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { JsonPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { AppIcons } from '../../../shared/app.icons';
import { Column } from '../../../vehicles/models/attribute.models';
import { Product } from '../../../shared/models/product';
import { AppSettings } from '../../../shared/app.settings';
import { DatumValues, DriverList } from '../../../driver/models/driverList.models';
import { EntityService } from '../../../shared/services/entity.service';
import { EntityList } from '../../../vehicles/models/entity.models';
import { ConfigService } from 'app/modules/shared/services/config.service';

@Component({
  selector: 'app-selected-people',
  standalone: true,
  imports: [
    DialogModule,
    CdkDropListGroup,
    PanelModule,
    FormsModule,
    InputTextModule,
    CheckboxModule,
    CdkDropList,
    CdkDrag,
    ButtonModule,
    TranslateModule,
    JsonPipe
  ],
  templateUrl: './selected-people.component.html',
  styleUrl: './selected-people.component.scss'
})
export class SelectedPeopleComponent implements OnInit, OnChanges {
  miIcons = AppIcons;
  @Input() selectedColumns = [];
  @Input() selectedPeople = [];
  headerText;
  @Input() isShow: boolean;
  @Input() manageColumnFromLocal: boolean = false;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Output() onManageColumns = new EventEmitter<Column[]>();
  @Output() onChangeSelectedDriver: EventEmitter<any> = new EventEmitter<any>();
  draggedProduct: Column | undefined | null;
  allDriverData: any = [];
  allDriverDataTemp: any = [];
  targetProducts!: Product[];
  driverListCount: Column[];
  listColumns: DatumValues;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_DRIVER_GROUP,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  };

  constructor(private configService: ConfigService, private cdr: ChangeDetectorRef, private driverService: EntityService) {

  }
  ngOnInit() {
    this.searchEntity();
    this.getDriverData();
  }

  ngOnChanges() {
  }

  searchEntity() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.allDriverData = [];
    this.entityData.countryCode = country[0].countryCode;
    this.driverService.searchEntity(AppSettings.ENTITY_CODE.DRIVER, this.entityData).subscribe((res: DriverList) => {
      this.listColumns = res?.data[0]?.values;
      this.allDriverData = res.data?.map(data => {
        const relatedDrivers = data?.relatedData?.map(relatedData => ({
          id: relatedData?.id,
          ...relatedData?.values
        })) || [];
        return { id: data.id, ...data.values, drivers: relatedDrivers };
      }) || [];
      this.driverListCount = this.allDriverData.length;
      this.allDriverDataTemp = this.allDriverData;
      this.allDriverData = this.allDriverData.map(ele => {
        if (this.selectedPeople?.includes(ele.id)) {
          ele.selected = true;
          this.selectedColumns.push(ele);
          return ele;
        }
        return ele;
      })
    });
  }

  getDriverData() {
    this.selectedColumns = this.manageColumnFromLocal ? this.selectedColumns : [];
    this.cdr.markForCheck();
    this.targetProducts = [];
  }

  dragStart(product: Column) {
    this.draggedProduct = product;
  }

  dragEnd() {
    this.draggedProduct = null;
  }

  findIndex(product: Column) {
    let index = -1;
    for (let i = 0; i < (this.driverListCount as Column[]).length; i++) {
      if (product.field === (this.driverListCount as Column[])[i].field) {
        index = i;
        break;
      }
    }
    return index;
  }

  onSearch(event, allDriverData) {
    const value = event.target.value.toLowerCase();
    if (allDriverData) {
      this.allDriverData = this.allDriverDataTemp.filter(ele => {
        const firstName = ele?.first_name ? ele.first_name.toLowerCase() : '';
        const lastName = ele?.last_name ? ele.last_name.toLowerCase() : '';
        return firstName.includes(value) || lastName.includes(value);
      });
    }
  }

  onHide(event) {
    this.isShow = false;
    this.onToggleOverLay.emit(this.isShow);
  }

  drop1(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  filterResults(text) {
    if (!text) {
      return;
    }
    this.driverListCount = this.driverListCount.filter(
      col => col?.header.toLowerCase().includes(text.toLowerCase())
    );
  }

  pushToSelectedColumns(selectedCol) {
    if (selectedCol.selected) {
      this.selectedColumns.push(selectedCol);
    } else {
      this.selectedColumns = this.selectedColumns.filter(function(el) {
        return el.selected === true;
      });
    }
    this.onChangeForSelectedDriver();
  }

  removeFromSelectedColumn(index, removedColumns) {
    const deletedColumn = this.selectedColumns.splice(index, 1);
    this.allDriverData = this.allDriverData.map(ele => {
      if (deletedColumn[0].id === ele.id) {
        ele.selected = false;
        return ele;
      }
      return ele;
    })
    this.onChangeForSelectedDriver();
  }

  onChangeForSelectedDriver() {
    this.onChangeSelectedDriver.emit(this.selectedColumns)
  }

}
