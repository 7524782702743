
import { Component, ElementRef, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SettingsService } from 'app/modules/settings/settings.service';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { ModuleHeaderComponent } from 'app/modules/shared/components/module-header/module-header.component';
import { Countries } from 'app/modules/shared/countries';
import { SearchLocationDirective } from 'app/modules/shared/directives/search-location.directive';
import { Language } from 'app/modules/shared/models/language';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';

interface UploadedFiles {
  file: File,
  fileId: string
}

interface AboutUsInfo {
  companyLocation?: string;
  mobileNumber?: string;
  latitude?: number;
  longitude?: number;
}
@Component({
  selector: 'app-about-company',
  standalone: true,
  imports: [SearchLocationDirective, EditorModule, BreadcrumbModule, ModuleHeaderComponent, TabViewModule, TranslateModule, ButtonModule, ButtonModule, DialogModule, ToastModule, InputTextModule, ReactiveFormsModule, FormsModule, TabViewModule, DropdownModule, FileUploadModule],
  templateUrl: './about-company.component.html',
  styleUrl: './about-company.component.scss'
})
export class AboutCompanyComponent {
  @ViewChild('nameInput', { static: false }) nameInput!: ElementRef;
  selectedPickupMapLocation;
  addBtnLabel = this.cs.getLabel('label_save');
  editLabel: string = this.cs.getLabel('settings.edit');
  userDetails: any;
  userId: number;
  countries: any[] | undefined;
  selectedCountry: any;
  selectedSupportedCountry: any;
  chooseLabel: string = this.cs.getLabel('lbl_select_image');
  accept: string;
  fileUploadInstruction: string;
  chooseIcon: any = null;
  dropAreaMsg: any = '';
  maxFileSize: any = AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE;
  img: any;
  showImg: boolean = false;
  docType: string = 'profile';
  field: any;
  showCheckIcon: boolean = true;
  isMultipleFilesUpload: boolean = true;
  @Output() fileUploadEvent: EventEmitter<any> = new EventEmitter<any>();
  uploadedFiles: UploadedFiles[] = [];
  uploadedFileIds: string[] = [];
  userName: string;
  language: Language;

  map: google.maps.Map;
  infoWindow: google.maps.InfoWindow;
  isRequiredGoogleAddress: boolean = false;
  btnLabel: string;
  routePath: MenuItem[] = [];
  miIcons = AppIcons;
  selectedCountryCode: any;


  aboutUsForm: FormGroup;
  constructor(private cs: CommonBindingDataService,
    private router: Router, private formBuilder: FormBuilder,
    private configService: ConfigService, private settingsService: SettingsService,
    private messageService: MessageService,
    private entityService: EntityService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.setCountryCode();
    this.initializeForm();
    this.setUpRoutePath();
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.formValidation();
    this.initMap();
    this.getCompanyInfo();
  }

  initializeForm() {
    this.aboutUsForm = this.fb.group({
      companyName: ['', Validators.required],
      website: [''],
      addressLine1: [''],
      addressLine2: [''],
      mobileNumber: ['', [Validators.pattern(/^\d+$/)]],
      email: ['', [Validators.required, Validators.email]],
      aboutCompany: ['', Validators.required],
      companyLocation: ['']
    });
  }

  setCountryCode() {
    this.countries = Countries.COUNTRY_CODE;
    const defaultCountry = this.countries[AppSettings.DEFAULT_COUNTRY_CODE];
    if (defaultCountry) {
      this.selectedCountry = defaultCountry;
      this.selectedCountryCode = defaultCountry.value;
    } else {
      this.selectedCountryCode = '';
    }
  }

  selectCountry(event) {
    const selectedCountry = event.value.value;
    if (selectedCountry) {
      this.selectedCountryCode = selectedCountry;
    } else {
      this.selectedCountryCode = '';
    }
  }

  onCancel() {
    this.aboutUsForm.reset();
    this.addBtnLabel = this.cs.getLabel('content.lbl_save');
  }

  getFormControl(name: string) {
    return this.aboutUsForm.get(name);
  }

  setUpRoutePath() {
    this.routePath = [
      { label: this.cs.getLabel('content.lbl_about_us'), routerLink: 'content/about-company' },
    ];
  }

  onAddAboutUs() {
    this.router.navigate(['app/organization/add']);
  }

  formValidation() {
    this.aboutUsForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      website: ['', Validators.required],
      addressLine1: [''],
      addressLine2: [''],
      email: ['', [Validators.required, Validators.pattern(AppSettings.EMAIL_PATTERN)]],
      mobileNumber: ['', [Validators.pattern(AppSettings.PHONE_PATTERN), Validators.maxLength(AppSettings.MAX_MOBILE_LENGTH), Validators.minLength(AppSettings.MIN_MOBILE_LENGTH)]],
      aboutCompany: [''],
      companyLocation: ['']
    });
  }

  getCompanyInfo() {
    this.settingsService.getCompanyInfo().subscribe((res: AboutUsInfo) => {
      this.aboutUsForm.patchValue(res);
      if (res.mobileNumber) {
        const contactNumberParts = res.mobileNumber.split('-');
        if (contactNumberParts.length > 1) {
          const countryCodeFromNumber = contactNumberParts[0];
          const number = contactNumberParts.slice(1).join('-');

          const selectedCountry = this.countries.find(c => c.value === countryCodeFromNumber);
          this.selectedCountryCode = selectedCountry;
          if (selectedCountry) {
            this.selectedCountry = selectedCountry;
            this.selectedCountryCode = selectedCountry.value;
          } else {
            this.selectedCountryCode = '';
          }
          this.aboutUsForm.get('mobileNumber').setValue(number);
        }
      }
      if (res.latitude !== undefined && res.longitude !== undefined) {
        this.selectedPickupMapLocation = {
          address: res.companyLocation || '',
          lat: res.latitude,
          lng: res.longitude
        };
        this.getAndStoreAddress(this.selectedPickupMapLocation);
      } else {
        this.selectedPickupMapLocation = null;
      }
      this.addBtnLabel = this.cs.getLabel('content.lbl_update');
    })
  }

  showCloseImageIcon() {
    this.showCheckIcon = false;
  }

  onAddUserProfile() {
    if (this.aboutUsForm.valid) {
      const companyInfoData = { ...this.aboutUsForm.value };
      companyInfoData["forTenantCode"] = this.configService.getForTenantCode();
      companyInfoData["companyLocation"] = this.selectedPickupMapLocation?.address ? this.selectedPickupMapLocation?.address : '';
      companyInfoData["latitude"] = this.selectedPickupMapLocation?.lat ? this.selectedPickupMapLocation?.lat : 0;
      companyInfoData["longitude"] = this.selectedPickupMapLocation?.lng ? this.selectedPickupMapLocation?.lng : 0;
      if (companyInfoData.mobileNumber.trim() === '') {
        companyInfoData["mobileNumber"] = '';
      } else if (this.selectedCountryCode) {
        companyInfoData["mobileNumber"] = `${this.selectedCountryCode}-${companyInfoData.mobileNumber}`;
      }
      const companyFormData = companyInfoData;

      this.settingsService.updateCompanyInfo(companyFormData).subscribe(res => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.about_us_information_update_msg') });
      }, (error) => {
        this.showErrorMessage('lbl_invalid_data');
      });
    } else {
      this.aboutUsForm.markAllAsTouched();
      this.showErrorMessage('settings.lbl_please_fill_the_form');
    }
  }

  private showSuccessMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Successful',
      detail: this.cs.getLabel(labelKey),
    });
  }

  private showErrorMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Error',
      detail: this.cs.getLabel(labelKey)
    });
  }

  getAndStoreAddress(event: any, addressType?: any) {
    this.selectedPickupMapLocation = event;
    const markerlocation = { lat: this.selectedPickupMapLocation.lat, lng: this.selectedPickupMapLocation.lng };
    this.setMarker(markerlocation);
    setTimeout(() => {
      this.map.setZoom(15);
    }, 500);
  }


  async setMarker(markerlocation): Promise<void> {
    this.isRequiredGoogleAddress = false;
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: markerlocation,
      title: 'Your Location'
    });

    this.map.setCenter(markerlocation);
    this.map.setZoom(17);
  }

  async initMap(): Promise<void> {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by this browser.');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        const userPosition = { lat: latitude, lng: longitude };

        const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;


        this.map = new Map(
          document.getElementById('map') as HTMLElement,
          {
            zoom: 12,
            center: userPosition,
            mapId: 'DEMO_MAP_ID',
          }
        );




        const marker = new AdvancedMarkerElement({
          map: this.map,
          position: userPosition,
          title: 'Your Location'
        });

        // const defaultBounds = {
        //   north: userPosition.lat + 0.1,
        //   south: userPosition.lat - 0.1,
        //   east: userPosition.lng + 0.1,
        //   west: userPosition.lng - 0.1,
        // };
        // const input = document.getElementById("search-input") as HTMLInputElement;
        // const options = {
        //   bounds: defaultBounds,
        //   componentRestrictions: { country: AppSettings.MAP_COUNTRY_COMPONENT_RESTRICTIONS },
        //   fields: ["address_components", "geometry", "name"],
        //   strictBounds: false,
        // };

        // const autocomplete = new google.maps.places.Autocomplete(input, options);


        // autocomplete.addListener('place_changed', () => {
        //   const place = autocomplete.getPlace();
        //   if (place.geometry.viewport) {
        //     this.map.fitBounds(place.geometry.viewport);
        //   } else {
        //     this.map.setCenter(place.geometry.location);
        //     this.map.setZoom(17);
        //   }
        //   marker.position = place.geometry.location;
        // });


      },
      (error) => {
        console.error('Error getting the user’s location:', error);
      }
    );
  }

}
