import { CommonModule, JsonPipe, NgClass } from '@angular/common';
import { Component, computed, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { CommonBindingDataService } from '../../../../services/common-binding-data.service';
import * as _ from 'lodash';
import { FilterService } from '../../../advance-filter-form/filter.service';


@Component({
  selector: 'app-mi-multi-select-filter',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, InputTextModule, JsonPipe, CheckboxModule, DividerModule, NgClass, CommonModule],
  templateUrl: './mi-multi-select-filter.component.html',
  styleUrl: './mi-multi-select-filter.component.scss'
})
export class MiMultiSelectFilterComponent {
  checked = [];
  selectedCategories;
  @Input() attributeValues;
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  searchValue: string = '';
  selectedControl: string[] = [];
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  inputPlaceholder: string;
  filteredValues: any[] = [];
  entityName = computed(() => this.filterService.currentEntityName);

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private filterService: FilterService) {
    this.formName = this.formGroupDirective.control;
  }


  ngOnInit() {
    this.filteredValues = this.field.presetValues;
    this.selectedControl = this.attributeValues;


    // const prevData = this.filterService.getFilterState(this.entityName());

    // console.log( prevData?.apiData, this.selectedControl)
    // this.filterService.setFilterState(this.entityName(), {
    //   apiData: prevData?.apiData || {},
    //   userData: [{ [this.field.attributeCode]: this.selectedControl }],
    //   selectedFilterCount: prevData?.selectedFilterCount || 0 
    // });
    // this.selectedControl = prevData?.apiData[this.field.attributeCode];



    if (this.field.presetValues?.length === this.selectedControl?.length) {
      this.checked = ['val1'];
    }
    this.inputPlaceholder = this.cs.getLabel('placeholder_search_by') + ' ' + this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.label', this.attributeLabels, this.field.attributeCode);
  }

  clearSearch() {
    this.searchValue = "";
    this.filterPresetValues();
    this.onSearchValueChange.emit('');
  }

  onSearch(event) {
    const value = event.target.value;
    this.filterPresetValues();
    if (event.target.value.length >= 3) {
      this.onSearchValueChange.emit(value);
    } else if (event.target.value.length === 0) {
      this.onSearchValueChange.emit(value);
    }
  }




  onChange(event) {
    if (event.checked.length > 0) {
      const values = [];
      this.field?.presetValues?.map(value => {
        values.push(value.labelValue);
      });
      this.selectedControl = values;
      this.formName.controls[this.field.attributeCode].setValue(values);
      this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    } else {
      this.selectedControl = [];
      this.formName.controls[this.field.attributeCode].setValue([]);
      this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    }
    // this.setData();

  }

  valueChange() {
    this.formName.controls[this.field.attributeCode].setValue(this.selectedControl);
    this.formName.controls[this.field.attributeCode].updateValueAndValidity();
  }

  filterPresetValues() {
    const searchLower = this.searchValue.toLowerCase();
    this.filteredValues = this.field.presetValues.filter(item =>
      item.labelKey.toLowerCase().includes(searchLower) ||
      item.labelValue.toLowerCase().includes(searchLower)
    );
  }

  setData() {
    const prevData = this.filterService.getFilterState(this.entityName());
    this.filterService.setFilterState(this.entityName(), {
      apiData: prevData?.apiData || {},
      userData: [{ [this.field.attributeCode]: this.selectedControl }],
      selectedFilterCount: prevData?.selectedFilterCount || 0
    });
  }

}
