import { NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DriverListCardComponent } from 'app/modules/dispatch/components/assign-driver/driver-list-card/driver-list-card.component';
import { DriverSidebarComponent } from 'app/modules/dispatch/components/driver-sidebar/driver-sidebar.component';
import { AppIcons } from 'app/modules/shared/app.icons';
import { DateTimeComponent } from 'app/modules/shared/components/mi-fields/date-time/date-time.component';
import { MiFieldsComponent } from 'app/modules/shared/components/mi-fields/mi-fields.component';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ManualDispatchDetailsComponent } from '../manual-dispatch-details/manual-dispatch-details.component';
import { ManualPassengerVehicleDetailsComponent } from '../manual-passenger-vehicle-details/manual-passenger-vehicle-details.component';
import { BookingService } from '../../services/booking.service';

@Component({
  selector: 'app-manual-dispatch',
  standalone: true,
  imports: [TranslateModule, ButtonModule,DriverSidebarComponent,InputSwitchModule,FormsModule, ReactiveFormsModule,NgClass, MiFieldsComponent,DateTimeComponent,DriverListCardComponent,ManualDispatchDetailsComponent,ManualPassengerVehicleDetailsComponent],  
  templateUrl: './manual-dispatch.component.html',
  styleUrl: './manual-dispatch.component.scss'
})
export class ManualDispatchComponent {
  @Input() assignConfiguration  = {additionalVehicleCount: 0, luggageVehicleCount: 0, isBookNow: false};
  @Input() miFormGroup: any;
  @Output() additionalRequired: EventEmitter<any> = new EventEmitter();
  rightDialogPosition: string = "center";
  miIcons = AppIcons;
  position: string;
  rightDriversSectionVisible: boolean = false;
  bookingId:string;
  autoToManual: boolean = false; 
  driverDataVisible: boolean = false; 
  currentDriverDetail: any;
  driverData;
  selectedPassengerDriverData: any;
  selectedAdditionalDriverData: any[] = [];
  selectedLuggageDriverData: any[] = [];
  currentType: string;
  isAssignedPassenger: boolean = false;
  isAssignedAdditional: boolean[] = [];
  isAssignedLuggage: boolean[] = [];
  formValueChanges: boolean = true;
  selectedAdditionalIndex: any;
  passengerVehicle: any[] = [];

  assignDriver: any[] = [];
  constructor(private cd: ChangeDetectorRef, private bookingService: BookingService, private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.miFormGroup.valueChanges.subscribe(value => {
      this.formValueChanges = false;
      this.cd.detectChanges();
      this.formValueChanges = true;
    });

    this.bookingService.driverDetail$.subscribe(driverDetail => {
      this.driverData = driverDetail;
      this.cd.detectChanges();

      if (this.currentType === 'passenger') {
        this.assignDriverData(this.currentType, 0);
      }

      for (let i = 0; i < this.assignConfiguration.additionalVehicleCount; i++) {
        if (this.selectedAdditionalIndex === i) {
          this.assignDriverData(this.currentType, i);
        }
      }
      
      for (let i = 0; i < this.assignConfiguration.luggageVehicleCount; i++) {
        if (this.selectedAdditionalIndex === i) {
          this.assignDriverData(this.currentType, i);
        }
      }
      this.cd.detectChanges();
    });
  }

  onSwitchChange(event) {
    this.formValueChanges = false;
    this.cd.detectChanges();
    this.formValueChanges = true;
  }

  showRightDriversDialog(position: string, vehicleType: string, index: number) {
    this.selectedAdditionalIndex = index;
    this.currentType = vehicleType
    this.rightDialogPosition = position;
    this.position = position;
    this.rightDriversSectionVisible = false;
    this.cd.detectChanges();
    this.rightDriversSectionVisible = true;
  }

  assignDriverData(vehicleType: string, index: number) {
    switch (vehicleType) {
      case 'passenger':
        if (!this.isAssignedPassenger) {
          this.selectedPassengerDriverData = this.driverData;
          this.driverData.type = 'passenger';
          this.assignDriver.push({...this.driverData, index: 0});
          this.isAssignedPassenger = true;
          this.rightDriversSectionVisible = false;
        }
        break;
        
      case 'additional':
        if (!this.isAssignedAdditional[index]) {
          this.selectedAdditionalDriverData[index] = this.driverData;
          this.driverData.type = 'additional';
          this.assignDriver.push({...this.driverData, index});
          this.isAssignedAdditional[index] = true;
          this.rightDriversSectionVisible = false;
        }
        break;
        
      case 'luggage':
        if (!this.isAssignedLuggage[index]) {
          this.selectedLuggageDriverData[index] = this.driverData;
          this.driverData.type = 'luggage';
          this.assignDriver.push({...this.driverData, index});
          this.isAssignedLuggage[index] = true;
          this.rightDriversSectionVisible = false;
        }
        break;

      default:
        break;
    }

    this.additionalRequired.emit(this.assignDriver);
    this.cd.detectChanges();
  }
  
  cancelAssignment(vehicleType: string, index: number) {
    switch (vehicleType) {
      case 'passenger':
        this.isAssignedPassenger = false;
        this.selectedPassengerDriverData = null;
        this.assignDriver = this.assignDriver.filter(driver => driver.type !== 'passenger');
        break;
  
      case 'additional':
        this.isAssignedAdditional[index] = false;
        this.selectedAdditionalDriverData[index] = null;
        this.assignDriver = this.assignDriver.filter(driver => {
          return !(driver.type === 'additional' && driver.index === index);
        });
        break;
  
      case 'luggage':
        this.isAssignedLuggage[index] = false;
        this.selectedLuggageDriverData[index] = null;
        this.assignDriver = this.assignDriver.filter(driver => {
          return !(driver.type === 'luggage' && driver.index === index);
        });
        break;
      default:
        break;
    }
  
    this.additionalRequired.emit(this.assignDriver);
    this.cd.detectChanges();
  }

  showDriverData() {
    this.driverDataVisible = true;
    this.cd.detectChanges();
  }

  hideDriverData() {
    this.driverDataVisible = false;
    this.cd.detectChanges();
  }

}
