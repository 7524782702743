import { Component } from '@angular/core';
import { TableModule } from 'primeng/table';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-table-skelton',
  standalone: true,
  imports: [SkeletonModule, TableModule],
  templateUrl: './table-skelton.component.html',
  styleUrl: './table-skelton.component.scss'
})
export class TableSkeltonComponent {

}
