import { NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AppIcons } from 'app/modules/shared/app.icons';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { BookingPassengerSavedAddressComponent } from './booking-passenger-saved-address/booking-passenger-saved-address.component';
import { BookingPassengerLocationComponent } from './booking-passenger-location/booking-passenger-location.component';

@Component({
  selector: 'app-booking-passenger-address',
  standalone: true,
  imports: [SidebarModule, ButtonModule, TranslateModule, TabViewModule, TableModule, InputTextModule, NgClass, ButtonModule, BookingPassengerSavedAddressComponent, BookingPassengerLocationComponent],
  templateUrl: './booking-passenger-address.component.html',
  styleUrl: './booking-passenger-address.component.scss'
})
export class BookingPassengerAddressComponent {
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Input() passengerAddressVisible: boolean = false;
  @Input() passengerId;
  miIcons = AppIcons;
  activeIndex: number = 0;
  searchValue: string = '';


  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  closeAddressDialog() {
    this.passengerAddressVisible = false;
    this.cd.detectChanges();
    this.onToggleOverLay.emit(this.passengerAddressVisible);
  }
}
