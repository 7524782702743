import { Injectable, signal } from '@angular/core';
import { AppSettings } from '../../shared/app.settings';
import { ConfigService } from '../../shared/services/config.service';
import { RestApiService } from '../../shared/services/rest-api.service';
import type { AssignVehicleToBookingPayload } from '../models/booking.models';
import { BehaviorSubject } from 'rxjs';
import { EventService } from 'app/modules/events/services/event.service';

export interface Passenger {
  id: number;
  name: string;
  passenger_profile_image?: string;
  unique_id: string;
  passenger_type: string;
}

export const FLAG = {
  CHILD: 'C',
  WHEELCHAIR: 'W',
  BOTH: 'C|W'
};

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  private selectedPassengerSubject = new BehaviorSubject<Passenger[]>([]);
  selectedPassenger$ = this.selectedPassengerSubject.asObservable();
  private requestedPassengerSubject = new BehaviorSubject<Passenger[]>([]);
  requestedPassenger$ = this.requestedPassengerSubject.asObservable();
  private driverDetailSignal = signal<any>(null); 
  private driverDetailSubject = new BehaviorSubject<any>(null);

  constructor(private restApiService: RestApiService, private confService: ConfigService,
    private eventService: EventService
  ) { }

  getBookingStatus() {
    return this.restApiService.get(' booking status', `${this.confService.getLoggedInTenantCode()}/api/v1/booking-statuses?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');

  }

  updateStatus(data) {
    this.setEventCode(data);
    return this.restApiService.post('Update Booking Status', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/custom/update-booking-status`, data, 'page-center');

  }

  assignVehicleToBooking(data: AssignVehicleToBookingPayload) {
    return this.restApiService.post('Assign Driver to booking', `${this.confService.getForTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/custom/booking-assign-driver`, data, 'page-center');
  }

  releaseDriverFromBooking(data) {
    this.setEventCode(data);
    return this.restApiService.post('RELEASE DRIVER FROM BOOKING', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/custom/booking-release-driver`, data, 'page-center');
  }

  setSelectedPassenger(passenger: Passenger) {
    const currentPassengers = this.selectedPassengerSubject.value;
    this.selectedPassengerSubject.next([...currentPassengers, passenger]);
  }

  setRequestedPassenger(passenger: Passenger) {
    const currentRequestedPassengers = this.requestedPassengerSubject.value;
    this.requestedPassengerSubject.next([...currentRequestedPassengers, passenger]);
  } 

  removePassenger(passengerId: number, type: 'selected' | 'requested') {
    if (type === 'selected') {
        const updatedPassengers = this.selectedPassengerSubject.value.filter(passenger => passenger.id !== passengerId);
        this.selectedPassengerSubject.next(updatedPassengers);
    } else if (type === 'requested') {
        const updatedRequestedPassengers = this.requestedPassengerSubject.value.filter(passenger => passenger.id !== passengerId);
        this.requestedPassengerSubject.next(updatedRequestedPassengers);
    }
  }

  clearPassengers() {
    this.selectedPassengerSubject.next([]);
    this.requestedPassengerSubject.next([]);
  }

  
  setDriverDetail(driverDetail: any) {
    console.log('Setting driver detail in signal:', driverDetail);
    this.driverDetailSignal.set(driverDetail);
    this.driverDetailSubject.next(driverDetail);
  }
  
  // Get the driver detail from the Signal
  getDriverDetail() {
    return this.driverDetailSignal();
  }

  get driverDetail$() {
    return this.driverDetailSubject.asObservable();
  }
  private setEventCode(data) {
    data.eventCode = this.eventService.getSelectedEventcode();
    return data;
   }

  getWheelchairFlag(driverDetail: any): string {
    if (driverDetail.wheelChair && driverDetail.child) {
      return FLAG.BOTH;
    } else if (driverDetail.wheelChair) {
      return FLAG.WHEELCHAIR;
    } else if (driverDetail.child) {
      return FLAG.CHILD;
    }
    return '';
  }

}
