<div>

    <input
        class="{{miIcons.INFO_CIRCLE_OUTLINE}} inline-block wh-18"
        type="button"
        [pTooltip]="messageData"
        tooltipEvent="focus"
        placeholder="auto"/>
    
</div>
