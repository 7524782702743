import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { forkJoin } from 'rxjs';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { ModuleHeaderComponent } from '../../../shared/components/module-header/module-header.component';
import type { Country } from '../../../shared/models/country';
import type { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import type { Column } from '../../../vehicles/models/attribute.models';
import type { EntityList } from '../../../vehicles/models/entity.models';
import type { Values } from '../../../vehicles/models/listResponse.models';
import { UserManagementService } from '../../services/user-management.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { PermissionAccessService } from 'app/modules/shared/services/permission-access.service';
@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrl: "./manage-user.component.scss",
  standalone: true,
  imports: [TabViewModule, TranslateModule, MITableComponent, BreadcrumbModule, ModuleHeaderComponent, AccessProviderDirective]
})
export class ManageUserComponent {
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];
  @Input() moduleName: string;
  value: number = 50;
  btnLabel: string;
  items: MenuItem[] | undefined;
  isShowMenu: boolean = true;
  attributeLabels = {};
  attributeLabelsData: any = {};
  filterAttributeLabels: any;
  labelData = {
    attributeLabels: [
      { labelKey: 'manage_users_grid.fields.Status.label', labelValue: 'Status' },
      { labelKey: 'manage_users_grid.fields.UserRole.label', labelValue: 'User Role' }
    ]
  };
  listColumns: Values;
  allColumns: any;
  pagination: any;
  totalRecords: number = 0;
  tableData: any = [];
  buttons: any[] = [];
  country: Country;
  language: Language;
  entityData: any = {
    actionStatus: "",
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: "",
    defaultSortColumn: "updatedAt",
    defaultSortType: "desc",
    status: 'both',

  };
  routePath: MenuItem[] = [];
  home: MenuItem = {};
  isModal: boolean = false;
  selectedColumns!: Column[];
  attributeData: any;
  data;
  rowItems: MenuItem[] | undefined;
  bulkSelectionsItems: MenuItem[] | undefined;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  columnsData;
  bulkDeleteLabel: string = this.cs.getLabel('manage_users.delete');
  bulkUpdateLabel: string = this.cs.getLabel('manage_users.lbl_update_user');
  bulkUpdateHeader: any;
  reviewHeader: any;
  entityNameToDisplay: any;
  btnUpdate: any;
  deleteRoleHeaderMsg: string;
  deleteRoleMsg: string;
  showBulkUpdateDialog: boolean = false;
  visible: boolean = false;
  filterFieldsArray: any[];
  uniqueId: string = 'manage_users_grid';
  isEntity: boolean = true;
  entityName;
  currentRow;
  filterList;
  entityDataForRole: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: '',
    actionStatus: '',
    status: 'both',
  };
  miIcons = AppIcons;

  constructor(
    private router: Router,
    private userManagementService: UserManagementService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private configService: ConfigService,
    private cd: ChangeDetectorRef,
    private permissionAccessService: PermissionAccessService,
  ) { }

  ngOnInit() {
    this.setUpRoutePath();
    this.setUpRowItems();
    this.setupBulkSelectionsItems();
    this.initializeOtherVariablesAndAPI();
    this.setupModuleHeaderSecondaryBtnMenuItems();
    this.initializeColumn();
  }

  initializeColumn() {
    this.allColumns = [
      {
        "attributeCode": "Name",
        "attributeIndex": 0,
        "field": "fullName",
        "header": this.cs.getLabel('manage_users.columns.lbl_name')
      },
      {
        "attributeCode": "email",
        "attributeIndex": 1,
        "field": "email",
        "header": this.cs.getLabel('manage_users.columns.lbl_user_email')
      },
      {
        "attributeCode": "userRole",
        "attributeIndex": 2,
        "field": "userRole",
        "header": this.cs.getLabel('manage_users.columns.lbl_user_role')
      },
      {
        "attributeCode": "username",
        "attributeIndex": 3,
        "field": "username",
        "header": this.cs.getLabel('manage_users.columns.lbl_user_name')
      },
      {
        "attributeCode": "contactNumber",
        "attributeIndex": 4,
        "field": "contactNumber",
        "header": this.cs.getLabel('manage_users.columns.lbl_phone_number')
      },
      {
        "attributeCode": "user_status",
        "attributeIndex": 5,
        "field": "active",
        "header": this.cs.getLabel('manage_users.columns.lbl_user_status')
      },
    ];
  }

  initializeOtherVariablesAndAPI() {
    this.btnLabel = this.cs.getLabel('manage_users.lbl_create_new');
    this.bulkDeleteLabel = this.cs.getLabel('manage_users.message.delete_user');
    this.bulkUpdateLabel = this.cs.getLabel('manage_users.lbl_update_user');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.bulkUpdateHeader = this.cs.getLabel('manage_users.lbl_bulk_update_header');
    this.entityNameToDisplay = this.cs.getLabel('manage_users.lbl_entity_name_display');
    this.btnUpdate = this.cs.getLabel('manage_users.btn_bulk_update');
    this.reviewHeader = this.cs.getLabel('manage_users.lbl_review_users');
    this.deleteRoleMsg = this.cs.getLabel('manage_users.message.confirm_delete_msg');
    this.deleteRoleHeaderMsg = this.cs.getLabel('manage_users.message.delete_user');
    this.getBulkUpdateAttributeData();
    this.getData();
    this.getUserRoles();
  }

  private setUpRoutePath() {
    this.routePath = [
      { label: this.cs.getLabel('manage_users.lbl_route_path') },
    ];
  }

  private setUpRowItems() {
    const isEdit = this.permissionAccessService.hasAccess('USER_MANAGEMENT', 'MANAGE_USERS', 'DETAILS', 'EDIT');
    const isUpdateStatus = this.permissionAccessService.hasAccess('USER_MANAGEMENT', 'MANAGE_USERS', 'DETAILS', 'UPDATE_STATUS');
    this.rowItems = [
      ...(isEdit ? [{
        label: this.cs.getLabel('manage_users.edit'),
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.update();
        }
      }] : []),
      {
        label: this.cs.getLabel('manage_users.reInvite'),
        icon: AppIcons.MAIL + " wh-122",
        iconClass: 'bulk-update-icon',
        command: () => {

        }
      },
      ...(isUpdateStatus ? [{
        label: this.cs.getLabel('manage_users.lbl_make_user_inactive'),
        icon: AppIcons.OVERDUE + " wh-16 bg-red-500",
        iconClass: "bulk-update-icon",
        visible: true,
        command: () => {
          this.makeUserActiveInactive(this.currentRow);
        },
      }] : []),
    ];
  }

  private setupBulkSelectionsItems() {
    const isBulkAction = this.permissionAccessService.hasAccess('USER_MANAGEMENT', 'MANAGE_USERS', 'DETAILS', 'BULK_ACTION');
    this.bulkSelectionsItems = [
      ...(isBulkAction ? [{
        label: this.bulkUpdateLabel,
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: () => {
          this.visible = true;
          this.tableRef.showBulkRowDialog();
        },
      }] : []),
      ...(isBulkAction ? [{
        label: this.bulkDeleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: () => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.BULK);
        },
      }] : []),
    ];
  }

  private setupModuleHeaderSecondaryBtnMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.cs.getLabel('manage_users.import_user_template'),
        icon: AppIcons.UPLOAD + " wh-16",
        command: () => {

        }
      },
      {
        label: this.cs.getLabel('vehicle.download_important_template'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {

        }
      },
      {
        label: this.cs.getLabel('vehicle.download_important_guide'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {

        }
      },
      {
        separator: true
      },
      {
        label: this.cs.getLabel('vehicle.export_xlsx_csv'),
        icon: AppIcons.EXPORT + " wh-16",
      },
    ];
  }

  getUserRoles() {
    this.entityDataForRole.countryCode = this.country[0].countryCode;
    this.userManagementService.searchEntity(this.entityDataForRole).subscribe((res: any) => {
      const roleNames = res?.data.map(data => data.roleName) || [];
      const userRoleFilter = this.filterList.find(filter => filter.attributeCode === 'UserRole');
      if (userRoleFilter) {
        userRoleFilter.presetValues = roleNames.map(roleName => ({
          labelKey: roleName,
          labelValue: roleName,
        }));
      }
    });
  }

  getFilterView() {
    this.filterAttributeLabels = this.cs.getAttributeLabels(this.labelData)
    this.filterList = [
      {
        "attributeId": 6,
        "attributeCode": "Status",
        "attributeIndex": 5,
        "presetValues": [
          {
            "labelKey": "Active",
            "labelValue": "Active",
          },
          {
            "labelKey": "Inactive",
            "labelValue": "Inactive",
          }
        ],
      },
      {
        "attributeId": 3,
        "attributeCode": "UserRole",
        "attributeIndex": 2,
        "presetValues": [],
      },
    ];
  }

  getBulkUpdateAttributeData() {
    this.attributeData = {
      tabs: [
        {
          groups: [
            {
              fields: [
                {
                  attributeCode: 'user_status',
                  attributeValue: 'Status',
                  isDisable: false,
                  presetValues: [
                    { labelValue: 'Active', labelKey: 'Active' },
                    { labelValue: 'Inactive', labelKey: 'Inactive' },
                  ]
                }
              ]
            }
          ]
        }
      ]
    };
    this.updateFilterFieldsArray(this.attributeData.tabs[0]?.groups[0]?.fields);
  }

  private updateFilterFieldsArray(fields: any[]) {
    if (!fields) return;

    this.filterFieldsArray = [];
    fields.forEach(field => {
      if (field.attributeCode === 'user_status') {
        this.filterFieldsArray.push(field)
      }
    });
  }

  getTableView() {
    this.isLoading = false;
    this.searchEntity();

    this.filterAvailableColumns = _.clone(this.allColumns);
    this.isEntity = false;
  }

  getData() {
    return forkJoin({
      tableViewData: this.getTableView(),
      filterViewDate: this.getFilterView(),
    })
  }

  searchEntity() {
    this.tableData = [];
    this.entityData.countryCode = this.country[0].countryCode;
    this.userManagementService.searchEntityUser(this.entityData).subscribe((res: any) => {
      this.listColumns = res?.data[0];
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;

      this.tableData = res?.data.map(data => {
        const fullName = `${data.firstName} ${data.lastName}`;
        const status = data.active ? AppSettings.USERS_STATUS.ACTIVE : AppSettings.USERS_STATUS.INACTIVE;
        return {
          id: data.userId,
          ...data,
          fullName: fullName,
          active: status,
        };
      }) || [];
    });
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  onFilterValueChange(event) {
    this.entityData.status = event.Status.toString() ? event.Status.toString() : 'both';

    if (event.UserRole !== undefined && event.UserRole.length > 0) {
      this.entityData.roleName = event.UserRole.map(role => `${role}`);
    } else {
      delete this.entityData.roleName;
    }

    this.entityData.offset = event.first;
    this.searchEntity();
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }

  update() {
    this.onUpdateUser(this.currentRow.userId);
  }

  onUpdateUser(userId) {
    this.router.navigate(['app/user-management/manage-users/edit-user/' + userId]);
  }

  makeUserActiveInactive(currentRow) {
    const isActive = currentRow.active === AppSettings.USERS_STATUS.ACTIVE;
    const headerMsg = isActive ? this.cs.getLabel('manage_users.inactive_user_header_message') : this.cs.getLabel('manage_users.active_user_header_message');
    const bodyMsg = isActive ? this.cs.getLabel('manage_users.inactive_status_message') : this.cs.getLabel('manage_users.active_status_message')
    const acceptLabel = isActive ? this.cs.getLabel('manage_users.lbl_deactivate') : this.cs.getLabel('manage_users.lbl_activate');
    this.confirmationService.confirm({
      header: headerMsg,
      message: bodyMsg,
      acceptLabel: acceptLabel,
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectLabel: this.cs.getLabel('manage_users.cancel'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: isActive ? 'bg-red-500' : 'bg-green-500',
      accept: () => {
        this.tableRef.changeStatusOfEntity();
      },
      reject: () => {
      }
    });

  }

  onChangeStatus(currentRow) {
    const newStatus = currentRow.active === AppSettings.USERS_STATUS.ACTIVE ? false : true;
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      active: newStatus
    };
    this.userManagementService.updateUserActionStatus(currentRow.userId, requestBody).subscribe(() => {
      setTimeout(() => {
        this.searchEntity();
      }, 500);
      if (newStatus) {
        this.showSuccessMessage('manage_users.message.msg_user_active');
      } else {
        this.showSuccessMessage('manage_users.message.msg_user_inactive');
      }
    }, error => {
      const errorMsg = error?.errors?.general?.[0]?.message ?? "An unknown error occurred.";
      this.showErrorMessage(errorMsg);
    });
  }

  private showSuccessMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Successful',
      detail: this.cs.getLabel(labelKey),
    });
  }

  private showErrorMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Error',
      detail: this.cs.getLabel(labelKey)
    });
  }

  setCurrentRowData(event) {
    this.currentRow = event;
    if (event.active === AppSettings.USERS_STATUS.INACTIVE) {
      this.rowItems[2].label = this.cs.getLabel('manage_users.lbl_make_user_active'),
        this.rowItems[2].icon = AppIcons.OVERDUE + " wh-16 bg-green-500",
        this.cd.detectChanges();
    } else {
      this.rowItems[2].label = this.cs.getLabel('manage_users.lbl_make_user_inactive'),
        this.rowItems[2].icon = AppIcons.OVERDUE + " wh-16 bg-red-500",
        this.cd.detectChanges();
    }
    this.setEditOptionDisabledForDeletedRecord(event);
  }

  setEditOptionDisabledForDeletedRecord(data) {
    this.rowItems[0].disabled = data.deleted;
    this.cd.detectChanges();
  }

  onStaticBulkDataUpdate(event: any) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      active: event.data[0].attributeValue === AppSettings.USERS_STATUS.ACTIVE,
      userIds: event.entityIds
    };
    this.userManagementService.updateBulkUserActionStatus(requestBody).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: res.message });
        this.searchEntity();
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].messageCode) });
      }
    })
  }

  onBulkDataDeleteEvent(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      userIds: event
    };
    this.userManagementService.deleteBulkUserActionStatus(requestBody).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: res.message });
        this.searchEntity();
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].messageCode) });
      }
    })
  }

  onAddUser() {
    this.router.navigate(['app/user-management/manage-users/add-user']);
  }

  onRowSelect(event) {
    if (event.type === 'row') {
      this.router.navigate(['app/user-management/manage-users/' + event.data.id,]);
    }
  }
}
