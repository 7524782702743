import { DatePipe, JsonPipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ManageZonesService } from 'app/modules/manage-zones/services/manage-zones.service';
import { AppSettings } from 'app/modules/shared/app.settings';
import type { Country } from 'app/modules/shared/models/country';
import { ConfigService } from 'app/modules/shared/services/config.service';
import * as _ from 'lodash';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { CommonBindingDataService } from '../../../../services/common-binding-data.service';

@Component({
  selector: 'app-mi-geo-fence-auto-complete',
  standalone: true,
  imports: [DatePipe, AutoCompleteModule, FormsModule, ReactiveFormsModule, TranslateModule, InputTextModule, JsonPipe, CheckboxModule, DividerModule, NgClass, ButtonModule],
  templateUrl: './mi-geo-fence-auto-complete.component.html',
  styleUrl: './mi-geo-fence-auto-complete.component.scss'
})
export class MiGeoFenceAutoCompleteComponent {

  checked = [];
  @Input() attributeValues;
  @Input() field: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  formName: FormGroup;
  searchValue: string = '';
  selectedControl: string[] = [];
  inputPlaceholder: string;
  filteredValues: any[] = [];
  suggestions: any[] | undefined;
  selectedItems: any = [];
  visibleItems: any = [];
  items: any[] | undefined;
  country: Country;
  ids = [];
  searchQuery: any;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private manageZoneService: ManageZonesService,
    private configService: ConfigService) {
    this.formName = this.formGroupDirective.control;
  }

  search(event: AutoCompleteCompleteEvent) {
    this.searchQuery = event.query;
    const viewCode = 'AUTOCOMPLETE_VIEW';
    this.fetchFromAPI(viewCode, event.query);
  }

  onSearchClear() {
    this.searchQuery = '';
    this.selectedItems = [];
  }

  onSelectItem(event: any) {
    const items = this.selectedItems.filter(item => typeof item === 'string');
    items.push(event.value);
    this.selectedItems = _.uniq(items);
    this.visibleItems.push(...this.selectedItems);
    this.visibleItems = _.uniq(this.visibleItems);
    console.log(this.visibleItems)
    // this.ids.push(this.selectedItems[0].zoneId)
    if (this.selectedItems.length > 0) {
      const selectedItem = this.selectedItems[0];
      let combinedString = selectedItem.zoneName;
      if (selectedItem.aliasName) {
        combinedString += ` (${selectedItem.aliasName})`;
      }

      this.ids.push(combinedString);
    }
    this.formName.controls[this.field.attributeCode].setValue(this.ids);
    this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    console.log(this.ids)
    this.selectedItems = [];
    this.searchQuery = '';
  }


  fetchFromAPI(viewCode: string, search: string) {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const requestBody = {
      limit: 100,
      offset: 0,
      searchStr: search,
      defaultSortColumn: "updatedAt",
      defaultSortType: "desc",
    };
    this.manageZoneService.getZonesList(requestBody).subscribe(res => {
      this.items = res.data || [];
      console.log(this.items)
    });
  }

  removeSelected(index: number): void {
    this.visibleItems.splice(index, 1);
    this.ids.splice(index, 1);
  }

}

