@if (selectedShiftType === SHIFT_TYPE.MANUALLY) {
<form [formGroup]="form">
    <div formArrayName="breakFields">
        <div class="card p-fluid mb-3">
            <label class="w-full mt-0 uppercase text-lg font-semibold" translate>shift.lbl_add_break <span>({{
                    breakCount}})</span></label>


            @for (data of breakFields.controls; track data; let idx = $index) {
            <div [formGroupName]="idx">
                <div class="w-full formgrid grid pt-2">
                    <div class="col-12 mt-3 mb-1">
                        <div class="grid">
                            <div class="col-6">
                                <label class="slot" translate>shift.lbl_break <span>{{ idx + 1 }}</span> </label>
                            </div>
                            @if (breakFields.controls.length >= 1) {
                            <div class="col-6 text-right remove" (click)="removeBreak(idx)">
                                <label class="pointer" translate>lbl_remove</label>
                                <i class="mi-lg basic-delete"></i>
                            </div>
                            }
                        </div>
                    </div>

                    <div class="field col-12 align-content-end md:col-6">
                        <label class="flex" translate>shift.lbl_reason_for_break</label>
                    </div>
                    <div class="col-12">
                        <p-dropdown [options]="breakReasons" formControlName="reasonForBreak"
                            optionLabel="label" labelValue="value" [showClear]="true" [placeholder]="'shift.lbl_select_reason' | translate" ></p-dropdown>
                    </div>

                    <div class="w-full formgrid grid pt-3 mb-3">
                        <div class="col-6 pr-0">
                            <div class="field col-12 align-content-end">
                                <label class="flex" translate>shift.lbl_break_start_time_from</label>
                            </div>
                            <div class="col-12">
                                <p-calendar [showIcon]="true" icon="mi-lg mi-endofshift"  formControlName="breakStartTime" [showTime]="true"
                                    timeOnly="true" [hourFormat]="hourFormat" [placeholder]="'shift.lbl_select_start_time' | translate"></p-calendar>
                            </div>
                        </div>
                        <div class="col-6 pr-0 pl-0">
                            <div class="field col-12 align-content-end">
                                <label class="flex" translate>shift.lbl_break_end_time_from</label>
                            </div>
                            <div class="col-12 pr-0">
                                <p-calendar [showIcon]="true" icon="mi-lg mi-endofshift"  formControlName="breakEndTime" [showTime]="true"
                                    timeOnly="true" [hourFormat]="hourFormat" [placeholder]="'shift.lbl_select_end_time' | translate"></p-calendar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }

            @if (breakFields.length < 2) {
                <div class="add-more pl-0 pt-3 pb-3" (click)="addMoreSlots()">
                    <i class="mi-lg mi-add"></i>
                    <label class="pointer" translate>shift.lbl_add_break</label>
                </div>
            }


        </div>
    </div>
</form>
} @else {
<!-- add reason -->
<div class="card p-fluid mb-3">
    <div class="flex">
        <label class="w-full mt-0 uppercase text-lg font-semibold" translate>lbl_break <span>({{
            shiftTemplateBreakList.length}})</span></label>

        @if (shiftTemplateBreakList.length > 0) {
            @if (showBreakReasonDropDown) {
                <p-button class="border-600 bg-white text-color mr-2 save-reason" (click)="addEditReason($event)"
                    [label]="'shift.lbl_save_reason' | translate">
                </p-button>
                } @else {
                <p-button icon="pi pi-plus" class="border-600 bg-white text-color mr-2 add-reason"
                    (click)="addEditReason($event)" [label]="breakReasonActionBtnLabel"></p-button>
                }
        }
    </div>

    <div class="w-full formgrid grid pt-2">

        @for (break of shiftTemplateBreakList; track break;let s = $index) {
        @if (showBreakReasonDropDown) {
        <div class="field col-12 flex align-content-end md:col-6">
            <label translate>shift.lbl_reason_for_break</label>
        </div>
        <div class="col-12">
            <p-dropdown [options]="breakReasons" [(ngModel)]="break.reason" optionLabel="label" optionValue="value" [showClear]="true"
                placeholder="Select reason"></p-dropdown>
        </div>

        <div class="w-full formgrid grid pt-3 mb-3">
            <div class="col-6 pr-0">
                <div class="field col-12 flex align-content-end">
                    <i class="mi-lg mi-basic_clock"></i>
                    <span class="flex time-label" translate>shift.lbl_break_start_time_from</span>
                </div>
                <div class="col-12">
                    <label class="time-value"> {{ break.breakStartTime | date: 'hh:mm a' }}</label>
                </div>
            </div>
            <div class="col-6 pr-0 pl-0">
                <div class="field col-12 flex align-content-end">
                    <i class="mi-lg mi-basic_clock"></i>
                    <span class="flex time-label" translate>shift.lbl_break_end_time_from</span>
                </div>
                <div class="col-12 pr-0">
                    <label class="time-value">{{break.breakEndTime | date: 'hh:mm a'}}</label>
                </div>
            </div>
        </div>
        @if (shiftTemplateBreakList.length !== s + 1) {
            <hr class="hr-line">
        }

        } @else {
        <div class="field col-12 flex align-content-end md:col-6">
            <label translate>shift.lbl_reason_for_break <span>:</span></label>
            <span class="not-added">{{ break?.reason ? break?.reason : 'Not Added' }}</span>
        </div>

        <div class="w-full formgrid grid pt-3 mb-3">
            <div class="col-6 pr-0">
                <div class="field col-12 flex align-content-end">
                    <i class="mi-lg mi-basic_clock"></i>
                    <span class="flex time-label" translate>shift.lbl_break_start_time_from</span>
                </div>
                <div class="col-12">
                    <label class="time-value"> {{  number.isInteger(break.breakStartTime) ? (break.breakStartTime | date: 'hh:mm a') : break.breakStartTime}}</label>
                </div>
            </div>
            <div class="col-6 pr-0 pl-0">
                <div class="field col-12 flex align-content-end">
                    <i class="mi-lg mi-basic_clock"></i>
                    <span class="flex time-label" translate>shift.lbl_break_end_time_from</span>
                </div>
                <div class="col-12 pr-0">
                    <label class="time-value">{{ number.isInteger(break.breakEndTime) ? (break.breakEndTime | date: 'hh:mm a') : break.breakEndTime}}</label>
                </div>
            </div>
        </div>
        @if (shiftTemplateBreakList.length !== s + 1) {
            <hr class="hr-line">
        }
        <!-- <hr class="hr-line"> -->
        }


        <!-- @for (break of shiftTemplateBreakList; track break;let s = $index) {
            <div class="w-full formgrid grid pt-3 mb-3">
                <div class="col-6 pr-0">
                    <div class="field col-12 flex align-content-end">
                        <i class="mi-lg mi-basic_clock"></i>
                        <span class="flex time-label" translate>shift.lbl_break_start_time_from</span>
                    </div>
                    <div class="col-12">
                        <label class="time-value"> {{ break.breakStartTime | date: 'hh:mm a' }}</label>
                    </div>
                </div>
                <div class="col-6 pr-0 pl-0">
                    <div class="field col-12 flex align-content-end">
                        <i class="mi-lg mi-basic_clock"></i>
                        <span class="flex time-label" translate>shift.lbl_break_end_time_from</span>
                    </div>
                    <div class="col-12 pr-0">
                        <label class="time-value">{{break.breakEndTime | date: 'hh:mm a'}}</label>
                    </div>
                </div>
            </div>
        } -->
        }
    </div>
</div>
}