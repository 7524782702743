<div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'VEHICLES', subPageCode: 'ADD' }">
    <p-dialog [header]="'settings.vehicle.lbl_add_features' | translate" [(visible)]="isVisible" [modal]="true" [style]="{ width: '600px' }" [draggable]="false" [resizable]="false" (onShow)="onDialogShow()" (onHide)="onHide()">
        <form [formGroup]="settingsForm">
            <div>
                <div class="form-field col-12 md:col-12 lg:col-12 pr-4 mb-2">
                    <label class="flex mb-2 font-semibold" translate>lbl_name
                        <span class="required">*</span>
                    </label>
                    <div class="form-control">
                        <input #nameInput formControlName="name" class="p-component p-element w-full" type="text" pInputText appAutoFocus [disabled]="rowData.customValue"/>
                    </div>
                    @if (settingsForm.controls.name.errors && (settingsForm.controls.name.dirty || settingsForm.controls.name.touched)) {
                        <div class="form-errors mt-1 required-field">
                            <div [hidden]="!settingsForm.controls.name.errors.required" translate>
                                settings.lbl_name_req
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div class="flex justify-content-end mr-4 mb-2">
                <button pButton class="mr-2 mb-2 bg-white default-text-light-black-color border-button" [label]="'cancel' | translate" (click)="onHide()"></button>
                <p-button class="mb-2 flex align-items-center justify-content-center" [label]="'label_save' | translate" (click)="onAddSettings()"></p-button>
            </div> 
        </form>
    </p-dialog>
</div>
<p-toast></p-toast>