@if(!isFullScreen){<p-breadcrumb [model]="routePath"></p-breadcrumb>}
<div class="flex flex-row">
  <div class="dropdown-menu bg-white">
    <span class="mi-lg mi-live_dashboard wh-18"></span>
    <p-dropdown
      [options]="liveDashboardMenu"
      [(ngModel)]="selectedMenuItem"
      optionLabel="label"
      (onChange)="onMenuItemChange($event.value)">

      <ng-template let-item pTemplate="item">
        <div>
          <img [src]="item.icon" class="icon-gif" />
          {{ item.label }}
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <div class="flex flex-grow-1 justify-content-between bg-white">
    <div class="flex-initial flex align-items-center justify-content-center font-bold">
      <p-tabMenu [model]="menuItemsStart"></p-tabMenu>
    </div>
    <div class="flex-initial flex align-items-center justify-content-center font-bold">
      <p-menubar [model]="menuItemsEnd"></p-menubar>
    </div>
  </div>
</div>

@if (showManageWidgets) {
  <app-manage-widgets #manageWidgets [overlayPanelToggle]="overlayPanelToggle" [showManageWidgets]="showManageWidgets" 
    [manageWidgetCards]="manageWidgetCards">
  </app-manage-widgets>
}
