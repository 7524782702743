<div class="flex flex-row justify-content-between card-container" (click)="onCardClick(driverDetail)">
    <div class="flex flex-row">
        <div class="flex flex-column mr-3 justify-content-between">
            <div class="image-container mt-1">
                <span class="mi-lg mi-booking_user wh-18 inline-block vertical-align-text-bottom"></span>
                <span class="mi-lg mi-stops wh-18 label-icon inline-block vertical-align-text-bottom relative top-9 right-11"
                    [ngClass]="{ 'bg-on-shift': driverDetail.shiftStatus === 'On Shift', 'bg-off-shift': driverDetail.shiftStatus === 'Off Shift' }"></span>
            </div>
            @if(driverDetail.wheelChair || driverDetail.child){<div class="child-wheelchair-flag flex justify-content-center align-items-center font-12 font-bold mb-1">
                @if(driverDetail.wheelChair && driverDetail.child){<ng-container >C|W</ng-container>}
                @if(driverDetail.wheelChair && !driverDetail.child){<ng-container >W</ng-container>}
                @if(!driverDetail.wheelChair && driverDetail.child){<ng-container>C</ng-container>}
            </div>}
        </div>
        <div class="flex flex-column">        
            <div>
                <span class="font-14 font-semibold default-text-black-color mr-2">{{driverDetail.first_name}} {{driverDetail.last_name}}</span>
                <span class="font-14 font-medium default-text-black-color mr-2" >{{driverDetail.unique_id}}</span>
                <span class="font-16 font-semibold default-text-light-gray mr-2" >|</span>
                <span class="font-14 font-normal default-text-black-color">{{groupName}}</span>
            </div>
            <div>
                <span class="font-13 font-medium default-text-black-color mr-2">{{nameCode}}</span>
                <span class="font-13 font-normal default-print-label-color">{{manufacturer}} {{variant}}</span>
                <span class="font-13 font-normal default-print-label-color"> ({{vehicleType}} - {{passengerCapacity}})</span>
            </div>
            <div class="mt-2 flex flex-row">
              <div class="mr-2 time-container" (mouseenter)="onMouseEnter($event)" 
              (mouseleave)="onMouseLeave($event)"  [ngClass]="{'hovered': isHovered, 'blue-container': !isHovered}" >
                   <span class="font-14 font-bold default-text-black-color">{{driverDetail.estimatedTime}}</span>
                   <span class="font-16 default-print-label-color mr-1 ml-1 relative top-2">|</span>
                   <span class="font-14 font-semibold default-text-black-color">{{driverDetail.distance}}</span>                   
              </div>
              <p-overlayPanel #op>
                <div class="m-2" style="width: 392px;">
                    <div class="font-16 font-bold default-text-black-color mb-2" translate>dispatch.driver_status.driver_status</div>
                    <div class="flex flex-row py-2">
                        <div class="w-5 font-14 font-normal default-print-label-color" translate>dispatch.driver_status.availability</div>
                        <div class="w-7 flex flex-column">
                            <div class="font-14 font-bold text-red-color" translate>dispatch.driver_status.busy</div>
                            <div class="font-14 font-medium default-text-black-color" translate>dispatch.driver_status.reconfirmed <span><i class="mi-lg reconfirmed bg-route-icon wh-18 inline-block vertical-align-text-bottom"></i></span></div>
                        </div>
                    </div>
                    <div class="flex flex-row py-2">
                        <div class="w-5 font-14 font-normal default-print-label-color" translate>dispatch.eta</div>
                        <div class="w-7">
                            <span class="font-16 font-bold default-text-black-color mr-2">3 Mins</span>
                            <span class="font-14 font-semibold default-print-label-color">(4.87 Miles)</span>
                        </div>
                    </div>
                    <div class="flex flex-row py-2">
                        <div class="w-5 font-14 font-normal default-print-label-color" translate>dispatch.driver_status.current_status</div>
                        <div class="w-7 flex flex-column">
                            <div class="font-14 font-semibold default-text-blue-color">BOOOO12345</div>
                            <div class="font-14 font-medium default-print-label-color">Drop off at <span class="font-14 font-semibold default-text-black-color">Park Plaza</span>
                                 estimated drop off time <span class="font-14 font-semibold default-text-black-color">3 mins</span> from now.
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row py-2">
                        <div class="w-5 font-14 font-normal default-print-label-color" translate>dispatch.driver_status.next_upcoming</div>
                        <div class="w-7 flex flex-column">
                            <div class="font-14 font-semibold default-text-blue-color">B000012345 <span class="font-14 font-medium default-print-label-color">(Pickup - 21:45)</span></div>
                            <div class="font-16 font-bold default-text-black-color mr-2">21:58 <span class="font-14 font-medium default-print-label-color">(4.87 Miles)</span></div>
                        </div>                        
                    </div>
                </div>
              </p-overlayPanel>
              <div class="shift-toggle font-14 font-semibold flex align-items-center" [ngClass]="{ 'on-shift': driverDetail.shiftStatus === 'On Shift', 'off-shift': driverDetail.shiftStatus === 'Off Shift' }">{{ driverDetail.shiftStatus }}</div>
            </div>  
        </div>
    </div>
    @if(isBookingScreen){
        <div class="flex align-items-center">
            <p-button [label]="'Assign'" class="assign-btn" (click)="onAssignClick(driverDetail, $event)"></p-button>
        </div>
    }

    @if(forDetail){
        <div (click)="onCloseDetailView()" class="cancel-btn"><i class="mi-lg mi-off_close mt-4 inline-block cursor-pointer wh-24"></i></div>
     }
</div>
