import { CommonModule, JsonPipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { CommonBindingDataService } from '../../../../services/common-binding-data.service';

@Component({
  selector: 'app-mi-days-multi-select',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, InputTextModule, JsonPipe, CheckboxModule, DividerModule, NgClass, CommonModule],
  templateUrl: './mi-days-multi-select.component.html',
  styleUrl: './mi-days-multi-select.component.scss'
})
export class MiDaysMultiSelectComponent {
  checked = [];
  selectedCategories;
  @Input() attributeValues;
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  searchValue: string = '';
  selectedControl: string[] = [];
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  inputPlaceholder: string;
  filteredValues: any[] = [];

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }


  ngOnInit() {
    this.field.presetValues = [{ labelKey: 'Monday', labelValue: 1, selected: false },
    { labelKey: 'Tuesday', labelValue: 2, selected: false },
    { labelKey: 'Wednesday', labelValue: 3, selected: false },
    { labelKey: 'Thursday', labelValue: 4, selected: false },
    { labelKey: 'Friday', labelValue: 5, selected: false },
    { labelKey: 'Saturday', labelValue: 6, selected: false },
    { labelKey: 'Sunday', labelValue: 7, selected: false }];
    this.filteredValues = this.field.presetValues;
    this.selectedControl = this.attributeValues;
    if (this.field.presetValues?.length === this.selectedControl?.length) {
      this.checked = ['val1'];
    }
    this.inputPlaceholder = this.cs.getLabel('placeholder_search') + ' ' + this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.label', this.attributeLabels, this.field.attributeCode);
  }

  clearSearch() {
    this.searchValue = "";
    this.filterPresetValues();
    this.onSearchValueChange.emit('');
  }

  onSearch(event) {
    const value = event.target.value;
    this.filterPresetValues();
    if (event.target.value.length >= 3) {
      this.onSearchValueChange.emit(value);
    } else if (event.target.value.length === 0) {
      this.onSearchValueChange.emit(value);
    }
  }




  onChange(event) {
    if (event.checked.length > 0) {
      const values = [];
      this.field?.presetValues?.map(value => {
        values.push(value.labelValue);
      });
      this.selectedControl = values;
      this.formName.controls[this.field.attributeCode].setValue(values);
      this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    } else {
      this.selectedControl = [];
      this.formName.controls[this.field.attributeCode].setValue([]);
      this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    }

  }

  valueChange() {
    this.formName.controls[this.field.attributeCode].setValue(this.selectedControl);
    this.formName.controls[this.field.attributeCode].updateValueAndValidity();
  }


  filterPresetValues() {
    const searchLower = this.searchValue.toLowerCase();
    this.filteredValues = this.field.presetValues.filter(item =>
      item.labelKey.toLowerCase().includes(searchLower)
    );
  }



}
