import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TrimSpacesDirective } from 'app/modules/shared/directives/trim-spaces.directive';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { AppSettings } from '../../../shared/app.settings';
import { Countries } from '../../../shared/countries';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { UserManagementService } from '../../services/user-management.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss', '../../../shared/components/mi-fields/mobile-input/flags.scss'],
  standalone: true,
  imports: [BreadcrumbModule, DialogModule, ButtonModule, TranslateModule, FormsModule, ReactiveFormsModule, InputTextModule, DropdownModule, TrimSpacesDirective, AccessProviderDirective]
})
export class AddUserComponent {
  @ViewChild('dropdown') dropdown!: Dropdown;
  country: Country;
  language: Language;
  routePath: MenuItem[] = [];
  paramId: number;
  userForm: FormGroup;
  userStatus: string;
  userId: string;
  formSubmitted: boolean = false;
  roleList = [];
  countries: any[] | undefined;
  selectedCountry: any;
  filterIcon = 'pi pi-search';
  showMobileValidationError: boolean = false;
  selectedCountryCode: any;

  constructor(
    public userManagementService: UserManagementService,
    public cs: CommonBindingDataService,
    private router: Router,
    private fb: FormBuilder,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private configService: ConfigService,
  ) { }

  ngOnInit() {
    this.userId = this.route.snapshot.paramMap.get("id");
    this.setRoutePath();
    this.getUserRoles();
    this.setCountries();
    this.initializeForm();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    if (this.userId) {
      this.getUserData();
    }
  }

  setRoutePath() {
    const manageUserLabel = this.userId ? this.cs.getLabel('manage_users.lbl_edit_user') : this.cs.getLabel('manage_users.lbl_add_user');
    const addEditLabel = this.userId ? this.cs.getLabel('manage_users.edit') : this.cs.getLabel('manage_users.add')
    this.routePath = [
      {
        label: manageUserLabel,
        routerLink: this.userId ? '../../../manage-users' : '../../manage-users',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
    ];
  }

  initializeForm() {
    this.userForm = this.fb.group({
      userRole: ['', [Validators.required]],
      roleName: [''],
      firstName: ['', [Validators.required, Validators.pattern(AppSettings.NAME_PATTERN), Validators.maxLength(AppSettings.MAX_LENGTH_FOR_FORM_VALIDATION)]],
      lastName: ['', [Validators.required, Validators.pattern(AppSettings.NAME_PATTERN), Validators.maxLength(AppSettings.MAX_LENGTH_FOR_FORM_VALIDATION)]],
      email: ['', [Validators.required, Validators.pattern(AppSettings.EMAIL_PATTERN)]],
      contactNumber: ['', [Validators.required, Validators.pattern(AppSettings.PHONE_PATTERN), Validators.maxLength(AppSettings.MAX_MOBILE_LENGTH), Validators.minLength(AppSettings.MIN_MOBILE_LENGTH)]],
      username: ['', [Validators.required, Validators.maxLength(AppSettings.MAX_LENGTH_FOR_FORM_VALIDATION)]],
    });
  }

  getUserRoles() {
    const requestBody = {
      limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
      offset: 0,
      searchStr: "",
      filters: [],
      countryCode: '',
      deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
      forTenantCode: '',
      actionStatus: '',
      status: "both"
    }
    this.userManagementService.searchEntity(requestBody).subscribe((res: any) => {
      if (res && res.data && Array.isArray(res.data)) {
        this.roleList = res.data.map(item => ({
          roleName: item.roleName,
          roleId: item.roleId
        }));
      }
    });
  }

  getname(e: any) {
    const selectedRole = this.roleList.find(role => role.roleId === e.value);
    this.userForm.patchValue({
      roleName: selectedRole.roleName
    });
  }

  saveUser() {
    if (this.userForm.valid) {
      const formData = { ...this.userForm.value };
      formData["tenantId"] = this.country[0]?.tenantId;
      formData["countryCode"] = this.country[0].countryCode;
      formData["active"] = true;
      formData["forTenantCode"] = this.configService.getForTenantCode();
      const selectedRoleId = formData.userRole;
      formData["roleIds"] = [selectedRoleId];
      if (this.selectedCountryCode) {
        formData["contactNumber"] = `${this.selectedCountryCode}-${formData.contactNumber}`;
      }
      const userFormData = formData;
      if (this.userId) {
        this.onEditUser(userFormData);
      } else {
        this.onAddUser(userFormData);
      }
    } else {
      console.error("Form is invalid");
    }
  }

  onAddUser(userFormData) {
    this.userManagementService.AddNewUser(userFormData).subscribe((res: any) => {
      this.showSuccessMessage('manage_users.message.msg_user_added_successfully');
      this.router.navigate(["app/user-management/manage-users"]);
    }, (error) => {
      const errorMessage = error?.errors?.general?.[0]?.message ?? "An unknown error occurred.";
      this.showErrorMessage(errorMessage);
    });
  }

  onEditUser(userFormData) {
    this.userManagementService.EditUser(this.userId, userFormData).subscribe((res: any) => {
      this.showSuccessMessage('manage_users.message.msg_user_update_successfully');
      this.router.navigate(["app/user-management/manage-users"]);
    }, (error) => {
      const errorMessage = error?.errors?.general?.[0]?.message ?? "An unknown error occurred.";
      this.showErrorMessage(errorMessage);
    }
    );
  }

  getUserData() {
    this.userManagementService.getUserDetails(this.userId).subscribe((userData: any) => {
      this.userForm.patchValue(userData);
      if (userData.rolelist && userData.rolelist.length > 0) {
        const roleId = userData.rolelist[0].roleId;
        this.userForm.get('userRole').patchValue(roleId);
      }

      if (userData.contactNumber) {
        const contactNumberParts = userData.contactNumber.split('-');
        console.log(contactNumberParts);
        if (contactNumberParts.length > 1) {
          const countryCodeFromNumber = contactNumberParts[0];
          console.log(countryCodeFromNumber, 'countryCodeFromNumber');
          const number = contactNumberParts.slice(1).join('-');
          console.log(number);

          const selectedCountry = this.countries.find(c => c.value === countryCodeFromNumber);
          this.selectedCountryCode = selectedCountry;
          if (selectedCountry) {
            this.selectedCountry = selectedCountry;
            this.selectedCountryCode = selectedCountry.value;
          } else {
            this.selectedCountryCode = '';
          }
          this.userForm.get('contactNumber').setValue(number);
        }
      }
    });
  }

  setCountries() {
    this.countries = Countries.COUNTRY_CODE;
    const defaultCountry = this.countries[AppSettings.DEFAULT_COUNTRY_CODE];
    if (defaultCountry) {
      this.selectedCountry = defaultCountry;
      this.selectedCountryCode = defaultCountry.value;
    } else {
      this.selectedCountryCode = '';
    }
  }

  onFilter(event) {
    if (event.filter) {
      this.filterIcon = 'pi pi-times'
    } else {
      this.filterIcon = 'pi pi-search';
    }
  }

  onFilterIconClick() {
    if (this.filterIcon.includes('times')) {
      this.dropdown.filterValue = null;
      this.filterIcon = 'pi pi-search';
    }
  }

  selectCountry(event) {
    const selectedCountry = event.value.value;
    if (selectedCountry) {
      this.selectedCountryCode = selectedCountry;
    } else {
      this.selectedCountryCode = '';
    }
  }

  cancelUserForm() {
    this.userForm.reset();
    this.router.navigate(['app/user-management/manage-users']);
  }

  private showErrorMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Error',
      detail: this.cs.getLabel(labelKey)
    });
  }


  private showSuccessMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Successful',
      detail: this.cs.getLabel(labelKey),
    });
  }
}
