<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'LEAVE_MANAGEMENT', pageCode: 'HOME', subPageCode: 'ADD' }">
  <div class="flex justify-content-end flex-wrap btn-with-links">
    <p-button class="flex align-items-center justify-content-center add-org-btn" icon="pi pi-plus" [label]="btnLabel" (click)="onAddNewLeave()" ></p-button>
  </div>
</div>
<div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'LEAVE_MANAGEMENT', pageCode: 'HOME', subPageCode: 'LIST' }">
<p-tabView>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <i class="pi pi-align-left mr-2"></i>
        <span translate>lbl_home</span>
      </ng-template>
      <ng-template pTemplate="body">
        <div class="grid flex bg-f5f5f5 pb-16">
          <div class="md:col-6 lg:col-3">
            <div class="card h-full shadow-card today-card my-3 ml-3">
              <div class="flex justify-content-between align-items-center mb-1 mt-1">
                <p class="mb-0">
                  <span class="font-16 mb-0 default-text-black-color font-semibold">{{'lbl_today' | translate}}</span>&nbsp;
                  <span class="font-13 text-color">({{todaysDate | date:'EEE dd MMMM'}})</span>
                </p>
                <span><a class="text-primary cursor-pointer" (click)="todaysViewAll()" translate>lbl_view_all</a></span>
              </div>
              <hr>
              @if(todaysLeaveCount > 0 || todaysWorkingCount > 0) {
                <div class="doughnut-chart">
                  <p-chart type="doughnut" [data]="data" [options]="options"
                    class="flex justify-content-start"></p-chart>
                </div>
              }
              @else {
                <app-no-result-found></app-no-result-found>
              }
            </div>
          </div>
          <div class=" md:col-6 lg:col-3">
            <div class="card h-full shadow-card tomorrow-card">
              <div class="flex justify-content-between align-items-center mb-1 mt-1">
                <p class="mb-0">
                  <span class="font-16 mb-0 default-text-black-color font-semibold">{{'lbl_tomorrow' | translate}}</span>&nbsp;
                  <span class="font-13 text-color">({{tomorrowDate | date:'EEE dd MMMM'}})</span>
                </p>
                <span><a class="text-primary cursor-pointer" (click)="tomorrowsViewAll()" translate>lbl_view_all</a></span>
              </div>
              <hr>
              @if(tomorrowsLeaveCount > 0 || tomorrowsWorkingCount > 0) {
                <div class="doughnut-chart">
                  <p-chart type="doughnut" [data]="tomorrowsData" [options]="tomorrowsOptions"
                    class="flex justify-content-start">
                  </p-chart>
                </div>
              }
              @else {
                <app-no-result-found></app-no-result-found>
              }
            </div>
          </div>
          <div class="md:col-12 lg:col-6">
            <div class="card h-full overview-card leaves-card">
              <div class="flex justify-content-between align-items-center mb-1">
                <p class="font-16 mb-1 default-text-black-color font-semibold" translate>lbl_leaves_overview
                  <span>
                    <p-dropdown [options]="leaves" [(ngModel)]="selectedLeave" optionLabel="label" optionValue="value" placeholder="Select Date" (onChange)="onChangeSelectedLeave($event)">
                    </p-dropdown>
                  </span>
                  <span>
                    @if(showCalendar) {
                      <p-calendar class="show-calendar" [(ngModel)]="rangeDates" [showIcon]="true" rangeSelection="true" hideOnDateTimeSelect="true"
                        (onSelect)="onDateSelect($event)" selectionMode="range" [readonlyInput]="true"></p-calendar>
                    }
                  </span>
                </p>
              </div>
              <hr class="leave-overview-hr">
              @if(isShowNoResult) {
                <div>
                  <p-chart type="pie" [data]="pieChartData" [options]="pieChartOptions" class="flex justify-content-start">
                  </p-chart>
                </div>
              }
              @else {
                <app-no-result-found></app-no-result-found>
              }
            </div>
          </div>
        </div>
        <div class="bg-f5f5f5">
          <div class="mx-16 request-heading bg-white font-18 font-semibold uppercase" translate>lbl_request
            <span>({{totalRecords}})</span>
          </div>
        </div>
        <app-mi-table
          [collectionOfColumns]="allColumns"
          [attributeLabels]="filterAttributeLabels"
          [data]="tableData"
          [isLoading]="isLoading"
          (onPageChange)="onPageChange($event)"
          (itemPerPageChange)="itemPerPageChange($event)"
          [message]="deleteLeaveMsg"
          [header]="deleteLeaveHeaderMsg"
          (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
          (onRowSelection)="onRowSelect($event)"
          [filterList]="filterList"
          (onValueChange)="onFilterValueChange($event)"
          [filterFields]="filterFieldsArray"
          [label]="btnLabel"
          (onBtnClick)="onAddNewLeave()"
          [attributeData]="attributeData"
          [rowItems]="rowItems"
          (onUpdate)="onUpdateUser($event)"
          [bulkSelectionsItems] = "bulkSelectionsItems"
          [bulkUpdateLabel]="bulkUpdateLabel"
          [bulkUpdateHeader]="bulkUpdateHeader"
          [bulkDeleteLabel]="bulkDeleteLabel"
          (setCurrentRowData)="setCurrentRowData($event)"
          [showBulkLeaveUpdateDialog]="showBulkLeaveUpdateDialog"
          (onBulkLeaveDataUpdateEvent)="onBulkDataUpdateEvent($event)"
          [reviewHeader]="reviewHeader"
          (onSearchValueChange)="onSearchValueChanges($event)"
          [totalRecords]="totalRecords"
          [visible]="visible"
          (onToggleOverLay)="visible = false"
          (onChangeStatus)="onChangeStatus($event)"
          [isEntity]="isEntity"
          [uniqueId]="uniqueId">
        </app-mi-table>
      </ng-template>
  </p-tabPanel>
</p-tabView>
</div>
<p-toast></p-toast>
