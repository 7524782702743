<p-breadcrumb [model]="routePath"></p-breadcrumb>

<div class="flex">
    <div class="col-fixed bg-surface hide-scrollbar">
        <div class="navigation-menu">
            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.USER_CIRCLE}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3 pb-3" translate>settings.lbl_account_settings</p>
                </div>
                <div>
                    <a class="default-text-blue-color ml-4 cursor-pointer" (click)="doLogOut()" translate>lbl_logout</a>
                </div>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.userProfile); updateBreadcrumb('User Profile')"
                [ngClass]="{'active': activeSection === sectionHandlers.userProfile}">
                <a [routerLink]="['/app/settings/user-profile']">
                    <label class="navigation-item-label" translate>settings.lbl_user_profile</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.notificationSettings);  updateBreadcrumb('Notification Settings')"
                [ngClass]="{'active': activeSection === sectionHandlers.notificationSettings}">
                <a [routerLink]="['/app/settings/notification-settings']">
                    <label class="navigation-item-label" translate>settings.lbl_notification_settings</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.loginPassword); updateBreadcrumb('Login & Password')"
                [ngClass]="{'active': activeSection === sectionHandlers.loginPassword}">
                <a [routerLink]="['/app/settings/login-and-password']">
                    <label class="navigation-item-label" translate>settings.lbl_login_and_password</label>
                </a>
            </div>
        </div>

        <div class="navigation-menu">
            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_administration</p>
                </div>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.generalSettings); updateBreadcrumb('General Settings')"
                [ngClass]="{'active': activeSection === sectionHandlers.generalSettings}">
                <a [routerLink]="['/app/settings/general-settings']">
                    <label class="navigation-item-label" translate>settings.lbl_general_settings</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.timeSettings); updateBreadcrumb('Time Settings')"
                [ngClass]="{'active': activeSection === sectionHandlers.timeSettings}">
                <a [routerLink]="['/app/settings/time-settings']">
                    <label class="navigation-item-label" translate>settings.lbl_time_settings</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.exportAccountData); updateBreadcrumb('Export Account Data')"
                [ngClass]="{'active': activeSection === sectionHandlers.exportAccountData}">
                <a [routerLink]="['/app/settings/export-account-data']">
                    <label class="navigation-item-label" translate>settings.lbl_export_account_data</label>
                </a>
            </div>
            <!-- <div class="navigation-item">
                <label class="navigation-item-label" translate>settings.lbl_integrations</label>
            </div> -->
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.security); updateBreadcrumb('Security')"
                [ngClass]="{'active': activeSection === sectionHandlers.security}">
                <a [routerLink]="['/app/settings/security']">
                    <label class="navigation-item-label" translate>settings.lbl_security</label>
                </a>
            </div>
        </div>


        <div class="navigation-menu">
            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.authentication.header</p>
                </div>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.authUser); updateBreadcrumb('Authentication / User')"
                [ngClass]="{'active': activeSection === sectionHandlers.authUser}">
                <a [routerLink]="['/app/settings/auth-user']">
                    <label class="navigation-item-label" translate>settings.authentication.user</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.authDriver); updateBreadcrumb('Authentication / Driver')"
                [ngClass]="{'active': activeSection === sectionHandlers.authDriver}">
                <a [routerLink]="['/app/settings/auth-driver']">
                    <label class="navigation-item-label" translate>settings.authentication.driver</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.authPassenger); updateBreadcrumb('Authentication / Passenger')"
                [ngClass]="{'active': activeSection === sectionHandlers.authPassenger}">
                <a [routerLink]="['/app/settings/auth-passenger']">
                    <label class="navigation-item-label" translate>settings.authentication.passenger</label>
                </a>
            </div>
           
        </div>

        <div class="navigation-menu">
            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_system_settings</p>
                </div>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.customFields); updateBreadcrumb('Custom Fields')"
                [ngClass]="{'active': activeSection === sectionHandlers.customFields}">
                <a [routerLink]="['/app/settings/custom-fields']">
                    <label class="navigation-item-label" translate>settings.lbl_custom_fields</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.labels); updateBreadcrumb('Labels')"
                [ngClass]="{'active': activeSection === sectionHandlers.labels}">
                <a [routerLink]="['/app/settings/labels']">
                    <label class="navigation-item-label" translate>settings.lbl_labels</label>
                </a>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_bookings</p>
                </div>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.journeyPreference); updateBreadcrumb('Journey Preference')"
                [ngClass]="{'active': activeSection === sectionHandlers.journeyPreference}">
                <a [routerLink]="['/app/settings/ride-preference']">
                    <label class="navigation-item-label" translate>settings.lbl_journey_preference</label>
                </a>
            </div>
            <!-- <div class="navigation-item" (click)="setActiveSection(sectionHandlers.bookingTransportationMode); updateBreadcrumb('Transportation Mode')"
                [ngClass]="{'active': activeSection === sectionHandlers.bookingTransportationMode}">
                <a [routerLink]="['/app/settings/booking-transportation-mode']">
                    <label class="navigation-item-label" translate>settings.lbl_transportation_mode</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.childSeats); updateBreadcrumb('Child Seats')"
                [ngClass]="{'active': activeSection === sectionHandlers.childSeats}">
                <a [routerLink]="['/app/settings/child-seats']">
                    <label class="navigation-item-label" translate>settings.lbl_child_seats</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.luggage); updateBreadcrumb('Luggage')"
                [ngClass]="{'active': activeSection === sectionHandlers.luggage}">
                <a [routerLink]="['/app/settings/luggage']">
                    <label class="navigation-item-label" translate>settings.lbl_luggage</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.vehicleBodyType); updateBreadcrumb('Vehicle Body Type')"
                [ngClass]="{'active': activeSection === sectionHandlers.vehicleBodyType}">
                <a [routerLink]="['/app/settings/vehicle-body-type']">
                    <label class="navigation-item-label" translate>settings.lbl_vehicle_body_type</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.vehicleDispatch); updateBreadcrumb('vehicleDispatch')"
                [ngClass]="{'active': activeSection === sectionHandlers.vehicleDispatch}">
                <a [routerLink]="['/app/settings/booking-vehicle-dispatch']">
                    <label class="navigation-item-label" translate>settings.lbl_vehicle_dispatch</label>
                </a>
            </div> -->

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_events</p>
                </div>
            </div>           

            
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.transportationType); updateBreadcrumb('Transportation Type')"
                [ngClass]="{'active': activeSection === sectionHandlers.transportationType}">
                <a [routerLink]="['/app/settings/transportation-type']">
                    <label class="navigation-item-label" translate>settings.lbl_transportation_type</label>
                </a>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_passengers</p>
                </div>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.passengerType); updateBreadcrumb('Passenger Type')"
                [ngClass]="{'active': activeSection === sectionHandlers.passengerType}">
                <a [routerLink]="['/app/settings/passenger-type']">
                    <label class="navigation-item-label" translate>settings.lbl_passenger_type</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.privilegeSettings); updateBreadcrumb('Privilege Settings')"
                [ngClass]="{'active': activeSection === sectionHandlers.privilegeSettings}">
                <a [routerLink]="['/app/settings/privilege-settings']">
                    <label class="navigation-item-label" translate>settings.lbl_privilege_settings</label>
                </a>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_vehicles</p>
                </div>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.vehicleStatus); updateBreadcrumb('Vehicle Status')"
                [ngClass]="{'active': activeSection === sectionHandlers.vehicleStatus}">
                <a [routerLink]="['/app/settings/vehicle-status']">
                    <label class="navigation-item-label" translate>settings.lbl_vehicle_status</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.vehicleTypes); updateBreadcrumb('Vehicle Types')"
                [ngClass]="{'active': activeSection === sectionHandlers.vehicleTypes}">
                <a [routerLink]="['/app/settings/vehicle-types']">
                    <label class="navigation-item-label" translate>settings.lbl_vehicle_types</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.bodyTypes); updateBreadcrumb('Body Types')"
                [ngClass]="{'active': activeSection === sectionHandlers.bodyTypes}">
                <a [routerLink]="['/app/settings/body-types']">
                    <label class="navigation-item-label" translate>settings.lbl_body_types</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.features); updateBreadcrumb('Features')"
                [ngClass]="{'active': activeSection === sectionHandlers.features}">
                <a [routerLink]="['/app/settings/features']">
                    <label class="navigation-item-label" translate>settings.lbl_features</label>
                </a>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_drivers</p>
                </div>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.leaveType); updateBreadcrumb('Leave Type')"
                [ngClass]="{'active': activeSection === sectionHandlers.leaveType}">
                <a [routerLink]="['/app/settings/leave-type']">
                    <label class="navigation-item-label" translate>settings.lbl_leave_type</label>
                </a>
            </div>

            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.reasonForDutyStatus); updateBreadcrumb('Reason For Duty Status')"
                [ngClass]="{'active': activeSection === sectionHandlers.reasonForDutyStatus}">
                <a [routerLink]="['/app/settings/reason-for-duty-status']">
                    <label class="navigation-item-label" translate>settings.lbl_reason_for_duty_status</label>
                </a>
            </div>
       
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.drivingRecord); updateBreadcrumb('Driving Record')"
                [ngClass]="{'active': activeSection === sectionHandlers.drivingRecord}">
                <a [routerLink]="['/app/settings/driver-driving-record']">
                    <label class="navigation-item-label" translate>settings.lbl_driving_record</label>
                </a>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_location</p>
                </div>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.locationCategory); updateBreadcrumb('Location Category')"
                [ngClass]="{'active': activeSection === sectionHandlers.locationCategory}">
                <a [routerLink]="['/app/settings/location-category']">
                    <label class="navigation-item-label" translate>settings.lbl_location_category</label>
                </a>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_concerns</p>
                </div>
            </div>

            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.incidentsStatus); updateBreadcrumb('Incidents Status')"
                [ngClass]="{'active': activeSection === sectionHandlers.incidentsStatus}">
                <a [routerLink]="['/app/settings/incidents-status']">
                    <label class="navigation-item-label" translate>settings.lbl_incidents_status</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.complaintsStatus); updateBreadcrumb('Complaints Status')"
                [ngClass]="{'active': activeSection === sectionHandlers.complaintsStatus}">
                <a [routerLink]="['/app/settings/complaints-status']">
                    <label class="navigation-item-label" translate>settings.lbl_complaints_status</label>
                </a>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_shift_management</p>
                </div>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.groupColor); updateBreadcrumb('Group Colors')"
                [ngClass]="{'active': activeSection === sectionHandlers.groupColor}">
                <a [routerLink]="['/app/settings/group-color']">
                    <label class="navigation-item-label" translate>settings.lbl_group_colors</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.reasonForBreak); updateBreadcrumb('Reason For Break')"
                [ngClass]="{'active': activeSection === sectionHandlers.reasonForBreak}">
                <a [routerLink]="['/app/settings/reason-for-break']">
                    <label class="navigation-item-label" translate>settings.lbl_reason_for_break</label>
                </a>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_communication</p>
                </div>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.messageType); updateBreadcrumb('Message Type')"
                [ngClass]="{'active': activeSection === sectionHandlers.messageType}">
                <a [routerLink]="['/app/settings/message-type']">
                    <label class="navigation-item-label" translate>settings.lbl_message_type</label>
                </a>
            </div>
        </div>
    </div>
    <div class="col p-0 scrollable">
        <div>
            @switch(activeSection) {
                @case('userProfile') {
                    <app-settings-user-profile></app-settings-user-profile>
                }
                @case('notificationSettings') {
                    <app-settings-notification-settings></app-settings-notification-settings>
                }
                @case('loginPassword') {
                    <app-settings-login-and-password></app-settings-login-and-password>
                }
                @case('generalSettings') {
                    <app-settings-general-settings></app-settings-general-settings>
                }
                @case('timeSettings') {
                    <app-settings-time-settings></app-settings-time-settings>
                }
                @case('authUser') {
                 <app-settings-user></app-settings-user>
                }
                @case('authDriver') {
                    <app-settings-driver></app-settings-driver>
                }
                @case('authPassenger') {
                  <app-settings-passenger></app-settings-passenger>
                }
                @case('exportAccountData') {
                    <app-settings-export-account-data></app-settings-export-account-data>
                }
                @case('security') {
                    <app-settings-security></app-settings-security>
                }
                @case('customFields') {
                    <app-settings-custom-fields></app-settings-custom-fields>
                }
                @case('labels') {
                    <app-settings-labels></app-settings-labels>
                }
                @case('journeyPreference') {
                    <app-settings-ride-preference></app-settings-ride-preference>
                }
                @case('transportationType') {
                    <app-settings-transportation-type></app-settings-transportation-type>
                }
                <!-- @case('bookingTransportationMode') {
                    <app-settings-booking-transportation-mode></app-settings-booking-transportation-mode>
                }
                @case('childSeats') {
                    <app-settings-child-seats></app-settings-child-seats>
                }
                @case('luggage') {
                    <app-settings-luggage></app-settings-luggage>
                }
                @case('vehicleBodyType') {
                    <app-settings-booking-vehicle-body-type></app-settings-booking-vehicle-body-type>
                }
                @case('vehicleDispatch') {
                    <app-settings-booking-vehicle-dispatch></app-settings-booking-vehicle-dispatch>
                }
                @case('transportationMode') {
                    <app-settings-transportation-mode></app-settings-transportation-mode>
                } -->
                @case('passengerType') {
                    <app-settings-passenger-type (goToPrivilegeSettingsChanges)="setActiveSection(sectionHandlers.privilegeSettings); updateBreadcrumb('Privilege Settings')"></app-settings-passenger-type>
                }
                @case('privilegeSettings') {
                    <app-settings-privilege-settings></app-settings-privilege-settings>
                }
                @case('vehicleStatus') {
                    <app-settings-vehicle-status></app-settings-vehicle-status>
                }
                @case('vehicleTypes') {
                    <app-settings-vehicle-types></app-settings-vehicle-types>
                }
                @case('bodyTypes') {
                    <app-settings-body-types></app-settings-body-types>
                }
                @case('features') {
                    <app-setting-features></app-setting-features>
                }
                @case('reasonForDutyStatus') {
                    <app-settings-reason-for-duty-status></app-settings-reason-for-duty-status>
                }
                @case('drivingRecord') {
                    <app-settings-driving-record></app-settings-driving-record>
                }
                @case('leaveType') {
                    <app-settings-leave-type></app-settings-leave-type>
                }
                @case('locationCategory') {
                    <app-settings-location-category></app-settings-location-category>
                }
                @case('incidentsStatus') {
                    <app-settings-status></app-settings-status>
                }
                @case('complaintsStatus') {
                    <app-settings-status></app-settings-status>
                }
                @case('groupColor') {
                    <app-settings-group-color></app-settings-group-color>
                }
                @case('reasonForBreak') {
                    <app-settings-reason-for-break></app-settings-reason-for-break>
                }
                @case('messageType') {
                    <app-settings-message-type></app-settings-message-type>
                }
                @default {
                    <app-settings-user-profile></app-settings-user-profile>
                }
            }
        </div>
    </div>
</div>
