<div>
  <label class="w-full mt-0 uppercase text-lg font-semibold" translate>
      organization.lbl_time_settings
  </label>
  <br />
  <div class="mt-1 mb-3">
      <label class="help-text" translate>
          organization.lbl_enable_or_disable_specific_notifications_about_vehicles
      </label>
  </div>

  <div class="grid">
      <div class="col-12 md:col-12 lg:col-6">
          <label class="font-semibold" translate>organization.time_setting_for</label>
      </div>
      <div class="col-12 md:col-12 lg:col-6  md:justify-content-around lg:justify-content-end flex">
        <div class="col-4 md:col-4 lg:col-auto ml-0 lg:ml-3 mr-0 lg:mr-3 pl-2 time-header">
            <label class="font-semibold time-heading" translate>organization.lbl_hours</label>
        </div>
        <div class="col-4 md:col-4 lg:col-auto ml-0 lg:ml-3 mr-0 lg:mr-3 time-header">
            <label class="font-semibold time-heading" translate>organization.lbl_minutes</label>
        </div>
        <div class="col-4 md:col-4 lg:col-auto ml-0 lg:ml-3 mr-0 lg:mr-3 time-header">
            <label class="font-semibold time-heading" translate>organization.lbl_seconds</label>
        </div>
      </div>
  </div>
</div>
<hr class="hr-line" />

@for (field of group.fields; track field.attributeCode; let j = $index) {
  <div class="grid">
      <div class="col-12 md:col-12 lg:col-6 horizontal">
          <label class="flex labels">
              {{cs.getLabelValue(moduleName + '.fields.' + field.attributeCode + '.label', attributeLabels, field.attributeCode)}}
              @if (field.validation?.required) {
                  <span class="required">*</span>
              }
              @if (cs.getLabelValue(moduleName + '.fields.' + field.attributeCode + '.helpTips', attributeLabels, 0)) {
                  <app-mi-tooltip
                      [messageData]="cs.getLabelValue(moduleName + '.fields.' + field.attributeCode + '.helpTips', attributeLabels, field.attributeCode)"
                  ></app-mi-tooltip>
              }
          </label>
          <div class="grid">
            <div class="md:col-11 lg:col-12">
          <app-mi-error
              [field]="field"
              [attributeLabels]="attributeLabels"
              [moduleName]="moduleName"
          ></app-mi-error>
      </div>
      </div>
      </div>
      <div class="col ml-4 text-align-end ">
          <app-hour-minute-second [field]="field"></app-hour-minute-second>
      </div>
  </div>
  <hr class="hr-line" />
}
