<p-overlayPanel #overlayPanel [style]="{ width: '288px'}">
    <div>
        <div class="font-16 font-semibold default-text-black-color height-22">{{offDutyReason}}</div>
        @if(offDutyReason === 'Log Off') {
            <hr>
            <div>
                <span class="font-14 font-semibold default-text-black-color" translate>lbl_next_shift_location</span>
            </div>
        }
    </div>
</p-overlayPanel>
