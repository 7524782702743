import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../../shared/services/rest-api.service';
import { ConfigService } from '../../shared/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  constructor(
    private restApiService: RestApiService, private confService: ConfigService
  ) { }

  getAttributeDefinition(entityType, forTenantId): Observable<any> {
    return this.restApiService.get(' Attribute Definition', `${this.confService.getLoggedInTenantCode()}/api/v1/attributes/${entityType}?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  addRole(roleModel): Observable<any> {
    return this.restApiService.post('', '/secure/roles', roleModel, 'page-center');
  }

  getRole(roleId): Observable<any> {
    return this.restApiService.get('', '/secure/roles/' + roleId, 'page-center');
  }

  getRoles(): Observable<any> {
    return this.restApiService.get('', '/secure/roles', 'page-center');
  }

  getLoginUserRoles(): Observable<any> {
    return this.restApiService.get('', '/secure/user-roles', 'page-center');
  }

  getLoginUserCities(): Observable<any> {
    return this.restApiService.get('', '/secure/service-area/cities', 'page-center');
  }

  getAllRoleAccesses(): Observable<any> {
    return this.restApiService.get('', '/secure/accesses-list', 'page-center');
  }

  updateRole(roleId, roleModel): Observable<any> {
    return this.restApiService.put('', '/secure/roles/' + roleId, roleModel, 'page-center');
  }

  activeDeactiveRoleStatus(roleId, data): Observable<any> {
    return this.restApiService.put('', `/secure/roles/` + roleId + `/status`, data, 'page-center');
  }

  addUser(userModel): Observable<any> {
    return this.restApiService.post('', '/secure/users', userModel, 'page-center');
  }

  getUser(userId): Observable<any> {
    return this.restApiService.get('', '/secure/users/' + userId, 'page-center');
  }

  updatUser(userId, userModel): Observable<any> {
    return this.restApiService.put('', '/secure/users/' + userId, userModel, 'page-center');
  }

  updateUserStatus(userId, data): Observable<any> {
    return this.restApiService.put('', `/secure/users/` + userId + `/status`, data, 'page-center');
  }

  searchEntity(data) {
    return this.restApiService.post('SEARCH ENTITY', `${this.confService.getLoggedInTenantCode()}/api/v1/roles/search?forTenantCode=${this.confService.getForTenantCode()}`, data, 'page-center')
  }

  searchEntityUser(data) {
    return this.restApiService.post('SEARCH ENTITY', `${this.confService.getLoggedInTenantCode()}/api/v1/users/search?forTenantCode=${this.confService.getForTenantCode()}`, data, 'page-center')
  }

  deleteBulkActionStatus(data) {
    return this.restApiService.put('delete Roles', `${this.confService.getLoggedInTenantCode()}/api/v1/roles/bulk-delete`, data);
  }

  deleteBulkUserActionStatus(data) {
    return this.restApiService.put('delete Users', `${this.confService.getLoggedInTenantCode()}/api/v1/users/bulk-delete`, data);
  }

  updateBulkUserActionStatus(data) {
    return this.restApiService.put('Update Bulk Users', `${this.confService.getLoggedInTenantCode()}/api/v1/users/bulk-update`, data);
  }

  updateBulkRoleActionStatus(data) {
    return this.restApiService.put('Update Bulk Roles', `${this.confService.getLoggedInTenantCode()}/api/v1/roles/bulk-update`, data);
  }

  updateUserActionStatus(userId, data) {
    return this.restApiService.put('Update User Status', `${this.confService.getLoggedInTenantCode()}/api/v1/users/${userId}/action`, data, 'page-center');
  }

  updateRoleActionStatus(roleId, data) {
    return this.restApiService.put('Update Role Status', `${this.confService.getLoggedInTenantCode()}/api/v1/roles/${roleId}/action`, data, 'page-center');
  }

  AddNewUser(data: {}) {
    return this.restApiService.post('Save User', `${this.confService.getLoggedInTenantCode()}/api/v1/users`, data, 'page-center');
  }

  getUserDetails(userId: string) {
    return this.restApiService.get('User Detail', `${this.confService.getLoggedInTenantCode()}/api/v1/users/${userId}?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  EditUser(userId: string, data: {}) {
    return this.restApiService.put('Update User', `${this.confService.getLoggedInTenantCode()}/api/v1/users/${userId}`, data, 'page-center');
  }

  getPermissionMenus() {
    return this.restApiService.get('Permission Menus', `${this.confService.getLoggedInTenantCode()}/api/v1/menus?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  AddNewRole(data) {
    return this.restApiService.post('Save Role', `${this.confService.getLoggedInTenantCode()}/api/v1/roles`, data, 'page-center');
  }

  getRoleDetails(roleId: string) {
    return this.restApiService.get('Role Detail', `${this.confService.getLoggedInTenantCode()}/api/v1/roles/${roleId}?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  EditRole(roleId: string, data: {}) {
    return this.restApiService.put('Update Role', `${this.confService.getLoggedInTenantCode()}/api/v1/roles/${roleId}`, data, 'page-center');
  }

  resetPassword(userId: string, data: {}) {
    return this.restApiService.put('Reset Password', `${this.confService.getLoggedInTenantCode()}/api/v1/users/${userId}/reset-password`, data, 'page-center');
  }

}
