<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'USER_MANAGEMENT', subMenuCode: 'MANAGE_ROLES', pageCode: 'DETAILS', subPageCode: 'ADD' }">
<app-module-header [label]="btnLabel" [showImportExportBtn]="showImportExportBtn" (onBtnClick)="onAddRole()" [showLastSync]="false"></app-module-header>
</div>
<div appAccessProvider [accessCode]="{ menuCode: 'USER_MANAGEMENT', subMenuCode: 'MANAGE_ROLES', pageCode: 'DETAILS', subPageCode: 'LIST' }">
<p-tabView [(activeIndex)]="activeIndex" (onChange)="tabViewChange($event)">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-14 mr-2"></i>
      <span translate>lbl_details</span>
    </ng-template>
    <ng-template pTemplate="body">
        <app-mi-table
          [collectionOfColumns]="allColumns"
          [attributeLabels]="filterAttributeLabels"
          [data]="tableData"
          [isLoading]="isLoading"
          (onPageChange)="onPageChange($event)"
          (itemPerPageChange)="itemPerPageChange($event)"
          [message]="deleteRoleMsg"
          [header]="deleteRoleHeaderMsg"
          (onDelete)="onIncidentComplaintDelete($event)"
          (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
          (onRowSelection)="onRowSelect($event)"
          [filterList]="filterList"
          [filterFields]="filterFieldsArray"
          [label]="btnLabel"
          (onBtnClick)="onAddRole()"
          [attributeData]="attributeData"
          [rowItems]="rowItems"
          [bulkSelectionsItems] = "bulkSelectionsItems"
          [bulkUpdateLabel]="bulkUpdateLabel"
          [bulkUpdateHeader]="bulkUpdateHeader"
          [entityNameToDisplay]="entityNameToDisplay"
          [btnUpdate]="btnUpdate"
          [bulkDeleteLabel]="bulkDeleteLabel"
          (setCurrentRowData)="setCurrentRowData($event)"
          [showBulkUpdateDialog]="showBulkUpdateDialog"
          (onStaticBulkDataUpdateEvent)="onStaticBulkDataUpdate($event)"
          [reviewHeader]="reviewHeader"
          (onSearchValueChange)="onSearchValueChanges($event)"
          [totalRecords]="totalRecords"
          [visible]="visible"
          (onToggleOverLay)="showBulkUpdateDialog = false; visiblePrintLabel = false"
          [isEntity]="isEntity"
          [uniqueId]="uniqueId"
          (onChangeStatus)="onChangeStatus($event)"
          >
        </app-mi-table>
    </ng-template>
  </p-tabPanel>
</p-tabView>
</div>
