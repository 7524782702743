import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-booking-passenger-saved-address',
  standalone: true,
  imports: [TranslateModule, TableModule, InputTextModule, NgClass, FormsModule],
  templateUrl: './booking-passenger-saved-address.component.html',
  styleUrl: './booking-passenger-saved-address.component.scss'
})
export class BookingPassengerSavedAddressComponent {
  @Input() passengerId;
  miIcons = AppIcons;
  addressList;
  addressListTemp;
  searchValue: string = '';
  constructor(private entityService: EntityService,) { }

  ngOnInit(): void {
    this.getPassengerDetailsById();
  }

  getPassengerDetailsById() {
    this.entityService.getEntity(this.passengerId, AppSettings.ENTITY_CODE.PASSENGERS, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((result: any) => {
      const passengerAttributes = result.attributeCodeValueDtoList;
      const relatedData = result.relatedData;
      const stayHomeAddressSection = passengerAttributes.find(attr => attr.attributeCode === AppSettings.FIELDS.STAY_HOME_ADDRESS_SECTION);

      if (stayHomeAddressSection && Array.isArray(stayHomeAddressSection.attributeValue)) {
        const stayHomeAddressIds = stayHomeAddressSection.attributeValue;
        const matchedAddresses = stayHomeAddressIds.map(id => {
          const addressData = relatedData.find(rel =>
            rel.entityCode === AppSettings.ENTITY_CODE.PASSENGER_ADDRESS && rel.entityId === id
          );
          return addressData ? addressData.attributeCodeValueDtoList : null;
        });
        this.processAddresses(matchedAddresses);
      }
    });
  }

  processAddresses(matchedAddresses: any[]) {
    const validAddresses = matchedAddresses.filter(addr => addr !== null);
    this.addressList = validAddresses.map(address => {
      const addressMap: any = {};
      address.forEach(attr => {
        addressMap[attr.attributeCode] = attr.attributeValue;
      });
      return addressMap;
    });
    this.addressListTemp = this.addressList;
  }

  onSearch(event: any) {
    const value = event.target.value.toLowerCase();
    const minLength = 3;
    if (value.length >= minLength) {
      this.addressList = this.addressListTemp?.filter(address => {
        return Object.keys(address).some(key => {
          return address[key]?.toLowerCase().includes(value);
        });
      });
    } else {
      this.addressList = [...this.addressListTemp];
    }
  }

  clearSearch() {
    this.searchValue = "";
    this.addressList = [...this.addressListTemp];
  }

  removeAddress(event) {
    console.log(event)
  }

}
