
<div class="w-full formgrid grid pt-3">
  @for (field of group.fields; track field.locationType;let j = $index) {
  <div [ngClass]="field.locationType === selectedAddress ? 'show' : 'hide'"
    class="field col-12 md:col-11 lg:col-{{field.uiColumns}}">
    @if(field.inputCode !== 'spacer'){
    <label
      class="flex">{{cs.getLabelValue(moduleName + '.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
      @if (field.validation?.required) {
      <span class="required">*</span>
      }
      @if (cs.getLabelValue(moduleName + '.fields.'+field.attributeCode+'.helpTips',attributeLabels,0)) {
      <app-mi-tooltip [messageData]="cs.getLabelValue(moduleName
                          +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)">
      </app-mi-tooltip>
      }
    </label>
    @if (field.attributeCode === 'select_location_type') {
    <app-select-button-with-add-new [field]="field" [attributeLabels]="attributeLabels" [locationTypeList]="defaultLocationList"
      [selectedButton]="selectedAddress" [moduleName]="moduleName" (onNewAddressAdd)="onNewAddressAdd($event)"
      (onSelectButtonChange)="onSelectButtonChange($event)" (onAddressDelete)="onAddressDelete($event)" [group]="group"></app-select-button-with-add-new>
    } @else if (field.attributeCode === 'select_location') {
      <app-geo-point [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName"></app-geo-point>
    } @else if (field.attributeCode === 'postal_code') {
      <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName" [pKeyFilter]="pKeyFilter"></app-mi-fields>
    } @else if (field.attributeCode === 'select_location_geolocation') {
      <app-geo-transport [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName" [isShowBookingMap]="isShowBookingMap"></app-geo-transport>
     }
      @else {
    <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName" [showImg]="showImg"></app-mi-fields>
    }


    <app-mi-error [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName"></app-mi-error>
    }
  </div>
  }
</div>