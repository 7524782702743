<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'MANAGE_LOCATIONS', pageCode: 'DETAILS', subPageCode: 'ADD' }">
  <app-module-header [label]="btnLabel" (onBtnClick)="onAddLocations()" [moduleHeaderSecondaryBtnMenuItems]="moduleHeaderSecondaryBtnMenuItems" [showLastSync]="false"></app-module-header>
</div>
<div appAccessProvider [accessCode]="{ menuCode: 'MANAGE_LOCATIONS', pageCode: 'DETAILS', subPageCode: 'LIST' }">
<p-tabView [(activeIndex)]="activeIndex" (onChange)="tabViewChange($event)">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-14 mr-2"></i>
      <span translate>lbl_details</span>
    </ng-template>
    <ng-template pTemplate="body">
      @if(data){

        <app-mi-table
          [collectionOfColumns]="allColumns"
          [attributeLabels]="filterAttributeLabels"
          [data]="tableData"
          entityName="location"
          [isLoading]="isLoading"
          (onPageChange)="onPageChange($event)"
          (itemPerPageChange)="itemPerPageChange($event)"
          [totalRecords]="totalRecords"
          [message]="deleteVehicleMsg"
          [header]="deleteVehicleHeaderMsg"
          (onDelete)="onLocationDelete($event)"
          (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
          (onRowSelection)="onRowSelect($event)"
          [filterList]="filterList"
          (onValueChange)="onFilterValueChange($event)"
          [filterFields]="filterFieldsArray"
          [label]="btnLabel"
          (onBtnClick)="onAddLocations()"
          [bulkUpdateLabel]="bulkUpdateLabel"
          [bulkDeleteLabel]="bulkDeleteLabel"
          [bulkUpdateHeader]="bulkUpdateHeader"
          [attributeData]="attributeData"
          (onBulkDataUpdateEvent)="onBulkDataUpdateEvent($event)"
          (onSearchValueChange)="onSearchValueChanges($event)"
          (onUpdate)="onUpdateLocations($event)"
          [rowItems]="rowItems"
          [bulkSelectionsItems] = "bulkSelectionsItems"
          (setCurrentRowData)="setCurrentRowData($event)"
          [visiblePrintLabel]="visiblePrintLabel"
          [showBulkUpdateDialog]="showBulkUpdateDialog"
          [visible]="visible"
          (onToggleOverLay)="showBulkUpdateDialog = false; visiblePrintLabel = false"
          (onChangeStatus)="onChangeStatus($event)"
          [reviewHeader]="reviewHeader"
          [btnUpdate]="btnUpdate"
          [entityNameToDisplay]="entityNameToDisplay"
          >
        </app-mi-table>
      }
    </ng-template>
  </p-tabPanel>

</p-tabView>
</div>
<input hidden type="file" id="upload" (change)="onChange($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" #uploader>