import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { convertSeconds } from 'app/modules/shared/utility-functions/date';
import { InputNumberModule } from 'primeng/inputnumber';

@Component({
  selector: 'app-hour-minute-second',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputNumberModule],
  templateUrl: './hour-minute-second.component.html',
  styleUrl: './hour-minute-second.component.scss'
})
export class HourMinuteSecondComponent {
  @Input() field: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  formName: FormGroup;

  value: number = 0;
  hour: number = 0;
  minutes: number = 0;
  seconds: number = 0;
  placeholder: string;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    const hourMinutesSeconds: any = convertSeconds(this.formName.controls[this.field.attributeCode].value);
    this.hour = hourMinutesSeconds.hour;
    this.minutes = hourMinutesSeconds.minutes;
    this.seconds = hourMinutesSeconds.seconds;

  }

  convertSeconds(seconds) {
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    this.hour = hours;
    this.minutes = minutes;
    this.seconds = remainingSeconds;
  }

  onTimeInput() {
    this.setFormFieldValue();
  }

  setFormFieldValue() {
    const seconds = ((this.hour * 60) * 60) + (this.minutes * 60) + this.seconds;
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(seconds);
    this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode).updateValueAndValidity();
  }
}
