import { DatePipe, NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EntityService } from '../../../../../shared/services/entity.service';
import { AppSettings } from '../../../../../shared/app.settings';

@Component({
  selector: 'app-driver-tab',
  templateUrl: './driver-tab.component.html',
  styleUrl: './driver-tab.component.scss',
  standalone: true,
  imports: [NgClass, TranslateModule, DatePipe],
})
export class DriverTabComponent {
  @Input() driverDetailItem: any;
  driverDetailsById: any[] = [];
  HOUR_MINUTE: any = AppSettings.HOUR_MINUTE;
  constructor(private entityService: EntityService) { }

  ngOnInit(): void {
    this.getDriverDetailsById();
  }

  getDriverDetailsById() {
    this.entityService.getEntity(this.driverDetailItem.id, AppSettings.ENTITY_CODE.DRIVER, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((result: any) => {
      const driverDetails = {};
      result.attributeCodeValueDtoList.map(data => {
        driverDetails[data.attributeCode] = data.attributeValue;
      })
      this.driverDetailsById.push(driverDetails);
      const driverGroupId = driverDetails['driver_group_id'];
      if (driverGroupId) {
        this.getGroupDetailsById(driverGroupId);
      }
    })
  }

  getGroupDetailsById(driverGrpId) {
    this.entityService.getEntity(driverGrpId, AppSettings.ENTITY_CODE.DRIVER_GROUP, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((result: any) => {
      const groupDetails = {};
      result.attributeCodeValueDtoList.map(data => {
        groupDetails[data.attributeCode] = data.attributeValue;
      })
      this.driverDetailsById[this.driverDetailsById.length - 1]['groupDetails'] = groupDetails;
    })
  }

}
