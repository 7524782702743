import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import { SearchLocationDirective } from 'app/modules/shared/directives/search-location.directive';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-geo-search',
  templateUrl: './geo-search.component.html',
  styleUrl: './geo-search.component.scss',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DropdownModule, TranslateModule, ButtonModule, DialogModule, BlockUIModule, InputTextModule, AutoCompleteModule, SearchLocationDirective],
})
export class GeoSearchComponent {
  @Input() field: any;
  @Input() form: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Output() onAddressChange: EventEmitter<any> = new EventEmitter();
  defaultLbl: string;
  placeholder: any;
  map: google.maps.Map;
  infoWindow: google.maps.InfoWindow;
  visible: boolean = false;

  zoom = 4;
  position: any;
  cancelBtnText: any;
  saveBtnText: any;
  blockedDocument: boolean = false;
  newGeoLocation: any;
  newGeoAddress: any;
  premiseAddress;
  marker;
  showGoogleSearchBox: boolean = false;
  searchText: string = null;
  suggestions: any = [];
  selectedLocation;
  filteredLocations: any;
  markerlocation;

  constructor(
    private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
  ) {
    this.formName = formGroupDirective.control;

  }

  ngOnInit(): void {
    if (this.form) {
      this.formName = this.form;
    }
    if (this.moduleName === AppSettings.ENTITY_CODE.LOCATION) {
      this.selectedLocation = this.formName.controls[AppSettings.LOCATION_ATTRIBUTES.LOCATION_ADDRESS].value;
    }
    if (this.moduleName === AppSettings.ENTITY_CODE.EVENTS) {
      this.selectedLocation = this.formName.controls[AppSettings.EVENT_ATTRIBUTES.EVENT_ADDRESS].value;
    }
    if (this.moduleName === AppSettings.ENTITY_CODE.DRIVER) {
      this.selectedLocation = this.formName.controls[AppSettings.DRIVER_ATTRIBUTES.DRIVER_ADDRESS].value;
    }
    this.cancelBtnText = this.cs.getLabel("cancel");
    this.saveBtnText = this.cs.getLabel("label_save");
    this.defaultLbl = this.cs.getLabel("lbl_please_select");
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, this.field.attributeCode);
  }

  getAndStoreAddress(event: any, addressType?: any) {
    debugger
    this.selectedLocation = event;
    this.markerlocation = { lat: this.selectedLocation.lat, lng: this.selectedLocation.lng };

    const geoLocationValue = {
      address: this.selectedLocation.address,
      lat: this.selectedLocation.lat,
      lng: this.selectedLocation.lng
    };
    if (this.moduleName === AppSettings.ENTITY_CODE.LOCATION) {
      this.formName.controls[AppSettings.LOCATION_ATTRIBUTES.LOCATION_ADDRESS].setValue(geoLocationValue);
      this.formName.controls[AppSettings.LOCATION_ATTRIBUTES.LOCATION_GEOLOCATION].setValue(geoLocationValue.address);
    }
    if (this.moduleName === AppSettings.ENTITY_CODE.EVENTS) {
      this.formName.controls[AppSettings.EVENT_ATTRIBUTES.EVENT_ADDRESS].setValue(geoLocationValue);
      this.formName.controls[AppSettings.EVENT_ATTRIBUTES.EVENT_GEOLOCATION].setValue(geoLocationValue.address);
    }
    if (this.moduleName === AppSettings.ENTITY_CODE.DRIVER) {
      this.formName.controls[AppSettings.DRIVER_ATTRIBUTES.DRIVER_ADDRESS].setValue(geoLocationValue);
      this.formName.controls[AppSettings.DRIVER_ATTRIBUTES.DRIVER_GEOLOCATION].setValue(geoLocationValue.address);
    }
  }


  showDialog() {
    this.visible = true;
    this.blockedDocument = true;
    this.showGoogleSearchBox = true;
    this.initMap();
  }

  close() {
    this.visible = false;
    this.blockedDocument = false;
    this.showGoogleSearchBox = false;
  }

  saveGeoLocation() {
    const latLngArray = this.newGeoLocation.split(',');

    const geoLocationValue = {
      address: this.newGeoAddress,
      lat: latLngArray[0],
      lng: latLngArray[1]
    };
    this.selectedLocation = {
      address: this.newGeoAddress,
      lat: parseFloat(latLngArray[0]),
      lng: parseFloat(latLngArray[1])
    }
    if (this.moduleName === 'location') {
      this.formName.controls[AppSettings.LOCATION_ATTRIBUTES.LOCATION_ADDRESS].setValue(geoLocationValue);
      this.formName.controls[AppSettings.LOCATION_ATTRIBUTES.LOCATION_GEOLOCATION].setValue(this.newGeoAddress);
    }
    if (this.moduleName === 'event') {
      this.formName.controls[AppSettings.EVENT_ATTRIBUTES.EVENT_ADDRESS].setValue(geoLocationValue);
      this.formName.controls[AppSettings.EVENT_ATTRIBUTES.EVENT_GEOLOCATION].setValue(this.newGeoAddress);
    }


    this.visible = false;
    this.blockedDocument = false;
    this.showGoogleSearchBox = false;
  }

  async initMap(): Promise<void> {
    let markerPoint;
    if (this.selectedLocation) {
      markerPoint = { lat: this.selectedLocation.lat, lng: this.selectedLocation.lng };
    } else {
      markerPoint = { lat: 18.509863497519373, lng: 74.31908731567906 }
    }

    const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: markerPoint,
      zoom: 15,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapId: AppSettings.MAP_ID
    });

    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: markerPoint,
      gmpDraggable: true
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.position = {
            lat: (this.selectedLocation && this.selectedLocation.lat) ? this.selectedLocation.lat : position.coords.latitude,
            lng: (this.selectedLocation && this.selectedLocation.lng) ? this.selectedLocation.lng : position.coords.longitude,
          };
          marker.position = this.position;
          const latLong = this.position.lat + ', ' + this.position.lng;
          this.getFormattedAddress(latLong);
          marker.addListener('dragend', (event) => {
            const currentPosition = marker.position as google.maps.LatLng;
            this.position = { lat: currentPosition.lat, lng: currentPosition.lng };
            const latLong = this.position.lat + ', ' + this.position.lng;
            this.getFormattedAddress(latLong);
          });
          this.map.panTo(this.position);
        },
        () => {
          this.handleLocationError(true, this.infoWindow, this.map.getCenter());
        });
    } else {
      this.handleLocationError(false, this.infoWindow, this.map.getCenter());
    }
  }

  getFormattedAddress(latLong) {
    this.cs.getFormattedAddressFromLatLong(latLong, AppSettings.GOOGLE_API_KEY).subscribe((result: any) => {
      if (result.results && result.results.length > 0) {
        this.newGeoAddress = null;
        this.newGeoAddress = result.results[0].formatted_address;
        const { lat, lng } = result.results[0].geometry.location
        this.newGeoLocation = `${lat},${lng}`

      }
    })
  }

  handleLocationError(browserHasGeolocation, infoWindow, pos) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation.",
    );
    infoWindow.open(this.map);
  }

}
