@if (img && showImg) {
  <app-mi-image-container class="img-preview" [miImage]="img" miHeight="170" miWidth="170" id="preview"></app-mi-image-container>
}

<form [formGroup]="formName">
  <p-fileUpload
    class="cursor-pointer"
    mode="advanced"
    [chooseLabel]="chooseLabel"
    (onSelect)="onUpload($event)"
    customUpload="true"
    [accept]="accept"
    [maxFileSize]="maxFileSize"
    [multiple]="isMultipleFilesUpload"
    [showCancelButton]="false"
    [showUploadButton]="false"
    [chooseIcon]="chooseIcon"
  >
    @if (moduleName === 'vehicle_document' || moduleName === 'driver_document') {
      <ng-template pTemplate="content" let-files>
        <div class="drop-area"> {{ dropAreaMsgForDocument }}</div>
      </ng-template>
    }
    @else {
      <ng-template pTemplate="content" let-files>
        <div class="drop-area"> {{ dropAreaMsg }}</div>
      </ng-template>
    }
  </p-fileUpload>
</form>

@if(moduleName === 'vehicle_document' || moduleName === 'driver_document') {
  <div>
    <label class="file-upload-instruction" translate>lbl_img_pdf_upload_instruction</label>
  </div>
}
@else {
  <div>
    <label class="file-upload-instruction" translate>lbl_img_upload_instruction</label>
  </div>
}

@if (uploadedFiles.length > 0) {
  <div class="col-{{field.uiColumns === 12 ? 6 : 12}} file-list-div pl-0">
    <div class="card list">
        <div class="col-12 p-0 file-list px-2 py-2">
            <label>Uploaded Files</label>
        </div>
        <div class="grid flex ml-2 mr-2">
          @for (file of uploadedFiles; track file) {
            <div [class]="moduleName === entityType.GENERAL_SETTINGS ? 'col-12' : 'col-6'" class="inline-flex mt-3 mb-2">
              <div class="flex justify-content-between image-details w-full">
                <div class="flex justify-content-start">
                  <div class="flex align-items-center ml-2">
                    @if(file?.file?.type !== 'application/pdf') {
                      <img src="../../../../../../assets/images/mi-theme/light/basic/image.svg">
                    }
                    @else {
                      <img src="../../../../../../assets/images/mi-theme/light/basic/pdf-logo.svg">
                    }
                  </div>
                  <div class="flex  align-items-center ml-2">
                    @if (moduleName === 'vehicle_document' || moduleName === 'driver_document') {
                      <label>{{ file.file.name }}</label>
                    }
                    @else {
                      <label>{{ file.file.name }} - {{ file.file.size }} bytes</label>
                    }
                  </div>
                </div>
                <div class="flex justify-content-between align-items-center ml-2">
                    <img (mouseover)="showCloseImageIcon()" (mouseout)="showCheckImageIcon()" src="../../../../../../assets/images/mi-theme/light/basic/basic_circle_check.svg">
                    <img (mouseover)="showCloseImageIcon()" (mouseout)="showCheckImageIcon()" (click)="removeImage(file)" class="close-icon" src="../../../../../../assets/images/mi-theme/light/basic/off_close.svg">
                    @if (moduleName === 'vehicle_document' || moduleName === 'driver_document') {
                      <div class="cursor-pointer mr-1 mt-1" (click)="downloadDocument(file)">
                        <i class="{{miIcons.DOWNLOAD}} wh-18 inline-block bg-default-text-blue"></i>
                      </div>
                    }
                </div>
              </div>
            </div>
          }
        </div>
    </div>
  </div>
}

<p-toast key="fileupload" position="top-right"></p-toast>
<input style="display: none;" type="text" pInputText [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" class="w-full"/>
