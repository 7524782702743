import { DatePipe, NgClass } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { RadioButtonModule } from "primeng/radiobutton";
import { AppIcons } from "../../../../../shared/app.icons";
import { AppSettings } from "../../../../../shared/app.settings";

@Component({
  selector: "app-booking-tab",
  templateUrl: "./booking-tab.component.html",
  styleUrls: ["./booking-tab.component.scss"],
  standalone: true,
  imports: [RadioButtonModule, FormsModule, NgClass, TranslateModule, DatePipe],
})
export class BookingTabComponent implements OnInit {
  @Input() bookingDetailItem: any;
  @Input() dispatchBookingDetails;
  assignType: string = "Auto";
  miIcons = AppIcons;
  TRANSPORTATION_TYPE = AppSettings.TRANSPORTATION_TYPE;
  TRANSPORTATION_MODE = AppSettings.TRANSPORTATION_MODE;

  constructor() { }

  ngOnInit(): void { }
}
