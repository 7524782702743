import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { filter, takeWhile } from 'rxjs/operators';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-mi-select-with-other',
  templateUrl: './mi-select-with-other.component.html',
  styleUrls: ['./mi-select-with-other.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DropdownModule, TranslateModule, InputTextModule, ButtonModule]
})
export class MiSelectWithOtherComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  alive = true;
  defaultLbl: string;
  placeholder: any;
  selectedValue: string = undefined;

  constructor(
    private messageService: MessageService,
    private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef) {
    this.formName = formGroupDirective.control;
  }

  ngOnInit(): void {
    this.defaultLbl = this.cs.getLabel("lbl_please_select")
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, null);
    // this.field.presetValues.push({ labelKey: 'Other', labelValue: 'Other' });
    this.cd.markForCheck();
  }

  ngAfterViewInit(): void {
    this.listenForLinkData();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  listenForLinkData() {
    if (!this.field?.dependsOnAttributeId) {
      return;
    }
    this.messageService.message$.pipe(
      filter(v => v.dependsOnAttributeId === this.field.dependsOnAttributeId),
      takeWhile(() => this.alive)
    ).subscribe((v) => {
      this.field.options = v.data
    });
    this.cd.markForCheck();
  }

  changedValue(value: string) {

    this.selectedValue = value;
    if (this.selectedValue === "Other") {
      this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(undefined);
    } else {
      this.cd.markForCheck();
    }
  }

}

