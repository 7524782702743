<app-dashboard-navbar></app-dashboard-navbar>

<div appAccessProvider [accessCode]="{ menuCode: 'DASHBOARD', pageCode: 'ACTIVITY_FEED' }">
  <app-filter-section (onSearchValueChange)="onSearchValueChanges($event)" [filterList]="filterList"
    (onValueChange)="onFilterValueChange($event)" [attributeLabels]="attributeLabels"></app-filter-section>

  <div class="bg-white accordion-container">
    <p-accordion>
      @for(node of accordionTabs; track node){
      <p-accordionTab>
        <ng-template pTemplate="header">
          <div>
            <i [ngClass]="node.icon" class="mi-lg inline-block vertical-align-text-bottom wh-20"></i>
            <span [ngClass]="node.labelClass" class="font-14 font-semibold mr-3">{{ node.label }}</span>
            @if(node.label === "Cancelled"){
              <span class="font-13 default-print-label-color font-normal mr-3 more-horizontal-icon pxy-2-5" translate>
                dashboard.vehicle_transfer
              </span>
            }
            <span class="default-text-light-black-color font-14 font-normal mr-1" translate>dashboard.by</span>
            <span class="font-14 font-semibold default-text-black-color mr-1">{{node.adminName}}</span>
            <span class="default-text-light-black-color font-14 font-normal">
              ({{node.roleName}})</span>
            <i class="mi-lg mi-ellipse inline-block wh-10 mx-3 bg-default-e00"></i>
            <span class="default-print-label-color font-14 font-normal">{{node.timeAgo}}</span>
            <i class="mi-lg mi-redirect_feed inline-block wh-20 ml-3 vertical-align-text-bottom bg-redirect-icon"
              (click)="goToBookingDetails(node.data.bookingId)"></i>
          </div>
        </ng-template>

        <ng-template pTemplate="content">
          @if(node.data){
          <div>
            <div class="mb-13">
              <span class="font-14 default-print-label-color">#{{ node.data.bookingId }}</span>
              <i class="mi-lg mi-private_ride wh-28 relative top-4 inline-block vertical-align-text-bottom ml-2"></i>
              <span class="font-14 font-semibold default-text-black-color mr-1">
                {{ node.data.owner_passenger_name }}, +{{node.data.number_of_passenger}}
              </span>
              <span class="font-13 mr-2 default-print-label-color">({{node.data.owner_pass_type}})</span>
              <i class="mi-lg mi-watchlist wh-16 inline-block vertical-align-text-bottom"></i>
            </div>
            <div class="flex flex-row">
              <div class="mt-1px">
                <i class="mi-lg mi-activity_feed_route_icon wh-20-45 inline-block bg-route-icon"></i>
              </div>
              <div class="flex flex-column">
                <p class="mb-6px default-text-light-black-color">
                  {{node.data.booking_pickup_location_address }}
                </p>
                <p class="default-text-light-black-color">
                  {{node.data.booking_dropoff_location_address}}
                </p>
              </div>
            </div>
          </div>
          }
        </ng-template>
      </p-accordionTab>
      }
    </p-accordion>
  </div>
</div>
