@if (showManageWidgets) {
    <p-overlayPanel #overlayPanel>
        <div class="overlay-panel-content">
            <div class="flex justify-content-between align-items-center mb-4">
                <div>
                    <span class="font-16 font-semibold default-text-black-color" translate>dashboard.widgets</span>&nbsp;
                    <span>({{manageWidgetCards.length}})</span>
                </div>
                <button class="border-none bg-white flex align-items-center cursor-pointer" (click)="restoreAllCards()" title="Restore">
                    <span class="mr-2 font-14 font-normal default-text-blue-color" translate>dashboard.restore</span>
                    <i class="mi-lg mi-refresh inline-block wh-20 bg-default-text-blue"></i>
                </button>
            </div>
        
            <!-- <div class="flex align-items-center mt-2">
                <p-inputSwitch [(ngModel)]="reorganizeAutomatically" class="mr-2"></p-inputSwitch>
                <span translate>dashboard.reorganize_automatically</span>
                <i class="pi pi-info-circle ml-2" title="Icon"></i>
            </div> -->
        
            <div class="checkbox-group mt-3">
                <div class="flex flex-column">
                    @for(card of manageWidgetCards; track card) {
                        <div class="mb-3">
                            <p-checkbox [(ngModel)]="card.isChecked" [label]="card.label" binary="true"
                                (onChange)="onCheckboxChange(card)">
                            </p-checkbox>
                        </div>
                    }
                </div>
            </div>
        </div>
    </p-overlayPanel>
}
