import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { MenuItem } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { TabViewModule } from 'primeng/tabview';
import { AppIcons } from '../../../shared/app.icons';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { LeaveManagementService } from '../../services/leave-management.service';
import { LeaveHistoryComponent } from '../leave-history/leave-history.component';
import { LeaveRequestComponent } from '../leave-request/leave-request.component';
import type { LeaveDetailsModal } from '../../models/leaveDetails.models';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-leave-details',
  standalone: true,
  imports: [CardModule, AvatarModule, DividerModule, MITableComponent, TranslateModule, TabViewModule, LeaveRequestComponent, LeaveHistoryComponent, BreadcrumbModule, ButtonModule, AccessProviderDirective],
  templateUrl: './leave-details.component.html',
  styleUrl: './leave-details.component.scss'
})
export class LeaveDetailsComponent {
  miIcons = AppIcons;
  leaveId: string;
  casualCount: number;
  earnedCount: number;
  sickCount: number;
  btnLabel: string;
  routePath: MenuItem[] = [];
  driverEntityId: string;
  driverFullName: string;
  uniqueId: string;
  emailId: string;
  callSign: string;
  driverInitials: string;
  leaveRequestCount: number;
  leaveHistoryCount: number;
  profileImage;
  dropdownList: any[] = [];
  labels: { labelKey: string; labelValue: string }[] = [];

  constructor(private leaveManagementService: LeaveManagementService, private router: Router, private configService: ConfigService,
    private route: ActivatedRoute, private cs: CommonBindingDataService, private entityService: EntityService) { }

  ngOnInit() {
    this.setDropdownValues();
    this.getLeaveId();
    this.setLabels();
    this.getBreadcrumb();
  }

  private getLeaveId() {
    this.leaveId = this.route.snapshot.paramMap.get('id');
    if (this.leaveId) {
      this.leaveManagementService.getLeaveByLeaveId(this.leaveId).subscribe((res: any) => {
        this.driverEntityId = res.driverEntityId;
        if (this.driverEntityId) {
          this.getEntityById(this.driverEntityId);
          this.getLeaveCountByDriverId(this.driverEntityId);
          this.getDetailsLeaveCountByDriverId();
        }
      })
    }
  }

  setDropdownValues() {
    const reqBody = {
      forTenantCode: this.configService.getForTenantCode(),
      attributeCode: "leave_type",
      searchStr: "",
      usageRequired: false
    }
    this.leaveManagementService.getLeaveTypes(reqBody).subscribe((res: any) => {
      this.labels = res.data.map(item => ({
        labelKey: item.labelKey,
        labelValue: item.labelValue
      }));
    })
  }

  private getLeaveCountByDriverId(driverEntityId) {
    this.leaveManagementService.getLeaveCountByDriverId(driverEntityId).subscribe((res: any) => {
      res.forEach(item => {
        if (item.leaveType === this.labels[0].labelKey) {
          this.casualCount = item.count;
        } else if (item.leaveType === this.labels[1].labelKey) {
          this.earnedCount = item.count;
        } else if (item.leaveType === this.labels[2].labelKey) {
          this.sickCount = item.count;
        }
      });
    })
  }

  getDetailsLeaveCountByDriverId() {
    this.leaveManagementService.getDetailsLeaveCountByDriverId(this.driverEntityId).subscribe((res: any) => {
      this.leaveRequestCount = res?.counts?.Request;
      this.leaveHistoryCount = res?.counts?.History;
    })
  }

  private setLabels() {
    this.btnLabel = this.cs.getLabel('lbl_new_leave');
  }

  private getBreadcrumb() {
    this.routePath = [{
      label: "Request",
      routerLink: '../../',
      icon: 'pi pi-arrow-left',
      iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
    },
    {
      label: "Overview",
      styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
      style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
    }];
  }

  getEntityById(driverEntityId) {
    this.entityService.getEntity(driverEntityId, AppSettings.ENTITY_CODE.DRIVER, AppSettings.VIEW_CODE.DETAIL_VIEW).subscribe((res: LeaveDetailsModal) => {
      const attributes = this.extractAttributes(res);
      this.driverInitials = this.extractInitials(attributes.firstName, attributes.lastName);
      this.driverFullName = attributes.firstName + ' ' + attributes.lastName;
      this.uniqueId = attributes.uniqueId;
      this.emailId = attributes.emailId;
      this.callSign = attributes.callSign;
      if (attributes.driverProfileImg) {
        this.getFile(attributes.driverProfileImg)
      }
    });
  }

  getFile(rowData) {
    this.entityService.getFile(rowData[0], AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = () => {
        const base64data = reader.result;
        this.profileImage = base64data;
      }
    })
  }

  extractAttributes(entityData: LeaveDetailsModal) {
    const firstName = entityData.attributeCodeValueDtoList.find(attr => attr.attributeCode === 'first_name')?.attributeValue || '';
    const lastName = entityData.attributeCodeValueDtoList.find(attr => attr.attributeCode === 'last_name')?.attributeValue || '';
    const uniqueId = entityData.attributeCodeValueDtoList.find(attr => attr.attributeCode === 'unique_id')?.attributeValue || '';
    const emailId = entityData.attributeCodeValueDtoList.find(attr => attr.attributeCode === 'email')?.attributeValue || '';
    const callSign = entityData.attributeCodeValueDtoList.find(attr => attr.attributeCode === 'call_sign_code')?.attributeValue || '';
    const driverProfileImg = entityData.attributeCodeValueDtoList.find(attr => attr.attributeCode === 'driver_profile_image')?.attributeValue || this.driverInitials;

    return { firstName, lastName, uniqueId, emailId, callSign, driverProfileImg };
  }

  extractInitials(firstName: string, lastName: string): string {
    const firstNameInitial = firstName ? firstName.charAt(0).toUpperCase() : '';
    const lastNameInitial = lastName ? lastName.charAt(0).toUpperCase() : '';
    return firstNameInitial + lastNameInitial;
  }

  onAddNewLeave() {
    this.router.navigate(["app/driver-leave-management/new-leave"]);
  }

}
