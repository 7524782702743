<div class="grid mt-2 ml-3 mr-3 overview-details">
    <div class="col-6 pl-0">

        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase text-lg" translate>manage_locations.lbl_details</label>
                </div>
                <div appAccessProvider [accessCode]="{ menuCode: 'MANAGE_LOCATIONS', pageCode: 'DETAILS', subPageCode: 'EDIT' }">
                    <div class="edit flex justify-content-center mb-3" (click)="redirectToEdit()">
                        <label class="card-action" translate>vehicle.lbl_edit</label>
                        <i class="mi-lg edit_pencil"></i>
                    </div>
                </div>
            </div>
            @for (field of details.fields; track field; let i = $index) {
                @if (i !== 0) {
                    <hr class="hr-line">
                }
            <div class="grid">
                <div class="col-6">
                    <label class="detail-heading" translate>{{cs.getLabelValue('location.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-6">
                    @if (field.attributeCode === 'location_status') {
                        <div class="status flex align-items-center">
                            <span [class]="attributeValues[field.attributeCode]?.toLowerCase()"></span>
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                        </div>
                    } @else {
                        <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                        @if(field.imperialUnit && attributeValues[field.attributeCode]){
                            {{getUnit(field)}}
                        }
                    }
                </div>
            </div>
        }
        </div>

    </div>
    <div class="col-6 pr-0">
        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase text-lg" translate>manage_locations.lbl_address</label>
                </div>
            </div>
           <div class="map-div" id="map"></div>
           <div class="col-12 mt-3 p-0">
                <label class="address font-sm text-400">{{ address }}</label>
            </div>
        </div>
    </div>
</div>
