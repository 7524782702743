import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { EditorModule } from 'primeng/editor';
import { TabViewModule } from 'primeng/tabview';
import { SettingsService } from 'app/modules/settings/settings.service';
import { AppSettings } from '../../../shared/app.settings';
import { AppIcons } from 'app/modules/shared/app.icons';

@Component({
  selector: 'app-terms-and-conditions',
  standalone: true,
  imports: [EditorModule, TranslateModule, BreadcrumbModule, ButtonModule, TabViewModule, FormsModule, ReactiveFormsModule],
  templateUrl: './terms-and-conditions.component.html',
  styleUrl: './terms-and-conditions.component.scss'
})
export class TermsAndConditionsComponent {
  btnLabel: string;
  routePath: MenuItem[] = [];
  termsForm: FormGroup;
  addBtnLabel: string;
  miIcons = AppIcons;

  constructor(private cs: CommonBindingDataService,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private messageService: MessageService,
    private configService: ConfigService) {

  }


  ngOnInit(): void {
    this.setLabels();
    this.setRoutePath();
    this.initForm();
    this.getTermsAndConditions();
  }

  private setLabels() {
    this.addBtnLabel = this.cs.getLabel('label_save');
  }

  private initForm() {
    this.termsForm = this.fb.group({
      termsAndConditions: ['', Validators.required],
    });
  }

  private setRoutePath() {
    this.routePath = [
      { label: this.cs.getLabel('content.lbl_terms_and_conditions'), routerLink: 'content/terms-and-conditions' },
    ];
  }


  onCancel() {
    this.termsForm.reset();
    this.termsForm.patchValue({
      termsAndConditions: ''
    });
    this.addBtnLabel = this.cs.getLabel('label_save');
  }

  onAdd() {
    if (this.termsForm.valid) {
      const termsContent = this.termsForm.get('termsAndConditions')?.value;
      const termsData = {
        forTenantCode: this.configService.getForTenantCode(),
        contentType: AppSettings.STATIC_CONTENT.TERMS_AND_CONDITION,
        staticContentReqDto: [
          {
            contentType: AppSettings.STATIC_CONTENT.TERMS_AND_CONDITION,
            roleCode: "driver",
            content: termsContent
          }
        ]
      };
      this.settingsService.updateStaticContentInfo(termsData).subscribe(res => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('settings.terms_and_cond_update_msg') });
      }, (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: error.errors.general[0].message });
      });
    } else {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('settings.lbl_please_fill_the_form') });
    }
  }

  getTermsAndConditions() {
    const contentType = AppSettings.STATIC_CONTENT.TERMS_AND_CONDITION;
    this.settingsService.getStaticContentData(contentType).subscribe((res: any) => {
      if (res && res.length > 0) {
        const staticContentReqDto = res[0]?.staticContentReqDto;
        if (staticContentReqDto && staticContentReqDto.length > 0) {
          const content = staticContentReqDto.find(dto => dto.contentType === AppSettings.STATIC_CONTENT.TERMS_AND_CONDITION)?.content;

          if (content) {
            this.termsForm.patchValue({
              termsAndConditions: content
            });
          }
        }
        this.addBtnLabel = this.cs.getLabel('content.lbl_update');
      }
    });
  }

}
