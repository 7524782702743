import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { AppSettings } from '../../shared/app.settings';
import { BasicTableComponent } from '../../shared/components/basic-table/basic-table.component';
import { MiFormComponent } from '../../shared/components/mi-form/mi-form.component';
import { Country } from '../../shared/models/country';
import { Language } from '../../shared/models/language';
import { CommonBindingDataService } from '../../shared/services/common-binding-data.service';
import { ConfigService } from '../../shared/services/config.service';
import type { AttributeData } from '../../vehicles/models/attribute.models';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Countries } from 'app/modules/shared/countries';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { GeoSearchComponent } from 'app/modules/shared/components/mi-fields/geo-search/geo-search.component';
import { MiUploadFileComponent } from 'app/modules/shared/components/mi-fields/mi-upload-file/mi-upload-file.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { EventService } from '../services/event.service';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { JsonPipe } from '@angular/common';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-add-event',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, FormsModule, ReactiveFormsModule,
    BasicTableComponent, ButtonModule, TranslateModule, InputTextModule, DropdownModule, GeoSearchComponent,
    MiUploadFileComponent, MultiSelectModule, CalendarModule, InputNumberModule, InputTextareaModule,
    JsonPipe,  AccessProviderDirective],
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss', '../../shared/components/mi-fields/mobile-input//flags.scss'],
  providers: [
    CommonBindingDataService,
  ]
})
export class AddEventComponent {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  activeIndex: number = 0;
  data: AttributeData;
  eventAttributeData: any;
  routePath: MenuItem[] = [];
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  eventAttributeLength;
  eventId: string;
  attributeValues = [];
  nextBtnLabel;
  eventEntityData: any;
  multiSelectDisplay: string = 'chip';
  dateFormat: any = AppSettings.DATE_FORMAT_DD_MMM_YYYY;
  imgFileId: any;
  showImgUpload: boolean = true;


  addEventForm: FormGroup;
  selectedCountryCodeForTransportSuperVisor: any;
  showTransportSupervisorContactNumberValidationError: boolean = false;
  showEmergencyContactNumberValidationError: boolean = false;
  selectedCountryCodeForEmergencyNumber: any;
  countries: any[] | undefined;
  filterIcon = 'pi pi-search';
  geoLocationAddressField: any;
  imgField: any;
  transportationTypeList: any[] = [];
  currentDate: Date = new Date();
  eventDetails: any;
  timeSettingsAttributeData: any;
  attributeLabelsForTimeSettings = {};
  timeSettingsModuleName = AppSettings.ENTITY_TYPE.TIME_SETTINGS;
  previousBtnLabel: any = this.cs.getLabel('lbl_cancel');
  saveTimeSettingsBtnLabel: any = this.cs.getLabel('settings.lbl_save_time_settings');
  dateFormatList: any = [];
  showGeoLocation: boolean = true;
  transportationStartDate: Date = new Date();
  transportationEndDate: Date = new Date();

  constructor(private eventService: EventService,
    private entityService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private configService: ConfigService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.eventId = this.route.snapshot.paramMap.get('id');
    this.setImgField();
    this.initializeForm();
    this.setLabels();
    this.setAppSettings();
    this.setRoutePath();
    this.setCountries();
    this.setGeoLocationField();
    this.getAttributesForTimeSetting();
    this.setDateFormatList();
    this.getCommonPresetLabels();
  }

  getCommonPresetLabels() {
    let requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      attributeCode: 'transportation_type',
      searchStr: '',
      usageRequired: true
    }
    this.entityService.getCommonPresetLabels(requestBody).subscribe((result: any) => {
      console.log(result);
      this.transportationTypeList = result.data.map(res => {
        return {
          labelValue: res.labelValue,
          labelKey: res.labelKey,
        }
      })
    })
  }

  setDateFormatList() {
    this.dateFormatList = [
      {
        name: 'MM/DD/YYYY',
        value: 'MM/DD/YYYY'
      },
      {
        name: 'DD/MM/YYYY',
        value: 'DD/MM/YYYY'
      }
    ]
  }

  setImgField() {
    this.imgField = {
      attributeId: AppSettings.ATTRIBUTE_ID_FOR_IMAGE_UPLOAD_IN_ORGANIZATION,
      attributeCode: 'event_image',
      attributeValue: null,
      inputCode: 'fileImg',
      imgId: null
    }
  }

  fileUploadEvent(event) {
    this.imgFileId = event[0];
  }

  setGeoLocationField() {
    this.geoLocationAddressField = {
      attributeCode: 'event_geolocation'
    };
    this.attributeLabels = {
      'event.fields.event_address.placeholder': 'select address'
    }
  }

  setCountries() {
    this.countries = Countries.COUNTRY_CODE;
    this.selectedCountryCodeForTransportSuperVisor = this.selectedCountryCodeForEmergencyNumber = this.countries[AppSettings.DEFAULT_COUNTRY_CODE];
  }

  initializeForm() {
    this.addEventForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(AppSettings.NAME_SPACE_PATTERN2)]],
      shortCode: ['', [Validators.required, Validators.pattern(AppSettings.SHORT_CODE)]],
      transportSupervisorNumberCountryCode: ['', [Validators.required]],
      transportSupervisorNumber: ['', [Validators.pattern(AppSettings.NUMBER_PATTERN), Validators.maxLength(10), Validators.minLength(10)]],
      emergencyContactNumberWithCountryCode: ['', [Validators.required]],
      emergencyContactNumber: ['', [Validators.pattern(AppSettings.NUMBER_PATTERN), Validators.maxLength(10), Validators.minLength(10)]],
      description: [''],
      event_address: ['', [Validators.required]],
      event_geolocation: ['', [Validators.required]],
      logo: [''],
      transportationType: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      startBookingDate: ['', [Validators.required]],
      endBookingDate: ['', [Validators.required]],
      operatingRadius: ['', [Validators.required]],
    });
    this.getEventDetails();
  }

  onSelectCountryCodeForTransportSupervisor(event): void {
    const transportSupervisorNumber = this.addEventForm.controls['transportSupervisorNumber'].value;
    const mobileNumber: any[] = [this.selectedCountryCodeForTransportSuperVisor?.value, transportSupervisorNumber];
    this.addEventForm.controls['contactNumberWithCountryCode'].setValue(mobileNumber.join('-'));
    this.addEventForm.get('contactNumberWithCountryCode').updateValueAndValidity();

    if (transportSupervisorNumber && (transportSupervisorNumber.length > AppSettings.MAX_MOBILE_LENGTH || transportSupervisorNumber.length < AppSettings.MAX_MOBILE_LENGTH)) {
      this.showTransportSupervisorContactNumberValidationError = true;
    } else {
      this.showTransportSupervisorContactNumberValidationError = false;
    }
  }

  getPhoneNumberWithCountryCodeForTransportSupervisor() {
    const mobileNumber: any[] = [this.selectedCountryCodeForTransportSuperVisor?.value, this.addEventForm.controls['transportSupervisorNumber'].value];
    this.addEventForm.controls['transportSupervisorNumberCountryCode'].setValue(this.selectedCountryCodeForTransportSuperVisor?.value);
    this.addEventForm.get('transportSupervisorNumberCountryCode').updateValueAndValidity();
  }

  onSelectCountryCodeForEmergencyNumber(event): void {
    const emergencyNumber = this.addEventForm.controls['emergencyContactNumber'].value;
    const mobileNumber: any[] = [this.selectedCountryCodeForEmergencyNumber?.value, emergencyNumber];
    this.addEventForm.controls['emergencyContactNumberWithCountryCode'].setValue(this.selectedCountryCodeForEmergencyNumber?.value);
    this.addEventForm.get('emergencyContactNumberWithCountryCode').updateValueAndValidity();

    const isValidLength = emergencyNumber && 
    emergencyNumber.length === AppSettings.MAX_MOBILE_LENGTH;
    this.showEmergencyContactNumberValidationError = !isValidLength;;
  }

  getPhoneNumberWithCountryCodeForEmergencyNumber() {
    const mobileNumber: any[] = [this.selectedCountryCodeForEmergencyNumber?.value, this.addEventForm.controls['emergencyContactNumber'].value];
    this.addEventForm.controls['emergencyContactNumberWithCountryCode'].setValue(this.selectedCountryCodeForEmergencyNumber?.value);
    this.addEventForm.get('emergencyContactNumberWithCountryCode').updateValueAndValidity();
  }

  onStartDateSelect(event) {
    console.log(event);
    this.transportationStartDate = event;
  }

  onEndDateSelect(event) {
    console.log(event);
    this.transportationEndDate = event;
  }

  onStartBookingDateSelect(event) {
    
  }

  onEndBookingDateSelect(event) {

  }

  getEventDetails() {
    if (!this.eventId) {
      return;
    }
    this.eventService.getEvent(this.eventId).subscribe( result => {
      this.eventDetails = result;
      this.setEventData();
    });
  }

  setEventData() {
    let transportationType = JSON.parse(this.eventDetails.transportationType);
    this.addEventForm.controls['name'].setValue(this.eventDetails.eventName);
    this.addEventForm.controls['shortCode'].setValue(this.eventDetails.eventShortcode);
    this.addEventForm.controls['transportSupervisorNumber'].setValue(this.eventDetails.transportSupervisorNumber);
    this.addEventForm.controls['emergencyContactNumber'].setValue(this.eventDetails.emergencyNumber);
    this.addEventForm.controls['transportSupervisorNumberCountryCode'].setValue(this.eventDetails.transportSupervisorNumberCountryCode);
    this.addEventForm.controls['emergencyContactNumberWithCountryCode'].setValue(this.eventDetails.emergencyNumberCountryCode);
    this.selectedCountryCodeForTransportSuperVisor = this.countries.find(ele => ele.value === this.eventDetails.transportSupervisorNumberCountryCode);
    this.selectedCountryCodeForEmergencyNumber = this.countries.find(ele => ele.value === this.eventDetails.emergencyNumberCountryCode);
    this.addEventForm.controls['description'].setValue(this.eventDetails.eventDescription);
    this.addEventForm.controls['transportationType'].setValue(transportationType);
    this.addEventForm.controls['startDate'].setValue(new Date(this.eventDetails.eventStartDateTime));
    this.addEventForm.controls['endDate'].setValue(new Date( this.eventDetails.eventEndDateTime));
    this.addEventForm.controls['startBookingDate'].setValue(new Date(this.eventDetails.startBookingDatePassengerApp));
    this.addEventForm.controls['endBookingDate'].setValue( new Date(this.eventDetails.stopBookingDatePassengerApp));
    this.addEventForm.controls['operatingRadius'].setValue(this.eventDetails.serviceAreaRadius);
    this.imgFileId = this.eventDetails.eventLogoImgId;
    this.setGeoLocation();
    this.imgField.imgId = this.eventDetails.eventLogoImgId;
    this.showImgUpload = false;
    this.cd.detectChanges();
    this.showImgUpload = true;
  }

  setGeoLocation() {
    const geoLocationValue = {
      address: this.eventDetails.eventAddress,
      lat:  this.eventDetails.latitude,
      lng: this.eventDetails.longitude,
    };
    this.addEventForm.controls['event_address'].setValue(geoLocationValue);
    this.addEventForm.controls['event_geolocation'].setValue(this.eventDetails.eventAddress);
    this.showGeoLocation = false;
    this.cd.detectChanges();
    this.showGeoLocation = true;
  }

  setLabels() {
    this.nextBtnLabel = this.eventId ? this.cs.getLabel('label_update') : this.cs.getLabel('lbl_save');
  }

  setAppSettings() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.moduleName = AppSettings.ENTITY_TYPE.EVENTS;
  }

  setAddressInPresetValuesIfNotPresent() {
    const addressField = this.eventAttributeData.tabs[0].groups[1].fields.find(element => element.attributeCode === 'address');
    if (!addressField) {
      return;
    }
    const addressPresent = addressField.presetValues.find(element => element.labelKey === this.eventDetails.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'address')?.attributeValue);
    this.eventAttributeData.tabs[0].groups[1].fields.forEach(element => {
      if (element.attributeCode === 'address') {
        element.presetValues.push({
          labelKey: this.eventDetails.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'address')?.attributeValue,
          labelValue: this.eventDetails.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'address')?.attributeValue,
        });
      }
    });
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.eventId ? this.cs.getLabel('events.edit_event') : this.cs.getLabel('events.add_event'),
        routerLink: '/app/events/list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      }
    ];
  }

  saveEvent() {
    if (!this.addEventForm.valid) {
      this.addEventForm.markAllAsTouched();
    } else {
      const requestBody  = this.setRequestBody();
      if (!this.eventId) {
        this.createEvent(requestBody);
      } else {
        this.updateEvent(requestBody);
      }
    }
  }

  setRequestBody() {
    let eventAddress = this.addEventForm.controls['event_address'].value
    return {
      eventName: this.addEventForm.controls['name'].value,
      eventShortcode: this.addEventForm.controls['shortCode'].value,
      transportSupervisorNumber: this.addEventForm.controls['transportSupervisorNumber'].value,
      emergencyNumber: this.addEventForm.controls['emergencyContactNumber'].value,
      eventDescription: this.addEventForm.controls['description'].value,
      eventAddress: this.addEventForm.controls['event_geolocation'].value,
      latitude: eventAddress ? eventAddress.lat : 0,
      longitude: eventAddress ? eventAddress.lng : 0,
      eventLogoImgId: this.imgFileId,
      eventBannerImgId: this.addEventForm.controls['name'].value,
      transportationType: JSON.stringify(this.addEventForm.controls['transportationType'].value),
      eventStartDateTime:  new Date(this.addEventForm.controls['startDate'].value).getTime(),
      eventEndDateTime:  new Date(this.addEventForm.controls['endDate'].value).getTime(),
      startBookingDatePassengerApp: new Date(this.addEventForm.controls['startBookingDate'].value).getTime(),
      stopBookingDatePassengerApp:  new Date(this.addEventForm.controls['endBookingDate'].value).getTime(),
      serviceAreaRadius: this.addEventForm.controls['operatingRadius'].value,
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      emergencyNumberCountryCode: this.addEventForm.controls['emergencyContactNumberWithCountryCode'].value,
      transportSupervisorNumberCountryCode: this.addEventForm.controls['transportSupervisorNumberCountryCode'].value,
      timezoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  }

  createEvent(requestBody) {
    this.eventService.createEvent(requestBody).subscribe({
      next : (res: any) => {
        this.eventId = res.eventId;
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail:  this.cs.getLabel('events.event_added_successfully')});
        this.activeIndex += 1;
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  updateEvent(requestBody) {
    this.eventService.updateEvent(requestBody, this.eventId).subscribe({
      next : (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: res.message });
        this.activeIndex += 1;
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

 
  onCancel() {
    this.miFormComponent.resetForm();
    this.router.navigate(['app/events/list']);
  }

  onFilter(event) {
    if (event.filter) {
      this.filterIcon = 'pi pi-times'
    } else {
      this.filterIcon = 'pi pi-search';
    }
  }

  getAttributesForTimeSetting() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_TYPE.TIME_SETTINGS,  AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.attributeLabelsForTimeSettings = this.cs.getAttributeLabels(res);
        this.timeSettingsAttributeData = this.cs.getOrganizedAttribute(res);
      }
    });
  }
}
