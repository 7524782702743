import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { ModuleHeaderComponent } from '../../../shared/components/module-header/module-header.component';
import type { Country } from '../../../shared/models/country';
import type { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import type { Column } from '../../../vehicles/models/attribute.models';
import type { Values } from '../../../vehicles/models/listResponse.models';
import { ShiftTemplateService } from '../../services/shift-template.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { PermissionAccessService } from 'app/modules/shared/services/permission-access.service';

@Component({
  selector: 'app-shift-template-list',
  standalone: true,
  imports: [BreadcrumbModule, ModuleHeaderComponent, TabViewModule, TranslateModule, MITableComponent, MITableComponent, AsyncPipe, AccessProviderDirective],
  templateUrl: './shift-template-list.component.html',
  styleUrl: './shift-template-list.component.scss'
})
export class ShiftTemplateListComponent {
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  @ViewChild('uploader', { static: true }) uploader: ElementRef<HTMLElement>;
  @Input() moduleName: string;
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];

  value: number = 50;
  btnLabel: string;
  items: MenuItem[] | undefined;
  isShowMenu: boolean = true;
  attributeLabels = {};
  attributeLabelsData: any = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  totalRecords: number = 0;
  tableData: any = [];
  buttons: any[] = [];
  country: Country;
  language: Language;
  entityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    forTenantCode: '',
  };
  routePath: MenuItem[] = [];
  home: MenuItem = {};
  isModal: boolean = false;
  selectedColumns!: Column[];
  attributeData: any;
  data;
  rowItems: MenuItem[] | undefined;
  bulkSelectionsItems: MenuItem[] | undefined;
  columnsData;
  bulkDeleteLabel: string = this.cs.getLabel('shiftTemplate.delete');
  bulkUpdateLabel: string = this.cs.getLabel('shiftTemplate.lbl_update_shift_template');
  bulkUpdateHeader: any;
  reviewHeader: any;
  showBulkUpdateDialog: boolean = false;
  visible: boolean = false;
  filterFieldsArray: any[];
  uniqueId: string = 'shift_template_grid';
  isEntity: boolean = false;
  entityName;
  showImportExportBtn: boolean = false;
  btnUpdate: any;
  deleteShiftTemplateMsg: any;
  deleteShiftTemplateHeaderMsg: any;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  currentRow: any;
  entityNameForBulkUpdate = AppSettings.ENTITY_TYPE.SHIFT_TEMPLATE;
  count: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cs: CommonBindingDataService,
    private configService: ConfigService,
    private cd: ChangeDetectorRef,
    private shiftTemplateService: ShiftTemplateService,
    private messageService: MessageService,
    private permissionAccessService: PermissionAccessService,
  ) { }

  ngOnInit() {
    this.setHeadersAndLabels();
    this.setModuleHeaderSecondaryBtnMenuItems();
    this.setRowSelectionOptions();
    this.setBulkSelectionOptions();
    this.initializeColumn();
  }

  setUpRoutePath() {
    this.routePath = [
      { label: `${this.cs.getLabel('shiftTemplate.shift_templates')} (${this.count})`, routerLink: 'shift-template/list' },
    ];
  }

  initializeColumn() {
    this.allColumns = [
      {
        attributeCode: "shiftName",
        attributeValue: 'Name',
        attributeIndex: 0,
        field: "shiftName",
        header: this.cs.getLabel('shiftTemplate.columns.name')
      },
      {
        attributeCode: "startTime",
        attributeValue: 'Start Time',
        attributeIndex: 1,
        field: "startTime",
        header: this.cs.getLabel('shiftTemplate.columns.startTime')
      },
      {
        attributeCode: "endTime",
        attributeValue: 'End Time',
        attributeIndex: 2,
        field: "endTime",
        header: this.cs.getLabel('shiftTemplate.columns.endTime')
      },
      {
        attributeCode: "calculateHours",
        attributeValue: 'Hours',
        attributeIndex: 3,
        field: "calculateHours",
        header: this.cs.getLabel('shiftTemplate.columns.hours')
      },
      {
        attributeCode: "breakCount",
        attributeValue: 'Breaks',
        attributeIndex: 4,
        field: "breakCount",
        header: this.cs.getLabel('shiftTemplate.columns.breaks')
      },
      {
        attributeCode: "createdAt",
        attributeValue: 'Created On',
        attributeIndex: 5,
        field: "createdAt",
        header: this.cs.getLabel('shiftTemplate.columns.createdOn')
      }
    ];
  }

  setHeadersAndLabels() {
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.bulkUpdateHeader = this.cs.getLabel('shiftTemplate.update_shift_templates');
    this.btnUpdate = this.cs.getLabel('shiftTemplate.lbl_update_shift_template');
    this.deleteShiftTemplateMsg = this.cs.getLabel('shiftTemplate.message.confirm_delete_msg');
    this.deleteShiftTemplateHeaderMsg = this.cs.getLabel('shiftTemplate.message.confirm_delete_header');
    this.btnLabel = this.cs.getLabel('add_new');
    this.getBulkUpdateAttributeData();
    this.getData();
  }

  getBulkUpdateAttributeData() {
    this.filterFieldsArray = [
      {
        attributeId: 2,
        attributeCode: "startTime",
        attributeValue: 'Start Time',
        attributeIndex: 1,
        field: "startTime",
        header: this.cs.getLabel('shiftTemplate.columns.startTime')
      },
      {
        attributeId: 3,
        attributeCode: "endTime",
        attributeValue: 'End Time',
        attributeIndex: 2,
        field: "endTime",
        header: this.cs.getLabel('shiftTemplate.columns.endTime')
      },
    ];
  }

  setModuleHeaderSecondaryBtnMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.cs.getLabel('shiftTemplate.import_shift_templates'),
        icon: AppIcons.UPLOAD + " wh-16",
        command: () => {
          const el: HTMLElement = this.uploader.nativeElement;
          el.click();
        }
      },
      {
        label: this.cs.getLabel('shiftTemplate.download_import_template'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
        }
      },
      {
        label: this.cs.getLabel('shiftTemplate.download_import_guide'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
        }
      },
      {
        separator: true
      },
      {
        label: this.cs.getLabel('vehicle.export_xlsx_csv'),
        icon: AppIcons.EXPORT + " wh-16",
      },
    ];
  }

  setBulkSelectionOptions() {
    const isBulkAction = this.permissionAccessService.hasAccess('SHIFT_MANAGEMENT', 'SHIFT_TEMPLATES', 'ALL', 'BULK_ACTION');
    this.bulkSelectionsItems = [
      ...(isBulkAction ? [{
        label: this.bulkDeleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: (e) => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.BULK);
        },
      }] : []),
    ];
  }

  setRowSelectionOptions() {
    const isEdit = this.permissionAccessService.hasAccess('SHIFT_MANAGEMENT', 'SHIFT_TEMPLATES', 'ALL', 'EDIT');
    this.rowItems = [
      ...(isEdit ? [{
        label: this.cs.getLabel('shiftTemplate.lbl_edit'),
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        command: (event) => {
          this.router.navigate(['app/shift-template/edit/' + this.currentRow.id]);
        }
      }] : []),
    ];
  }

  setCurrentRowData(event) {
    this.currentRow = event;
    this.setEditOptionDisabledForDeletedRecord(event);
  }

  setEditOptionDisabledForDeletedRecord(data) {
    this.rowItems[0].disabled = data.deleted;
    this.cd.detectChanges();
  }

  getData() {
    this.searchEntity();
  }


  searchEntity() {
    this.shiftTemplateService.searchShiftTemplateList(this.entityData).subscribe((res: any) => {
      this.count = res.count;
      this.setUpRoutePath();
      this.listColumns = res?.data[0];
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = [];
      for (const data of res.data) {
        data.calculateHours = data.calculateHours / 60;
        data.calculateHours = data.calculateHours <= AppSettings.SINGLE_DIGIT_HIGHEST_NUMBER ? `0${data.calculateHours}:00` : `${data.calculateHours}:00`,
          this.tableData.push({
            id: data.shiftTemplateId,
            ...data
          });
      }
    })
    this.isLoading = false;
  }

  onAddShiftTemplate() {
    this.router.navigate(['app/shift-template/add']);
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }

  onBulkDataDeleteEvent(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      shiftTemplateIds: event
    };
    this.bulkDeleteApiCall(requestBody);
  }

  onShiftTemplateDelete(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      shiftTemplateIds: [event]
    };
    this.bulkDeleteApiCall(requestBody);
  }

  bulkDeleteApiCall(requestBody) {
    this.shiftTemplateService.bulkDelete(requestBody).subscribe({
      complete: () => {
        this.searchEntity();
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('shiftTemplate.lbl_shift_template_deleted_successfully') });
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    })
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  onBulkDataUpdateEvent(event) {
    this.searchEntity();
  }

  onChange(event) {

  }
}
