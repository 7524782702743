<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'LEAVE_MANAGEMENT', pageCode: 'HOME', subPageCode: 'ADD' }">
<div class="flex justify-content-end flex-wrap btn-with-links">
  <button pButton class="mr-2 bg-white default-text-light-black-color cursor-pointer border-button" [label]="'cancel' | translate" (click)="onClose()"></button>
  <p-button class="flex align-items-center justify-content-center cursor-pointer" [label]="btnLabel"  (click)="addEditLeave()"></p-button>
</div>
<p-tabView [(activeIndex)]="activeIndex">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <div class="tab-icons">
        <i class="{{miIcons.DETAILS}} wh-24"></i>
        <span translate>lbl_new_leave</span>
      </div>
    </ng-template>
    <div class="card">
      <form [formGroup]="applyNewLeave">
        <div class="grid">
          <div class="form-field col-6 md:col-6 lg:col-6 field">
            <label class="flex" translate>lbl_driver_name_id
              <span class="required">*</span>
            </label>
            <p-autoComplete [placeholder]="'lbl_search_by_driver_name_id' | translate" 
              [suggestions]="suggestions" 
              (onSelect)="onSelectDriver($event)" 
              class="w-full" 
              (completeMethod)="search($event)" 
              [field]="'name'" 
              formControlName="driverName">
              <ng-template let-driver pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div class="driver-popup bold-name">
                    <span class="ellipsis mr-2">
                      {{driver.name}}
                    </span>
                    <span class="unique ellipsis mr-2">
                      {{driver.unique_id}}
                    </span>
                  </div>
                </div>
              </ng-template>
            </p-autoComplete>

            <input class="hidden" type="text" pInputText formControlName="driverName" [value]="displayDriverName()">
            @if(applyNewLeave.controls.driverName.errors &&
              (applyNewLeave.controls.driverName.dirty || applyNewLeave.controls.driverName.touched)) {
              <div class="form-errors">
                <div [hidden]="!applyNewLeave.controls.driverName.errors.required" translate>
                  lbl_driver_name_id_req
                </div>
              </div>
            }
          </div>
          <div class="form-field col-6 md:col-6 lg:col-6 field">
            <label class="flex" translate>lbl_leave_type
                <span class="required">*</span>
            </label>
            <p-dropdown class="uppercase" class="p-component p-element w-full" [style]="{'width':'100%'}" [options]="dropdownList" 
              [placeholder]="'lbl_please_select' | translate" formControlName="leaveType"></p-dropdown>
              @if(applyNewLeave.controls.leaveType.errors &&
                (applyNewLeave.controls.leaveType.dirty || applyNewLeave.controls.leaveType.touched)) {
                <div class="form-errors">
                  <div [hidden]="!applyNewLeave.controls.leaveType.errors.required" translate>
                    lbl_leave_type_req
                  </div>
                </div>
              }
          </div>
        </div>
        <div class="grid">
          <div class="form-field col-3 md:col-3 lg:col-3 field">
            <label class="flex" translate>lbl_from_date
              <span class="required">*</span>
            </label>
            <p-calendar [appendTo]="'body'" class="p-component p-element w-full" [style]="{'width':'100%'}" dateFormat="dd/mm/yy" 
              [showIcon]="true" [placeholder]="'lbl_please_select' | translate" formControlName="fromDate"
              [minDate]="minDate" [maxDate]="maxDate">
            </p-calendar>
              @if(applyNewLeave.controls.fromDate.errors &&
                (applyNewLeave.controls.fromDate.dirty || applyNewLeave.controls.fromDate.touched)){
                <div class="form-errors">
                  <div [hidden]="!applyNewLeave.controls.fromDate.errors.required" translate>
                    lbl_from_date_req
                  </div>
              </div>
              }
          </div>
          <div class="form-field col-3 md:col-3 lg:col-3 field">
            <label class="flex" translate>lbl_to_date
              <span class="required">*</span>
            </label>
            <p-calendar [appendTo]="'body'" class="p-component p-element w-full" [style]="{'width':'100%'}" dateFormat="dd/mm/yy" 
              [showIcon]="true" [placeholder]="'lbl_please_select' | translate" formControlName="toDate" 
              [maxDate]="maxDate" [minDate]="minDateForToDate">
            </p-calendar>
              @if(applyNewLeave.controls.toDate.errors &&
                (applyNewLeave.controls.toDate.dirty || applyNewLeave.controls.toDate.touched)) {
                <div class="form-errors">
                  <div [hidden]="!applyNewLeave.controls.toDate.errors.required" translate>
                    lbl_to_date_req
                  </div>
                </div>
              }
          </div>
          <div class="col-6 md:col-6 lg:col-6"></div>
        </div>
        <div class="grid">
          <div class="form-field col-6 md:col-6 lg:col-6 field">
            <label class="flex" translate>lbl_reason_for_leave
            </label>
            <textarea rows="5" cols="30" pInputTextarea formControlName="reasonForLeave" 
              class="p-inputtext p-component p-element w-full" [placeholder]="'lbl_reason' | translate"></textarea>
          </div>
        </div>
      </form>
    </div>
    <div class="col-12 flex justify-content-end relative right-9">
        <button pButton class="mr-2 bg-f5f5f5 default-text-black-color cursor-pointer border-button" [label]="'cancel' | translate" (click)="onClose()"></button>
      <p-button [label]="btnLabel" (click)="addEditLeave()"></p-button>
    </div>

    <p-toast></p-toast>
  </p-tabPanel>
</p-tabView>
</div>
