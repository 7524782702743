<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'MANAGE_LOCATIONS', pageCode: 'DETAILS', subPageCode: 'VIEW' }">
<div class="grid mt-1 details-div mr-0">
    <div class="col-8">
        @if (showStatus) {
            <app-overview-status [dataForStatus]="dataForStatus" [locationCategory]="locationCategory"></app-overview-status>
        }
    </div>
    <div class="col-4 flex justify-content-end align-items-center">
        <div>
            <div appAccessProvider [accessCode]="{ menuCode: 'MANAGE_LOCATIONS', pageCode: 'DETAILS', subPageCode: 'UPDATE_STATUS' }">
                <p-button styleClass="p-button-secondary" class="menu-btn p-2 p-button-secondary" (click)="menu.toggle($event)" icon="{{miIcons.MORE_HORIZONTAL}} block wh-24"></p-button>
            </div>
        </div>
        <div>
            <div appAccessProvider [accessCode]="{ menuCode: 'MANAGE_LOCATIONS', pageCode: 'DETAILS', subPageCode: 'EDIT' }">
                <button (click)="edit()" pButton class=" border-400 bg-white text-color mr-1 pl-2 pr-2 pt-1 pb-1 mr-2 hover:surface-100" [label]="editBtnLabel">
                    <i class="mi-lg mr-2 edit-pen edit_pencil"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<div>
    @if (locationAttributeData) {
        <app-overview-details
            [attributeValues]="attributeValues"
            [attributeLabels]="attributeLabels"
            [locationAttributeData]="locationAttributeData"
            [locationDetails]="locationDetails"
        ></app-overview-details>
    }
</div>

<div class="header-module-menu">
    <p-menu  #menu  [model]="moduleHeaderSecondaryBtnMenuItems" [popup]="true"></p-menu>
</div>
</div>