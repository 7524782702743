@if(hideService.getVisibilityState(cardId) | async){
  <div class="card mb-3 height-225px shadow-card">
    <div class="flex flex-column">
      <div class="flex justify-content-between">
        <h5 class="font-16 mb-4 default-text-black-color font-bold" translate>
          dashboard.cancelled_journey
        </h5>
        <app-hide-button [cardId]="cardId" class="pt-10"></app-hide-button>
      </div>

      <ul class="list-none p-0 m-0">
        @for(item of cancelledJourneyData; track item){
        <li
          class="flex flex-column justify-content-between md:flex-row md:align-items-center md:justify-content-between mb-3"
        >
          <div>
            <span [ngClass]="item.labelClass">{{ item.label }}</span>
          </div>
          <div class="mt-2 md:mt-0 flex align-items-center">
            <span [ngClass]="item.countClass">{{ item.count }}</span>
          </div>
        </li>
        }
      </ul>
    </div>
  </div>
}
