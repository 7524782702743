import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'app/modules/shared/services/config.service';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { FilterResponseModal } from '../../../manage-groups/models/filterResponse.models';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../shared/services/entity.service';
import type { EntityCount, EntityCountsResponse } from '../../../vehicles/models/entity.models';
import type { ListResponse } from '../../../vehicles/models/listResponse.models';
import type { Values } from '../../models/driverList.models';

@Component({
  selector: 'app-assignment-history',
  standalone: true,
  imports: [MITableComponent],
  templateUrl: './assignment-history.component.html',
  styleUrl: './assignment-history.component.scss'
})
export class AssignmentHistoryComponent {
  @Input() driverDetails: any;
  filterList;
  btnLabel: string;
  attributeLabels = {};
  filterAttributeLabels = {};
  isLoading = true;
  filterAvailableColumns = [];
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  entityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  };
  routePath: MenuItem[] = [];
  deleteBroadcastMsg: string;
  deleteBroadcastHeaderMsg: string;
  deleteLabel: string = this.cs.getLabel('broadcasting.delete');
  editLabel: string = this.cs.getLabel('broadcasting.edit');
  filterFieldsArray: any[];
  attributeData: any;
  data: any;
  rowItems: MenuItem[] | undefined;
  currentRow: any;
  visible: boolean = false;
  totalRecords: number = 0;
  attributeIdOfDriverEntityId: any;
  fieldForDuration: any;

  constructor(private assignmentHistoryService: EntityService,
    private cs: CommonBindingDataService, private confirmationService: ConfirmationService,
    private router: Router, private messageService: MessageService, private actRoute: ActivatedRoute, private configService: ConfigService) { }

  ngOnInit() {
    this.setLabels();
    this.getAttributeData();
    this.getData();
  }

  public setLabels() {
    this.btnLabel = this.cs.getLabel('broadcasting.lbl_new_broadcast');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.deleteBroadcastMsg = this.cs.getLabel('broadcasting.message.confirm_delete_msg');
    this.deleteBroadcastHeaderMsg = this.cs.getLabel('broadcasting.message.confirm_delete_header');
  }

  getAttributeData() {
    this.assignmentHistoryService.getAttributeDefinition(AppSettings.ENTITY_CODE.VEHICLE_ASSIGNMENT_HISTORY, AppSettings.VIEW_CODE.BULK_UPDATE_VIEW).subscribe(res => {
      if (res) {
        this.attributeData = res;
        this.isLoading = false;
        this.filterFieldsArray = res?.tabs[0]?.groups[0]?.fields;
      }
    });
  }


  getTableView() {
    this.assignmentHistoryService.getAttributeDefinition(AppSettings.ENTITY_CODE.VEHICLE_ASSIGNMENT_HISTORY, AppSettings.VIEW_CODE.DEFAULT_TABLE_DRIVER_ASSIGNMENT_VIEW).subscribe(res => {
      if (res) {
        this.isLoading = false;
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.allColumns = this.cs.getColumns(this.data);
        if (this.data && this.data.tabs && this.data.tabs[0] && this.data.tabs[0].groups && this.data.tabs[0].groups[0] && this.data.tabs[0].groups[0].fields) {
          this.fieldForDuration = _.cloneDeep(this.data.tabs[0].groups[0].fields[this.data.tabs[0].groups[0].fields.length - 1]);
        }
        this.fieldForDuration.attributeCode = 'duration';
        this.allColumns.push(this.fieldForDuration);

        this.allColumns?.forEach((key, index) => {
          this.allColumns[index] = key;
          this.allColumns[index].field = key.attributeCode;
          this.allColumns[index].header = this.cs.getLabelValue(
            "vehicle_assignment_history" + ".fields." + key.attributeCode + ".label",
            this.attributeLabels,
            key.attributeCode
          );
        });
        this.filterAvailableColumns = _.clone(this.allColumns);
        this.searchEntity();
      }
    });
  }

  getFilterView() {
    this.assignmentHistoryService.getAttributeDefinition(AppSettings.ENTITY_CODE.VEHICLE_ASSIGNMENT_HISTORY, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe((filterResponse: FilterResponseModal) => {
      if (filterResponse) {
        this.attributeIdOfDriverEntityId = filterResponse.tabs[0].groups[0].fields.find(element => element.attributeCode === 'driver_entity_id')?.attributeId;
        this.getTableView();
      }
    })
  }

  getEntityCount() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const entityCountPayload = <EntityCount>{
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.VEHICLE_ASSIGNMENT_HISTORY,
    };

    this.assignmentHistoryService.getEntityCount(AppSettings.ENTITY_CODE.VEHICLE_ASSIGNMENT_HISTORY, entityCountPayload)
      .subscribe((res: EntityCountsResponse) => {
        this.entityCount = res;
      });
  }

  getData() {
    return forkJoin({
      filterViewDate: this.getFilterView(),
      getEntityCount: this.getEntityCount(),
    })
  }


  searchEntity() {
    this.tableData = [];
    this.entityData.filters = [{ attributeId: this.attributeIdOfDriverEntityId, attributeValue: [this.driverDetails.entityId] }];
    this.entityData.countryCode = this.country[0].countryCode;
    this.assignmentHistoryService.searchEntity(AppSettings.ENTITY_CODE.VEHICLE_ASSIGNMENT_HISTORY, this.entityData).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0]?.values;
      this.cs.setDuration(res);
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = this.cs.getTableData(res);
    })
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }
}
