<div appAccessProvider [accessCode]="{ menuCode: 'AUTHENTICATION', pageCode: 'USER', subPageCode: 'EDIT' }">
<div class="main-settings">
    <p-tabView>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-14 mr-2 relative"></i>
                <span translate>settings.lbl_user_login_setting</span>
            </ng-template>
            <div class="flex justify-content-end flex-wrap btn-with-links">
                <button pButton class="mr-2 bg-white default-text-light-black-color border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
                <p-button class="flex align-items-center justify-content-center" [label]="addBtnLabel" (click)="onAddUser()"></p-button>
            </div>
            <div class="card background-not">
                <div class="font-semibold pb-2">
                    <label class="navigation-item-label font-18 uppercase" translate>settings.lbl_user_login_password</label>
                </div>
                <form [formGroup]="profileForm">
                    <div class="grid">
                        <div class="form-field col-6 md:col-6 lg:col-6 field">
                            <label class="flex" translate>settings.lbl_default_password
                                <span class="required">*</span>
                            </label>
                            <div class="form-control">
                                
                                
                <p-password class="w-full"  formControlName="defaultPassword" [toggleMask]="true" />
                            </div>
                            @if (profileForm.controls.defaultPassword.errors && (profileForm.controls.defaultPassword.dirty || profileForm.controls.defaultPassword.touched)) {
                                <div class="form-errors mt-1 required-field">
                                    <div [hidden]="!profileForm.controls.defaultPassword.errors.required" translate>
                                        settings.authentication.password_is_required
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </form>
            </div>
            <div class="flex justify-content-end mr-17">
                <button pButton class="mr-2 mb-2 bg-color-gray default-text-light-black-color border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
                <p-button class="mb-2 flex align-items-center justify-content-center" [label]="'label_save' | translate" (click)="onAddUser()"></p-button>
            </div> 
        </p-tabPanel>
    </p-tabView>
</div>
</div>
