import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import type { AttributeData } from 'app/modules/vehicles/models/attribute.models';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { AppIcons } from '../../app.icons';
import { AppSettings } from '../../app.settings';
import { Country } from '../../models/country';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { ConfigService } from '../../services/config.service';
import { EntityService } from '../../services/entity.service';

interface UploadedFiles {
  file: File,
  fileId: string
}

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrl: './document-details.component.scss',
  standalone: true,
  imports: [BreadcrumbModule, TranslateModule, ButtonModule, DatePipe],
})
export class DocumentDetailsComponent implements OnInit {
  routePath: MenuItem[] = [];
  country: Country;
  editBtnLabel: any;
  data: AttributeData;
  attributeLabels = {};
  attributeData: any;
  attributeLength;
  attributeValues = [];
  entityName: string;
  entityCode: string;
  documentId: string;
  entityId: string;
  documentDetails: any;
  tab: any;
  details: any;
  dd_MMM_yyyy_FORMAT: any = AppSettings.dd_MMM_yyyy_FORMAT;
  uploadedFiles: UploadedFiles[] = [];
  field: any;
  img: any;
  miIcons = AppIcons;

  constructor(private documentService: EntityService,
    private route: ActivatedRoute,
    private router: Router,
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private configService: ConfigService) { }

  ngOnInit() {
    this.documentId = this.route.snapshot.paramMap.get('documentId');
    this.entityId = this.route.snapshot.paramMap.get('id');
    this.route.queryParams.subscribe(params => {
      this.entityCode = params['entityCode'];
      this.entityName = this.entityCode === AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT ? 'vehicle_document' : 'driver_document';

      this.getDocumentDetails();
      this.getAttributeData();
    });

    this.editBtnLabel = this.cs.getLabel('lbl_edit');
    this.getRoutePath();
  }

  getRoutePath() {
    this.routePath = [
      {
        label: "Document",
        routerLink: `../../${this.entityId}`,
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
    ];
  }

  onBack() {
    if (this.entityName === 'vehicle_document') {
      this.router.navigate([`app/vehicles/${this.entityId}`]);
    } else if (this.entityName === 'driver_document') {
      this.router.navigate([`app/drivers/${this.entityId}`]);
    }
  }

  getAttributeData() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.documentService.getAttributeDefinition(this.entityName, AppSettings.VIEW_CODE.DETAIL_VIEW).subscribe(res => {
      this.data = res;
      this.cd.detectChanges();
      this.attributeLabels = this.cs.getAttributeLabels(this.data);
      this.attributeData = this.cs.getOrganizedAttribute(this.data);
      this.attributeLength = this.attributeData.tabs.length;

      this.tab = this.attributeData.tabs.find(ele => ele.tabCode === 'documents');
      this.details = this.tab.groups.find(ele => ele.code === 'details');
    })
  }

  getDocumentDetails() {
    this.documentService.getEntity(this.documentId, this.entityName, AppSettings.VIEW_CODE.DETAIL_VIEW).subscribe((result: any) => {
      this.attributeValues = this.cs.getOrgAttributeValues(result);
      this.documentDetails = result;

      const documentFileIds = this.attributeValues['upload_document'];
      documentFileIds.forEach(fileId => this.getDocumentByFileId(fileId));
    });
  }

  getDocumentByFileId(fileId) {
    this.documentService.getFile(fileId, AppSettings.DOCUMENTS_TYPE.DOCUMENT, 'no').subscribe(result => {
      const file = new File([result], this.entityName);
      this.uploadedFiles.push({ file: file, fileId: fileId });
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        that.img = base64data;
      }
    })
  }

  getImageByFileId(fileId) {
    this.documentService.getFile(fileId, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], this.entityName);
      this.uploadedFiles.push({ file: file, fileId: fileId });
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        that.img = base64data;
      }
    })
  }

  downloadDocument(event) {
    if (event) {
      const fileId = event?.fileId;
      const documentTypes = event?.file?.type;
      const fileName = event?.file?.name;
      this.getFile(fileId, documentTypes, fileName);
    } else {
      console.error('Invalid Documents data.');
    }
  }

  getFile(imgFileId, documentTypes, fileName) {
    this.documentService.getFile(imgFileId, AppSettings.DOCUMENTS_TYPE.DOCUMENT, 'no').subscribe(result => {
      const blob = new Blob([result], { type: `${documentTypes}` });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, error => {
      console.error('Error fetching file:', error);
    });
  }

  edit() {
    if (this.entityName === 'vehicle_document') {
      this.router.navigate(['app/vehicles/add/' + this.entityId,]);
    } else if (this.entityName === 'driver_document') {
      this.router.navigate(['app/drivers/add/' + this.entityId,]);
    }
  }

}
