import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { Router } from '@angular/router';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Language } from 'app/modules/shared/models/language';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-overview-details',
  templateUrl: './overview-details.component.html',
  styleUrl: './overview-details.component.scss',
  standalone: true,
  imports: [ButtonModule, TranslateModule, ChartModule, OverlayPanelModule, FormsModule, AccessProviderDirective]
})
export class OverviewDetailsComponent implements OnInit {
  iconPos: any = 'right';
  @Input() attributeValues: any;
  @Input() attributeLabels: any;
  @Input() locationAttributeData: any;
  @Input() locationDetails: any;
  address: any;

  selectedItem: any = [];
  data: any;
  options: any;
  tab: any;
  details: any;
  center: google.maps.LatLngLiteral = {
    lat: 24,
    lng: 12,
  };
  zoom = 4;
  map: google.maps.Map;
  language: Language;
  languageCode: string;

  constructor(public cs: CommonBindingDataService, private router: Router) { }

  ngOnInit() {
    this.tab = this.locationAttributeData.tabs.find(ele => ele.tabCode === 'overview');
    this.details = this.tab.groups.find(ele => ele.code === 'details');
    this.setChartData();
    this.initMap();
    this.address = this.attributeValues[AppSettings.LOCATION_ATTRIBUTES.LOCATION_ADDRESS];
    const geoPoint = this.attributeValues[AppSettings.LOCATION_ATTRIBUTES.LOCATION_GEOLOCATION];
    const [lat, lng] = geoPoint?.split(',').map(parseFloat);
    const markerlocation = { lat, lng };
    this.setMarker(markerlocation);
  }

  redirectToEdit() {
    this.router.navigate(['/app/manage-locations/add/' + this.locationDetails.entityId]);
  }

  getUnit(field) {
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const tempUnit = JSON.parse(field?.imperialUnit);
    this.languageCode = this.language[0].langCode;
    return tempUnit[this.languageCode];
  }

  async setMarker(markerlocation): Promise<void> {
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: markerlocation,
    });
    this.map.setCenter(markerlocation);
    this.map.setZoom(17);
  }

  async initMap(): Promise<void> {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 18.50932924799519, lng: 74.32022387996037 },
      zoom: 8,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapId: AppSettings.MAP_ID,
    });
  }

  setChartData() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'First Dataset',
          data: [15, 17, 19],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--blue-500'),
          backgroundColor: documentStyle.getPropertyValue('--blue-500'),
          tension: 0.4,
          showLine: false
        },
        {
          label: 'Second Dataset',
          data: [16, 18, 20],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--pink-500'),
          backgroundColor: documentStyle.getPropertyValue('--pink-500'),
          tension: 0.4,
          showLine: false
        }
      ]
    };

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  }

}
