import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { AppSettings } from '../../../shared/app.settings';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import type { entityResponse } from '../../../vehicles/models/attribute.models';
import type { AssignVehicleToBookingPayload, GetAttributes } from '../../models/booking.models';
import { BookingService } from '../../services/booking.service';
import { AssignVehicleFromBookingsComponent } from '../ui-components/assign-vehicle-from-bookings/assign-vehicle-from-bookings.component';
import { BookingFormComponent } from '../ui-components/booking-form/booking-form.component';
import { BookingPassengerAddressComponent } from '../ui-components/booking-passenger-address/booking-passenger-address.component';
import { SelectPassengersComponent } from './select-passengers/select-passengers.component';
import { UIElementService } from 'app/modules/shared/services/ui-element.service';


interface BookingStop {
  select_location_geolocation: string;
  select_location_address: string;
}

interface BookingData {
  [key: string]: any; // Adjust this to your specific structure if needed
}

export enum AssignmentType {
  NORMAL = 'NORMAL',
  LUGGAGE = 'LUGGAGE',
  ADDITIONAL = 'ADDITIONAL'
}

@Component({
  selector: 'app-add-booking',
  standalone: true,
  imports: [RouterOutlet, RouterLink, RouterLinkActive, RouterModule, BreadcrumbModule, TabViewModule, DialogModule, MiFormComponent,
    ButtonModule, TranslateModule, SelectPassengersComponent, BookingFormComponent, AssignVehicleFromBookingsComponent,
    BookingPassengerAddressComponent],
  templateUrl: './add-booking.component.html',
  styleUrl: './add-booking.component.scss'
})
export class AddBookingComponent {
  @ViewChild(BookingFormComponent) miFormComponent: BookingFormComponent;
  activeIndex: number = 0;
  data: GetAttributes;
  bookingAttributeData: any;
  routePath: MenuItem[] = [];
  attributeLabels: any = {};
  moduleName: string;
  country: Country;
  language: Language;
  relation: string = 'oneToOne'
  cols: any = [];
  bookingAttributeLength;
  bookingId: string;
  bookingDocumentId;
  attributeValues = [];
  nextBtnLabel;
  previousBtnLabel;
  entitiesData;
  bookingBookingPass: GetAttributes;
  bookingPass: GetAttributes;
  prefixForOneToManyRelatedFields: any = '_';
  stopLocationCode = 'booking_stops_location_geolocation';
  passengersAttributeCode = 'booking_passenger_section';
  selectVehicleCode = 'selectVehicleCode';
  assignVehicleControlCode = 'vehicle_assignment';
  assignedVehicleId: string;
  stopGeoLocations = [];
  stopAddresses = []
  bookingData: any;
  selectedPassengers: any[] = [];
  addedPassengerList: any = [];
  passengerView: any;
  isEdit = false;
  bookingBtnLabel: string;
  isEditView: boolean = false;
  passengerAddressVisible: boolean = false;
  passengerId: any;
  bookingPetInfo;
  filteredLabels: any;
  requestedPassengers: any[] = [];

  additionalRequirementData;
  passengerVehicleId = null;
  additionalVehicleIds: any[] = [];
  luggageVehicleIds: any[] = [];
  assignMentType = AssignmentType;
  luggageBookingIds: string[] = [];
  passengerBookingIds: string[] = [];

  constructor(private entityService: EntityService,
    private bookingService: BookingService,
    public cs: CommonBindingDataService,
    private configService: ConfigService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private confService: ConfigService,
  private uiService: UIElementService, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.setLabels()
    if (this.bookingId) {
      this.setEditFlow();
    } else {
      this.getAttributes()
    }
    this.setRoutePath();
    this.bookingService.selectedPassenger$.subscribe(passengers => {
      console.log('passenger:',passengers);
      if (passengers.length > 0) {
        this.passengerId = passengers[0].id;
        this.selectedPassengers = passengers;
        this.addedPassengerList = passengers;
      } else {
          this.passengerId = null;
      }
    });

    this.bookingService.requestedPassenger$.subscribe(requestedPassengers => {
      console.log('requested passengers:', requestedPassengers);
      this.requestedPassengers = requestedPassengers;
    });
  }

  setEditFlow() {
    this.bookingBtnLabel = this.cs.getLabel('bookings.update_book_request');
    this.isEdit = true;
    this.entityService.getEntity(this.bookingId, AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        // console.log('booking res:', res);
        const data = res;
        const relatedData = _.groupBy(res['relatedData'], 'entityCode');
        this.addedPassengerList = this.cs.getPassengerData(relatedData.booking_pass);
        this.attributeValues = this.cs.getOrgAttributeValues(data);
        const dateAttributes = [
          AppSettings.DATE_ATTRIBUTE_IDS.DEPARTURE_TIME_AND_DATE,
          AppSettings.DATE_ATTRIBUTE_IDS.RETURN_TIME_AND_DATE,
        ];
        dateAttributes.forEach(attr => {
          this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
        });
        this.getAttributes();

        this.selectedPassengers = this.attributeValues['booking_passenger_section'];
      }
    });
  }

  setLabels() {
    this.nextBtnLabel = this.cs.getLabel('bookings.lbl_book_request');
    this.bookingBtnLabel = this.cs.getLabel('bookings.book_request');
    this.previousBtnLabel = this.cs.getLabel('lbl_previous');
    this.bookingId = this.route.snapshot.paramMap.get('id');
    this.moduleName = AppSettings.ENTITY_CODE.BOOKING;
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const href = this.router.url;
    this.isEditView = href.includes('edit');
  }

  onNextBtnClick() {
    const btn = document.getElementById('saveBookingId');
    btn.click();
  }

  getAttributes() {
    const BOOKING_PASS_VIEW = AppSettings.ENTITY_CODE.BOOKING_PASS + '_' + AppSettings.VIEW_CODE.ADD_EDIT_VIEW;
    const BOOKING_VIEW = AppSettings.ENTITY_CODE.BOOKING + '_' + AppSettings.VIEW_CODE.ADD_EDIT_VIEW;
    const savedBookingPassView = this.entityService.getMiState(BOOKING_PASS_VIEW);
    const savedBookingView = this.entityService.getMiState(BOOKING_VIEW);
    if (savedBookingPassView && savedBookingView) {
      this.setPassengerView(savedBookingPassView);
      this.setBookingView(savedBookingView);
    } else {
      this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING_PASS, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((passenger: GetAttributes) => {
        if (passenger) {
          const passengerData = passenger;
          this.bookingPass = this.bookingBookingPass = _.find(passengerData.relatedAttributes, { entityCode: 'passenger' });
          this.bookingPass.relations = _.find(passengerData.relations, { otherEntityCode: 'passenger', relation: "oneToOne" });

          this.entityService.setMiState(BOOKING_PASS_VIEW, passengerData);
          this.setPassengerView(passengerData);

          this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((res: GetAttributes) => {
            if (res) {
              this.entityService.setMiState(BOOKING_VIEW, res);
              this.setBookingView(res);
            }
          });
        }
      });
    }
  }

  setPassengerView(passengerAttributeView) {
    this.passengerView = {
      relations: _.find(passengerAttributeView.relations, { otherEntityCode: 'passenger', relation: "oneToOne" })
    }
  }

  setBookingView(res) {
    this.data = res;
    this.attributeLabels = this.cs.getAttributeLabels(this.data);
    this.filteredLabels = Object.keys(this.attributeLabels)
    .filter(key => key.startsWith('booking_pass') && key.endsWith('label')&& key !== 'booking_pass.passenger_id.label')
    .reduce((result, key) => {
      result[key] = this.attributeLabels[key];
      return result;
    }, {});

    this.bookingAttributeData = this.cs.getOrganizedAttribute(this.data);

    this.bookingAttributeData.tabs[0].groups = this.bookingAttributeData.tabs[0].groups.filter(group => group.code !== 'admin_note');
    this.bookingAttributeLength = this.bookingAttributeData.tabs.length;
    this.bookingBookingPass = _.find(this.data.relatedAttributes, { entityCode: 'booking_pass' });
    this.bookingBookingPass.relations = _.find(this.data.relations, { otherEntityCode: 'booking_pass', relation: "oneToMany" })
    this.bookingBookingPass.ownerRelation = _.find(this.data.relations, { otherEntityCode: 'booking_pass', relation: "oneToOne" })
    this.bookingPetInfo = _.find(this.data.relatedAttributes, { entityCode: 'booking_pet_info' });
    this.bookingPetInfo.relations = _.find(this.data.relations, { otherEntityCode: 'booking_pet_info', relation: "oneToMany" })

    console.log(this.bookingAttributeData)



    this.bookingAttributeData.tabs[0]=  this.updateValidation( this.bookingAttributeData.tabs, 'booking_pickup_location_address');
    this.bookingAttributeData.tabs[0]=  this.updateValidation( this.bookingAttributeData.tabs, 'booking_pickup_location_geolocation');
    this.bookingAttributeData.tabs[0]=  this.updateValidation( this.bookingAttributeData.tabs, 'booking_dropoff_location_address');
    this.bookingAttributeData.tabs[0]=  this.updateValidation( this.bookingAttributeData.tabs, 'booking_dropoff_location_geolocation');
    this.bookingAttributeData.tabs[0]=  this.updateValidation( this.bookingAttributeData.tabs, 'vehicle_body_type_preference');
    this.bookingAttributeData.tabs[0]=  this.updateValidation( this.bookingAttributeData.tabs, 'booking_passenger_section');
   
   
  }
  updateValidation(data, fieldNames) {
   return _.find(data, (item) => 
      _.find(item.groups, (group) => 
          _.find(group.fields, (field) => {
              if (field.attributeCode === fieldNames) {
                  field.validation.required = false; // Set required to true
                  return true; // Stop searching once found
              }
          })
      )
  );
}
  

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('bookings.header'),
        routerLink: this.bookingId ? '../../current-bookings' : '../current-bookings',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: this.bookingId ? this.cs.getLabel('bookings.edit') : this.cs.getLabel('bookings.details'),
        routerLink: '../current-bookings',
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      }
    ];
  }


  extractLocationData(data: BookingData, field: 'geolocation' | 'address'): string[] {
    const results: string[] = [];

    // Loop through keys in the data object
    for (const key in data) {
        if (data.hasOwnProperty(key) && key.startsWith('100')) {
            const stop = data[key] as BookingStop;

            // Check the specified field and push the corresponding value
            if (field === 'geolocation' && stop.select_location_geolocation) {
                results.push(stop.select_location_geolocation);
            } else if (field === 'address' && stop.select_location_address) {
                results.push(stop.select_location_address);
            }
        }
    }

    return results.slice(1, results.length - 1);
}

 fetchAdditionalRequirementData() {
  this.additionalRequirementData.forEach(vehicle => {
    switch (vehicle.type) {
      case 'passenger':
        if (vehicle.index === 0) {
          this.passengerVehicleId = vehicle.id;
        }
        break;
      case 'additional':
        this.additionalVehicleIds.push(vehicle.id);
        break;
      case 'luggage':
        this.luggageVehicleIds.push(vehicle.id);
        break;
    }
  });
 }


  onSaveBooking(event) {
    console.log('add-booking:',event);
    if (this.selectedPassengers.length === 0) {
      this.showErrorMessage();
      return;
    }

    this.initializeBookingData();

    const passengerIds: string[] = this.getPassengerIds();

    // this.setRelationshipData(passengerIds);

    const bookingData = _.cloneDeep(event.formData);

    // Set the selected passenger IDs to booking_for & co-pass
    if (this.selectedPassengers.length > 0) {
      const passengerIds = this.selectedPassengers.map(passenger => passenger.id);
      const coPassengerIds = passengerIds.slice(1);
      
      // bookingData.booking_for = passengerIds.join(',');
      // bookingData.co_passenger_ids = coPassengerIds.join(',');

      bookingData.booking_for = passengerIds[0];
      bookingData.co_passenger_ids = coPassengerIds;
      bookingData.owner_pass_id = passengerIds[0];
    } else {
        bookingData.booking_for = null;
        bookingData.co_passenger_ids = null;
        bookingData.owner_pass_id = null;
    }

    if (this.requestedPassengers.length > 0) {
      const requestPassengerId = this.requestedPassengers[0].id;
      bookingData.requested_by = requestPassengerId;
    }

    // console.log(bookingData);



    const keysWithSubstring = _.keys(bookingData).filter(key => key.includes('booking_stops_location_geolocation'));
    const pickupKey = keysWithSubstring[1];
    const dropoffKey = keysWithSubstring[keysWithSubstring.length - 1];
    
    this.setGeolocationData(bookingData, 'booking_pickup_location', pickupKey);
    this.setGeolocationData(bookingData, 'booking_dropoff_location', dropoffKey);

    bookingData.booking_stops_location_geolocation = this.extractLocationData(bookingData, 'geolocation');
    bookingData.booking_stops_location_address = this.extractLocationData(bookingData, 'address');

    this.populateBookingData(bookingData);

    if (this.stopGeoLocations) {
      // this.addStopAttributes();
    }

    this.additionalRequirementData = event.vehicleAssignmentData;
    this.fetchAdditionalRequirementData();

    this.assignedVehicleId = this.passengerVehicleId;

    if (this.additionalVehicleIds.length > 0) {
      bookingData.passenger_vehicle_ids = this.additionalVehicleIds;
    }

    if (this.luggageVehicleIds.length > 0) {
      bookingData.luggage_vehicle_ids = this.luggageVehicleIds;
    }

    console.log(bookingData);

    // this.assignedVehicleId = _.find(this.bookingData.data, { attributeCode: this.selectVehicleCode })?.attributeValue;
    this.bookingData.data = this.bookingData.data.filter(this.filterBookingData.bind(this));

    this.entitiesData = {
      'countryCode': this.country[0].countryCode,
      'tenantCode': this.confService.getForTenantCode(),
      'entityCode': this.data.entityCode
    }

    if (!this.bookingId) {
      if (this.assignedVehicleId) {
        this.createEntityAndSaveBooking();
      }
      if (this.additionalVehicleIds.length > 0) {
        this.createEntityAndSaveBooking();
      } 
      if (this.luggageVehicleIds.length > 0) {
        this.createEntityAndSaveBooking();
      }
    } else {
      this.saveBookingData(this.bookingData);
    }
  }



  setAttributeValuesForStops(key, attributeValue) {
    if (attributeValue) {
      this.stopGeoLocations.push(attributeValue.geoLocation);
      this.stopAddresses.push(attributeValue.address);
    }
  }

  getPassengerIds() {
    return this.selectedPassengers.map(item => typeof item === 'object' ? item.booking_pass_entityId : item) || [];
  }

  saveBookingData(bookingData) {
    this.mapAttributeIds(bookingData);
    // this.addPassengerIdsToBookingData(bookingData);

    this.entityService.saveAttributeData(this.entitiesData.entityCode, this.bookingId, bookingData).subscribe(res => {
      if (this.isEditView) {
        this.showSuccessMessage('bookings.booking_update');
      } else {
        this.showSuccessMessage('bookings.booking_added');
      }

      if (this.assignedVehicleId) {
        this.assignVehicleToBooking(this.bookingId, this.assignedVehicleId, AssignmentType.NORMAL);
      }

      if (this.luggageVehicleIds.length > 0) {
        this.vehicleBookingAssignment(this.luggageVehicleIds, AssignmentType.LUGGAGE);
      }

      if (this.additionalVehicleIds.length > 0) {
        this.vehicleBookingAssignment(this.additionalVehicleIds, AssignmentType.ADDITIONAL);
      }

      if (this.assignedVehicleId || this.luggageVehicleIds.length || this.additionalVehicleIds.length) {
        this.router.navigate(['app/bookings/current-bookings']);
      }
    });
  }

  onPreviousClick() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    }
    if (this.isLastIndex() === false) {
      this.nextBtnLabel = this.cs.getLabel('bookings.lbl_book_request');
    }
  }

  onNextClick() {
    if (this.activeIndex < this.bookingAttributeLength - 1) {
      this.activeIndex++;
    }
    if (this.isLastIndex() === true) {
      this.nextBtnLabel = this.cs.getLabel('bookings.lbl_book_request');
    }
  }

  getLastIndex(): number {
    return this.bookingAttributeLength - 1;
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }

  onAddDocument(event) {
  }

  onCancel() {

    this.router.navigate(['app/bookings/current-bookings']);
  }

  onPassengerList(data) {
    this.selectedPassengers = data;
  }

  private mapAttributeIds(bookingData) {
    bookingData.data = this.cs.mapAttributeIds(bookingData.data, this.bookingAttributeData.tabs);
  }


  private assignVehicleToBooking(bookingId: string, vehicleId: string, type: AssignmentType) {
    const data: AssignVehicleToBookingPayload = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      bookingEntityId: bookingId,
      driverEntityId: vehicleId
    };

    this.bookingService.assignVehicleToBooking(data).subscribe(res => {
      // this.router.navigate(['app/bookings/current-bookings']);
      if (res) {
        this.showSuccessMessage('Vehicle is assigned to booking');
  
        if (type === AssignmentType.LUGGAGE) {
          this.luggageBookingIds.push(bookingId);
        } else if (type === AssignmentType.ADDITIONAL) {
          this.passengerBookingIds.push(bookingId);
        }
      }

    });
  }

  private createEntityAndSaveBooking() {
    this.entityService.createEntities(this.entitiesData.entityCode, this.entitiesData).subscribe((res: entityResponse) => {
      this.bookingId = res.entityId;
      this.saveBookingData(this.bookingData);
    });
  }

  vehicleBookingAssignment(vehicles: string[], type: AssignmentType) {
    console.log('vehicles:', vehicles);
    vehicles.forEach(vehicleId => {
      this.entityService.createEntities(this.entitiesData.entityCode, this.entitiesData).subscribe((res: entityResponse) => {
        if (res.entityId) {
          this.assignVehicleToBooking(res.entityId, vehicleId, type);
        }
      });
    });
  }

  private filterBookingData(item: any): boolean {
    return (
      (!_.isArray(item.attributeValue) || item.attributeValue.length > 0) &&
      item.attributeValue !== null &&

      item.attributeValue !== "" &&
      item.attributeCode !== 'stops' &&
      item.attributeCode !== this.assignVehicleControlCode &&
      item.attributeCode !== this.selectVehicleCode &&
      !/^100[0-9]/.test(item.attributeCode)
    );
  }

  private addStopAttributes() {
    this.bookingData.data.push({
      attributeCode: "booking_stops_location_geolocation",
      attributeValue: this.stopGeoLocations
    });
    this.bookingData.data.push({
      attributeCode: "booking_stops_location_address",
      attributeValue: this.stopAddresses
    });
  }

  private setGeolocationData(bookingData: any, prefix: string, key: string, count?:number) {
    const locationData = bookingData[key];
    // bookingData[`${prefix}_address`] = bookingData[count+'_booking_stop_location_address'];
    // bookingData[`${prefix}_geolocation`] = bookingData[count+'_booking_stop_location_geolocation'];
       if (locationData) {
        bookingData[`${prefix}_address`] = locationData.select_location_address;
        bookingData[`${prefix}_geolocation`] = locationData.select_location_geolocation;
      }
  }

  private initializeBookingData() {
    this.bookingData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
      relationshipData: []
    };
  }


  private setRelationshipData(passengerIds: string[]) {
    passengerIds.forEach((item, index) => {
      const relationshipConfig = index === 0 ? this.bookingBookingPass.relations : this.bookingBookingPass.ownerRelation;
      this.bookingData.relationshipData.push({
        entityRelationshipConfigId: relationshipConfig.entityRelationshipConfigId,
        otherEntityId: item
      });
    });
  }

  private populateBookingData(bookingData: any) {
    for (const [key, value] of Object.entries(bookingData)) {
      let attributeValue = value;
      if (AppSettings.BOOKING.DATE_ATTRIBUTE_CODES.includes(key)) {
        attributeValue = new Date(value as string).getTime();
      }
      if (key.includes('booking_stops_location_geolocation')) {
        // this.setAttributeValuesForStops(key, attributeValue);
      } else {
        this.bookingData.data.push({ attributeCode: key, attributeValue });
      }
    }
  }

  private showErrorMessage() {
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Error',
      detail: this.cs.getLabel('bookings.msg_please_select_passengers')
    });
  }


  private showSuccessMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Successful',
      detail: this.cs.getLabel(labelKey),
    });
  }

  passengerSidebarOpen() {
    this.uiService.showSideDriverDrawer();
  }

  handleOverlayToggle() {
    this.uiService.hideSideDriverDrawer();
  }

}
