import { CommonModule, JsonPipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import type { Country } from 'app/modules/shared/models/country';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import * as _ from 'lodash';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { CommonBindingDataService } from '../../../../services/common-binding-data.service';

@Component({
  selector: 'app-mi-auto-complete',
  standalone: true,
  imports: [JsonPipe, AutoCompleteModule, FormsModule, ReactiveFormsModule, TranslateModule, InputTextModule, JsonPipe, CheckboxModule, DividerModule, NgClass, CommonModule],
  templateUrl: './mi-auto-complete.component.html',
  styleUrl: './mi-auto-complete.component.scss'
})
export class MiAutoCompleteComponent {
  checked = [];
  @Input() attributeValues;
  @Input() field: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  formName: FormGroup;
  searchValue: string = '';
  selectedControl: string[] = [];
  inputPlaceholder: string;
  filteredValues: any[] = [];
  suggestions: any[] | undefined;
  selectedItems: any = [];

  items: any[] | undefined;
  country: Country;
  searchQuery: any;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private entityService: EntityService,
    private configService: ConfigService) {
    this.formName = this.formGroupDirective.control;
  }

  search(event: AutoCompleteCompleteEvent) {
    this.searchQuery = event.query;
    const viewCode = this.field.attributeCode.toUpperCase() + '_' + 'AUTOCOMPLETE_VIEW'
    this.fetchFromAPI(viewCode, event.query);
  }

  onSearchClear() {
    this.searchQuery = '';
    this.selectedItems = [];
  }

  onSelectItem(event) {

    const items = this.selectedItems.filter(item => typeof item === 'string');
    items.push(event.value[this.field.attributeCode]);
    this.selectedItems = _.uniq(items);
    this.formName.controls[this.field.attributeCode].setValue(this.selectedItems);
    this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    this.searchQuery = '';
  }


  fetchFromAPI(viewCode: string, search: string) {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const requestBody = {
      viewCode: viewCode,
      countryCode: this.country[0].countryCode,
      forTenantCode: this.configService.getForTenantCode(),
      searchText: search,
      actionStatus: "",
      deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED
    }

    const attributeCodes = ['active_vehicle_id', 'default_vehicle_id']

    if (this.field.attributeCode === 'checkin_location_id') {
      this.moduleName = AppSettings.ENTITY_CODE.LOCATION
    }
    if (this.field.attributeCode === 'checkin_location_id') {
      this.moduleName = AppSettings.ENTITY_CODE.LOCATION
    }
    if (attributeCodes.includes(this.field.attributeCode)) {
      this.moduleName = AppSettings.ENTITY_CODE.VEHICLE
    }
    if (this.field.attributeCode === 'driver') {
      this.moduleName = AppSettings.ENTITY_CODE.DRIVER
    }
    this.entityService.autoComplete(AppSettings.ENTITY_CODE[this.moduleName.toUpperCase()], requestBody).subscribe(res => {
      this.items = this.cs.getTableData(res) || [];
    });
  }
}
