<!-- @if(field.attributeCode==="departure_time_and_date" || field.attributeCode=== "return_time_and_date"){ -->
@if(field.attributeCode==="pickup_date"){
    <div class="flex justify-content-left departure-time-and-date mb-3">
        <div class="flex align-items-center w-full justify-content-between">

                <!-- <div class="col-7 md:col-7 sm:col-7 lg:col-6 relative p-0">
                    <p-calendar
                        class="time-picker booking"
                        [(ngModel)]="timeValue"
                        [touchUI]="isTouchDevice"
                        [iconDisplay]="'input'"
                        [showIcon]="true"
                        [timeOnly]="true"
                        [showTime]="true"
                        [hourFormat]="12"
                        (onSelect)="onDateSelect($event,'time')"
                        [placeholder]="'bookings.lbl_time' | translate"
                        inputId="templatedisplay">
                        <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
                            <i class="mi-lg mi-basic-clock wh-16 pointer-events-none" (click)="clickCallBack($event)"></i>
                        </ng-template>
                    </p-calendar>
        
                    <div class="input-switch-container">
                        <p-inputSwitch [(ngModel)]="switchValue" (onChange)="onSwitchChange($event)"></p-inputSwitch>
                        <span class="switch-text " [ngClass]="{'later': !switchValue,'now':switchValue}">{{ switchValue ? 'Now'
                            : 'Later' }}</span>
                    </div>
                </div> -->
                <div class="col-5 md:col-5 sm:col-5 lg:col-12 p-0">
                <form [formGroup]="formName">
                    <ng-template pTemplate="inputicon">
                        <i class="mi-lg calendar_icon wh-16 pointer-events-none"></i>
                    </ng-template>
                    <p-calendar
                        class="date-picker booking  w-6"
                        [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
                        [touchUI]="isTouchDevice"
                        [readonlyInput]="true"
                        [showIcon]="true"
                        [placeholder]="placeholder"
                        (onSelect)="onDateSelect($event,'date')"
                        appendTo="body"
                        dateFormat="dd MM yy"
                        [showButtonBar]="true"
                        [defaultDate]="defaultDate"
                        [minDate]="minDate" />
                </form>
            </div>
        </div>
    </div>

}@else if(field.attributeCode=== "pickup_time") {
    <div class="flex justify-content-left departure-time-and-date mb-3 ml-2">
        <div class="flex align-items-center w-full justify-content-between">
            <div class="col-7 md:col-7 sm:col-7 lg:col-12 relative p-0">
                <p-calendar
                    class="time-picker booking"
                    [(ngModel)]="timeValue"
                    [touchUI]="isTouchDevice"
                    [iconDisplay]="'input'"
                    [showIcon]="true"
                    [timeOnly]="true"
                    [showTime]="true"
                    [hourFormat]="12"
                    (onSelect)="onDateSelect($event,'time')"
                    [placeholder]="'bookings.lbl_time' | translate"
                    inputId="templatedisplay">
                    <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
                        <i class="mi-lg mi-basic-clock wh-16 pointer-events-none" (click)="clickCallBack($event)"></i>
                    </ng-template>
                </p-calendar>
        
                <div class="input-switch-container">
                    <p-inputSwitch [(ngModel)]="switchValue" (onChange)="onSwitchChange($event)"></p-inputSwitch>
                    <span class="switch-text " [ngClass]="{'later': !switchValue,'now':switchValue}">{{ switchValue ? 'Now'
                        : 'Later' }}</span>
                </div>
            </div>
        </div>
    </div>
}@else if(field.attributeCode=== "time_and_date") {
    <form [formGroup]="formName">
        <input style="display: none;" type="text" pInputText [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" class="w-full"/>
    </form>
    <p-calendar appendTo="body" [(ngModel)]="rangeDates" [showIcon]="true" rangeSelection="true" hideOnDateTimeSelect="true"
        [placeholder]="placeholder" (onSelect)="onDateRangeSelect()" selectionMode="range" [readonlyInput]="true"></p-calendar>
}@else{
    <form [formGroup]="formName">
    <p-calendar
        [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
        class="incident-date-time-filter"
        [showIcon]="true"
        [showTime]="true"
        dateFormat="dd/mm/yy"
        [placeholder]="placeholder"
        (onSelect)="onDateSelect($event)"
        appendTo="body"
        icon="mi-lg calendar_date_time"
    >

</p-calendar>
</form>
}