import { NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MiFieldsComponent } from '../mi-fields/mi-fields.component';
import { MiFilterFieldsComponent } from '../mi-fields/mi-filter-fields/mi-filter-fields.component';

@Component({
  selector: 'app-advance-filter-form',
  templateUrl: './advance-filter-form.component.html',
  styleUrls: ['./advance-filter-form.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MiFieldsComponent, NgClass, MiFilterFieldsComponent]
})
export class AdvanceFilterFormComponent implements OnInit {
  filterForm: FormGroup | undefined;
  @Input() fields: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() filterList;
  @Input() selected: any = {};
  @Input() attributeValues;
  @Input() isFilterBox: boolean = false;
  @Output() onValueChange: EventEmitter<any> = new EventEmitter();
  @ViewChild("userForm") userForm: NgForm;
  formGroupFields = [];
  constructor(private cd: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.selected = this.fields[0];
    this.buildGroups();
    this.filterForm.valueChanges.subscribe(val => {
      this.onValueChange.emit(val);
    });
  }

  private buildGroups() {
    const miFormControl = this.getFormControl();
    this.filterForm = new FormGroup(miFormControl);
  }

  private getFormControl() {
    for (const field of this.fields) {
      field.fieldName = field.attributeCode;
      console.log(this.attributeValues?.[field?.attributeCode])
      this.formGroupFields[field.attributeCode] = new FormControl(this.attributeValues?.[field?.attributeCode]);
    }
    return this.formGroupFields;
  }

  resetForm() {
    this.filterForm.reset();
    this.filterForm.markAsPristine();
    this.filterForm.markAsUntouched();
    for (const field of this.fields) {
      this.filterForm.controls[field.attributeCode].setValue('');
      this.filterForm.controls[field.attributeCode].updateValueAndValidity();
    }
    this.filterForm.updateValueAndValidity();
    this.cd.detectChanges();

  }
}