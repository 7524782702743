<app-dashboard-navbar [includeManageWidgets]="false"></app-dashboard-navbar>

<div style="position: relative" appAccessProvider [accessCode]="{ menuCode: 'DASHBOARD', pageCode: 'HEAT_MAP' }">
  <div class="map-div" id="map"></div>
  <div id="style-selector-control" class="map-control mr-3 dark-light-toggle">
    <p-toggleButton
      id="toggle_button"
      class="p-0"
      id="style-selector"
      offIcon="mi-lg mi-night_mode wh-40"
      onIcon="mi-lg mi-day_mode wh-40"
      (onChange)="toggleDarkAndLightMap($event)"
      [(ngModel)]="checked"
    ></p-toggleButton>
  </div>
  <button
    id="my-location-control"
    class="geolocation-button"
    (click)="getCurrentLocation()"
  >
    <i
      class="mi-lg mi-locate_me_target wh-24"
      style="background-color: #1e90ff"
    ></i>
  </button>
</div>
