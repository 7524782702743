import { Component, Input } from '@angular/core';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MiTooltipComponent } from '../../../../shared/components/mi-fields/mi-tooltip/mi-tooltip.component';
import { AppSettings } from '../../../../shared/app.settings';
import { EntityService } from '../../../../shared/services/entity.service';
import { DatePipe } from '@angular/common';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-privilege-settings',
  standalone: true,
  imports: [TranslateModule, MiTooltipComponent, DatePipe, AccessProviderDirective],
  templateUrl: './privilege-settings.component.html',
  styleUrl: './privilege-settings.component.scss',
  providers: [DatePipe]
})
export class PrivilegeSettingsComponent {
  iconPos: any = 'right';
  @Input() attributeValues: any;
  @Input() attributeLabels: any;
  @Input() vehicleAttributeData: any;
  @Input() passengerDetails: any;
  appAccess: any;
  classification: any;
  transportationRestrictions: any;
  bookingTimeRestriction: any;
  assignLocation: any;
  homeStayLocationRestriction: any;
  specialRequest: any;
  otherInformation: any;
  tab: any;
  bookingTimeRestrictionArray: any = [];
  showBookingTimeRestrictions: boolean = false;

  constructor(
    public cs: CommonBindingDataService,
    private router: Router,
    private entityService: EntityService,
    private datePipe: DatePipe
  ) { }


  ngOnInit() {
    this.tab = this.vehicleAttributeData.tabs.find(ele => ele.tabCode === 'privilege_settings');
    this.appAccess = this.tab.groups.find(ele => ele.code === 'app_access');
    this.classification = this.tab.groups.find(ele => ele.code === 'classification');
    this.transportationRestrictions = this.tab.groups.find(ele => ele.code === 'transportation_restrictions');
    this.bookingTimeRestriction = this.tab.groups.find(ele => ele.code === 'booking_time_restriction');
    this.assignLocation = this.tab.groups.find(ele => ele.code === 'assign_location');
    this.homeStayLocationRestriction = this.tab.groups.find(ele => ele.code === 'home_stay_location_restrictions');
    this.specialRequest = this.tab.groups.find(ele => ele.code === 'special_request');
    this.otherInformation = this.tab.groups.find(ele => ele.code === 'other_information');
    this.getBookingTimeRestriction();
    this.parseAttributeValues();
  }


  private parseAttributeValues() {
    const dateAttributes = [
      AppSettings.DATE_ATTRIBUTE_IDS.PASSENGER_DATE_OF_BIRTH,
      AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_DATE,
      AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_START_DATE,
      AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_END_DATE,
      AppSettings.DATE_ATTRIBUTE_IDS.VOID_DATE_TIME
    ];
    dateAttributes.forEach(attr => {
      this.attributeValues[attr] = this.attributeValues[attr] ? this.datePipe.transform(new Date(this.attributeValues[attr]), 'dd MMM yyyy') : null;
    });
  }

  getBookingTimeRestriction() {
    const bookingTimeRestrictionIdArray = this.attributeValues[AppSettings.FIELDS.BOOKING_TIME_RESTRICTION] as [];
    const that = this;
    bookingTimeRestrictionIdArray?.forEach(function(entityId, i) {
      that.entityService.getEntity(entityId, AppSettings.ENTITY_CODE.PASSENGER_BOOKING_TIME_RESTRICTION, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((res: any) => {
        that.bookingTimeRestrictionArray[i] = { index: i, bookingTimeRestrictions: res.attributeCodeValueDtoList };
        if (i === (bookingTimeRestrictionIdArray.length - 1)) {
          setTimeout(() => {
            that.showBookingTimeRestrictions = true;
          }, 500);

        }
      });
    });

  }

  redirectToEdit() {
    this.router.navigate(['/app/passengers/edit/' + this.passengerDetails.entityId]);
  }

}
