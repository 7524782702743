import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DriverDetailsComponent } from '../assign-driver/driver-details/driver-details.component';
import { AssignDriverComponent } from '../assign-driver/assign-driver.component';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { SidebarModule } from 'primeng/sidebar';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import * as _ from 'lodash';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-driver-sidebar',
  standalone: true,
  templateUrl: './driver-sidebar.component.html',
  styleUrl: './driver-sidebar.component.scss',
  imports: [
    DriverDetailsComponent,
    AssignDriverComponent,
    DialogHeaderComponent,
    SidebarModule,
    AccessProviderDirective
  ],
})
export class DriverSidebarComponent {
  @ViewChild(AssignDriverComponent) assignDiverComponent: AssignDriverComponent;
  @ViewChild(DialogHeaderComponent) dialogComponent: DialogHeaderComponent;
  @Input() visible: boolean;
  @Input() position: string;
  @Input() rightDialogPosition: string;
  @Input() bookingId: string;
  
  showDriverDetails: boolean = false;
  assignDriverHeader: string;
  driverDetailItem: any = {};



  onShowDriverDetail(event) {
    this.showDriverDetails = event.hasData;
    this.driverDetailItem = event.eventData;
    this.bookingId = event.bookingId
  }

  onCloseDriverDetailView(event) {
    this.showDriverDetails = event;
  }

  onDriverFilterValueChange(event) {
    const driverFormData = event;
    this.assignDiverComponent.entityData.filters = [];
    for (const [key, value] of Object.entries(driverFormData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.assignDiverComponent.entityData.filters.push({
          attributeCode: key,
          attributeValue,
        });
      }
    }
    this.assignDiverComponent.entityData.filters = _.filter(
      _.uniq(this.assignDiverComponent.entityData.filters, function(item, key, a) {
        if (item.attributeValue.length > 0) {
          return item.attributeId;
        }
      }),
      function(element) {
        if (element.attributeValue.length > 0) {
          return true;
        }
        return false;
      }
    );
    this.assignDiverComponent.entityData.offset = event.first;
    this.assignDiverComponent.entityData.filters = this.cs.mapAttributeIds(this.assignDiverComponent.entityData.filters, this.dialogComponent.filterList, 'filter');
    this.assignDiverComponent.searchEntity();
  }

  constructor(private cs: CommonBindingDataService) {

  }

  ngOnInit() {

    this.assignDriverHeader = this.cs.getLabel("dispatch.assign_driver");
  }

}
