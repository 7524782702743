import { Component, OnInit } from "@angular/core";
import { DashboardNavbarComponent } from "../dashboard-navbar/dashboard-navbar.component";
import { MenuItem } from "primeng/api";
import { MenuModule } from "primeng/menu";
import { ButtonModule } from "primeng/button";
import { NgClass } from "@angular/common";
import { ChartModule } from "primeng/chart";
import { Chart } from "chart.js";
import { TranslateModule } from "@ngx-translate/core";
import { VehicleStatusComponent } from "./vehicle-status/vehicle-status.component";
import { EquipmentStatusComponent } from "./equipment-status/equipment-status.component";
import { VehicleAssignmentsComponent } from "./vehicle-assignments/vehicle-assignments.component";
import { OpenIssuesComponent } from "./open-issues/open-issues.component";
import { ServiceRemindersComponent } from "./service-reminders/service-reminders.component";
import { ServiceComplianceComponent } from "./service-compliance/service-compliance.component";
import { TimeToResolveGraphComponent } from "./time-to-resolve-graph/time-to-resolve-graph.component";
import { RepairPriorityLineChartComponent } from "./repair-priority-line-chart/repair-priority-line-chart.component";
import { InspectionFailureComponent } from "./inspection-failure/inspection-failure.component";
import { OverdueInspectionsComponent } from "./overdue-inspections/overdue-inspections.component";
import { InspectionPieChartComponent } from "./inspection-pie-chart/inspection-pie-chart.component";
import { InspectionSubmissionsComponent } from "./inspection-submissions/inspection-submissions.component";
import { ServiceCostBarChartComponent } from "./service-cost-bar-chart/service-cost-bar-chart.component";
import { RecentCommentsSectionComponent } from "./recent-comments-section/recent-comments-section.component";
import data from "../../../../../../src/assets/json/dashboard/data.json";
import { DashboardService } from "../dashboard/dashboard.service";
import { AppSettings } from "app/modules/shared/app.settings";
import { ConfigService } from "app/modules/shared/services/config.service";
import { Country } from "app/modules/shared/models/country";
import { BookingStatusComponent } from "../dashboard/booking-status/booking-status.component";
import { RestApiService } from "app/modules/shared/services/rest-api.service";
import { Subscription } from "rxjs";
import { JourneyStatusComponent } from "../dashboard/journey-status/journey-status.component";
import { PassengerByTypesComponent } from "../dashboard/passenger-by-types/passenger-by-types.component";
import { CancelledJourneyComponent } from "../dashboard/cancelled-journey/cancelled-journey.component";
import { CommonBindingDataService } from "app/modules/shared/services/common-binding-data.service";
import { AccessProviderDirective } from "app/modules/shared/directives/access-provider.directive";
import { Language } from "app/modules/shared/models/language";

interface DashboardCards {
  label: string;
  value: string;
  activeIndex: number;
  actualIndex: number;
  height: string;
  uiColumns: number;
  isChecked: boolean;
}

@Component({
  selector: "app-vehicles",
  templateUrl: "./vehicles.component.html",
  styleUrls: ["./vehicles.component.scss"],
  standalone: true,
  imports: [
    DashboardNavbarComponent,
    MenuModule,
    ButtonModule,
    NgClass,
    ChartModule,
    TranslateModule,
    VehicleStatusComponent,
    EquipmentStatusComponent,
    VehicleAssignmentsComponent,
    OpenIssuesComponent,
    ServiceRemindersComponent,
    ServiceComplianceComponent,
    TimeToResolveGraphComponent,
    RepairPriorityLineChartComponent,
    InspectionFailureComponent,
    OverdueInspectionsComponent,
    InspectionPieChartComponent,
    InspectionSubmissionsComponent,
    ServiceCostBarChartComponent,
    RecentCommentsSectionComponent,
    BookingStatusComponent,
    JourneyStatusComponent,
    PassengerByTypesComponent,
    CancelledJourneyComponent,
    AccessProviderDirective
  ],
})
export class VehiclesComponent implements OnInit {
  items!: MenuItem[];
  vehicleStatusData: any[];
  equipmentStatusData: any[];
  vehicleAssignmentData: any[];
  pieChartData: any;
  pieChartOptions: any;
  lineChartData: any;
  lineChartOptions: any;
  barData: any;
  barOptions: any;
  commentSectionsData: any[];
  country: Country;
  language: Language;
  bookingStatusData: any[];
  liveStatusData: any[];
  driverStatusData: any[];
  transportRequestData: any[];
  playerData: any[];
  journeyStatusData: any[];
  totalVehiclesData: any[];
  cancelledJourneyData: any[];
  subscription!: Subscription;
  widgetSubscription!: Subscription;
  widgetData: DashboardCards[] = [];
  reqPayload = {
    limit: 20,
    offset: 0,
    searchStr: '',
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    forTenantCode: this.configService.getForTenantCode(),
    attributeCode: AppSettings.ATTRIBUTE_CODE.BOOKING_STATUS
  };
  countPayload: {
    forTenantCode: string;
    countryCode: string;
  }
  cancelledJourneyPayload: {
    forTenantCode: string,
    countryCode: string,
    languageCode: string,
    eventCode: string,
    fromDate: number,
    toDate: number,
  };

  constructor(
    private dashboardService: DashboardService,
    private configService: ConfigService,
    private restApiService: RestApiService,
    private cs: CommonBindingDataService,
  ) { }

  ngOnInit() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.countPayload = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
    };
    this.cancelledJourneyPayload = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      eventCode: "e001",
      fromDate: 0,
      toDate: 0,
    };
    this.initPieChart();
    this.initLineChart();
    this.initBarChart();
    this.getVehicleModuleData();
    this.getVehicleAssignmentData();
    this.getBookingStatusData();
    this.items = [{ label: "Hide", icon: "pi pi-eye-slash" }];
    // this.vehicleStatusData = data.vehicleModuleData;
    this.equipmentStatusData = data.equipmentStatusData;
    this.commentSectionsData = data.commentSectionsData;

    this.widgetSubscription = this.dashboardService.changeObservable$.subscribe(data => {
      this.widgetData = data;
    });
  }

  isVisible(labelKey: string): boolean {
    if (!this.widgetData || this.widgetData.length === 0) {
      return true;
    }
    const item = this.widgetData.find(widget => widget.value === this.cs.getLabel(labelKey));
    return item ? item.isChecked : true;
  }

  isBookingStatusVisible(): boolean {
    return this.isVisible('dashboard_card_ids.booking_status');
  }

  isVehicleStatusVisible(): boolean {
    return this.isVisible('dashboard_card_ids.vehicle_status');
  }

  isVehicleAssignmentVisible(): boolean {
    return this.isVisible('dashboard_card_ids.assigned_vehicles');
  }

  isCancelledJourneyVisible(): boolean {
    return this.isVisible('dashboard_card_ids.cancelled_journey');
  }

  isJourneyStatusVisible(): boolean {
    return this.isVisible('dashboard_card_ids.journey_status');
  }

  isPassengerByTypesVisible(): boolean {
    return this.isVisible('dashboard_card_ids.passenger_by_types');
  }

  initPieChart() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");

    this.pieChartData = {
      labels: ["With Inspection", "Without Inspection"],
      datasets: [
        {
          data: [540, 325],
          backgroundColor: ["#fb7757", "#23c896"],
        },
      ],
    };
    this.pieChartOptions = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
            color: textColor,
          },
        },
      },
    };
  }

  initLineChart() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    this.lineChartData = {
      labels: [
        "May `23",
        "Jun `23",
        "Jul `23",
        "Aug `23",
        "Sep `23",
        "Oct `23",
      ],
      datasets: [
        {
          label: "No Repair Priority Class",
          data: [65, 59, 80, 81, 56, 55],
          fill: true,
          borderColor: "#777",
          tension: 0,
        },
        {
          label: "Emergency",
          data: [28, 48, 40, 19, 86, 27],
          fill: true,
          borderColor: "#f00",
          tension: 0,
        },
        {
          label: "Non-Scheduled",
          data: [32, 47, 39, 77, 75, 11],
          fill: true,
          borderColor: "#ffa500",
          tension: 0,
        },
        {
          label: "Scheduled",
          data: [17, 45, 78, 96, 91, 44],
          fill: true,
          borderColor: "#080",
          tension: 0,
        },
      ],
    };

    this.lineChartOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
            color: textColor,
            generateLabels: function(chart: any) {
              const labels =
                Chart.defaults.plugins.legend.labels.generateLabels(chart);
              labels.forEach((label) => {
                label.fillStyle = label.strokeStyle;
              });
              return labels;
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
            drawOnChartArea: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
            callback: function(value: number) {
              return `${value}%`;
            },
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
      elements: {
        point: {
          radius: 4,
          backgroundColor: (context: any) => {
            const dataset = this.lineChartData.datasets[context.datasetIndex];
            return dataset.borderColor;
          },
        },
      },
    };
  }

  initBarChart() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    this.barData = {
      labels: [
        "May `23",
        "Jun `23",
        "Jul `23",
        "Aug `23",
        "Sep `23",
        "Oct `23",
      ],
      datasets: [
        {
          label: "My First dataset",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: [650, 590, 800, 810, 560, 550],
          barThickness: 40,
        },
      ],
    };

    this.barOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
            drawOnChartArea: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };
  }

  getVehicleModuleData() {
    const vehiclePayload = {
      limit: 20,
      offset: 0,
      searchStr: '',
      defaultSortColumn: 'updatedAt',
      defaultSortType: 'desc',
      forTenantCode: this.configService.getForTenantCode(),
      attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.VEHICLE_STATUS
    };
    this.dashboardService.getVehicleModuleData(vehiclePayload).subscribe((res) => {
      this.vehicleStatusData = res.data;
    });
  }

  getVehicleAssignmentData() {
    const countPayload = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
    };
    this.dashboardService.getTotalVehicleData(countPayload).subscribe((res) => {
      const countsData = res.counts;
      this.vehicleAssignmentData = [{
        labelKey: 'ASSIGNED',
        labelValue: 'ASSIGNED',
        usage: countsData.ASSIGNED,
      }, {
        labelKey: 'UNASSIGNED',
        labelValue: 'UNASSIGNED',
        usage: countsData.UNASSIGNED,
      }];
    });
  }

  getBookingStatusData() {
    const payload = this.getMultiApiPayload();
    this.dashboardService.multipleApiData(payload).subscribe((res) => {
      const liveJourneyTypeData = JSON.parse(res.liveJourneyTypeData.responseBody);
      const newRequest = JSON.parse(res?.newRequest?.responseBody);
      const criticalWatchListData = JSON.parse(res?.criticalWatchListData?.responseBody);
      const passengerByType = JSON.parse(res?.passengerByType?.responseBody);
      const totalVehicleData = JSON.parse(res?.totalVehicleData?.responseBody);
      const vehicleByBodyType = JSON.parse(res?.vehicleByBodyType?.responseBody);
      const cancelledJourney = JSON.parse(res.cancelledJourney.responseBody);

      const newRequestData = newRequest.data.find(v => v.labelKey === AppSettings.BOOKING.STATUS.NEW_REQUEST);
      const countsData = criticalWatchListData.counts;
      this.bookingStatusData = [newRequestData, {
        labelKey: AppSettings.BOOKING_TAB_LIST.CRITICAL,
        labelValue: AppSettings.BOOKING_TAB_LIST.CRITICAL,
        usage: countsData.CRITICAL,
      }, {
          labelKey: AppSettings.BOOKING_TAB_LIST.WATCHLIST,
          labelValue: AppSettings.BOOKING_TAB_LIST.WATCHLIST,
          usage: countsData.WATCHLIST,
        }];

      const completedData = newRequest.data.find(v => v.labelKey === AppSettings.BOOKING_STATUS.COMPLETED);
      const expiredData = newRequest.data.find(v => v.labelKey === AppSettings.BOOKING_STATUS.EXPIRED);
      this.journeyStatusData = [completedData, expiredData];
      this.liveStatusData = newRequest.data;
      this.transportRequestData = liveJourneyTypeData.data;
      this.playerData = passengerByType.data;
      const totalCount = totalVehicleData.counts.ALL;
      const attributeData = vehicleByBodyType.data;
      const totalAvailable = attributeData.reduce((total, a) => total + a.usage, 0);
      this.totalVehiclesData = [{
        label: `All(${totalCount})`,
        available: totalAvailable,
        occupied: 0
      }];
      attributeData.forEach(v => this.totalVehiclesData.push({
        ...v,
        label: v.labelValue,
        available: v.usage,
        occupied: 0
      }));

      const totalCancelledJourney = Object.values(cancelledJourney).reduce((sum: number, count: number) => {
        return sum + count;
      }, 0);

      this.cancelledJourneyData = Object.keys(cancelledJourney).map(key => ({
        label: key,
        count: cancelledJourney[key],
        labelClass: "font-13 default-text-black-color",
        countClass: "font-16 font-semibold default-text-black-color"
      }));

      this.cancelledJourneyData.unshift({
        label: "Total",
        count: totalCancelledJourney,
        labelClass: "font-14 font-semibold",
        countClass: "font-20 default-text-blue-color font-bold"
      });
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.widgetSubscription) {
      this.widgetSubscription.unsubscribe();
    }
  }

  private getMultiApiPayload() {
    return [
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/attributes/preset-values/search`,
        inputBody: this.reqPayload,
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.NEW_REQUEST,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/custom/canceled-booking-count-usertype`,
        inputBody: this.cancelledJourneyPayload,
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.CANCELLED_JOURNEY,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/entities/counts`,
        inputBody: this.countPayload,
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.CRITICAL_WATCHLIST_DATA,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.VEHICLE}/entities/counts`,
        inputBody: this.countPayload,
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.TOTAL_VEHICLE_DATA,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.VEHICLE}/attributes/preset-values/search`,
        inputBody: {
          limit: 20,
          offset: 0,
          searchStr: '',
          defaultSortColumn: 'updatedAt',
          defaultSortType: 'desc',
          forTenantCode: this.configService.getForTenantCode(),
          attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.BODY_TYPE
        },
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.VEHICLE_BY_BODY_TYPE,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/attributes/preset-values/search`,
        inputBody: {
          limit: 20,
          offset: 0,
          searchStr: '',
          defaultSortColumn: 'updatedAt',
          defaultSortType: 'desc',
          forTenantCode: this.configService.getForTenantCode(),
          attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.TRANSPORTATION_TYPE
        },
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.LIVE_JOURNEY_TYPE_DATA,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.PASSENGERS}/custom/preset-labels/search`,
        inputBody: {
          forTenantCode: this.configService.getForTenantCode(),
          attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.PASSENGER_TYPE,
          searchStr: '',
          usageRequired: true
        },
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.PASSENGER_BY_TYPE,
        headers: this.restApiService.getHeaderForMultipleAPI()
      }
    ];
  }
}
