import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, OnDestroy } from "@angular/core";
import { MenuItem } from "primeng/api";
import { TabMenuModule } from "primeng/tabmenu";
import { BookingListCardComponent } from "./booking-list-card/booking-list-card.component";
import data from "../../../../../../src/assets/json/dispatch/data.json";
import { EntityList } from "../../../vehicles/models/entity.models";
import { AppSettings } from "../../../shared/app.settings";
import { Country } from "../../../shared/models/country";
import { Language } from "../../../shared/models/language";
import { EntityService } from "../../../shared/services/entity.service";
import { BookingResponse } from "../../../bookings/models/booking.models";
import { ConfigService } from "../../../shared/services/config.service";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { NoResultFoundComponent } from "app/modules/shared/components/no-result-found/no-result-found.component";

@Component({
  selector: "app-booking-section",
  templateUrl: "./booking-section.component.html",
  styleUrls: ["./booking-section.component.scss"],
  standalone: true,
  imports: [TabMenuModule, BookingListCardComponent, NoResultFoundComponent],
})
export class BookingSectionComponent implements OnInit, OnDestroy {
  @Output() showBookingDetails: EventEmitter<any> = new EventEmitter<any>();
  items: MenuItem[];
  bookingsList: any = [];
  newBookingStatusList: any[] = [];
  activeItem: MenuItem;
  country: Country;
  language: Language;
  entityData = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: '',
    actionStatus: ''
  };
  prevSearch;
  maxDataReached = false;
  private loading = false;
  scrollTimeoutId: any;
  constructor(private enitityService: EntityService,
    private configService: ConfigService,
    private cs: CommonBindingDataService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.initializeTabs();
    this.getBookingList();
    this.activeItem = this.items[0];
    this.getBookingList();

    this.scrollTimeoutId = setTimeout(() => {
      const cb = () => {
        if (!this.loading && !this.maxDataReached) {
          this.entityData.offset = this.entityData.offset + AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT;
          this.getBookingList();
        }
      };
      this.applyInfiniteScroll('.booking-detail-container', cb);
    }, 30);
  }

  ngOnDestroy() {
    if (this.scrollTimeoutId) {
      clearTimeout(this.scrollTimeoutId);
    }
  }
  applyInfiniteScroll(selector, onEndPageCallback) {
    const contentEl = document.querySelector(selector) as HTMLElement;
    const handleInfiniteScroll = () => {
      const heightDiff = contentEl.scrollHeight - contentEl.offsetHeight;
      const scrollThreshold = heightDiff * 0.6;
      if (contentEl.scrollTop >= scrollThreshold && !this.loading) {
        onEndPageCallback();
      }
    };

    const checkNeedMoreDataImmediately = () => {
      const heightDiff = contentEl.scrollHeight - contentEl.offsetHeight;
      if (heightDiff <= 0 && !this.loading && !this.maxDataReached) {
        onEndPageCallback();
      }
    };
    contentEl.addEventListener("scroll", handleInfiniteScroll);
    setTimeout(() => checkNeedMoreDataImmediately(), 100);
  }

  initializeTabs() {
    this.items = [
      { label: "ALL", number: '', booking_status: '' },
      { label: "NEW", number: '', booking_status: '1' },
      { label: "ASSIGNED", number: '', booking_status: '3' },
      { label: "COMPLETED", number: '', booking_status: '9' },
    ];
  }

  ngDoCheck() {
    this.entityData.searchStr = this.cs.getSearchData();
    if (this.entityData.searchStr !== this.prevSearch && (this.entityData.searchStr.length >= 3 || this.entityData.searchStr.length === 0)) {
      this.prevSearch = this.entityData.searchStr;
      this.getBookingList();
    }
  }

  handleBookingCardClicked(event) {
    const hasData = event !== null && event !== undefined;
    const eventData = hasData ? event : null;
    const emissionData = { hasData: hasData, eventData: eventData };
    this.showBookingDetails.emit(emissionData);
  }

  getBookingList() {
    if (this.loading) return;
    this.loading = true;
    this.entityData.countryCode = this.country[0].countryCode;
    this.enitityService.searchEntity(AppSettings.ENTITY_CODE.BOOKING, this.entityData).subscribe((res: BookingResponse) => {
      this.loading = false;
      if (!res) {
        console.error('Invalid response.');
        return;
      }
      this.items[0].number = res.count;
      const newBookings = res.data?.map(data => {
        const relatedPassengers = data?.relatedData?.map(relatedData => ({
          id: relatedData.id,
          ...relatedData.values
        })) || [];

        return { id: data.id, ...data.values, passengers: relatedPassengers };
      }) || [];
      this.bookingsList = [...this.bookingsList, ...newBookings];
      if (newBookings.length < this.entityData.limit) {
        this.maxDataReached = true;
      }

      this.onActiveItemChange(this.items[3]);
      this.onActiveItemChange(this.items[2]);
      this.onActiveItemChange(this.items[1]);
      this.onActiveItemChange(this.items[0]);
    });
  }

  onActiveItemChange(event) {
    this.items[0] = event;
    if (event.label === 'ALL') {
      this.newBookingStatusList = this.bookingsList
    } else {
      this.newBookingStatusList = this.bookingsList.filter(ele => ele.booking_status == event.booking_status)
    }
    this.items[0].number = this.newBookingStatusList.length
  }

}
