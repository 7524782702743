import { Component, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { EntityList } from 'app/modules/vehicles/models/entity.models';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';

@Component({
  selector: 'app-show-duty-reason',
  standalone: true,
  imports: [OverlayPanelModule, TranslateModule],
  templateUrl: './show-duty-reason.component.html',
  styleUrl: './show-duty-reason.component.scss'
})
export class ShowDutyReasonComponent{
  @ViewChild(OverlayPanel) overlayPanel: OverlayPanel;
  offDutyReason: string;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.BOTH,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  };

  constructor(
    private cs: CommonBindingDataService,
    private entityService: EntityService,
    private configService: ConfigService
  ) { }

  showOverlayPanel() {
    this.overlayPanel.toggle(event);
  }

  searchEntity(offDutyReason: string) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.entityData.countryCode = country[0].countryCode;
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.DRIVER_NEXT_SHIFT, this.entityData).subscribe(res => {
      console.log(res); // need to implement after backend issue resolved and deployed
    });
  }

}
