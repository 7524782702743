import { DatePipe, JsonPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { EntityService } from 'app/modules/shared/services/entity.service';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AppIcons } from '../../../../shared/app.icons';
import { AppSettings } from '../../../../shared/app.settings';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../../shared/services/config.service';
import { BookingService } from '../../../services/booking.service';
import { AddedPassengerInputComponent } from '../../add-booking/added-passenger-input/added-passenger-input.component';
import { BookingDriverInfoComponent } from '../booking-driver-info/booking-driver-info.component';
import { BookingVehicleInfoComponent } from '../booking-vehicle-info/booking-vehicle-info.component';

@Component({
  selector: 'app-booking-info',
  standalone: true,
  imports: [TranslateModule, JsonPipe, ButtonModule, FormsModule, BookingVehicleInfoComponent, BookingDriverInfoComponent, JsonPipe, DatePipe, AddedPassengerInputComponent, InputTextareaModule],
  templateUrl: './booking-info.component.html',
  styleUrl: './booking-info.component.scss'
})
export class BookingInfoComponent {
  adminNote: string;
  iconPos: any = 'right';
  @Input() attributeValues: any;
  @Input() attributeLabels: any;
  @Input() vehicleAttributeData: any;
  @Input() bookingAttributeData: any;
  @Input() driverAttributeData: any;
  @Input() bookingDetails: any;
  @Input() passengerRelatedData: any;
  @Input() vehicleRelatedData: any;
  @Input() driverRelatedData: any;
  @Input() bookingId: string;
  appAccess: any;
  classification: any;
  transportationRestrictions: any;
  bookingTimeRestriction: any;
  assignLocation: any;
  homeStayLocationRestriction: any;
  specialRequest: any;
  otherInformation: any;
  tab: any;
  bookingInfo;
  flightDetails;
  driverInstructions;
  passengers;
  vehicleId: string;
  driverId: string;
  vehicleAttributeId = 29; // this needs to remove
  driverAttributeId = 30;// this needs to remove
  BOOKING_STATUS_LABEL = AppSettings.BOOKING.STATUS_BY_ID;
  miIcons = AppIcons;
  dd_MMM_yyyy_FORMAT: any = AppSettings.dd_MMM_yyyy_FORMAT;
  hh_mm: string = AppSettings.hh_m_FORMAT;
  TRANSPORTATION_MODE = AppSettings.TRANSPORTATION_MODE;
  childSeats = [
    'child_seat', 'infant_carrier', 'booster'
  ]
  childSeatsHr = [
    'child_seat', 'booster'
  ];

  excludeFields = ["passenger_profile_image"]
  passengersPrivileges: any;
  passengersDetails: any;
  selectAddedPassenger: any;
  addedPassengerList: any;
  adminNoteAttributeId: any;
  bookingData: any;
  country: Country;
  language: Language;
  @Input() bookingStatus: any;
  completedBooking: any;

  constructor(
    public cs: CommonBindingDataService,
    private router: Router,
    private bookingService: BookingService,
    private configService: ConfigService,
    private entityService: EntityService,
    private messageService: MessageService
  ) { }

  ngOnInit() {

    console.log(this.vehicleRelatedData);
    this.attributeLabels = this.cs.getAttributeLabels(this.bookingAttributeData);
    this.tab = this.bookingAttributeData.tabs.find(ele => ele.tabCode === 'details');
    this.bookingInfo = this.tab.groups.find(ele => ele.code === 'booking_details');
    this.flightDetails = this.tab.groups.find(ele => ele.code === 'flight_details');
    this.driverInstructions = this.tab.groups.find(ele => ele.code === 'driver_instructions');
    this.passengers = this.tab.groups.find(ele => ele.code === 'passengers');
    const bookingPass = this.bookingAttributeData.relatedAttributes.find(ele => ele.entityCode === "booking_pass")
    this.passengersDetails = bookingPass.tabs[0].groups.find(ele => ele.code === "details")
    this.passengersPrivileges = bookingPass.tabs[0].groups.find(ele => ele.code === "privileges");
    console.log(this.attributeValues)
    this.driverId = this.attributeValues[this.driverAttributeId]
    this.vehicleId = this.attributeValues.vehicle_entity_id;
    this.setBookingStatus();
    this.adminNote = this.attributeValues.admin_note
    this.adminNoteAttributeId = this.findAdminNoteAttributeId(this.tab);
    this.completedBooking = _.find(this.bookingStatus, { bookingCode: 'COMPLETED' });

    if (this.passengerRelatedData) {
      this.selectAddedPassenger = this.passengerRelatedData[0];
    }


  }

  redirectToEdit() {
    console.log("edit is called")
    this.router.navigate(['/app/bookings/edit/' + this.bookingDetails.entityId]);
  }

  setBookingStatus() {
  }



  findStatusObj(selectedStatus) {
    return this.bookingStatus.find(ele => ele.bookingStatusId === selectedStatus);
  }

  getIcon(selectedStatus) {
    const booking = this.findStatusObj(selectedStatus);
    return booking?.bookingIcon.toLowerCase();
  }

  onSelectAddedPassenger(event) {
    this.selectAddedPassenger = event;
  }

  onRemove(event) {



  }

  getPassengerImageUrl(rowData) {
    if (rowData?.passenger_profile_image) {
      return `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/files/profile/download/${rowData?.passenger_profile_image[0]}?forTenantCode=${this.configService.getForTenantCode()}`;
    } else {
      return '../../../../../assets/images/mi-theme/light/basic/default_user.svg';
    }
  }


  findAdminNoteAttributeId(detailsTab) {
    if (detailsTab) {
      const adminNoteGroup = _.find(detailsTab.groups, { code: 'admin_note' });

      if (adminNoteGroup) {
        const adminNoteField = _.find(adminNoteGroup.fields, { attributeCode: 'admin_note' });

        if (adminNoteField) {
          return adminNoteField.attributeId;
        }
      }
    }
    return null;
  }


  onSaveAdminNote() {
    this.initializeBookingData();
    this.bookingData.data.push({
      attributeId: this.adminNoteAttributeId,
      attributeValue: this.adminNote
    })
    this.entityService.saveAttributeData(AppSettings.ENTITY_CODE.BOOKING, this.bookingId, this.bookingData).subscribe(res => {
      this.showSuccessMessage('bookings.booking_note_added');
      this.adminNote = '';
    });


  }


  private initializeBookingData() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.bookingData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.BOOKING,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
      relationshipData: []
    };
  }

  private showErrorMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Error',
      detail: this.cs.getLabel(labelKey)
    });
  }


  private showSuccessMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Successful',
      detail: this.cs.getLabel(labelKey),
    });
  }



}