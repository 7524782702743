import { ChangeDetectorRef, Component, ElementRef, Input, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AppIcons } from '../../../../shared/app.icons';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-permission-card',
  templateUrl: './permission-card.component.html',
  styleUrl: './permission-card.component.scss',
  standalone: true,
  imports: [ButtonModule, TranslateModule, FormsModule, ReactiveFormsModule, InputTextModule, InputSwitchModule, OverlayPanelModule, NgClass],
})
export class PermissionCardComponent {
  miIcons = AppIcons;
  @ViewChildren('permissionCard') permissionCard: QueryList<ElementRef>;
  @Input() permissionArray;
  @Input() selectedModule;

  constructor() {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedModule && changes.selectedModule.currentValue) {
      this.scrollToSelectedModule();
    }

    if (changes.permissionArray && changes.permissionArray.currentValue) {
      this.permissionArray.forEach(permission => {
        this.updatePermissionState(permission);
      });
    }
  }

  updatePermissionState(permission: any, event?) {
    if (event) {
      if (event.type === 'change') {
        const pageId = event.target.value;
        const isChecked = event.target.checked;
        this.updatePageSelection(permission, pageId, isChecked);

        permission.selected = this.checkIfAnySelected(permission);

      } else if (event.type === 'click') {
        const newState = permission.selected;
        this.setAllPagesAndSubPages(permission, newState);
        permission.selected = newState;
      }
    } else {
      permission.selected = this.checkIfAnySelected(permission);
    }
  }

  setAllPagesAndSubPages(permission: any, isChecked: boolean) {
    permission.pages.forEach(page => {
      page.selected = isChecked;
      if (page.subPages) {
        page.subPages.forEach(subPage => subPage.selected = isChecked);
      }
    });

    if (permission.subMenus) {
      permission.subMenus.forEach(subMenu => {
        subMenu.pages.forEach(screen => {
          screen.selected = isChecked;
          if (screen.subPages) {
            screen.subPages.forEach(subPage => subPage.selected = isChecked);
          }
        });
      });
    }
  }

  updatePageSelection(permission: any, pageId: string, isChecked: boolean) {
    permission.pages.forEach(page => {
      if (page.pageId === pageId) {
        page.selected = isChecked;
        if (page.subPages) {
          page.subPages.forEach(subPage => subPage.selected = isChecked);
        }
      } else if (page.subPages) {
        page.subPages.forEach(subPage => {
          if (subPage.pageId === pageId) {
            subPage.selected = isChecked;
            page.selected = page.subPages.some(sp => sp.selected);
          }
        });
      }
    });

    if (permission.subMenus) {
      permission.subMenus.forEach(subMenu => {
        subMenu.pages.forEach(screen => {
          if (screen.pageId === pageId) {
            screen.selected = isChecked;
            if (screen.subPages) {
              screen.subPages.forEach(subPage => subPage.selected = isChecked);
              screen.selected = screen.subPages.some(sp => sp.selected);
            }
          } else if (screen.subPages) {
            screen.subPages.forEach(subPage => {
              if (subPage.pageId === pageId) {
                subPage.selected = isChecked;
                screen.selected = screen.subPages.some(sp => sp.selected);
              }
            });
          }
        });
      });
    }

    permission.selected = this.checkIfAnySelected(permission);
  }

  checkIfAnySelected(permission: any) {
    return permission.pages.some(page => page.selected || (page.subPages && page.subPages.some(subPage => subPage.selected))) ||
      (permission.subMenus && permission.subMenus.some(subMenu =>
        subMenu.pages.some(screen => screen.selected || (screen.subPages && screen.subPages.some(subPage => subPage.selected)))
      ));
  }

  updateCheckboxStates(permission: any) {
    this.setAllPagesAndSubPages(permission, permission.selected);
  }

  countSelectedSubPages(subPages: any[]) {
    return subPages.filter(subPage => subPage.selected);
  }

  scrollToSelectedModule() {
    this.permissionCard?.forEach((card) => {
      const labelElement = card.nativeElement.querySelector('label');
      if (labelElement && labelElement.textContent.trim() === this.selectedModule) {
        card.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });
  }

  getSelectedPermission(permissionArray) {
    return permissionArray.map(permission => ({
      ...permission,
      subMenus: permission.subMenus ? this.getSelectedSubMenus(permission.subMenus) : [],
      pages: permission.pages ? this.getSelectedPages(permission.pages) : [],
      selected: permission.selected
    })).filter(permission => permission.selected || permission.subMenus.length || permission.pages.length);
  }

  getSelectedSubMenus(subMenus) {
    return subMenus.map(subMenu => ({
      ...subMenu,
      subMenus: subMenu.subMenus ? this.getSelectedSubMenus(subMenu.subMenus) : [],
      pages: subMenu.pages ? this.getSelectedPages(subMenu.pages) : [],
      selected: subMenu.selected
    })).filter(subMenu => subMenu.selected || subMenu.subMenus.length || subMenu.pages.length);
  }

  getSelectedPages(pages) {
    return pages.map(page => ({
      ...page,
      subPages: page.subPages ? this.getSelectedPages(page.subPages) : [],
      selected: page.selected
    })).filter(page => page.selected || page.subPages.length);
  }
}
