import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { EventService } from 'app/modules/events/services/event.service';
import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';

@Component({
  selector: 'app-select-button',
  standalone: true,
  imports: [TabMenuModule],
  templateUrl: './select-button.component.html',
  styleUrl: './select-button.component.scss'
})
export class SelectButtonComponent implements OnInit {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  items = [];
  activeItem: MenuItem | undefined;


  constructor(
    private formGroupDirective: FormGroupDirective, private eventService: EventService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    const eventId = this.eventService.getSelectedEventId();
    if (eventId) {
      this.eventService.getTransportationTypes(eventId).subscribe(result => {
          if (result && Array.isArray(result)) {
          this.field.presetValues = result.map(item => ({
            labelKey: item.transportationType,
            labelValue: item.transportationType,
            parentLabelKey: null
          }));
    
          this.field.presetValues.map(res => {
            let className = res.labelKey.toLowerCase().replace(/\s+/g, '-');
            if (res.labelKey === 'Airport') {
              className = 'airport-icon';
            }
            this.items.push({
              label: res.labelValue,
              icon: `pi pi-fw mi-${className} wh-24 mi-lg`
            });
          });
  
          this.activeItem = this.items[0];
        }
      });
    } else {
      console.log('eventId not found');
    }

    // this.field.presetValues.map(res => {
    //   const className = res.labelKey.toLowerCase();
    //   this.items.push({ label: res.labelValue, icon: `pi pi-fw mi-${className} wh-24 mi-lg` })
    // });
    // this.activeItem = this.items[0];
  }

  onActiveItemChange(event: MenuItem) {
    this.activeItem = event;
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(this.activeItem.label);
  }


}
