<div class="flex flex-column p-3 pl-4 pt-0">
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.name</div>
        <div class="w-6">
            <span><img src="../../../../../assets/images/mi-theme/light/basic/Rectangle.png" alt="" class="inline-block vertical-align-middle mr-2"></span>
            <span class="font-14 font-bold default-text-black-color" translate>{{ nameCode }}</span>
        </div>
    </div>
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.group</div>
        <div class="w-6">
            <div class="font-14 font-medium">{{ groupName }}</div>
        </div>
    </div>
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.capacity</div>
        <div class="w-6">
            <div class="font-14 font-bold">{{ passengerCapacity }}</div>
        </div>
    </div>
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.vehicle_type</div>
        <div class="w-6">
            <div class="font-14 font-bold">{{ vehicleType }}</div>
        </div>
    </div>
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.body_type</div>
        <div class="w-6">
            <div class="font-14 font-bold">{{ bodyType }}</div>
        </div>
    </div>
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.make_model</div>
        <div class="w-6">
            <div class="font-14 font-medium">{{ manufacturer }} {{ variant }} {{ yearOfManufacturing }}</div>
        </div>
    </div>
</div>
