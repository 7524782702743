import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MiFormComponent } from 'app/modules/shared/components/mi-form/mi-form.component';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import type { AttributeData, entityResponse } from 'app/modules/vehicles/models/attribute.models';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-booking-passenger-location',
  standalone: true,
  imports: [MiFormComponent],
  templateUrl: './booking-passenger-location.component.html',
  styleUrl: './booking-passenger-location.component.scss'
})
export class BookingPassengerLocationComponent {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  @Output() closeAddressDialog: EventEmitter<any> = new EventEmitter();
  @Input() passengerId;
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  data: AttributeData;
  passengerAttributeData: any;
  passengerAddressEntityCode: any;
  selectLocationAddressAttribute: any;
  passengerAttributeLength: number | undefined;
  attributeIdForStayHomeAddress: any;
  stayHomeAddressData: any[] = [];
  passengerData;
  addressTab: any[] = [];
  nextBtnLabel: string;
  attributeValues: any[] = [];
  defaultLocationList = AppSettings.DEFAULT_ADDRESS_LIST;
  addressEntityIdList: any[] = [];
  stayHomeAddressFields: any = 10;
  start: any = 0;
  end: any = 0;
  passengerEntityData: any;
  relationshipDataForAddress: any[] = [];

  constructor(private entityService: EntityService, private cs: CommonBindingDataService,
    private messageService: MessageService, private router: Router,
    private configService: ConfigService,) { }

  ngOnInit() {
    this.setLabels();
    this.setAppSettings();
    this.loadPassengerData();
  }

  setLabels() {
    this.nextBtnLabel = this.cs.getLabel('lbl_save_address');
  }

  private setAppSettings() {
    this.moduleName = AppSettings.ENTITY_TYPE.PASSENGER;
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  private loadPassengerData() {
    if (this.passengerId) {
      this.entityService
        .getEntity(this.passengerId, AppSettings.ENTITY_TYPE.PASSENGER, AppSettings.VIEW_CODE.ADD_EDIT_VIEW)
        .subscribe((res) => {
          if (res) {
            const data = res;
            // const attributeValues = this.getAddressAttributeValues(data);
            this.attributeValues = this.cs.getOrgAttributeValues(data);
          }
          this.getAttributes();
        });
    } else {
      this.getAttributes();
    }
  }

  getAddressAttributeValues(passengerData: any) {
    const passenger = passengerData;
    const stayHomeAddressSection = passenger.attributeCodeValueDtoList.find(
      (attr: any) => attr.attributeCode === 'stay_home_address_section'
    );
    const addressIds = stayHomeAddressSection ? stayHomeAddressSection.attributeValue : [];
    const addresses = passenger.relatedData || [];
    const mappedAddresses = addresses
      .filter((address: any) => addressIds.includes(address.entityId))
      .map((address: any) => {
        const addressAttributes = address.attributeCodeValueDtoList;
        const attributeValues = {};
        addressAttributes.forEach((attr: any) => {
          attributeValues[attr.attributeCode] = attr.attributeValue;
        });
        return {
          entityId: address.entityId,
          ...attributeValues,
        };
      });
    return mappedAddresses;
  }

  getAttributes() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_TYPE.PASSENGER, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.passengerAddressEntityCode = this.data.relatedAttributes.find(ele => ele.entityCode === AppSettings.ENTITY_CODE.PASSENGER_ADDRESS).entityCode;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.passengerAttributeData = this.cs.getOrganizedAttribute(this.data);
        let detailsTab = this.passengerAttributeData.tabs.find(ele => ele.tabCode === 'details');
        let stayHomeAddressGroup = detailsTab.groups.find(ele => ele.code === 'stay_home_address');
        this.updateUiColumns(stayHomeAddressGroup.fields, 12);
        this.addressTab = [];
        this.addressTab.push(stayHomeAddressGroup);
        this.passengerAttributeLength = this.addressTab?.length;
        this.attributeIdForStayHomeAddress = this.getAttributeIdForGroupCode(AppSettings.GROUP_CODE.STAY_HOME_ADDRESS);
        this.getLocationAddressAttribute();
      }
    });
  }

  updateUiColumns(fields: any[], newColumnValue: number) {
    fields.forEach(field => {
      field.uiColumns = newColumnValue;
    });
  }

  getAttributeIdForStayHomeAddress() {
    this.passengerAttributeData.tabs.forEach(tab => {
      tab.groups.forEach(group => {
        if (group.code === AppSettings.GROUP_CODE.STAY_HOME_ADDRESS) {
          this.attributeIdForStayHomeAddress = group.relation.ownerAttributeId;
        }
      });
    });
  }

  getLocationAddressAttribute() {
    this.passengerAttributeData.tabs.forEach(tab => {
      tab.groups.forEach(group => {
        if (group.code === AppSettings.GROUP_CODE.STAY_HOME_ADDRESS) {
          this.selectLocationAddressAttribute = group.fields.find(ele => ele.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS);
        }
      });
    });
    let stayHomeAddressGroup = this.passengerAttributeData.tabs[0].groups.find(ele => ele.code === AppSettings.GROUP_CODE.STAY_HOME_ADDRESS);
    const index = stayHomeAddressGroup.fields.findIndex(ele => ele.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS);
    stayHomeAddressGroup.fields.splice(index, 1);
  }

  getAttributeIdForGroupCode(groupCode: string): string | undefined {
    const group = _.flatMap(this.passengerAttributeData.tabs, 'groups').find(group => group.code === groupCode);
    return group ? group.relation.ownerAttributeId : undefined;
  }

  setAttributeValuesForStayHomeAddress(key, attributeValue) {
    const keyId = key.substring(key.indexOf('_') + 1);
    if (!AppSettings.SPACERS_ATTRIBUTE_CODES_FOR_STAY_HOME_ADDRESS.includes(keyId)) {
      if (keyId === AppSettings.PASSENGER_ATTRIBUTE_CODE.SELECT_LOCATION_GEOLOCATION) {
        this.setStayHomeAddress(keyId, attributeValue?.geoLocation);
        this.stayHomeAddressData.push({
          attributeId: this.selectLocationAddressAttribute.attributeId,
          attributeValue: attributeValue?.address
        });
      } else {
        this.setStayHomeAddress(keyId, attributeValue);
      }
    }
  }

  setStayHomeAddress(keyId, attributeValue) {
    this.stayHomeAddressData.push({
      attributeCode: keyId,
      attributeValue: keyId === AppSettings.FIELDS.INSTRUCTIONS ? (attributeValue ? attributeValue : '   ') : (attributeValue ? attributeValue : '')
    });
  }

  setPassengerData(key, attributeValue) {
    if (!AppSettings.SPACERS_ATTRIBUTE_CODES_FOR_PASSENGER_DETAILS.includes(key)) {
      if (attributeValue) {
        this.passengerData.data.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
  }

  onSavePassenger(event) {
    if (!this.country || !this.language) {
      console.error('Data, country, or language not initialized.');
      return;
    }

    this.stayHomeAddressData = [];
    this.passengerData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: []
    };
    const passengerFormData = event;
    for (let k = 0; k < this.defaultLocationList.length; k++) {
      passengerFormData[`${k}_${AppSettings.ADDRESS_FIELDS.SELECT_LOCATION_TYPE}`] = this.defaultLocationList[k].labelKey
    }
    const dateIds = [AppSettings.DATE_ATTRIBUTE_IDS.PASSENGER_DATE_OF_BIRTH];
    for (const [key, value] of Object.entries(passengerFormData)) {
      let attributeValue = value;
      if (dateIds.includes(key)) {
        attributeValue = new Date(<string>value).getTime();
      }
      const arr = key.split('_');
      if (parseInt(arr[0]) >= 0) {
        this.setAttributeValuesForStayHomeAddress(key, attributeValue);
      } else {
        this.setPassengerData(key, attributeValue);
      }
    }

    this.stayHomeAddressData = this.cs.mapAttributeIdsForRelatedData(this.stayHomeAddressData, this.passengerAttributeData.tabs);
    this.passengerData.data = this.cs.mapAttributeIds(this.passengerData.data, this.passengerAttributeData.tabs);
    this.setPassengerEntityData(this.data.entityCode);
    this.saveAddress();
  }

  setPassengerEntityData(entityCode) {
    this.passengerEntityData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: entityCode
    }
  }

  saveAddress() {
    const numberOfStayHomeAddressRecords = Math.ceil(this.stayHomeAddressData.length / this.stayHomeAddressFields);
    this.start = 0;
    this.end = this.stayHomeAddressFields;
    const addressEntitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: this.passengerAddressEntityCode
    };

    const hasValidData = (data) => {
      return data.some(attr => attr.attributeValue && attr.attributeValue.trim() !== "");
    };

    let homeAddress = null;
    let hotelAddress = null;
    let otherAddress = null;

    for (let i = 0; i < numberOfStayHomeAddressRecords; i++) {
      const addressData = this.stayHomeAddressData.slice(this.start, this.end);
      this.start = this.end;
      this.end += this.stayHomeAddressFields;

      if (i === 0 && hasValidData(addressData)) {
        homeAddress = addressData;
      } else if (i === 1 && hasValidData(addressData)) {
        hotelAddress = addressData;
      } else if (i > 1 && hasValidData(addressData)) {
        otherAddress = addressData;
      }
    }

    if (homeAddress) {
      const homeEntity = {
        forTenantCode: this.configService.getForTenantCode(),
        entityCode: this.passengerAddressEntityCode,
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        data: homeAddress,
      };
      this.createAndSaveAddressEntity(addressEntitiesData, 0, 1, homeEntity);
    }

    if (hotelAddress) {
      const hotelEntity = {
        forTenantCode: this.configService.getForTenantCode(),
        entityCode: this.passengerAddressEntityCode,
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        data: hotelAddress,
      };
      this.createAndSaveAddressEntity(addressEntitiesData, 1, 1, hotelEntity);
    }

    if (otherAddress) {
      const otherEntity = {
        forTenantCode: this.configService.getForTenantCode(),
        entityCode: this.passengerAddressEntityCode,
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        data: otherAddress,
      };
      this.createAndSaveAddressEntity(addressEntitiesData, 2, numberOfStayHomeAddressRecords - 2, otherEntity);
    }
  }


  createAndSaveAddressEntity(addressEntitiesData, index, numberOfAddress, address) {
    this.entityService.createEntities(addressEntitiesData.entityCode, addressEntitiesData).subscribe({
      next: (res: entityResponse) => {
        const entityId = res.entityId;
        this.addressEntityIdList.push(res.entityId);
        this.entityService.saveAttributeData(addressEntitiesData.entityCode, entityId, address).subscribe((res: any) => {
        });
        this.relationshipDataForAddress.push(
          {
            entityRelationshipConfigId: this.passengerAttributeData.tabs[0].groups.find(ele => ele.code === 'stay_home_address').relation.entityRelationshipConfigId,
            otherEntityId: entityId
          }
        );
        if (index === (numberOfAddress - 1)) {
          this.passengerData.data.push({
            attributeId: this.attributeIdForStayHomeAddress,
            attributeValue: this.addressEntityIdList
          });
          this.savePassengerToDb(this.passengerEntityData.entityCode)
        }

      },
      error: (error) => {
        console.error('something wrong occurred: ' + error);
      }
    });
  }

  saveAddressAttribute(numberOfAddress, index, address, entityId, addressEntitiesData) {
    if (entityId) {
      this.entityService.saveAttributeData(addressEntitiesData.entityCode, entityId, address).subscribe((res: any) => {
        this.relationshipDataForAddress.push(
          {
            entityRelationshipConfigId: this.passengerAttributeData.tabs[0].groups.find(ele => ele.code === 'stay_home_address').relation.entityRelationshipConfigId,
            otherEntityId: entityId
          }
        );
        if (index === (numberOfAddress - 1)) {
          this.passengerData.data.push({
            attributeId: this.attributeIdForStayHomeAddress,
            attributeValue: this.addressEntityIdList
          });
          this.savePassengerToDb(this.passengerEntityData.entityCode)
        }
      });
    } else {
      this.createAndSaveAddressEntity(addressEntitiesData, index, numberOfAddress, address);
    }
  }

  savePassengerToDb(entityCode) {
    this.passengerData.relationshipData = this.relationshipDataForAddress;
    this.passengerData.data = this.cs.mapAttributeIds(this.passengerData.data, this.passengerAttributeData.tabs);
    if (this.passengerId) {
      this.savePassengerApiCall(entityCode);
    } else {
      this.createPassengerEntity();
    }
  }

  savePassengerApiCall(entityCode) {
    this.entityService.saveAttributeData(entityCode, this.passengerId, this.passengerData).subscribe(result => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('passengers.lbl_passenger_saved_successfully') });
      this.miFormComponent.resetForm();
      this.router.navigate(['app/bookings/add']);
      this.onPreviousClick();
    },
      (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      })
  }

  createPassengerEntity() {
    this.entityService.createEntities(this.passengerEntityData.entityCode, this.passengerEntityData).subscribe({
      next: (res: entityResponse) => {
        this.passengerId = res.entityId;
        this.savePassengerApiCall(this.passengerEntityData.entityCode);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  onPreviousClick() {
    this.miFormComponent.resetForm();
    this.closeAddressDialog.emit();
  }

}
