<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'USER_MANAGEMENT', subMenuCode: 'MANAGE_USERS', pageCode: 'DETAILS', subPageCode: 'VIEW' }">
<div class="grid mt-1 details-div bg-white pt-2 pb-2">
    <div class="col-8">
        <div class="col ml-2">
            <div class="inline-flex align-items-center">
                <label class="category-name mr-3">{{userDetails?.firstName}} {{userDetails?.lastName}}</label>
                <span [class]="userDetails?.active ? 'status-dot active' : 'status-dot inactive'" style="width: 7px; height: 7px;"></span>
                <label class="text-sm">{{userDetails?.active ? 'Active' : 'Inactive'}}</label>
            </div>
        </div>
    </div>
    <div class="col-4 text-right align-content-center">
        <div appAccessProvider [accessCode]="{ menuCode: 'USER_MANAGEMENT', subMenuCode: 'MANAGE_USERS', pageCode: 'DETAILS', subPageCode: 'EDIT' }">
            <button (click)="edit()" pButton class=" border-400 bg-white text-color pl-2 pr-2 pt-1 pb-1 mr-3 hover:surface-100" [label]="editBtnLabel">
                <i class="mi-lg mr-2 edit-pen edit_pencil"></i>
            </button>
        </div>
    </div>
</div>

<div class="grid mt-2 ml-3 mr-3 overview-details">
    <div class="col-6 md:col-12 lg:col-6 pl-0">
        <div class="card p-fluid border-0">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>manage_users.lbl_details</label>
                </div>
            </div>
            <div class="grid">
                <div class="col-6 pb-1 mt-1 mb-1">
                    <label class="detail-heading" translate>manage_users.lbl_status</label>
                </div>
                <div class="col-6 pb-1 mt-1 mb-1">
                    <span [class]="userDetails?.active ? 'status-dot active' : 'status-dot inactive'"></span>
                    <label class="detail-value">{{userDetails?.active ? 'Active' : 'Inactive'}}</label>
                </div>
            </div>
            <hr class="hr-line">
            <div class="grid">
                <div class="col-6 pb-1 mt-1 mb-1">
                    <label class="detail-heading" translate>manage_users.lbl_name</label>
                </div>
                <div class="col-6 pb-1 mt-1 mb-1">
                    <label class="detail-value">{{userDetails?.firstName}} {{userDetails?.lastName}}</label>
                </div>
            </div>
            <hr class="hr-line">
            <div class="grid">
                <div class="col-6 pb-1 mt-1 mb-1">
                    <label class="detail-heading" translate>manage_users.lbl_email</label>
                </div>
                <div class="col-6 pb-1 mt-1 mb-1">
                    <label class="detail-value">{{userDetails?.email}}</label>
                </div>
            </div>
            <hr class="hr-line">
            <div class="grid">
                <div class="col-6 pb-1 mt-1 mb-1">
                    <label class="detail-heading" translate>manage_users.lbl_role</label>
                </div>
                <div class="col-6 pb-1 mt-1 mb-1">
                    <label class="detail-value">{{roleName}}</label>
                </div>
            </div>
            <hr class="hr-line">
            <div class="grid">
                <div class="col-6 pb-1 mt-1 mb-1">
                    <label class="detail-heading" translate>manage_users.lbl_contact</label>
                </div>
                <div class="col-6 pb-1 mt-1 mb-1">
                    <label class="detail-value">{{userDetails?.contactNumber}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6 md:col-12 lg:col-6 pr-0">
        <div class="card p-fluid border-0 pb-2">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>manage_users.lbl_credential</label>
                </div>
            </div>
            <div class="grid flex align-items-center">
                <div class="col-6 pb-1 mt-1 mb-1">
                    <label class="detail-heading" translate>manage_users.lbl_username</label>
                </div>
                <div class="col-6 pb-1 mt-1 mb-1">
                    <label class="detail-value">{{userDetails?.username}}</label>
                </div>
            </div>
            <hr class="hr-line mb-2">
            <div class="grid flex align-items-center">
                <div class="col-3 pb-1 mt-1 mb-1">
                    <div class="flex justify-content-start">
                        <p-button [label]="'manage_users.lbl_reset_password' | translate" [outlined]="true" severity="danger" (click)="onShowDialog()" />
                    </div>
                </div>
                <div class="col-9 pb-1 mt-1 mb-1">
                    <p class="text-sm text-500" translate>manage_users.reset_info</p>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<p-dialog [header]="'manage_users.lbl_reset_passwords' | translate" [(visible)]="visible" [modal]="true" [draggable]="false" (onHide)="onHide()" [style]="{width: '50vw'}">
    <div class="pt-16">
        <label class="font-bold" translate>manage_users.lbl_default_password</label>
        <span class="text-red-500 ml-1">*</span>
        <p-password #passwordInput="ngModel" id="password" [(ngModel)]="addNewPassword" [placeholder]="'lbl_enter_here' | translate" [toggleMask]="true" class="password-input w-full mt-2 mb-2"></p-password>
        @if ((passwordInput.touched || passwordInput.dirty) && !isValidInput() && addNewPassword.length > 0) {
            <span class="text-sm text-red-600" translate>manage_users.reset_password_err_msg</span>
        }
    </div>
    <div class="col-12 flex justify-content-end px-0 py-3">
        <button class="mr-2 bg-white text-color-secondary border-none width-90 hover:surface-100" (click)="onHide()" [label]="'cancel' | translate" pButton></button>
        <p-button [label]="'manage_users.lbl_reset_password' | translate" severity="danger" (click)="onResetPassword()" [disabled]="!isValidInput() || addNewPassword === ''"></p-button>
    </div>
</p-dialog>