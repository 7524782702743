<div appAccessProvider [accessCode]="{ menuCode: 'COMMUNICATION', pageCode: 'BROADCASTING', subPageCode: 'ADD' }">
  <app-module-header [label]="btnLabel" (onBtnClick)="onAddBroadcast()"></app-module-header>
</div>
<div appAccessProvider [accessCode]="{ menuCode: 'COMMUNICATION', pageCode: 'BROADCASTING', subPageCode: 'LIST' }">
<p-tabView [(activeIndex)]="activeIndex" (onChange)="tabViewChange($event)">
  <p-tabPanel>
    <ng-template pTemplate="body">
      @if(data){
        <app-mi-table
          [collectionOfColumns]="allColumns"
          [attributeLabels]="filterAttributeLabels"
          [data]="tableData"
          entityName="broadcast_message"
          [isLoading]="isLoading"
          (onPageChange)="onPageChange($event)"
          (itemPerPageChange)="itemPerPageChange($event)"
          [totalRecords]="totalRecords"
          [message]="deleteBroadcastMsg"
          [header]="deleteBroadcastHeaderMsg"
          (onDelete)="onBroadcastDelete($event)"
          (onRowSelection)="onRowSelect($event)"
          [filterList]="filterList"
          (onValueChange)="onFilterValueChange($event)"
          [filterFields]="filterFieldsArray"
          [label]="btnLabel"
          (onBtnClick)="onAddBroadcast()"
          [attributeData]="attributeData"
          (onSearchValueChange)="onSearchValueChanges($event)"
          (onUpdate)="onUpdateBroadcast($event)"
          [rowItems]="rowItems"
          (setCurrentRowData)="setCurrentRowData($event)"
          [visible]="visible"
          >
        </app-mi-table>
      }
    </ng-template>
  </p-tabPanel>
</p-tabView>

@if(isAddBroadcastVisible) {
  <app-add-broadcast [isAddBroadcastVisible]="isAddBroadcastVisible" (onToggleOverLay)="onToggleOverlayForAdd()" [broadcastIdForUpdate]="broadcastId"></app-add-broadcast>
}
</div>
