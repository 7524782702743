import { NgClass } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import * as _ from 'lodash';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AppSettings } from "app/modules/shared/app.settings";
import { Country } from "app/modules/shared/models/country";
import { Language } from "app/modules/shared/models/language";
import { CommonBindingDataService } from "app/modules/shared/services/common-binding-data.service";
import { ConfigService } from "app/modules/shared/services/config.service";
import { EntityService } from "app/modules/shared/services/entity.service";
import { MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { OverlayPanel, OverlayPanelModule } from "primeng/overlaypanel";
import { BookingService } from "../../services/booking.service";

@Component({
  selector: 'app-manual-passenger-vehicle-details',
  standalone: true,
  imports: [ButtonModule, NgClass, OverlayPanelModule, TranslateModule],
  templateUrl: './manual-passenger-vehicle-details.component.html',
  styleUrl: './manual-passenger-vehicle-details.component.scss'
})
export class ManualPassengerVehicleDetailsComponent implements OnInit{

  isHovered: boolean = false;
  @ViewChild("op") op: OverlayPanel;
  @Input() driverDetail: any;
  @Input() forDetail: boolean = false;
  @Input() bookingId;
  @Input() isBookingScreen: boolean = true;
  @Output() closeBtnClicked: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() driverCardClicked: EventEmitter<any> = new EventEmitter<any>();
  country: Country;
  language: Language;
  firstName: string;
  lastName: string;
  uniqueId: string;
  groupName: string;
  vehicleNameCode: string;
  vehicleManufacturer: string;
  vehicleModel: string;
  vehicleType: string;
  vehiclePassengerCapacity: string;
  vehicleColor: string;
  groupColor: string;
  wheelchairFlag: string;
  constructor(private enitityService: EntityService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private bookingService: BookingService) { }



  ngOnInit(): void {
    if (this.driverDetail) {
      this.firstName = this.driverDetail.first_name;
      this.lastName = this.driverDetail.last_name;
      this.uniqueId = this.driverDetail.unique_id;

      const group = this.driverDetail.drivers.find(driver => driver.group_name);
      this.groupName = group ? group.group_name : null;
      this.groupColor = group ? group.group_color : null;

      const vehicle = this.driverDetail.drivers.find(driver => driver.name_code);
      if (vehicle) {
        this.vehicleNameCode = vehicle.name_code;
        this.vehicleManufacturer = vehicle.manufacturer;
        this.vehicleModel = vehicle.model;
        this.vehicleType = vehicle.vehicle_type;
        this.vehiclePassengerCapacity = vehicle.passenger_capacity;
        this.vehicleColor = vehicle.color;
      }

      this.wheelchairFlag = this.bookingService.getWheelchairFlag(this.driverDetail);
    }

  }


  onCardClick(item) {
    const driverDetails = {
      driver: item,
      bookingId: this.bookingId
    }
    this.driverCardClicked.emit(driverDetails);
  }

  onCloseDetailView() {
    this.closeBtnClicked.emit(false);
  }

  onMouseEnter(event: MouseEvent) {
    this.isHovered = true;
    this.op.toggle(event);
  }

  onMouseLeave(event: MouseEvent) {
    this.isHovered = false;
    this.op.hide();
  }
  onAssignClick(driverDetail, event: Event) {
    event.stopPropagation();
    if (this.bookingId) {
      this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
      this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
      const data = {
        forTenantCode: this.configService.getForTenantCode(),
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        bookingEntityId: this.bookingId,
        driverEntityId: driverDetail.id
      }
      this.enitityService.assignDriver(data).subscribe(() => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('dispatch.driver_assign_msg') });
      })
    } else {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('dispatch.booking_assign_first') });
    }
  }

}
