import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { AppIcons } from '../../../../shared/app.icons';
import { ConfigService } from '../../../../shared/services/config.service';
import { TooltipModule } from 'primeng/tooltip';
import { BookingService } from 'app/modules/bookings/services/booking.service';

@Component({
  selector: 'app-added-passenger-input',
  standalone: true,
  imports: [DropdownModule, FormsModule, TranslateModule, NgClass,TooltipModule],
  templateUrl: './added-passenger-input.component.html',
  styleUrl: './added-passenger-input.component.scss'
})
export class AddedPassengerInputComponent {
  @Input() addedPassengerList;
  @Input() selectAddedPassenger;
  @Output() onAddedPassenger: EventEmitter<any> = new EventEmitter();
  @Output() onCloseRemove: EventEmitter<any> = new EventEmitter();

  @Input() isPassengerDetails = false;

  miIcons = AppIcons;

  constructor(private configService: ConfigService,
    private bookingService: BookingService,
  ) {

  }

  ngOnInit() {
    // console.log(this.selectAddedPassenger);
    this.bookingService.selectedPassenger$.subscribe(passengers => {
      // debugger
      // console.log('passenger:',passengers);
      this.addedPassengerList = passengers;
      this.selectAddedPassenger  = this.addedPassengerList[0];
      this.onAddedPassenger.emit(this.selectAddedPassenger);
    });
  }

  onSelectAddedPassenger(event) {
    console.log(event);
    this.selectAddedPassenger = this.addedPassengerList.find(ele =>ele.id === event.value);
    this.onAddedPassenger.emit(this.selectAddedPassenger);
  }

  onRemove(event) {
    this.onCloseRemove.emit(event);
  }




}
