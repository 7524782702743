<div appAccessProvider [accessCode]="{ menuCode: 'ADMINISTRATION', pageCode: 'EXPORT_ACCOUNT_DATA', subPageCode: 'EDIT' }">
<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white default-text-light-black-color cursor-pointer border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button  class="flex align-items-center justify-content-center cursor-pointer" (click)="onNextBtnClick()" [label]="saveSecuritySettingsBtnLabel"></p-button>
</div>

<p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
              <i class="mi-lg details mr-2"></i>
              <span translate>settings.security</span>
            </div>
          </ng-template>
          @if (showForm) {
            @for(tab of securitySettingsAttributeData?.tabs; track tab?.tabCode) {
              <app-mi-form
                  [attributeLabels]="attributeLabelsForSecuritySettings"
                  [moduleName]="securitySettingsModuleName"
                  [attributeValues]="attributeValues"
                  [groups]="tab.groups"
                  [isMultipleFilesUpload]="false"
                  [previousBtnText]="previousBtnLabel"
                  [submitBtnText]="saveSecuritySettingsBtnLabel"
                  (saveData)="saveSecuritySettings($event)"

                  [saveBtnId]="'security'"
              >
              </app-mi-form>
          }
          }

</p-tabPanel>
</p-tabView>
</div>
