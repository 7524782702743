import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { DashboardService } from '../dashboard/dashboard.service';

@Component({
  selector: 'app-manage-widgets',
  standalone: true,
  imports: [TranslateModule,
    CheckboxModule,
    FormsModule,
    OverlayPanelModule,
    InputSwitchModule,
  ],
  templateUrl: './manage-widgets.component.html',
  styleUrl: './manage-widgets.component.scss'
})
export class ManageWidgetsComponent implements OnInit {
  @ViewChild("overlayPanel") overlayPanel: OverlayPanel;
  @Input() overlayPanelToggle;
  @Input() showManageWidgets;
  @Input() manageWidgetCards;
  reorganizeAutomatically;

  constructor(private dashboardService: DashboardService,) { }

  ngOnInit() {
    this.restoreAllCards()
  }

  onCheckboxChange(card: any) {
    this.dashboardService.emitChange(this.manageWidgetCards);
  }

  openOverlayPanel(event: any) {
    this.overlayPanel.toggle(event.originalEvent);
  }

  restoreAllCards() {
    this.manageWidgetCards.forEach(card => {
      card.isChecked = true;
    });
  }

}
