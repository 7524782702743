import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ButtonModule } from 'primeng/button';
import { AppSettings } from '../../../../shared/app.settings';
import { MiErrorComponent } from '../../../../shared/components/mi-error/mi-error.component';
import { MiFieldsComponent } from '../../../../shared/components/mi-fields/mi-fields.component';
import { MiTooltipComponent } from '../../../../shared/components/mi-fields/mi-tooltip/mi-tooltip.component';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { JsonPipe, NgClass } from '@angular/common';
import { AppIcons } from '../../../../shared/app.icons';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { MiValidationsService } from 'app/modules/shared/services/mi-validations.service';
import { UIElementService } from 'app/modules/shared/services/ui-element.service';
import { BookingService } from 'app/modules/bookings/services/booking.service';
@Component({
  selector: 'app-stop-locations',
  standalone: true,
  imports: [DragDropModule, FormsModule, JsonPipe, ReactiveFormsModule, MiFieldsComponent, MiErrorComponent, ButtonModule, MiTooltipComponent, TranslateModule, NgClass],
  templateUrl: './stop-locations.component.html',
  styleUrl: './stop-locations.component.scss'
})
export class StopLocationsComponent implements OnInit {
  @Output() stopRemoved = new EventEmitter<number>();
  @Input() field: any;
  @Input() isHorizontalForm: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() miFormGroup: FormGroup;
  @Input() attributeValues;
  stopLocations: any = [];
  @Input() edit: boolean = false;
  passengerId: any;
  stops: any = [];
  groupCopy: any = [];
  bookingTypeRestrictionCount = 1000;
  miIcons = AppIcons;
  currentSelectedCode: string;
  showHamburger: boolean = false;

  constructor(
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private validationsService: MiValidationsService,
    private uiService: UIElementService,
    private bookingService: BookingService
  ) {
  }

  ngOnInit(): void {
    this.moduleName = AppSettings.ENTITY_CODE.BOOKING;
    this.groupCopy = _.cloneDeep(this.field);

    if (this.edit) {
      this.setFieldsForEdit();
    } else {
      this.setFields();
    }

    this.addMoreStops();  
    this.addMoreStops();  
    // console.log(this.stops);
    this.bookingService.selectedPassenger$.subscribe(passengers => {
      // console.log('passenger:',passengers);
      if (passengers.length > 0) {
        this.passengerId = passengers[0].id;
      } else {
          this.passengerId = null;
      }
    });
  }

  getLetter(index: number): string {
    return String.fromCharCode(65 + index);
  }

  addStopPoint(event: Event) {
    if (this.stops.length < 4) {
      this.addMoreStops();
      this.showHamburger = true;
    }
  }

  setFieldsForEdit() {


    this.stopLocations = this.attributeValues.booking_stops_location_geolocation.map((address, index) => ({
      address: this.attributeValues.booking_stops_location_address[index],
      geoLocation: this.attributeValues.booking_stops_location_geolocation[index]
    }));

    for (let j = 0; j < this.stopLocations.length; j++) {
      this.addMoreStops(this.stopLocations[j]);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log( this.stops, event.previousIndex, event.currentIndex);
    moveItemInArray(this.stops, event.previousIndex, event.currentIndex);
  }

  setFields() {
    this.currentSelectedCode = this.field.inputCode;
    const validators = this.validationsService.addValidator(this.field.validation, this.currentSelectedCode);
    this.field.attributeCodeOneToMultiple = `${this.bookingTypeRestrictionCount}_${this.field.attributeCode}`;
    this.field.isOneToMultiple = true;
    this.field.fieldName = this.field.attributeCode;
    const value = this.attributeValues ? this.attributeValues[this.field?.attributeCodeOneToMultiple] : '';
    this.miFormGroup.registerControl(this.field.attributeCodeOneToMultiple, new FormControl('', validators));
    // this.stops.push(this.field);
    // this.miFormGroup.controls[this.field.attributeCodeOneToMultiple]?.valueChanges.subscribe(value => {
    //   console.log("called stops");
    //   this.stops.push(this.field);
    // });
  }

  swipeAddress(event: Event, index: number) {
    console.log(event);
    const newIndex = index === this.stops.length - 1 ? index - 1 : index + 1;
    if (newIndex >= 0 && newIndex < this.stops.length) {
        const temp = this.stops[index];
        this.stops[index] = this.stops[newIndex];
        this.stops[newIndex] = temp;

        this.updateFormControls(index);
        this.updateFormControls(newIndex);
        
        this.cd.detectChanges();
    }
  }

  updateFormControls(index: number) {
    const stop = this.stops[index];
    this.miFormGroup.removeControl(stop.attributeCodeOneToMultiple);
    const validators = this.validationsService.addValidator(stop.validation, this.currentSelectedCode);
    const value = this.attributeValues ? this.attributeValues[stop?.attributeCodeOneToMultiple] : '';
    this.miFormGroup.registerControl(stop.attributeCodeOneToMultiple, new FormControl(value, validators));
  }

  addMoreStops(stopLocations?) {
    if (this.stops.length > 4) {
      event.stopPropagation();
      return;
    }
    this.bookingTypeRestrictionCount += 1;
    const newStop = _.cloneDeep(this.groupCopy);
    const validators = this.validationsService.addValidator(newStop.validation, this.currentSelectedCode);
    newStop.attributeCodeOneToMultiple = `${this.bookingTypeRestrictionCount}_${newStop.attributeCode}`;
    newStop.isOneToMultiple = true;
    newStop.fieldName = newStop.attributeCode;
    const value = this.attributeValues ? this.attributeValues[newStop?.attributeCodeOneToMultiple] : '';
    this.miFormGroup.registerControl(newStop?.attributeCodeOneToMultiple, new FormControl(value, validators));
    this.stops.push(newStop);
    this.cd.detectChanges();
  }

  removeStop(index, stop) {
    this.deleteStopFromDb(index, stop);
    this.stopRemoved.emit(index);
    if (this.stops.length > 2) {
      this.showHamburger = true;
    } else {
        this.showHamburger = false;
    }
  }

  deleteStopFromDb(index, stop) {
    this.stops.splice(index, 1);
    this.miFormGroup.removeControl(stop.attributeCodeOneToMultiple);
    this.cd.detectChanges();
  }

  showDialog(stop) {
    // console.log('click from stop geo');
    // console.log(stop);
    this.uiService.showGeoLocationDialog();
  }
}
