<p-dialog [header]="'lbl_print_labels' | translate" (onHide)="onHide($event)" [(visible)]="visiblePrintLabel"
  [modal]="true" [style]="{ width: '50vw'}">
  <p class="m-0 pt-2 pb-4">{{ "print_label_sub_header" | translate }} <span class="font-bold">{{ bulkRowCount }} {{ "print_label_sub_header2" | translate }}</span></p>
  <div class="grid">
    <div class="flex flex-column col-5 md:col-5 lg:col-5 padding-16">
      <label class="text-color font-bold right-8 relative" translate>lbl_select_label_quantity
        <span class="info">
          <i class="{{miIcons.INFO_CIRCLE_OUTLINE}} inline-block wh-18 relative top-5" type="text" pInputText [pTooltip]="'lbl_print_label_tooltip_msg' | translate" placeholder="auto"></i>
        </span>
      </label>
      <p-inputNumber class="py-3 right-8 relative" [(ngModel)]="selectLabelValue" [showButtons]="true" buttonLayout="horizontal" inputId="horizontal" 
        spinnerMode="horizontal" [step]="1" decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" 
        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"></p-inputNumber>

      <label class="text-color font-bold mb-16 right-8 relative" translate>lbl_select_label_size</label>
      <div class="grid mb-16 relative right-2">
        <div class="p-field-radiobutton flex mb-8px w-full">
          <div class="card p-radiobutton w-full border-1 border-round wh-radio-card cursor-pointer flex align-items-center align-content-center">
            <input class="relative cursor-pointer" type="radio" id="small" name="options" value="small" (onClick)="loadContent('small')" [(ngModel)]="selectedRadioOption" />
            <label for="small" class="relative left-10">
              <div class="font-bold default-text-black-color" translate>lbl_small_size</div>
              <div class="default-text-light-gray" translate>lbl_avery_30_per_sheet</div>
            </label>
          </div>
        </div>
        <div class="p-field-radiobutton flex w-full">
          <div class="card p-radiobutton w-full border-1 border-round wh-radio-card cursor-pointer flex align-items-center align-content-center">
            <input class="relative cursor-pointer" type="radio" id="large" name="options" value="large" (onClick)="loadContent('large')" [(ngModel)]="selectedRadioOption" />
            <label for="large" class="relative left-10">
              <div class="font-bold default-text-black-color" translate>lbl_large_size</div>
              <div class="default-text-light-gray" translate>lbl_avery_10_per_sheet</div>
            </label>
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="col-12 relative right-10 pr-0">
          @if (selectedRadioOption === appSettings?.PRINT_LABEL_RADIO_OPTIONS[0]?.value) {
            <div class="default-text-black-color relative bottom-8" translate>lbl_small_labels_we_recommend</div>
            <div class="default-text-blue-color relative bottom-8">{{'lbl_avery_inkjet' | translate}} {{'lbl_inches_1' | translate}}</div>
            <div class="default-text-blue-color relative bottom-2">{{'lbl_avery_laser' | translate}} {{'lbl_inches_1' | translate}}</div>
          }
        </div>
        <div class="col-12 relative bottom-14 right-10">
          @if (selectedRadioOption === appSettings?.PRINT_LABEL_RADIO_OPTIONS[1]?.value) {
            <div class="default-text-black-color relative bottom-8" translate>lbl_large_labels_we_recommend</div>
            <div class="default-text-blue-color relative bottom-8">{{'lbl_avery_inkjet' | translate}} {{'lbl_inches_2' | translate}}</div>
            <div class="default-text-blue-color relative bottom-2">{{'lbl_avery_laser' | translate}} {{'lbl_inches_2' | translate}}</div>
          }
        </div>
      </div>
    </div>
    <div class="col-7 md:col-7 lg:col-7 template-preview">
      <div class="grid">
        <div class="col">
          <label translate>lbl_template_preview</label>
        </div>
        <div class="col">
          <label translate>lbl_label_preview</label>
        </div>
        <div class="col-12 md:col-12 lg:col-12 ml-2">
          @if (selectedRadioOption === appSettings?.PRINT_LABEL_RADIO_OPTIONS[0]?.value) {
            <div class="flex flex-wrap">
              <div class="grid col bg-white border-round flex flex-wrap justify-content-space-evenly wh-template-div">
                @for (data of smallRadioData; track data;) {
                  <div class="wh-template-preview bg-white border-1 border-solid default-print-label-color border-round"></div>
                  <div class="whm-template-preview bg-white border-1 border-solid default-print-label-color border-round"></div>
                  <div class="wh-template-preview bg-white border-1 border-solid default-print-label-color border-round"></div>
                }
              </div>
              <div class="col bg-white border-round wh-label-div flex justify-content-center">
                <div class="qr-wrapper" (mouseenter)="handleMouseEnter()" (mouseleave)="handleMouseLeave()">
                  @if (barcodeImage) {
                    <img class="wh-70 w-full qr-image" [src]="barcodeImage" [ngClass]="{'zoomed': zoomed}"/>
                    <p class="font-bold">{{singleRowDataName}}</p>
                  }
                  @if (!barcodeImage) {
                    <img class="wh-70 w-full qr-image" src="../../../../../assets/images/mi-theme/light/basic/QR_Code.svg" [ngClass]="{'zoomed': zoomed}"/>
                    <p class="font-bold" translate>lbl_vehicle_name</p>
                  }
                  @if (showZoomIcon) {
                    <button class="zoom-icon" (click)="zoomQR()">
                      <i class="pi pi-search-plus"></i>
                    </button>
                  }
                </div>
              </div>
            </div>
          }
        </div>
        <div class="col-12 md:col-12 lg:col-12 top-m-14 relative ml-2">
          @if (selectedRadioOption === appSettings?.PRINT_LABEL_RADIO_OPTIONS[1]?.value) {
            <div class="flex flex-wrap">
              <div class="grid col bg-white border-round flex flex-wrap justify-content-space-evenly wh-template-div">
                @for (data of largeRadioData; track data;) {
                  <div class="wh-label-preview bg-white border-1 border-solid default-print-label-color border-round" style="width: 48%;"></div>
                  <div class="wh-label-preview bg-white border-1 border-solid default-print-label-color border-round relative left-3" style="width: 48%;"></div>
                }
              </div>
              <div class="col bg-white border-round wh-label-div flex justify-content-center">
                <div class="qr-wrapper" (mouseenter)="handleMouseEnter()" (mouseleave)="handleMouseLeave()">
                  @if (barcodeImage) {
                    <img class="wh-70 w-full qr-image" [src]="barcodeImage" [ngClass]="{'zoomed': zoomed}"/>
                    <p class="font-bold">{{singleRowDataName}}</p>
                  }
                  @if (!barcodeImage) {
                    <img class="wh-70 w-full qr-image" src="../../../../../assets/images/mi-theme/light/basic/QR_Code.svg" [ngClass]="{'zoomed': zoomed}"/>
                    <p class="font-bold" translate>lbl_vehicle_name</p>
                  }
                  @if (showZoomIcon) {
                    <button class="zoom-icon" (click)="zoomQR()">
                      <i class="pi pi-search-plus"></i>
                    </button>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
    <div class="col-12 relative text-right pt-3">
      <button pButton class="mr-2 bg-white text-color-secondary border-none border-button" (click)="onHide($event)" [label]="'cancel' | translate"></button>
      <p-button [label]="'lbl_generate_template' | translate" (click)="generateTemplate()"></p-button>
    </div>
  </div>
</p-dialog>

<p-toast></p-toast>
