import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { AppSettings } from 'app/modules/shared/app.settings';


@Component({
  selector: 'app-date-year',
  templateUrl: './date-year.component.html',
  styleUrls: ['./date-year.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CalendarModule]
})
export class DateYearComponent {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  placeholder: any;
  minDate: Date;
  maxDate: Date;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit(): void {
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, null);
    const currentDate = new Date();
    this.maxDate = new Date(currentDate.getFullYear(), 11, 31);
    const minYear = currentDate.getFullYear() - 24;
    this.minDate = new Date(minYear, 0, 1);

  }

}