import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { TabViewModule } from 'primeng/tabview';
import { forkJoin } from 'rxjs';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import type { EntityCountsResponse } from '../../../vehicles/models/entity.models';
import type { EntityList, LeaveManagementModal, Values } from '../../models/leaveManagement.models';
import { LeaveManagementService } from '../../services/leave-management.service';

@Component({
  selector: 'app-today-leave-view-all',
  standalone: true,
  imports: [
    BreadcrumbModule,
    TabViewModule,
    TranslateModule,
    MITableComponent,
    ChartModule,
    ButtonModule
  ],
  templateUrl: './today-leave-view-all.component.html',
  styleUrl: './today-leave-view-all.component.scss',
  providers: [DatePipe]
})
export class TodayLeaveViewAllComponent implements OnInit {
  btnLabel: string;
  routePath: MenuItem[] = [];
  data: any;
  todaysDate: Date = new Date();
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  leaveListData: any = {};
  totalRecords: number = 0;
  entityName;
  currentRow;
  filterList;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];
  isEntity: boolean = true;
  attributeData: any;
  filterFieldsArray: any[];
  uniqueId: string = 'leave_management';
  rowItems: MenuItem[] | undefined;
  rejectLabel: string = this.cs.getLabel('leave_management.lbl_reject');
  editLabel: string = this.cs.getLabel('leave_management.lbl_edit');
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  };

  constructor(private cs: CommonBindingDataService, private router: Router, private datePipe: DatePipe,
    private leaveManagementService: LeaveManagementService, private configService: ConfigService) { }

  ngOnInit() {
    this.initializeOtherVariablesAndAPI();
    this.initializeColumn();
    this.getBreadcrumb();
    this.getListData();
  }

  initializeColumn() {
    this.allColumns = [
      {
        "attributeCode": "fullName",
        "attributeIndex": 0,
        "field": "fullName",
        "header": this.cs.getLabel('leave_management.columns.lbl_name')
      },
      {
        "attributeCode": "driverUniqueId",
        "attributeIndex": 1,
        "field": "driverUniqueId",
        "header": this.cs.getLabel('leave_management.columns.lbl_id')
      },
      {
        "attributeCode": "driverCallSign",
        "attributeIndex": 2,
        "field": "driverCallSign",
        "header": this.cs.getLabel('leave_management.columns.lbl_call_sign')
      },
      {
        "attributeCode": "leaveType",
        "attributeIndex": 3,
        "field": "leaveType",
        "header": this.cs.getLabel('leave_management.columns.lbl_leave_type')
      }
    ];
  }

  initializeOtherVariablesAndAPI() {
    this.btnLabel = this.cs.getLabel('lbl_new_leave');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.getData();
  }

  private getBreadcrumb() {
    const formattedTodaysDate = this.datePipe.transform(this.todaysDate, AppSettings.LEAVE_DATE_FORMAT)
    this.routePath = [
      {
        label: this.cs.getLabel('label_today') + ' ' + '(' + formattedTodaysDate + ')',
        routerLink: '../../driver-leave-management',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      }
    ];
  }

  private getListData() {
    this.btnLabel = this.cs.getLabel("lbl_new_leave");
  }

  getTableView() {
    this.isLoading = false;
    this.searchEntity();
    this.filterAvailableColumns = _.clone(this.allColumns);
    this.isEntity = false;
  }

  getFilterView() {
    this.filterList = [
      {
        "attributeCode": "Leave Type",
        "attributeIndex": 3,
        "presetValues": [
          {
            "labelKey": "Casual Leave",
            "labelValue": "Casual Leave",
          },
          {
            "labelKey": "Earned Leave",
            "labelValue": "Earned Leave",
          },
          {
            "labelKey": "Sick Leave",
            "labelValue": "Sick Leave",
          },
        ],
      },
    ];
  }


  private updateFilterFieldsArray(fields: any[]) {
    if (!fields) return;

    this.filterFieldsArray = [];
    fields.forEach(field => {
      if (field.attributeCode === 'approvalStatus') {
        this.filterFieldsArray.push(field);
      }
    });
  }

  getData() {
    return forkJoin({
      tableViewData: this.getTableView(),
      filterViewDate: this.getFilterView(),
    })
  }

  searchEntity() {
    this.tableData = [];
    this.entityData.countryCode = this.country[0]?.countryCode;
    this.entityData.fromDateStr = this.cs.formatDate(this.todaysDate);
    this.leaveManagementService.searchEntityForTomorrowLeave(AppSettings.ENTITY_CODE.LEAVES, this.entityData).subscribe((res: LeaveManagementModal) => {
      this.listColumns = res?.data[0]?.values;
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = res?.data.map(data => ({
        ...data,
        fullName: `${data.driverFirstName} ${data.driverLastName}`
      })) || [];
      this.leaveListData = res;
    })
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  onFilterValueChange(event: { [key: string]: any }) {
    for (const [key, value] of Object.entries(event)) {
      if (value) {
        const attributeValue = Array.isArray(value) ? value : [value];
        if (key === 'Leave Type') {
          this.entityData.leaveType = attributeValue;
        }
      }
    }
    if (!this.entityData.leaveType || this.entityData.leaveType.length === 0) {
      delete this.entityData.leaveType;
    }
    this.entityData.offset = event.first;
    this.searchEntity();
  }

  onAddNewLeave() {
    this.router.navigate(["app/driver-leave-management/new-leave"]);
  }
}
