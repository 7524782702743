<div appAccessProvider [accessCode]="{ menuCode: 'ADMINISTRATION', pageCode: 'GENERAL_SETTING', subPageCode: 'EDIT' }">
<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white border-button default-text-light-black-color cursor-pointer" [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button  class="flex align-items-center justify-content-center cursor-pointer" (click)="onNextBtnClick()" [label]="saveGeneralSettingsBtnLabel"></p-button>
</div>

<p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
              <i class="mi-lg details mr-2"></i>
              <span translate>settings.general_settings</span>
            </div>
          </ng-template>
          @if (showAddress) {
            @for(tab of generalAttributeData?.tabs; track tab?.tabCode) {
                <app-mi-form class="general-group"
                    [attributeLabels]="attributeLabels"
                    [moduleName]="generalSettingsModuleName"
                    [attributeValues]="attributeValues"
                    [addressEntityIdList]="addressEntityIdList"
                    [groups]="tab.groups"
                    [showImg]="true"
                    [defaultLocationList]="defaultLocationList"
                    [isMultipleFilesUpload]="false"
                    [previousBtnText]="previousBtnLabel"
                    [submitBtnText]="saveGeneralSettingsBtnLabel"
                    (saveData)="saveGeneralSettings($event)"
                    [saveBtnId]="'general'">
                </app-mi-form>
            }
          }
</p-tabPanel>
</p-tabView>
</div>