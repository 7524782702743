import { Component } from '@angular/core';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'app-error-skelton',
  standalone: true,
  imports: [TagModule],
  templateUrl: './error-skelton.component.html',
  styleUrl: './error-skelton.component.scss'
})
export class ErrorSkeltonComponent {

}
