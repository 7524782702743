import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { AppIcons } from '../../../../../shared/app.icons';
import { AppSettings } from '../../../../../shared/app.settings';
import { CommonBindingDataService } from '../../../../../shared/services/common-binding-data.service';

import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SettingsService } from 'app/modules/settings/settings.service';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { Column } from '../../../../../shared/components/mi-table/models/table';
import { ListResponse } from '../../../../../vehicles/models/listResponse.models';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { SettingsAddReasonForBreakComponent } from './settings-add-reason-for-break/settings-add-reason-for-break.component';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-settings-reason-for-break',
  standalone: true,
  imports: [TabViewModule, TranslateModule, ButtonModule, InputTextModule, TableModule, MenuModule, FormsModule, NgClass, AccessProviderDirective, SettingsAddReasonForBreakComponent],
  templateUrl: './settings-reason-for-break.component.html',
  styleUrl: './settings-reason-for-break.component.scss'
})
export class SettingsReasonForBreakComponent implements OnInit{
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  @Output() onPageChange: EventEmitter<any> = new EventEmitter();
  miIcons = AppIcons;
  addBtnLabel = this.cs.getLabel('settings.shift_management.lbl_add_reason_for_break');
  editLabel: string = this.cs.getLabel('settings.edit');
  deleteLabel: string = this.cs.getLabel('settings.delete');
  deleteHeaderMsg: string = this.cs.getLabel('settings.shift_management.reason_for_break_delete_header');
  deleteMsg: string = this.cs.getLabel('settings.shift_management.delete_msg');
  isVisible: boolean = false;
  isModal: boolean = false;
  searchValue: string = '';
  totalRecords: number;
  columns;
  tableDataTemp: any = [];
  listColumns;
  pagination: any;
  tableData: any = [];
  cols!: Column[];
  selectedRows;
  rowItems: MenuItem[] | undefined;
  bulkSelectionsItems: MenuItem[] | undefined;
  currentRow;
  rowData;

  constructor(private cs: CommonBindingDataService, private settingsService: SettingsService,
    private confirmationService: ConfirmationService, private messageService: MessageService, private configService: ConfigService) { }

  ngOnInit() {
    this.getColumns();
    this.setRowItems();
    this.searchAttributeSettings();
    this.initializeBulkSelectionsItems();
  }

  rowMouseOver(rowData) {
    rowData.showRowMenu = true;
  }

  rowMouseOut(rowData) {
    rowData.showRowMenu = false;
  }

  getColumns() {
    this.cols = [
      { field: 'labelValue', header: this.cs.getLabel('lbl_name') },
      { field: 'usage', header: this.cs.getLabel('lbl_usage') }
    ];
  }

  public setRowItems() {
    this.rowItems = [
      {
        label: this.editLabel,
        icon: AppIcons.EDIT_2 + " wh-16",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.update();
        }
      },
      {
        label: this.deleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-16",
        iconClass: "bulk-update-icon",
        command: (e) => {
          const clonedEvent = _.cloneDeep(e);
          this.delete(clonedEvent, this.selectedRows, AppSettings.DELETE_TYPE.ROW);
        },
      },
    ];
  }


  private initializeBulkSelectionsItems() {
    this.bulkSelectionsItems = [
      {
        label: this.deleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-16",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        style: { 'width': '170px' },
        command: (e) => {
          const clonedEvent = _.cloneDeep(e);
          this.delete(clonedEvent, this.selectedRows, AppSettings.DELETE_TYPE.BULK);
        },
      },
    ];
  }

  update() {
    this.rowData = this.currentRow;
    this.isVisible = true;
  }

  delete(event: Event, rowData: any, deleteType: string) {
    this.confirmationService.confirm({
      header: this.deleteHeaderMsg,
      message: this.deleteMsg,
      acceptIcon: '',
      rejectIcon: '',
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        if (deleteType === AppSettings.DELETE_TYPE.ROW) {
          this.onSettingsDelete(this.currentRow.presetLabelId);
        } else {
          this.onBulkDataDeleteEvent(this.selectedRows.map(ele => {
            return ele.presetLabelId
          }));
        }
      },
      reject: () => {
      }
    });
  }

  onSettingsDelete(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      presetLabelIds: [
        event
      ]
    };
    this.settingsService.deleteCommonPresetLabels(requestBody).subscribe(response => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.shift_management.lbl_reason_for_break_delete_successfully') });
      this.searchAttributeSettings();
    })
  }

  onBulkDataDeleteEvent(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      presetLabelIds: event
    };
    this.settingsService.deleteCommonPresetLabels(requestBody).subscribe(response => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.shift_management.lbl_reason_for_breaks_delete_successfully') });
      this.selectedRows = [];
      this.searchAttributeSettings();
    })
  }

  onToggleManageColumn(event) {
    this.isModal = true;
  }

  setCurrentRow(rowData) {
    this.currentRow = rowData;
    this.updateContextMenu();
  }

  updateContextMenu() {
    this.rowItems = [
      {
        label: this.editLabel,
        icon: AppIcons.EDIT_2 + " wh-16",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.update();
        },
        disabled: !this.currentRow?.customValue
      },
      {
        label: this.deleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-16",
        iconClass: "bulk-update-icon",
        style: { 'opacity': '1', },
        command: () => {
          const clonedEvent = _.cloneDeep(this.currentRow);
          this.delete(clonedEvent, this.selectedRows, AppSettings.DELETE_TYPE.ROW);
        },
        disabled: !this.currentRow?.customValue
      }
    ];
  }

  clearSelected(): void {
    this.selectedRows = [];
  }

  onAddDialogOpen() {
    this.isVisible = true;
    this.rowData = '';
  }

  handleOverlayToggle() {
    this.isVisible = false;
    this.searchAttributeSettings();
  }

  searchAttributeSettings() {
    let requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.REASON_FOR_BREAK,
      searchStr: '',
      usageRequired: true
    }
    this.settingsService.getCommonPresetLabels(requestBody).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0];
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = this.cs.getTableDataForSettings(res);
      this.tableDataTemp = this.tableData;
    })
  }

  onSearch(event, settingsData) {
    const value = event.target.value.toLowerCase();
    if (settingsData) {
      this.tableData = this.tableDataTemp.filter(ele => ele?.labelValue.toLowerCase().includes(value));
    }
  }

  clearSearch() {
    this.searchValue = "";
    this.searchAttributeSettings();
    this.onSearchValueChange.emit('');
  }

  onPageChange2(event: PageEvent) {
    this.onPageChange.emit(event);
  }
}
