<div class="flex mi-filter-container">
  <div class="flex flex-wrap justify-content-center">
    <div class="flex-1 flex justify-content-end mr-2">
      <span class="p-input-icon-left">
        <i [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue }"
          class="pi relative left-20 cursor-pointer" (click)="clearSearch()"></i>
        <input class="width-200" type="text" pInputText [placeholder]="'lbl_search' | translate"
          [(ngModel)]="searchValue" (input)="onSearch($event)"/>
      </span>
    </div>

    @if(filterList && filterList?.length>0){
    <app-quick-filter-form
      [fields]="filterList"
      [moduleName]="moduleName"
      class="relative mr-2"
      [attributeLabels]="attributeLabels"
      (onValueChange)="onFilterValueChange(false, $event)">
    </app-quick-filter-form>

    } 
    @if(selectedFilterCount > 0){
      <button  pButton [label]="'clear_all' | translate" (click)="clearFilter()" class="mr-2 bg-white text-primary border-none cursor-pointer clear-btn"></button>
    }
  </div>

  <!-- <div class="flex-1 flex align-items-center justify-content-end custom-feed-container">
    <button class="flex text-color nav-item-border bg-white cursor-pointer align-items-center font-14 mr-2"
      pButton label="{{ 'customize_feeds' | translate }}" (click)="openDialog()">
      <span>
        <i class="{{miIcons.DASHBOARD_MANAGE_COLUMN}} wh-18 inline-block relative right-5 top-3"></i>
      </span>
    </button>
  </div> -->
</div>


