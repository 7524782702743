<div class="flex flex-column p-3 pl-4 pt-0">
  <div class="flex flex-row align-items-center border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.journey_status</div>
    <div class="w-6 journey-status flex flex-row justify-content-between">
        <div>
            <i class="mi-lg user_check bg-green inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-2"></i>
            <span class="font-14 font-bold text-green-color" translate>dispatch.acknowledged</span>
        </div>
        <div><i class="mi-lg chevron-down inline-block bg-print-label-color vertical-align-text-bottom cursor-pointer wh-20"></i></div>
    </div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.assign</div>
    <div class="w-6 flex flex-row align-items-center">
        <div class="flex align-items-center mr-3">
            <p-radioButton [disabled]="true" name="assign" value="Auto" [(ngModel)]="assignType" inputId="auto" #autoRadioButton></p-radioButton>
            <label [ngClass]="{'font-bold': autoRadioButton.checked}" for="auto" class="ml-2" translate>dispatch.auto</label>
        </div>
        
        <div class="flex align-items-center">
            <p-radioButton [disabled]="true" name="assign" value="Manual" [(ngModel)]="assignType" inputId="manual"></p-radioButton>
            <label for="manual" class="ml-2" translate>dispatch.manual</label>
        </div>
    </div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.trans_mode</div>
    <div class="w-6">
        <span>
          @switch (bookingDetailItem.transportation_mode) {
            @case (TRANSPORTATION_MODE.CAR) {
            <i class="{{miIcons.BOOKING_CAR}} bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-2 "></i>
            }
  
            @case (TRANSPORTATION_MODE.SHUTTLE) {
            <i class="{{miIcons.BOOKING_SHUTTLE_OLD}} bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-2 "></i>
            }
  
            @case (TRANSPORTATION_MODE.BUS) {
            <i class="{{miIcons.BOOKING_BUS}} bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-2 "></i>
            }
          }
        </span>
        <span class="font-16 font-semibold default-text-black-color" translate>{{bookingDetailItem.transportation_mode}}</span>
    </div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.trans_type</div>
    <div class="w-6">
        <span>
          @switch (bookingDetailItem.transportation_type) {
            @case (TRANSPORTATION_TYPE.AIRPORT) {
              <i class="{{miIcons.BOOKING_AIRPORT}} bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-2"></i>
            }

            @case (TRANSPORTATION_TYPE.CITY_RIDE) {
              <i class="{{miIcons.BOOKING_CITY_RIDE}} bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-2"></i>
            }

            @case (TRANSPORTATION_TYPE.OUTSTATION) {
              <i class="{{miIcons.BOOKING_OUTSTATION}} bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-2"></i>
            }

            @case (TRANSPORTATION_TYPE.DEDICATED) {
              <i class="{{miIcons.BOOKING_DEDICATED}} bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-2"></i>
            }
          }
        </span>
        <span class="font-16 font-semibold default-text-black-color" translate>{{bookingDetailItem.transportation_type}}</span>
    </div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.trip</div>
    <div class="w-6 font-14 font-normal default-text-black-color">{{bookingDetailItem.trip}}</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.ride</div>
    <div class="w-6 font-14 font-normal default-text-black-color">Shared Ride</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.eta</div>
    <div class="w-6 font-16 font-bold default-text-black-color">16:35</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.pickup</div>
    <div class="w-6 default-text-black-color"><span class="font-16 font-bold default-text-black-color">
      {{bookingDetailItem.departure_time_and_date | date: 'hh:mm'}}</span>, {{bookingDetailItem.departure_time_and_date | date: 'dd MMM yyyy'}}
    </div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.return</div>
    <div class="w-6 default-text-black-color"><span class="font-16 font-bold default-text-black-color">16:04</span>, 28 Jul 2023</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.distance</div>
    <div class="w-6 font-16 font-bold default-text-black-color">4.45 Miles</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.pickup_location</div>
    <div class="w-6 default-text-black-color">
        <span><i class="mi-lg mi-home bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-16 mr-1"></i></span>
        {{bookingDetailItem.booking_pickup_location_address}}
    </div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.stop_location</div>
    <div class="w-6">{{bookingDetailItem.booking_stops_location_address}}</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.drop_location</div>
    <div class="w-6 default-text-black-color">
        <span><i class="mi-lg mi-home bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-16 mr-1"></i></span>
        {{bookingDetailItem.booking_dropoff_location_address}}
    </div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.duration</div>
    <div class="w-6">--</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.waiting_time</div>
    <div class="w-6">10 Min</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.total_pass</div>
    <div class="w-6 font-16 font-bold default-text-black-color">{{ (bookingDetailItem?.number_of_passenger ? bookingDetailItem?.number_of_passenger : null) + bookingDetailItem?.passengers?.length }}</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.child_seats</div>
    <div class="w-6 flex flex-column">
        <div class="default-text-black-color" translate>dispatch.infant_carrier <span class="default-text-blue-color font-16 font-bold">
          {{bookingDetailItem.infant_carrier}}</span>
        </div>
        <div class="default-text-black-color" translate>dispatch.child_seat <span class="default-text-blue-color font-16 font-bold">
          {{bookingDetailItem.child_seat}}</span>
        </div>
    </div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.luggages</div>
    <div class="w-6 default-text-blue-color font-16 font-bold">{{bookingDetailItem.luggages}}</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.wheelchairs</div>
    <div class="w-6">{{dispatchBookingDetails.wheelchair}}</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal pr-4" translate>dispatch.add_vehicle_requirements</div>
    <div class="w-6 font-16 font-bold default-text-black-color">{{dispatchBookingDetails.additional_vehicle_requirement}}</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.add_requirements</div>
    <div class="w-6 flex flex-column">
        <div>{{bookingDetailItem.ride_preference}}</div>
    </div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.booking_by_name</div>
    <div class="w-6"><span class="font-semibold default-text-black-color">Benjamin Davis</span> (Passenger)</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.booking_time</div>
    <div class="w-6 font-semibold default-text-black-color">18:47, 20 Sep 2023</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.booking_note</div>
    <div class="w-6">{{bookingDetailItem.booking_note}}</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.note</div>
    <div class="w-6">{{bookingDetailItem.note_for_driver}}</div>
  </div>
</div>
