import { Component } from "@angular/core";
import { ConfirmationService, MenuItem, Message } from "primeng/api";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { TranslateModule } from "@ngx-translate/core";
import { AppIcons } from "../../../shared/app.icons";
import { UserCommonService } from "../../../shared/services/user-common.service";
import { NavigationEnd, Router, RouterModule } from "@angular/router";
import { StorageService } from "../../../shared/services/storage.service";
import { SettingsLoginAndPasswordComponent } from "./settings-login-and-password/settings-login-and-password.component";
import { SettingsNotificationSettingsComponent } from "./settings-notification-settings/settings-notification-settings.component";
import { SettingsUserProfileComponent } from "./settings-user-profile/settings-user-profile.component";
import { SettingsPassengerTypeComponent } from "./passenger/settings-passenger-type/settings-passenger-type.component";
import { SettingsPrivilegeSettingsComponent } from "./passenger/settings-privilege-settings/settings-privilege-settings.component";
import { SettingsVehicleStatusComponent } from "./vehicle/settings-vehicle-status/settings-vehicle-status.component";
import { SettingsVehicleTypesComponent } from "./vehicle/settings-vehicle-types/settings-vehicle-types.component";
import { SettingsBodyTypesComponent } from "./vehicle/settings-body-types/settings-body-types.component";
import { SettingFeaturesComponent } from "./vehicle/setting-features/setting-features.component";
import { SettingsReasonForDutyStatusComponent } from "./driver/settings-reason-for-duty-status/settings-reason-for-duty-status.component";
import { SettingsLocationCategoryComponent } from "./location/settings-location-category/settings-location-category.component";
import { SettingsStatusComponent } from "./incident-complaint/settings-status/settings-status.component";
import { SettingsGroupColorComponent } from "./shift-management/settings-group-color/settings-group-color.component";
import { SettingsSavedReportsComponent } from "./reports/settings-saved-reports/settings-saved-reports.component";
import { SettingsCustomFieldsComponent } from "./settings-custom-fields/settings-custom-fields.component";
import { SettingsLabelsComponent } from "./settings-labels/settings-labels.component";
import { SettingsGeneralSettingsComponent } from "./administration/settings-general-settings/settings-general-settings.component";
import { SettingsTimeSettingsComponent } from "./administration/settings-time-settings/settings-time-settings.component";
import { SettingsExportAccountDataComponent } from "./administration/settings-export-account-data/settings-export-account-data.component";
import { SettingsSecurityComponent } from "./administration/settings-security/settings-security.component";
import { SettingsTransportationTypeComponent } from "./events/settings-transportation-type/settings-transportation-type.component";
import { SettingsRidePreferenceComponent } from "./bookings/settings-ride-preference/settings-ride-preference.component";
import { SettingsMessageTypeComponent } from "./communication/settings-message-type/settings-message-type.component";
import { SettingsDrivingRecordComponent } from "./driver/settings-driving-record/settings-driving-record.component";
import { AppSettings } from "app/modules/shared/app.settings";
import { SettingsUserComponent } from "./authentication/settings-user/settings-user.component";
import { SettingsDriverComponent } from "./authentication/settings-driver/settings-driver.component";
import { SettingsPassengerComponent } from "./authentication/settings-passenger/settings-passenger.component";
import { NgClass } from "@angular/common";
import { SettingsLeaveTypeComponent } from "./driver/settings-leave-type/settings-leave-type.component";
import { SettingsReasonForBreakComponent } from "./shift-management/settings-reason-for-break/settings-reason-for-break.component";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-settings",
  standalone: true,
  imports: [
    RouterModule,
    BreadcrumbModule,
    TranslateModule,
    SettingsLoginAndPasswordComponent,
    SettingsNotificationSettingsComponent,
    SettingsUserProfileComponent,
    SettingsTransportationTypeComponent,
    SettingsPassengerTypeComponent,
    SettingsPrivilegeSettingsComponent,
    SettingsVehicleStatusComponent,
    SettingsVehicleTypesComponent,
    SettingsBodyTypesComponent,
    SettingFeaturesComponent,
    SettingsMessageTypeComponent,
    SettingsReasonForDutyStatusComponent,
    SettingsLocationCategoryComponent,
    SettingsStatusComponent,
    SettingsGroupColorComponent,
    SettingsSavedReportsComponent,
    SettingsCustomFieldsComponent,
    SettingsLabelsComponent,
    SettingsGeneralSettingsComponent,
    SettingsTimeSettingsComponent,
    SettingsExportAccountDataComponent,
    SettingsSecurityComponent,
    SettingsRidePreferenceComponent,
    SettingsDrivingRecordComponent,
    SettingsUserComponent,
    SettingsDriverComponent,
    SettingsPassengerComponent,
    SettingsLeaveTypeComponent,
    SettingsReasonForBreakComponent,
    NgClass
  ],
  templateUrl: "./settings.component.html",
  styleUrl: "./settings.component.scss",
})
export class SettingsComponent {
  miIcons = AppIcons;
  routePath: MenuItem[] = [];
  message: Message[] = [];
  activeSection: string = "userProfile";
  sectionHandlers = {
    userProfile: "userProfile",
    notificationSettings: "notificationSettings",
    loginPassword: "loginPassword",
    generalSettings: "generalSettings",
    timeSettings: "timeSettings",
    authUser: 'authUser',
    authDriver: 'authDriver',
    authPassenger: 'authPassenger',
    exportAccountData: "exportAccountData",
    security: "security",
    customFields: "customFields",
    labels: "labels",
    journeyPreference: "journeyPreference",
    transportationType: "transportationType",
    passengerType: "passengerType",
    privilegeSettings: "privilegeSettings",
    vehicleStatus: "vehicleStatus",
    vehicleTypes: "vehicleTypes",
    bodyTypes: "bodyTypes",
    features: "features",
    reasonForDutyStatus: "reasonForDutyStatus",
    drivingRecord: 'drivingRecord',
    locationCategory: "locationCategory",
    incidentsStatus: "incidentsStatus",
    complaintsStatus: "complaintsStatus",
    groupColor: "groupColor",
    messageType: "messageType",
    leaveType: "leaveType",
    reasonForBreak: 'reasonForBreak',
  };
  private routeSubscription: Subscription;

  constructor(
    private cs: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private userCommandService: UserCommonService,
    private storageService: StorageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.routeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateActiveSection();
    });
    this.setBreadcrumb();
  }

  updateActiveSection() {
    const currentUrl = this.router.url;

    if (currentUrl.includes('user-profile')) {
      this.activeSection = 'userProfile';
    } else if (currentUrl.includes('login-and-password')) {
      this.activeSection = 'loginPassword';
    }
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  private setBreadcrumb() {
    this.routePath = [
      { label: this.cs.getLabel("settings.lbl_settings") },
      {
        label: this.cs.getLabel("settings.lbl_user_profile"),
        styleClass: "breadcrumb-child forward-slash breadcrumb-text",
        style: { display: "flex", top: "2px", position: "relative" },
      },
    ];
  }

  updateBreadcrumb(label: string) {
    this.routePath = [
      { label: this.cs.getLabel("settings.lbl_settings") },
      {
        label,
        styleClass: "breadcrumb-child forward-slash breadcrumb-text",
        style: { display: "flex", top: "2px", position: "relative" },
      },
    ];
  }

  doLogOut() {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.cs.getLabel("msg_logout"),
      header: this.cs.getLabel("header_logout_confirmation"),
      icon: "",
      acceptButtonStyleClass: "p-button-danger",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",

      accept: () => {
        this.logout();
      },
    });
  }

  logout() {
    this.userCommandService.signOut().subscribe(
      (results) => {
        this.storageService.removeAll();
        this.router.navigate(["/auth/login"]);
        location.reload();
      },
      (error) => {
        this.errorMessage(error);
      }
    );
  }

  errorMessage(error) {
    error = JSON.parse(error);
    this.message = [];
    this.message.push({
      severity: "error",
      summary: "error",
      detail: error.general[0].message,
    });
  }

  setActiveSection(section: string) {
    const sectionLabel = this.sectionHandlers[section];
    if (sectionLabel) {
      localStorage.setItem(AppSettings.FIELDS.SECTION_LABEL, sectionLabel);
      this.updateBreadcrumb(sectionLabel);
      this.activeSection = sectionLabel;
    }
  }
}
