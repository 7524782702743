import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-vehicle-tab',
  templateUrl: './vehicle-tab.component.html',
  styleUrl: './vehicle-tab.component.scss',
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class VehicleTabComponent {
  @Input() driverDetailItem: any;
  nameCode: any;
  groupName: any;
  passengerCapacity: any;
  vehicleType: any;
  bodyType: any;
  manufacturer: any;
  variant: any;
  yearOfManufacturing: any;
  constructor() { }

  ngOnInit(): void {
    const name = _.find(this.driverDetailItem.drivers, driver => driver.name_code);
    this.nameCode = name ? name.name_code : null;

    const group = _.find(this.driverDetailItem.drivers, driver => driver.group_name);
    this.groupName = group ? group.group_name : null;

    const passenger_capacity = _.find(this.driverDetailItem.drivers, driver => driver.passenger_capacity);
    this.passengerCapacity = passenger_capacity ? passenger_capacity.passenger_capacity : null;

    const vehicle_type = _.find(this.driverDetailItem.drivers, driver => driver.vehicle_type);
    this.vehicleType = vehicle_type ? vehicle_type.vehicle_type : null;

    const body_type = _.find(this.driverDetailItem.drivers, driver => driver.body_type);
    this.bodyType = body_type ? body_type.body_type : null;

    const manufacturer = _.find(this.driverDetailItem.drivers, driver => driver.manufacturer);
    this.manufacturer = manufacturer ? manufacturer.manufacturer : null;

    const variant = _.find(this.driverDetailItem.drivers, driver => driver.variant);
    this.variant = variant ? variant.variant : null;

    const year_of_manufacturing = _.find(this.driverDetailItem.drivers, driver => driver.year_of_manufacturing);
    this.yearOfManufacturing = year_of_manufacturing ? year_of_manufacturing.year_of_manufacturing : null;
  }
}
