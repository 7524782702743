<div appAccessProvider [accessCode]="{ menuCode: 'AUTHENTICATION', pageCode: 'PASSENGER', subPageCode: 'EDIT' }">
<div class="main-settings">
    <p-tabView>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-14 mr-2 relative"></i>
                <span translate>settings.lbl_passenger_setting</span>
            </ng-template>
            <div class="flex justify-content-end flex-wrap btn-with-links">
                <button pButton class="mr-2 bg-white default-text-light-black-color border-button"
                    [label]="'cancel' | translate" (click)="onCancel()"></button>
                <p-button class="flex align-items-center justify-content-center" [label]="addBtnLabel"
                    (click)="onAddUser()"></p-button>
            </div>
            @if(isReady){
            <form [formGroup]="profileForm">
                <div class="card p-0">


                    <div class="grid p-0 m-0 ">
                        <div class="form-field col-4 md:col-6 lg:col-4 field border-bottom-1 border-300 p-3 m-0">
                            <div class="font-semibold ">
                                <label class="navigation-item-label font-14 "
                                    translate>settings.lbl_select_passenger_app_login_option</label>
                            </div>
                        </div>
                        <div class="form-field col-8 md:col-6 lg:col-8 field border-bottom-1 border-300 p-3 m-0">
                            <div class="font-semibold">
                                <label class="navigation-item-label font-14"
                                    translate>settings.lbl_password_option</label>
                            </div>
                        </div>
                    </div>


                    <div class="grid p-0 m-0 ">

                        @if(passwordOptions.length> 0){

                        <div class="form-field col-4 md:col-6 lg:col-4 field border-bottom-1 border-300 p-0 m-0">
                            <div formGroupName="passwordOptions" class="m-3">
                                @for (item of passwordOptions; track $index) {
                                <div class="font-16 mb-16  text-left">
                                    <p-checkbox
                                        [formControlName]="item.loginOptionCode"
                                        [value]="item.loginOptionId"
                                        [label]="item.loginOptionType">
                                    </p-checkbox>
                                </div>
                                }
                            </div>



                        </div>
                        <div class="form-field col-8 md:col-6 lg:col-8 field border-bottom-1 border-300 p-0 m-0">
                            <div class="m-3">

                                <label class="flex " translate>settings.lbl_default_password
                                    <span class="pl-1 info">
                                        <i class="{{miIcons.INFO_CIRCLE_OUTLINE}} inline-block wh-18"
                                            type="text"
                                            pInputText
                                            [pTooltip]="tooltipContent"
                                            placeholder="auto"></i>
                                        <ng-template #tooltipContent>
                                            <div class="flex align-items-center">
                                                <span> Default password is the initial, pre-configured password assigned
                                                    to users for accessing the system, which should be changed to
                                                    prevent unauthorized access.<ul>
                                                        <li>The password should be at least 8-12 characters long.</li>
                                                        <li>It must contain a mix of uppercase and lowercase letters,
                                                            numbers, and special characters.</li>
                                                        <li>It should avoid sequences like "1234" or "abcd."</li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </ng-template>
                                    </span>

                                    @if(profileForm.controls.defaultPassword?.errors?.required){
                                    <span class="required">*</span>
                                    }

                                </label>

                                <div class="form-control w-6 col-8 md:col-8 lg:col-8 px-0">
                                    <p-password class="w-6" formControlName="defaultPassword" [toggleMask]="true" />
                                </div>
                                <div class="form-field col-6 md:col-6 lg:col-6 field mb-0 px-0">
                                    <label class="flex" translate>
                                        settings.authentication.password_help_text
                                    </label>
                                </div>
                                @if (profileForm.controls.defaultPassword.errors &&
                                (profileForm.controls.defaultPassword.dirty ||
                                profileForm.controls.defaultPassword.touched)) {
                                <div class="form-errors required-field">
                                    <div [hidden]="!profileForm.controls.defaultPassword.errors.required" translate>
                                        settings.authentication.password_is_required
                                    </div>

                                    <div [hidden]="!profileForm.get('defaultPassword')?.errors?.pattern" translate>
                                        settings.authentication.lbl_password_pattern_error
                                    </div>
                                </div>


                                }
                            </div>

                        </div>
                        }

                        @if(hasSso.length> 0){
                        <div class="form-field col-4 md:col-6 lg:col-4 field border-bottom-1 border-300 p-0">
                            <div class="m-3">
                                <div formGroupName="hasSso">
                                    @for (item of hasSso; track $index) {
                                    <div class="font-16 mb-16 text-left">
                                        <p-checkbox
                                            [formControlName]="item.loginOptionCode"
                                            [value]="item.loginOptionId"
                                            [label]="item.loginOptionType">
                                        </p-checkbox>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="form-field col-8 md:col-6 lg:col-8 field border-bottom-1 border-300 px-0">
                            <div class="m-3">
                                <label class="flex" translate>settings.authentication.lbl_meta_url
                                    @if(profileForm.controls.metaData?.errors?.required){
                                    <span class="required">*</span>
                                    }
                                </label>

                                <div class="form-control">
                                    <input #nameInput formControlName="metaData" class="p-component p-element w-6"
                                        type="text" pInputText />

                                </div>

                                <div class="form-field col-6 md:col-6 lg:col-6 field px-0">
                                    <label class="flex px-0" translate>
                                        settings.authentication.lbl_enter_meta_url
                                    </label>
                                </div>
                                @if (profileForm.controls.metaData.errors && (profileForm.controls.metaData.dirty ||
                                profileForm.controls.metaData.touched)) {
                                <div class="form-errors required-field">
                                    <div [hidden]="!profileForm.controls.metaData.errors.required" translate>
                                        settings.authentication.lbl_error_meta_url_required
                                    </div>
                                </div>
                                }

                            </div>
                        </div>
                        }


                        @if(hasOtp.length> 0){
                        <div formGroupName="hasOtp" class="">
                            @for (item of hasOtp; track $index) {
                            <div class="font-16 mb-16 ml-3 text-left">
                                <p-checkbox
                                    [formControlName]="item.loginOptionCode"
                                    [value]="item.loginOptionId"
                                    [label]="item.loginOptionType">
                                </p-checkbox>
                                <label class="text-500 font-14" translate>
                                    settings.authentication.lbl_description</label>
                            </div>
                            }
                        </div>

                        }

                    </div>
                </div>
                <div class="card mt-1 p-0">
                    <div class="flex p-3">
                        <div class="flex">
                            <div class="font-semibold font-18 uppercase">
                                <label class="navigation-item-label "
                                    translate>settings.authentication.lbl_session_timeout</label>
                            </div>
                        </div>
                    </div>
                    <div class="p-3 pt-0">
                        <label class="text-500 font-14" translate>
                            settings.authentication.lbl_session_description</label>
                    </div>


                    <div class="form-field col-6 md:col-6 lg:col-6 field p-2 ml-2 mb-2">
                        <label class="flex" translate>settings.authentication.lbl_mobile_app
                        </label>
                        <div class="form-control mb-3 w-full">
                            <p-dropdown
                                class="w-full"
                                formControlName="timeout"
                                [options]="timeoutOptions"
                                optionLabel="label"
                                optionValue="value"
                                placeholder="{{'settings.authentication.lbl_select_an_option'|translate}}">
                            </p-dropdown>
                        </div>
                    </div>
                </div>



            </form>
            <div class="flex justify-content-end mr-17 p-2">
                <button pButton class="mr-2 mb-2 bg-color-gray default-text-light-black-color border-button"
                    [label]="'cancel' | translate" (click)="onCancel()"></button>
                <p-button class="mb-2 flex align-items-center justify-content-center" [label]="'label_save' | translate"
                    (click)="onAddUser()" [disabled]="profileForm.invalid"></p-button>
            </div>
            }
        </p-tabPanel>
    </p-tabView>
</div>
</div>
