<p-breadcrumb [model]="routePath" (click)="onBack()"></p-breadcrumb>
<div class="grid mt-1 details-div bg-white border-bottom-1 border-200 mr-0">
    <div class="col-8">
        <div class="col ml-2">
            <div class="inline-flex align-items-center">
                 <label class="document-type mr-3">{{ attributeValues['document_type'] ? attributeValues['document_type'] : '--' }}</label>
            </div>
        </div>
    </div>
    <div class="col-4 text-right align-content-center">
        <button pButton class=" border-400 bg-white text-color mr-1 pl-2 pr-2 pt-1 pb-1 mr-2 hover:surface-100" [label]="editBtnLabel" (click)="edit()">
            <i class="mi-lg mr-2 edit-pen edit_pencil"></i>
        </button>
    </div>
</div>
<div class="grid mt-2 ml-3 mr-3 overview-details">
    <div class="col-6 pl-0">
        <div class="card p-fluid border-0">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>lbl_details</label>
                </div>
            </div>
            @if (details) {

                @for (field of details.fields; track field; let i = $index) {
                    @if (i !== 0) {
                        <hr class="hr-line">
                    }
                    @if (field.attributeCode === 'issued_date' || field.attributeCode === 'expiry_date' || field.attributeCode === 'document_added_on' || field.attributeCode === 'added_on') {
                        <div class="grid">
                            <div class="col-6 pb-1 mt-1 mb-1">
                               <label class="detail-heading">{{ cs.getLabelValue(entityCode + '.fields.' + field.attributeCode + '.label', attributeLabels, field.attributeCode) }}</label>
                            </div>
                            <div class="col-6 pb-1 mt-1 mb-1">
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? (attributeValues[field.attributeCode] | date: dd_MMM_yyyy_FORMAT ) : '--' }}</label>
                            </div>
                        </div>
                    } @else {
                        <div class="grid">
                            <div class="col-6 pb-1 mt-1 mb-1">
                               <label class="detail-heading">{{ cs.getLabelValue(entityCode + '.fields.' + field.attributeCode + '.label', attributeLabels, field.attributeCode) }}</label>
                            </div>
                            <div class="col-6 pb-1 mt-1 mb-1">
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                            </div>
                        </div>
                    }
                }
            }
        </div>
    </div>
    <div class="col-6 pr-0">
        <div class="card p-fluid border-0 pb-2">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>lbl_files</label>
                </div>
            </div>
            <div class="grid flex align-items-center">
                @if (uploadedFiles.length > 0) {
                    @for (file of uploadedFiles; track file) {
                        <div class="col-6">
                            <div class="flex justify-content-between image-details w-full">
                                <div class="flex justify-content-start">
                                    <div class="flex align-items-center ml-2">
                                      @if(file?.file?.type !== 'application/pdf') {
                                        <img src="../../../../../../assets/images/mi-theme/light/basic/image.svg">
                                      }
                                      @else {
                                        <img src="../../../../../../assets/images/mi-theme/light/basic/pdf-logo.svg">
                                      }
                                    </div>
                                    <div class="flex  align-items-center ml-2">
                                      @if (entityName === 'vehicle_document' || entityName === 'driver_document') {
                                        <label>{{ file.file.name }}</label>
                                      } @else {
                                        <label>{{ file.file.name }} - {{ file.file.size }} bytes</label>
                                      }
                                    </div>
                                </div>
                                <div class="flex justify-content-between align-items-center ml-2">
                                    @if (entityName === 'vehicle_document' || entityName === 'driver_document') {
                                        <div class="cursor-pointer mr-1 mt-1" (click)="downloadDocument(file)">
                                            <i class="{{miIcons.DOWNLOAD}} wh-18 inline-block bg-default-text-blue"></i>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                }
            </div>
        </div>
    </div>
</div>
