import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTrimSpaces]',
  standalone: true,
})
export class TrimSpacesDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('blur') onBlur() {
    this.trimValue();
  }

  private trimValue() {
    const input = this.el.nativeElement as HTMLInputElement;
    const originalValue = input.value;
    const trimmedValue = originalValue.trim();

    if (originalValue !== trimmedValue) {
      this.renderer.setProperty(input, 'value', trimmedValue);
      const event = new Event('input', { bubbles: true });
      input.dispatchEvent(event);
    }
  }
}
