<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'SHIFT_MANAGEMENT', subMenuCode: 'MANAGE_GROUPS', pageCode: 'ALL', subPageCode: 'ADD' }">
  <div class="flex justify-content-end flex-wrap btn-with-links">
    <p-button class="flex align-items-center justify-content-center add-group-btn" icon="pi pi-plus" [label]="btnLabel" (click)="onAddGroups()" ></p-button>
  </div>
  <app-module-header [label]="btnLabel" (onBtnClick)="onAddGroups()" [moduleHeaderSecondaryBtnMenuItems]="moduleHeaderSecondaryBtnMenuItems"></app-module-header>
</div>
<div appAccessProvider [accessCode]="{ menuCode: 'SHIFT_MANAGEMENT', subMenuCode: 'MANAGE_GROUPS', pageCode: 'ALL', subPageCode: 'LIST' }">
<p-tabView [(activeIndex)]="activeIndex" (onChange)="tabViewChange($event)">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-14 mr-2"></i>
      <span translate>lbl_all</span>&nbsp;<span class="text-color-secondary">({{totalRecords}})</span>
    </ng-template>
    <ng-template pTemplate="body">
      @if(data){
        <app-mi-table
          [collectionOfColumns]="allColumns"
          [attributeLabels]="filterAttributeLabels"
          [data]="tableData"
          entityName="driver_group"
          [isLoading]="isLoading"
          (onPageChange)="onPageChange($event)"
          (itemPerPageChange)="itemPerPageChange($event)"
          [totalRecords]="totalRecords"
          [message]="deleteGroupMsg"
          [header]="deleteGroupHeaderMsg"
          (onDelete)="onGroupDelete($event)"
          (onRowSelection)="onRowSelect($event)"
          [filterList]="filterList"
          (onValueChange)="onFilterValueChange($event)"
          [filterFields]="filterFieldsArray"
          [label]="btnLabel"
          (onBtnClick)="onAddGroups()"
          [attributeData]="attributeData"
          (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
          (onBulkDataUpdateEvent)="onBulkDataUpdateEvent($event)"
          [bulkSelectionsItems] = "bulkSelectionsItems"
          [bulkUpdateLabel]="bulkUpdateLabel"
          [bulkDeleteLabel]="bulkDeleteLabel"
          (onSearchValueChange)="onSearchValueChanges($event)"
          (onUpdate)="onUpdateGroups($event)"
          [rowItems]="rowItems"
          (setCurrentRowData)="setCurrentRowData($event)"
          [visible]="visible"
          [bulkUpdateHeader]="bulkUpdateHeader"
          [reviewHeader]="reviewHeader"
          [entityNameToDisplay]="groupEntityName"
          [btnUpdate]="updateBtnLbl"
          >
        </app-mi-table>
      }
    </ng-template>
  </p-tabPanel>

</p-tabView>
</div>
<input hidden type="file" id="upload" (change)="onChange($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" #uploader>