<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'USER_MANAGEMENT', subMenuCode: 'MANAGE_USERS', pageCode: 'DETAILS', subPageCode: 'ADD' }">
<div class="user-details m-2 p-2">
    <div class="alignment-center w-full">
        <form [formGroup]="userForm">
            <div class="card border-none p-3">
                <h5 class="uppercase font-semibold" translate>manage_users.user_form.user_details</h5>
                <div class="grid mb-3">
                    <div class="col-6 md:col-12 lg:col-6 flex flex-column gap-1 input-group">
                        <div class="frm-label form-lbl">
                            <label for="userRole" class="font-semibold" translate>manage_users.user_form.user_role</label><span class="text-red-500 ml-1">*</span>
                        </div>
                        <div class="frm-control">
                            <p-dropdown [resetFilterOnHide]="true" [filter]="true" formControlName="userRole"
                                [options]="roleList" (onChange)="getname($event)"
                                id="user-role" [placeholder]="'manage_users.user_form.select_user_role' | translate"
                                class="multi-dropdown dropdwon full-width w-full custom-dropdown py-0" optionLabel="roleName"
                                optionValue="roleId"></p-dropdown>
                            <input formControlName="roleName" class="w-full" type="hidden" pInputText>
                            @if (userForm.controls.userRole.errors && (userForm.controls.userRole.dirty || userForm.controls.userRole.touched)) {
                                <div class="form-errors mt-1">
                                    <div [hidden]="!userForm.controls.userRole.errors.required" class="text-red-500" translate>
                                        manage_users.form_error.user_role_err
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div class="col-3 md:col-6 lg:col-3 flex flex-column gap-1 input-group">
                        <div class="frm-label form-lbl">
                            <label for="firstName" class="font-semibold" translate>manage_users.user_form.first_name</label><span class="text-red-500 ml-1">*</span>
                        </div>
                        <div class="frm-control">
                            <input formControlName="firstName" class="full-width w-full custom-form" type="text" [placeholder]="'manage_users.user_form.placeholder' | translate"  pInputText id="user-first-name" autocomplete="off"/>
                        </div>
                        @if (userForm.controls.firstName.errors && (userForm.controls.firstName.dirty || userForm.controls.firstName.touched)) {
                            <div class="form-errors">
                                <div [hidden]="!userForm.controls.firstName.errors.required" class="text-red-500" translate>
                                    manage_users.form_error.first_name_err
                                </div>
                                <div [hidden]="!userForm.controls.firstName.errors.pattern" class="text-red-500" translate>
                                    manage_users.form_error.firstname_pattern_err
                                </div>
                                @if (userForm.controls.firstName.errors.maxlength) {
                                    <div class="text-red-500" translate>
                                        manage_users.form_error.firstname_max
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div class="col-3 md:col-6 lg:col-3 flex flex-column gap-1 input-group">
                        <div class="frm-label form-lbl">
                            <label for="lastName" class="font-semibold" translate>manage_users.user_form.last_name</label><span class="text-red-500 ml-1">*</span>
                        </div>
                        <div class="frm-control">
                            <input formControlName="lastName" class="full-width w-full custom-form" type="text" [placeholder]="'manage_users.user_form.placeholder' | translate"  pInputText id="user-last-name" autocomplete="off"/>
                        </div>
                        @if (userForm.controls.lastName.errors && (userForm.controls.lastName.dirty || userForm.controls.lastName.touched)) {
                            <div class="form-errors">
                                <div [hidden]="!userForm.controls.lastName.errors.required" class="text-red-500" translate>
                                    manage_users.form_error.last_name_err
                                </div>
                                <div [hidden]="!userForm.controls.lastName.errors.pattern" class="text-red-500" translate>
                                    manage_users.form_error.lastname_pattern_err
                                </div>
                                @if (userForm.controls.lastName.errors.maxlength) {
                                    <div class="text-red-500" translate>
                                        manage_users.form_error.lastname_max
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div class="grid mb-3">
                    <div class="col-6 md:col-12 lg:col-6 flex flex-column gap-1 input-group">
                        <div class="frm-label form-lbl">
                            <label for="username" class="font-semibold" translate>manage_users.user_form.username</label><span class="text-red-500 ml-1">*</span>
                        </div>
                        <div class="frm-control">
                            <input appTrimSpaces formControlName="username" class="full-width w-full custom-form" type="text" [placeholder]="'manage_users.user_form.placeholder' | translate"  pInputText id="username" autocomplete="off"/>
                        </div>
                        @if (userForm.controls.username.errors && (userForm.controls.username.dirty || userForm.controls.username.touched)) {
                            <div class="form-errors">
                                <div [hidden]="!userForm.controls.username.errors.required" class="text-red-500" translate>
                                    manage_users.form_error.username_err
                                </div>
                                @if (userForm.controls.username.errors.maxlength) {
                                    <div class="text-red-500" translate>
                                        manage_users.form_error.username_max
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div class="col-3 md:col-6 lg:col-3 flex flex-column gap-1 input-group">
                        <div class="frm-label form-lbl">
                            <label for="email" class="font-semibold" translate>manage_users.user_form.email_address</label><span class="text-red-500 ml-1">*</span>
                        </div>
                        <div class="frm-control">
                            <input formControlName="email" class="full-width w-full custom-form" type="text" [placeholder]="'manage_users.user_form.placeholder' | translate"  pInputText id="user-email" autocomplete="off"/>
                        </div>
                        @if (userForm.controls.email.errors && (userForm.controls.email.dirty || userForm.controls.email.touched)) {
                            <div class="form-errors">
                                <div [hidden]="!userForm.controls.email.errors.required" class="text-red-500" translate>
                                    manage_users.form_error.email_required_err
                                </div>
                                <div [hidden]="!userForm.controls.email.errors.pattern" class="text-red-500" translate>
                                    manage_users.form_error.email_pattern_err
                                </div>
                            </div>
                        }
                    </div>
                    <div class="col-3 md:col-6 lg:col-3 flex flex-column gap-1 input-group">
                        <div class="frm-label form-lbl">
                            <label for="mobileNumber" class="font-semibold" translate>manage_users.user_form.mobile_number</label><span class="text-red-500 ml-1">*</span>
                        </div>
                        <div class="frm-control">
                            <div class="flex mobile-dropdown">
                                <p-dropdown #dropdown (onChange)="selectCountry($event)" [options]="countries" [(ngModel)]="selectedCountry" [filter]="true" filterBy="value" (onFilter)="onFilter($event)" [ngModelOptions]="{standalone : true}" optionLabel="name" [showClear]="false">
                                    <ng-template pTemplate="selectedItem">
                                        @if(selectedCountry) {
                                            <div class="flex align-items-center gap-2">
                                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountry?.code?.toLowerCase()" class="width-18"/>
                                                <div>{{ selectedCountry.value }}</div>
                                            </div>
                                        }
                                    </ng-template>
                                    <ng-template let-country pTemplate="item">
                                        <div class="flex align-items-center gap-2">
                                            <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" class="width-18"/>
                                            <div>{{ country.value }}</div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="filtericon">
                                        <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
                                    </ng-template>
                                </p-dropdown>
                                <input pInputText type="text" id="user-mobile" class="w-full input-mobile" (input)="selectCountry($event)" formControlName="contactNumber" pKeyFilter="int" />
                            </div>
                        </div>
                        @if (userForm.controls.contactNumber.errors && (userForm.controls.contactNumber.dirty || userForm.controls.contactNumber.touched)) {
                            <div class="form-errors">
                                <div [hidden]="!userForm.controls.contactNumber.errors.required" class="text-red-500" translate>
                                    manage_users.form_error.contact_required_err
                                </div>
                                <div [hidden]="!userForm.controls.contactNumber.errors.pattern" class="text-red-500" translate>
                                    manage_users.form_error.contact_pattern_err
                                </div>
                                @if (userForm.controls.contactNumber.errors.maxlength || userForm.controls.contactNumber.errors.minlength) {
                                    <div class="text-red-500" translate>
                                        lbl_mobile_number_max_length
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="flex save-button justify-content-end mt-3">
        <button type="button" pButton [label]="'manage_users.cancel' | translate" (click)="cancelUserForm()"
            class="mr-2 text-color-secondary surface-ground border-button"
            id="cancelBtnId"></button>
        
        <button type="button" pButton id="saveBtnId"
            [label]="userId ? ('manage_users.update' | translate) : ('manage_users.save' | translate)"
            (click)="saveUser()" [disabled]="userForm.invalid"
            class="pelement blue-btn  shadow-none"></button>
    </div>
</div>
</div>
