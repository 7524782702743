

  @if (miImage) {
    <p-image 
      [src]="miImage" 
      [previewImageSrc]="miImage"
      [alt]="'miImage'" 
      [width] = "miWidth"
      [height] = "miHeight"
      class="shared-img vertical-align-middle border-round-sm event-img mr-2"
      [preview]="true" />
  } @else {
    <div class="no-image inline-flex mr-2">
      <img [src]="defaultLoaderImg" class="vertical-align-middle border-round-sm mr-2" />
    </div>
  }

