import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { AppSettings } from '../../../app.settings';
import { KeyFilterModule } from 'primeng/keyfilter';

@Component({
  selector: 'app-checkbox-with-add-new',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CheckboxModule, ButtonModule, TranslateModule, DialogModule, InputTextModule, KeyFilterModule],
  templateUrl: './checkbox-with-add-new.component.html',
  styleUrl: './checkbox-with-add-new.component.scss'
})
export class CheckboxWithAddNewComponent implements OnInit {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  visible: boolean = false;
  addFeatureValue: string;
  checked: boolean = true;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  featuresArray: any[] = [];
  blockChars = AppSettings.BLOCK_CHARS;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.featuresArray = this.formName.controls[this.field.attributeCode].value;
    if (this.featuresArray) {
      this.setAddMissingElements(this.featuresArray);
    }
    this.formName.controls[this.field.attributeCode].setValue(this.featuresArray);
  }

  setAddMissingElements(attributeValues) {
    const existingKeys = new Set(this.field.presetValues.map(item => item.labelKey));

    const missingObject = attributeValues?.filter(name => !existingKeys.has(name)).map(name => ({
      labelKey: name,
      labelValue: name,
    }));

    this.field.presetValues = [...this.field.presetValues, ...missingObject];
  }

  valueChange(event, labelKey) {
    if (event.checked) {
      if (!this.featuresArray) {
        this.featuresArray = [];
      }
      this.featuresArray.push(labelKey);
    } else {
      const index = this.featuresArray?.findIndex(ele => ele === labelKey);
      this.featuresArray.splice(index, 1);
    }
    this.formName.controls[this.field.attributeCode].setValue(this.featuresArray);
  }

  onAddNewFeatures() {
    this.field.presetValues.push({
      labelKey: this.addFeatureValue,
      labelValue: this.addFeatureValue
    });
    this.visible = false;
    this.addFeatureValue = '';
  }

  onShowDialog() {
    this.visible = true;
  }


  onHide() {
    this.visible = false;
    this.onToggleOverLay.emit();
    this.addFeatureValue = '';
  }

  isValidInput(): boolean {
    return /^[^\s!@#$%^&*()_.`~+\-=]+.*$/.test(this.addFeatureValue);
  }
}
