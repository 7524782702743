import { JsonPipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { AppSettings } from '../../../../app.settings';
import { CommonBindingDataService } from '../../../../services/common-binding-data.service';


@Component({
  selector: 'app-mi-year-filter',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, InputTextModule, JsonPipe, CheckboxModule, DividerModule, NgClass],
  templateUrl: './mi-year-filter.component.html',
  styleUrl: './mi-year-filter.component.scss'
})

export class MiYearFilterComponent {
  checked = [];
  selectedCategories;
  @Input() attributeValues;
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  searchValue: string = '';
  selectedControl: string[] = [];
  yearList = this.years();
  inputPlaceholder: string;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }


  ngOnInit() {
    if (this.attributeValues) {
      this.selectedControl = this.attributeValues;
      if (this.yearList?.length === this.selectedControl?.length) {
        this.checked = ['val1'];
      }
      this.formName.controls[this.field.attributeCode].setValue(this.attributeValues);
      this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    }
    this.inputPlaceholder = this.cs.getLabel('placeholder_search_by') + ' ' + this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.label', this.attributeLabels, this.field.attributeCode);
  }

  onChange(event) {
    if (event.checked.length > 0) {
      const values = [];
      this.yearList.map(value => {
        values.push(value.labelValue);
      });
      this.selectedControl = values;
      this.formName.controls[this.field.attributeCode].setValue(values);
      this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    } else {
      this.selectedControl = [];
      this.formName.controls[this.field.attributeCode].setValue([]);
      this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    }
  }

  valueChange() {
    this.formName.controls[this.field.attributeCode].setValue(this.selectedControl);
    this.formName.controls[this.field.attributeCode].updateValueAndValidity();
  }

  years() {
    const back = AppSettings.DATE_YEAR_PREVIOUS_YEAR_LIMIT;
    const years = []
    const year = new Date().getFullYear();
    for (let i = 0; i < back; i++) {
      years.push({
        labelKey: year - i,
        labelValue: year - i,
      })
    }
    return years
  }

  onSearch(event) {
    const value = event.target.value;
    this.searchValue = value;
    this.filterValues();
    if (value.length >= 3) {
      this.onSearchValueChange.emit(value);
    } else if (value.length === 0) {
      this.yearList = this.years();
      this.onSearchValueChange.emit(value);
    }
  }

  filterValues() {
    const searchLower = this.searchValue.toLowerCase();
    this.yearList = this.years().filter(item => {
      const labelKey = item.labelKey ? item.labelKey.toString().toLowerCase() : '';
      const labelValue = item.labelValue ? item.labelValue.toString().toLowerCase() : '';

      return labelKey.includes(searchLower) || labelValue.includes(searchLower);
    });
  }

  clearSearch() {
    this.searchValue = "";
    this.filterValues();
    this.onSearchValueChange.emit('');
    this.yearList = this.years();
  }
}
